import { Component, OnInit } from '@angular/core';
import { SiteContentDetail } from '../../models/siteContent';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
  selector: 'reviews-modal',
  templateUrl: './reviews-modal.component.html',
  styleUrls: ['./reviews-modal.component.scss']
})
export class ReviewsModalComponent implements OnInit {

  sitecontent: SiteContentDetail;
  constructor(private siteContentService: SiteContentService) { }

  ngOnInit() {
    this.sitecontent = this.siteContentService.getSiteContentData();
  }
}
