import { Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { PaypalService } from '../../../service/paypal.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { OrderDetails, OrderItem } from 'src/app/modules/auth/models/order-details';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import resolveCurrencyBySymbol from 'src/app/shared/helper/resolve-currency-by-symbol';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';

@Component({
	selector: 'app-paypal-ic-complete',
	templateUrl: './paypal-ic-complete.component.html',
	styleUrls: ['./paypal-ic-complete.component.scss']
})
export class PaypalIcCompleteComponent implements OnInit, OnDestroy {
	sitecontent: SiteContentDetail;
	payerID: string;
	token: string;
	orderId: string;
	imageLoading = "ClientApp/dist/assets/shared/images/placeholder-loading-small.gif";
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private sharedService: SharedService,
		private paypalService: PaypalService,
		private route: ActivatedRoute,
		private injector: Injector,
		private siteContentService: SiteContentService, 
		private authService: AuthService, 
		private attraqtService: AttraqtService) {

		$("body").removeClass();
		$("body").addClass("checkout ng-scope");
	}

	ngOnInit() {
		this.subscribeToState();
		this.getSiteContent();
		this.route.queryParamMap.subscribe(queryParams => {
			this.payerID = queryParams.get("PayerID");
			this.token = queryParams.get("token");
			this.orderId = queryParams.get("OrderId");

			this.paypalService.completePayment(this.token, this.payerID, this.orderId).subscribe(data => {
				if (data && data.orderId) {
					const routerService = this.injector.get(Router);
					const ngZone = this.injector.get(NgZone);
					this.authService
							.getWebOrderDetailsById(data.orderId)
							.subscribe((orderDetails: OrderDetails) => {
								if (orderDetails.Order.OrderItems.length && this.facetFilterState.attraqtPLPEnabled) {
									orderDetails.Order.OrderItems.forEach(
										(orderItem: OrderItem) => {
											this.attraqtService.sendActivity(
												new AttraqtActivityBuilder(
													AttraqtActivityActionEnum.Purchase
												)
													.setTarget({
														product: orderItem.ProductId.toString(),
													})
													.setMetadata({
														orderId: data.orderId.toString(),
														price: orderItem.TotalPrice * 100,
														currency: resolveCurrencyBySymbol(
															orderDetails.Order.CurrencySymbol
														),
														quantity: orderItem.Quantity,
														locale: localStorage.getItem(NAVIGATOR_LANGUAGE),
													})
													.build()
											);
										}
									);
								}
							});
					ngZone.run(() => {
						routerService.navigate(['../../checkout/receipt', data.orderId], { relativeTo: this.route });
					});
				}
			})
		})
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
