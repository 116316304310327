import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';

@Component({
  selector: 'social-footer,[social-footer]',
  templateUrl: './social-footer.component.html',
  styleUrls: ['./social-footer.component.scss']
})
export class SocialFooterComponent implements OnInit {
  @Input() siteData: SiteContentDetail;
  constructor(
    private matIconRegistry: MatIconRegistry, private domSanitzer: DomSanitizer
  ) { 

      this.matIconRegistry.addSvgIconLiteral(
      'twitter_x',
      this.domSanitzer.bypassSecurityTrustHtml('<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>')
      );
  }

  ngOnInit() {

  }

}
