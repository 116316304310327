import { Pipe, PipeTransform } from '@angular/core';
import { Store, StoreImage } from '../models/stores-vm';
//import { Store } from 'src/app/model/store';

@Pipe({
    name: 'storeImageFilter',
    pure: false
})
export class StoreFilterImagePipe implements PipeTransform {
    transform(storeImages: StoreImage[]): StoreImage[] {
        if (storeImages && storeImages.length > 0) {
            storeImages = storeImages.filter(x => x.Active).sort((n1, n2) => {
                if (n1.SortOrder > n2.SortOrder) {
                    return 1;
                }

                if (n1.SortOrder < n2.SortOrder) {
                    return -1;
                }

                return 0;
            });
        }
        if (storeImages && storeImages.length > 0) {
         return storeImages;
        }
        return [] as StoreImage[];
    }
}