import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
	selector: 'app-subscribe-form,[app-subscribe-form]',
	templateUrl: './subscribe-form.component.html',
	styleUrls: ['./subscribe-form.component.scss']
})
export class SubscribeFormComponent implements OnInit {
	signUpNewsLetterForm: UntypedFormGroup;
	submitted = false;
	@Input() sitecontent: SiteContentDetail;
	LpAppSetting: LpAppSetting;

	constructor(private fb: UntypedFormBuilder, private router: Router,private sharedService: SharedService) { }

	ngOnInit() {
		this.getLpAppSetting();
	}
	
	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.initiateForm();
	}

	initiateForm(){
		this.signUpNewsLetterForm = this.fb.group({
			email: ['', [Validators.required, Validators.email, Validators.pattern(this.LpAppSetting.RegexLib.Email)]]
		});
	}

	onSubmit() {

		localStorage.setItem("userEmail", this.signUpNewsLetterForm.controls['email'].value);
		this.signUpNewsLetterForm.reset();
		this.router.navigate(['subscribe']);
	}
}
