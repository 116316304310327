import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { ShippingOptions, ShippingVM } from '../models/shippingoptions';

declare global {
	interface Window {
	}
}

@Injectable({
	providedIn: 'root'
})
export class AmazonpayService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	initialiseLogin(clientId, isSandbox) {
		window.amazon['Login'].setClientId(clientId);

		if (isSandbox) {
			window.amazon['Login'].setSandboxMode(true);
		}

		window.amazon['Login'].setLanguage(document.documentElement.lang);
	}

	initialiseOrder(reference, accessToken): Observable<any> {
		return this.http.post(this.environmentService.environment.amazonUrl + 'InitialiseOrder',
			{
				"reference": reference,
				"access_token": accessToken,
				//"isAlreadyLoggedIn": true
			}, this.httpOptions);
	}

	getShippingAddress(reference): Observable<any> {
		return this.http.post(this.environmentService.environment.amazonUrl + 'GetShippingAddress',
			{
				"reference": reference,
			}, this.httpOptions);
	}

	getOrderConstraints(reference) {
		// return httpService.processRequest({
		//     method: "POST",
		//     url: "/Amazon/GetOrderConstraints",
		//     data: {
		//         reference: reference
		//     },
		//     headers: { "Content-Type": "application/json" }
		// });
	}

	confirmPayment(reference, isGift): Observable<any> {
		return this.http.post(this.environmentService.environment.amazonUrl + 'ConfirmPayment',
			{
				"reference": reference,
				"isGift": isGift
			}, this.httpOptions);
	}

	confirmOrder(reference): Observable<any> {
		return this.http.post(this.environmentService.environment.amazonUrl + 'ConfirmOrder',
			{
				"reference": reference,
			}, this.httpOptions);
	}

	setShippingMethod(reference, shippingId): Observable<any> {
		return this.http.post(this.environmentService.environment.amazonUrl + 'SetShipping',
			{
				"reference": reference,
				"shippingId": shippingId
			}, this.httpOptions);
	}

	getShippingOptions(shippingModel: ShippingOptions): Observable<ShippingVM[]> {
		return this.http.post<ShippingVM[]>(this.environmentService.environment.webserviceUrl + 'GetShippingOptions', shippingModel, this.httpOptions).pipe(
			map(response => response)
		);
	}

	amazonCheckout(accessToken, authenticationStatus, orderReference): Observable<any> {

		return this.http.post(this.environmentService.environment.amazonUrl + 'Checkout',
			{
				"access_token": accessToken,
				"authenticationStatus": authenticationStatus,
				"orderReference": orderReference,
			}, this.httpOptions);

		//return this.http.get<any>(this.environmentService.environment.amazonUrl + `Checkout?access_token=${accessToken}&authenticationStatus=${authenticationStatus}&orderReference=${orderReference}`, this.httpOptions);
	}
}
