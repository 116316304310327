import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsFromCategoriesCriteria } from 'src/app/model/content-page-row-viewmodel';
import * as $ from 'jquery';
import { LpAppSetting } from 'src/app/model/lpapp-setting.js';
import { SharedService } from 'src/app/shared/shared.service';
import { SearchService } from '../../../shared/components/header/search/search.service';

@Component({
	selector: 'app-search-products',
	templateUrl: './search-products.component.html',
	styleUrls: ['./search-products.component.scss']
})
export class SearchProductsComponent implements OnInit {
	productsFromCategoriesCriteria: ProductsFromCategoriesCriteria;
	LpAppSetting: LpAppSetting;

	constructor(
		private route: ActivatedRoute,
		private sharedService: SharedService,
		public searchService: SearchService) {
	}

	ngOnInit() {
		this.getLpAppSetting();
		localStorage.removeItem('filteredList');
		localStorage.removeItem('filterFacestsData');

		this.route.queryParams
			.subscribe(params => {
				if (params && params.q) {
					this.productsFromCategoriesCriteria = {} as ProductsFromCategoriesCriteria;
					this.productsFromCategoriesCriteria.searchText = params.q;
				} else {
					this.searchService.openSearchModal();
				}
			});
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}
}
