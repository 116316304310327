export enum PaymentStatus {
    UnPaid = 1,
    Paid = 2,
    PaymentFailed = 3,
    Cancelled = 4,
    Ineligible = 5,
    Pending = 6
}

export enum PaymentTypes {
    Amazon = 1,
    Barclays = 2,
    EBay = 3,
    GlobalCollect = 4,      // No longer supported
    GoogleCheckout = 5,     // No longer supported
    Paypal = 6,
    Card = 7,               // No longer supported
    YesPay = 8,             // Kiosk
    SagePayServer = 9,
    Sofort = 10,
    SagePayServerToken = 11,
    Klarna = 12,
    PrePaid = 13,
    Pentagon = 14,
    Mirakl = 15,
    Borderfree = 16,
    SagePayPI = 17,
    OneIota = 18,
    AmazonMWS = 19,
    KlarnaV3 = 20,
    Adyen = 21
}