import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { WebsiteContentDetail } from "src/app/shared/models/websiteContent";
import { WebsiteService } from "src/app/shared/service/website.service";

@Injectable()
export class WebsiteResolver {

  constructor(
     
    private restRequest:WebsiteService

  ){

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WebsiteContentDetail> {
     // In my case i am using custom service for getting rest calls but you can use simple http.get()...
    return this.restRequest.getWebsites();

  }
}