import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ViewportService } from 'src/app/shared/viewport.service';

@Component({
	selector: 'app-default',
	templateUrl: './default.component.html',
	styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

	isVisible = false;
	isFooterVisible = false;

	constructor(@Inject(PLATFORM_ID)
	private platformId: any) {

	}

	getNotification(evt) {
		this.isVisible = evt;
	}

	getFooterNotification(evt) {
		this.isFooterVisible = evt;
	}

	ngOnInit() {
	}

	isBrowser() {
		return isPlatformBrowser(this.platformId);
	}
}
