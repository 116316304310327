import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

import { SharedService } from "src/app/shared/shared.service";
import { LpAppSetting } from "src/app/model/lpapp-setting";

@Injectable()
export class LpAppSettingsResolver {
	constructor(private sharedService: SharedService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<LpAppSetting> {
		return this.sharedService.getLpAppSettings();
	}
}
