import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AddScriptStyleLinks } from '../../../../../assets/shared/tracking';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
	showSignInForm: boolean = true;
	showCreateInForm: boolean = false;
	showForgotPassInForm: boolean = false;
	activeLink = '';
	LpAppSetting: LpAppSetting;
	classlist = "fixed-header mask-hide ng-scope ";
	navLinks = [
		{ path: '/account/signin', label: 'signin' },
		{ path: '/account/register', label: 'register' },
		{ path: '/account/forgottenpassword', label: 'forgottenpassword' },
		{ path: '/account/resetpassword', label: 'resetpassword' }
	];

	constructor(
		private router: Router,
		private scrollService: ScrollService,
		private sharedService: SharedService) {

		$("body").removeClass();
		$("body").addClass(this.classlist);
		router.events
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === 'popstate') {

					this.scrollService.windowScrollTo(0, 0);
				}
			});
	}

	ngOnInit() {
		this.getLpAppSetting();
		this.navLinks.filter((item, i) => {
			if (item.path == this.router.url) {
				this.activeLink = this.navLinks[i].label;
			}
		})
		if (this.activeLink == "signin") {
			this.changeToSignIn();
		} else if (this.activeLink == "register") {
			this.changeToCreateForm();
		} else {
			this.changeToForgotForm();
		}
		this.scrollService.windowScrollTo(0, 0);
	}

	changeToCreateForm() {
		this.showCreateInForm = true;
		this.showForgotPassInForm = false;
		this.showSignInForm = false;
		localStorage.setItem('createForm', 'true');
		localStorage.removeItem('forgotForm');
	}
	changeToForgotForm() {
		this.showCreateInForm = false;
		this.showForgotPassInForm = true;
		this.showSignInForm = false;
		localStorage.setItem('forgotForm', 'true');
		localStorage.removeItem('createForm');
	}
	changeToSignIn() {
		this.showCreateInForm = false;
		this.showForgotPassInForm = false;
		this.showSignInForm = true;
	}
	showSentMessage() {
		this.showCreateInForm = false;
		this.showForgotPassInForm = false;
		this.showSignInForm = false;
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.addBodyClass();
		setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
	}
	
	addBodyClass() {
		if (this.LpAppSetting) {
			if (this.LpAppSetting.UserSettings.PromoLineBarHeader == "") {
				this.classlist += "no-promo-text ";
				$("body").removeClass();
				$("body").addClass(this.classlist);
			}

		}
	}
}
