import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appImageOrientation]'
})
export class ImageOrientationDirective implements AfterViewInit,AfterContentChecked{
  el;
  @Input() lazy = '';
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
   let isLazyLoaded = this.lazy !== undefined && this.lazy !== "";
  let width = this.el.nativeElement.offsetWidth;
  let height = this.el.nativeElement.offsetHeight;

  if (isLazyLoaded || width == 0 || height == 0) {
      
  } else {
      this.setImageClass(this.el.nativeElement, width, height);
  }

  }
  setImageClass(el,width,height){
  if (width == 0 || height == 0) {
      return;
  }

  // Lazy loaded images may have two onloads, one for the placeholder, one for the image
  this.removeClass("img-landscape",el);
  this.removeClass("img-portrait",el);

  if (width > height) {
      this.addClass("img-landscape",el);
  } else {
      this.addClass("img-portrait",el);
  }

  }
  ngAfterContentChecked(){
    let width = this.el.nativeElement.offsetWidth;
    let height = this.el.nativeElement.offsetHeight;
    this.setImageClass(this.el.nativeElement, width, height);
  }
}
