import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountComponent } from './components/account/account.component';
import { AddressbookComponent } from './components/addressbook/addressbook.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SafePasswordTipsComponent } from './components/safe-password-tips/safe-password-tips.component';
import { SavedCardsComponent } from './components/saved-cards/saved-cards.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { WebOrdersComponent } from './components/web-orders/web-orders.component';



@NgModule({
	declarations: [AccountComponent, SignInComponent, ForgotPasswordComponent,
		RegisterComponent, SafePasswordTipsComponent,
		MyAccountComponent,
		OrderDetailsComponent,
		PersonalDetailsComponent,
		WebOrdersComponent,
		AddressbookComponent,
		SavedCardsComponent,
		ResetPasswordComponent,
		SignOutComponent],
	imports: [
		CommonModule, ReactiveFormsModule, FormsModule, NgxCaptchaModule, SharedModule, DataTablesModule
	]
})
export class AuthModule { AccountComponent }
