import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appAskAQuestion]'
})
export class AskAQuestionDirective {

  el;
  constructor(el:ElementRef,private renderer2:Renderer2) {
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  @HostListener("click", ['$event'])
  onClick(event) {
    this.addClass('quickview-loading',this.el.nativeElement)
   this.showModal();
  }
  showModal(){
    alert('ss');
    this.removeClass('quickview-loading',this.el.nativeElement)
  }
}
