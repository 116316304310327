import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TitleContentVM } from 'src/app/model/title-content-viewmodel';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
	selector: '[title-content],title-content',
	templateUrl: './title-content.component.html',
	styleUrls: ['./title-content.component.scss']
})
export class TitleContentComponent implements OnInit {
	@Input()
	titleContentVM: TitleContentVM;

	readMore: boolean = false;
	siteContent: SiteContentDetail;

	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
	}

	getPosteDate(PublishedDate: string, ParentWebPage: string): string {
		let content = '';

		if (PublishedDate) {
			content += PublishedDate + ' / ';
		}

		if (ParentWebPage) {
			content += ParentWebPage;
		}

		return content;
	}

	clickReadMore(e) {

		const element: HTMLElement = e.target;

		if (element.textContent.toLowerCase().includes("read")) {
			this.readMore = !this.readMore;
			e.preventDefault();
		}
	}

	getPublishedDate(titleContent) {
		let content = '';

		if (titleContent && titleContent.PublishedDate) {
			content = titleContent.PublishedDate;
		}

		if (titleContent && titleContent.ParentWebPage) {
			content += ' / ' + titleContent.ParentWebPage;
		}

		return content;
	}

	readmoreClicked() {
		this.readMore = true;
	}

	isNullOrWhitespace(input): boolean {

		if (typeof input === 'undefined' || input == null) return true;

		return input.replace(/\s/g, '').length < 1;
	}
}
