import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { HomeService } from 'src/app/modules/Home/home.service';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { CourierEnum } from '../../enums/courierEnum';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingToClickAndCollect, UpdateBasketShipping } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { SharedService } from '../../shared.service';

@Component({
	selector: 'click-and-collect,[click-and-collect]',
	templateUrl: './click-and-collect.component.html',
	styleUrls: ['./click-and-collect.component.scss']
})

export class ClickAndCollectComponent implements OnInit {
	@Input() sitecontent: SiteContentDetail;
	@Input() regExLibrary: RegExLibrary;
	@Input() label: Label;
	@Input() deliveryDetail: any;
	@Input() currentCulture: string;

	@Output() refreshShippingOption = new EventEmitter();
	@Output() selectStoreChanged = new EventEmitter();

	findPostCodeForm: UntypedFormGroup;
	frmcontinueToPmt: UntypedFormGroup;
	courierEnum = CourierEnum;
	//postCode = '';
	collectionModel: any;
	nearestStore: any;
	hncStoreExclNearest = [];
	restOfTheStores = [];
	toggle = false;
	selectedStore: any;
	IsGift = false;
	isLoading = false;
	basketTranslation = {};
	basket = {};
	clickedMore: boolean = false; // Button show More Stores
	storeList: any[] = [];
	storeTranslation = {};
	selectedShippingId = null;
	ukCountryId = 1;
	countryId = this.ukCountryId;
	PhoneCountry = {};
	selectedCountryCode = '';
	countries = {};
	translation = {};
	searchResultStores: any[];
	cutoffStoreKm = 8.0; // 5 mile criteria which is 8 km
	submitPostCodeForm: boolean = false;
	submitcontinueToPmtControls: boolean = false;
	updateBasketShipping: UpdateBasketShipping;
	shippingToClickAndCollect: ShippingToClickAndCollect;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private fb: UntypedFormBuilder,
		private homeService: HomeService,
		private commonService: DefaultService,
		private sharedService: SharedService,
		private cdr: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.initiatePostalForm();
		this.initiateformcontinueToPmt();
	}

	ngAfterContentChecked() {
		this.cdr.detectChanges();
	}

	get frmcontinueToPmtControls() {
		return this.frmcontinueToPmt.controls;
	}

	get postCodeFormControls() {
		return this.findPostCodeForm.controls;
	}

	initiateformcontinueToPmt() {
		let defaultTitle = ''

		if (this.deliveryDetail && this.deliveryDetail.TitleList) {
			for (var i = 0; i < this.deliveryDetail.TitleList.length; i++) {
				if (i === 0) {
					defaultTitle = this.deliveryDetail.TitleList[0].Name;
				}
			}
		}

		this.frmcontinueToPmt = this.fb.group({
			firstName: [this.deliveryDetail.FirstName ? this.deliveryDetail.FirstName : '', [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
			lastName: [this.deliveryDetail.LastName ? this.deliveryDetail.LastName : '', [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
			title: [this.deliveryDetail.Title ? this.deliveryDetail.Title : defaultTitle, Validators.required],
			phoneNumber: [this.deliveryDetail.PhoneNumber ? this.deliveryDetail.PhoneNumber : '', [
				Validators.required,
				Validators.minLength(5), Validators.maxLength(20),
				Validators.pattern(this.regExLibrary.Phone)
			]],
			phoneCountryId: [this.deliveryDetail.PhoneCountryId ? this.deliveryDetail.PhoneCountryId : ''],

			countryCode: ['GB'],
			isGift: [false],
			selectedStore: [''],
			storeReference: [''],
			courier: [''],
			understandRequirements: [null, Validators.required],
		});
		//this.cdr.detectChanges();
	}

	continueToPayment() {

		this.submitcontinueToPmtControls = true;

		if (this.selectedStore) {
			if (this.selectedStore.Courier) {
				this.frmcontinueToPmt.controls['courier'].setValue(this.selectedStore.Courier);
			}

			if (this.selectedStore.SelectStoreReference) {
				this.frmcontinueToPmt.controls['selectedStore'].setValue(this.selectedStore.SelectStoreReference);
			}

			if (this.selectedStore.StoreReference) {
				this.frmcontinueToPmt.controls['storeReference'].setValue(this.selectedStore.StoreReference);
			}
		}

		if (this.frmcontinueToPmt.controls['understandRequirements'] && !this.frmcontinueToPmt.controls['understandRequirements'].value) {
			return;
		}

		if (this.frmcontinueToPmt.invalid) {
			return;
		}

		this.homeService.saveClickAndCollectAddress(this.frmcontinueToPmt.value).subscribe(data => {
			this.router.navigate(['checkout/payment']);
		})
	}

	initiatePostalForm() {
		this.findPostCodeForm = this.fb.group({
			postcode: ['', [Validators.required, Validators.maxLength(10)]],
		});
	}

	submitfindPostCodeForm() {
		this.submitPostCodeForm = true;

		if (this.findPostCodeForm.invalid) {
			return;
		}

		this.clickedMore = false;

		this.homeService.getPostcodeDetails(this.findPostCodeForm.value).subscribe(data => {
			localStorage.setItem("ccpostcode", this.findPostCodeForm.controls['postcode'].value);
			this.loadStores(data.desc);
		});
	}

	selectStoreClicked(storeValue) {
		this.selectedStore = storeValue;
		this.selectStoreChanged.emit(storeValue);
		this.updateShippingOption();
	}

	updateShippingOption() {
		this.updateBasketShipping = {} as UpdateBasketShipping;

		let Doddleenum = this.courierEnum.Doddle;
		let CollectPlusenum = this.courierEnum.CollectPlus;
		this.deliveryDetail.Basket.ShippingTotal = this.selectedStore.ShippingPrice;

		if (this.selectedStore.Courier == CollectPlusenum || this.selectedStore.Courier == Doddleenum) {

			if (this.deliveryDetail && this.deliveryDetail.Basket && this.deliveryDetail.Basket.Shipping) {
				this.updateBasketShipping.ShippingId = this.deliveryDetail.Basket.Shipping.Id
			}

			this.updateBasketShipping.CountryId = this.deliveryDetail.CountryId;
			this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;
			this.shippingToClickAndCollect.price = this.selectedStore.ShippingPrice;
			this.shippingToClickAndCollect.shippingText = this.sitecontent.Collection;
			this.shippingToClickAndCollect.showdeliveryoption = false;
			this.refreshShippingOption.emit(this.shippingToClickAndCollect);
			this.homeService.updateBasketShippingOption(this.updateBasketShipping);
		}
	}

	loadStores(data) {
		//var model = data.Details;

		var stores = data.Stores;

		if (!data.Stores) {
			return;
		}

		this.selectedStore = {};

		$.each(stores, function (idx, store) {
			var matchingShippingOption = $.grep(data.ShippingOptions, function (x: any) {
				return x.Courier === store.Courier;
			});

			if (matchingShippingOption[0]) {
				store.ShippingOption = matchingShippingOption[0];
			}
		});

		stores.forEach(x => {
			x.collectplus = x.ShippingPrice != 0.00 ? 'collectplus' : '';
		});

		this.storeList = [];

		stores.forEach(val => {
			this.storeList.push({
				Active: false,
				City: !val.Address.City ? '' : val.Address.City,
				Country: !val.Address.Country ? '' : val.Address.Country,
				Distance: null,
				FullAddress: (!val.Address.StreetAddress1 ? '' : val.Address.StreetAddress1) + ' ' + (!val.Address.StreetAddress2 ? '' : val.Address.StreetAddress2),
				FullUrl: val.SeoUrl,
				Latitude: val.Latitude,
				Longitude: val.Longitude,
				MoreInfoText: "More info",
				Name: val.StoreName,
				OpeningTimes: null,
				PhoneNumber: !val.Address.Phone ? '' : val.Address.Phone,
				StoreId: val.StoreReference,
				collectplus: val.ShippingPrice != 0.00 ? 'collectplus' : '',
				selectedStoreReference: ""
			});
		});

		var couriers = stores.map(a => a.Courier).filter((value, index, self) => self.indexOf(value) === index);

		let HawesAndCurtisenum = this.courierEnum.HawesAndCurtis;
		let HawesAndCurtisGermanyenum = this.courierEnum.HawesAndCurtisGermany;

		var handcCourier = $.grep(couriers, function (x) {
			return x == HawesAndCurtisenum || x == HawesAndCurtisGermanyenum;
		});

		var restOfTheCourier = $.grep(couriers, function (x) {
			return x != HawesAndCurtisenum && x != HawesAndCurtisGermanyenum;
		});

		var handcStores = $.grep(stores, function (x: any) {
			return $.inArray(x.Courier, handcCourier) === 0;
		});

		var resOfTheStores = $.grep(stores, function (x: any) {
			return $.inArray(x.Courier, restOfTheCourier) === 0;
		});

		var sortedHandCStores = handcStores.sort(function (a: any, b: any) {
			return a.Distance - b.Distance;
		});

		this.nearestStore = sortedHandCStores[0];

		this.hncStoreExclNearest = this.arrayRemove(sortedHandCStores, this.nearestStore);
		this.restOfTheStores = resOfTheStores;

		$.each(this.hncStoreExclNearest, function (idx, val) {
			val.index = 'name' + idx;
			val.checked = '';
		});

		this.searchResultStores = this.hncStoreExclNearest;

		let nearestOtherStoreKm = 9999;
		let nearestCollectPlusKm = 9999;

		$.each(this.hncStoreExclNearest, function (idx, val) {
			if (val.Distance < nearestOtherStoreKm) {
				nearestOtherStoreKm = val.Distance;
			}
		});

		$.each(this.restOfTheStores, function (idx, val) {
			if (val.Distance < nearestCollectPlusKm) {
				nearestCollectPlusKm = val.Distance;
			}
		});

		// Switch on CollectPlus stores since closest to customers (5 mile criteria which is 8 km)
		if (nearestCollectPlusKm < nearestOtherStoreKm && nearestOtherStoreKm > this.cutoffStoreKm) {
			this.toggle = true;
			this.showCollectPlusStore();
		} else {
			this.toggle = false;
		}
	}

	toggleMoreShop() {
		this.clickedMore = !this.clickedMore;
	}

	isEmptyObj(obj) {
		return $.isEmptyObject(obj);
	}

	hasCollectPlusStores() {
		var clone = this.hncStoreExclNearest;
		var allStores = this.arrayAdd(clone, this.restOfTheStores);

		//Sort based on distance
		allStores.sort(function (a, b) {
			return a.Distance - b.Distance;
		});

		function checkClickAndCollectStore(store) {
			return store.collectplus == 'collectplus';
		}

		return allStores.some(checkClickAndCollectStore);
	}

	showCollectPlusStore() {
		this.searchResultStores = [];

		if (this.toggle === true) {
			var clone = this.hncStoreExclNearest;
			this.searchResultStores = this.arrayAdd(clone, this.restOfTheStores);
		} else {
			this.searchResultStores = this.hncStoreExclNearest;
		}

		this.searchResultStores.sort(function (a, b) {
			return a.Distance - b.Distance;
		});
	}

	arrayAdd(arr1, arr2) {
		$.each(arr2, function (idx, value) {
			arr1.push(value);
		});

		return arr1;
	}

	arrayRemove(arr, value) {

		return arr.filter(function (ele) {
			return ele != value;
		});
	}
}
