import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogPostVM } from 'src/app/model/blogpost-viewmodel';
import { ContentPageRowControl } from 'src/app/model/content-page-row-control';
import { ContentPageRowControlProperty } from 'src/app/model/content-page-rown-control-property';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { SubPageVM, WebPageSearchCriteria } from 'src/app/model/subpage-viewmodel';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../shared/service/scroll.service';
import { DefaultService } from '../../layout/default.service';


@Component({
  selector: '[blog-post],blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {
  @Input() blogPostVM: BlogPostVM;
  baseUrl: string;
  titel: string;
  fullUrl: string;
  subpagevm: SubPageVM;
  contentPageRowControlProperties: ContentPageRowControlProperty[];
  contentPageRowControlProperty: ContentPageRowControlProperty;
  siteContent: SiteContentDetail;

	constructor(
		private defaultService: DefaultService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) {
  }

  ngOnInit() {
    this.siteContent = this.siteContentService.getSiteContentData();
    this.baseUrl = window.location.href;
  }
  getTileAndUrl() {
    if (this.blogPostVM && this.blogPostVM.BlogPostControls && this.blogPostVM.BlogPostControls.length > 0) {
      for (var i = 0; i < this.blogPostVM.BlogPostControls.length; i++) {
        if (this.blogPostVM.BlogPostControls[i].ControlType == 'SideBySide' && this.blogPostVM.BlogPostControls[i].ControlType == 'TitleContent') {

        }

      }
    }
  }
  getContentPageRowControlProperties() {
    this.contentPageRowControlProperties = [];
    this.contentPageRowControlProperty = {} as ContentPageRowControlProperty;
    this.contentPageRowControlProperty.ControlTypeElementKey = "WebPage";
    this.contentPageRowControlProperty.Value = "938";
    this.contentPageRowControlProperties.push(this.contentPageRowControlProperty);

    this.contentPageRowControlProperty = {} as ContentPageRowControlProperty;
    this.contentPageRowControlProperty.ControlTypeElementKey = "PageSize";
    this.contentPageRowControlProperty.Value = "3";
    this.contentPageRowControlProperties.push(this.contentPageRowControlProperty);

    this.contentPageRowControlProperty = {} as ContentPageRowControlProperty;
    this.contentPageRowControlProperty.ControlTypeElementKey = "PagesPerRow";
    this.contentPageRowControlProperty.Value = "3";
    this.contentPageRowControlProperties.push(this.contentPageRowControlProperty);

    this.contentPageRowControlProperty = {} as ContentPageRowControlProperty;
    this.contentPageRowControlProperty.ControlTypeElementKey = "WebPageSearchType";
    this.contentPageRowControlProperty.Value = "BlogPages";
    this.contentPageRowControlProperties.push(this.contentPageRowControlProperty);

    this.contentPageRowControlProperty = {} as ContentPageRowControlProperty;
    this.contentPageRowControlProperty.ControlTypeElementKey = "options";
    this.contentPageRowControlProperty.Value = "no-paging";
    this.contentPageRowControlProperties.push(this.contentPageRowControlProperty);

    return this.contentPageRowControlProperties;

  }

  getSubpageData() {

    this.subpagevm = {} as SubPageVM;
    this.subpagevm.WebPageSearchCriteria = {} as WebPageSearchCriteria;
    this.subpagevm.WebPageSearchCriteria.PageNumber = 1;
    this.subpagevm.WebPageSearchCriteria.PageSize = 3;
    this.subpagevm.WebPageSearchCriteria.SortBy = "date";
    this.subpagevm.WebPageSearchCriteria.SortDescending = true;
    this.subpagevm.WebPageSearchCriteria.IncludeChildren = true;
    this.subpagevm.WebPageSearchCriteria.IncludeCurrentPage = false;
    this.subpagevm.WebPageSearchCriteria.WebPageSearchType = 0;
    this.subpagevm.WebPageSearchCriteria.WebPageId = 938;
    this.subpagevm.WebPageSearchCriteria.IsSummary = true;

    this.subpagevm.ContentPageRowControl = {} as ContentPageRowControl;
    this.subpagevm.ContentPageRowControl.Properties = this.getContentPageRowControlProperties();

    return this.subpagevm;
  }
  scrollTop() {
	  this.scrollService.windowScrollTo(0, 0);
  }

  getRelatedProduct(productFromSkus, imageKey): Observable<RelatedProductVM> {

    if (productFromSkus && productFromSkus.Sku) {
      productFromSkus.Skus = productFromSkus.Sku.replace(/\r\n/g, ',');
      productFromSkus.Sku = productFromSkus.Sku.replace(/\r\n/g, ',');
      if (imageKey) {
        productFromSkus.ImageKeys = imageKey;
      }
    }
    return this.defaultService.getProductFromSkus(productFromSkus);
  }

}
