import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IconmodelpopupComponent } from 'src/app/modules/Home/iconmodelpopup/iconmodelpopup.component';


@Injectable()
export class IconBarModalService {

  matDialogRef: MatDialogRef<IconmodelpopupComponent>;
  constructor( private matDialog: MatDialog) { }


  openIconBarModal(url,title) {
      
    if(this.matDialogRef){
      this.matDialogRef.afterClosed().subscribe(res => {
          this.matDialogRef.close();
      });
    }
   
    if(url && (!this.matDialogRef || (this.matDialogRef && !this.matDialogRef.componentInstance))){
      this.matDialogRef = this.matDialog.open(IconmodelpopupComponent, {
        data: { videosource: url,title:title },
        //disableClose: true
        disableClose: false
      });
    }

  }
}