import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ScriptStore } from "./script.service.store";
import { Script } from '../models/script';

declare var document: any;

export enum ScriptLocation {
	Head,
	Body
}

@Injectable()
export class ScriptService {

	private scripts: Script[] = [];

	constructor(@Inject(DOCUMENT) private document) {
		ScriptStore.forEach((script: any) => {
			let newScript: Script = { loaded: false, src: script.src, name: script.name };

			this.scripts.push(newScript);
		});
	}

	getScript(name: string) {
		return this.scripts.find(x => x.name == name);
	}

	unload(scriptLocation: ScriptLocation, ...scripts: string[]) {
		var promises: any[] = [];
		scripts.forEach((script) => promises.push(this.unloadScript(script, scriptLocation)));
		return Promise.all(promises);
	}

	load(scriptLocation: ScriptLocation, ...scripts: string[]) {
		var promises: any[] = [];
		scripts.forEach((script) => promises.push(this.loadScript(script, scriptLocation)));
		return Promise.all(promises).then(scripts => scripts);
	}

	loadScript(scriptName: string, scriptLocation: ScriptLocation) {
		return new Promise((resolve, reject) => {
			let scriptToLoad = this.getScript(scriptName);

			if (scriptToLoad && scriptToLoad.loaded) {
				// Script already loaded
				resolve({ script: scriptName, loaded: true, status: 'Already Loaded' });
			}
			else {
				// Load script
				let script = this.document.createElement('script');
				script.type = 'text/javascript';
				script.src = scriptToLoad.src;
				if (script.readyState) {  // IE
					script.onreadystatechange = () => {
						if (script.readyState === "loaded" || script.readyState === "complete") {
							script.onreadystatechange = null;
							scriptToLoad.loaded = true;
							resolve({ script: scriptName, loaded: true, status: 'Loaded' });
						}
					};
				} else {  // Other browsers
					script.onload = () => {
						scriptToLoad.loaded = true;
						resolve({ script: scriptName, loaded: true, status: 'Loaded' });
					};
				}

				script.onerror = (error: any) => resolve({ script: scriptName, loaded: false, status: 'Error while loading' });

				if (scriptLocation == ScriptLocation.Body) {
					this.document.body.appendChild(script);
				}
				else {
					this.document.head.appendChild(script);
				}
			}
		});
	}

	unloadScript(scriptName: string, scriptLocation: ScriptLocation) {
		return new Promise((resolve, reject) => {
			let scriptToUnloadIndex = this.scripts.findIndex(x => x.name == scriptName);

			if (scriptToUnloadIndex != -1) {
				let updatedScript = this.scripts[scriptToUnloadIndex];
				updatedScript.loaded = false;

				this.scripts[scriptToUnloadIndex] = updatedScript;

				var allScripts = [];

				if (scriptLocation == ScriptLocation.Body) {
					allScripts = this.document.body.getElementsByTagName("script");
				}
				else {
					allScripts = this.document.head.getElementsByTagName("script");
				}

				for (var i = allScripts.length; i >= 0; i--) {
					if (allScripts[i] && allScripts[i].getAttribute("src") != null && allScripts[i].getAttribute("src").indexOf(updatedScript.src) != -1)
						allScripts[i].parentNode.removeChild(allScripts[i])
				}
			}
		});
	}
}
