import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appPinterestPin]'
})
export class PinterestPinDirective {

 
  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
   this.addClass('btn-pinterest-pin',this.el.nativeElement);
  }
  @HostListener("click", ['$event'])
  onClick(event) {
    this.pinImage(event,'','');
  }
  pinImage(e, item, ispinterest) {
    e.preventDefault();
    
    // if (ispinterest && item.ItemId != "") {
    //     PinUtils.repin(item.ItemId);
    // } else {
    //     PinUtils.pinOne({
    //         'media': item.ImageUrl,
    //         'description': item.Title
    //     });
    // }
    }
}
