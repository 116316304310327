import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { LpAppSetting, UserSettings } from 'src/app/model/lpapp-setting';
import { PageTypeDimensionEnum } from '../../../enums/page-type-dimension-enum';
import { PromoLineBarEnum } from '../../../enums/promo-line-bar-enum';
import { geolocation } from '../../../model/geolocationdetails';
import { ProductWebPageModel } from '../../../model/product-webpage-model';
import { PromoLineBarViewModel } from '../../../model/promo-line-bar-viewmodel';
import { TrackingScript } from '../../../model/tracking-script';
import { TrackingVM } from '../../../model/tracking-viewmodel';
import { WebPageVM } from '../../../model/webpage-viewmodel';
import { DefaultService } from '../../../modules/layout/default.service';
import { Label } from '../../models/Labels';
import { HeaderMenu, MenuItem } from '../../models/menuItem';
import { SiteContentDetail } from '../../models/siteContent';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'api-test',
	templateUrl: './api-test.component.html',
	styleUrls: ['./api-test.component.scss']
})
export class ApiTestComponent implements OnInit {

	load_webPageVM: boolean = true;
	webPageVM!: WebPageVM;
	load_userSettings: boolean = true;
	userSettings!: UserSettings;
	load_siteContentDetail: boolean = true;
	siteContentDetail!: SiteContentDetail;
	load_label: boolean = true;
	label!: Label;
	load_headerMenus: boolean = true;
	headerMenus!: HeaderMenu;
	load_footerMenus: boolean = true;
	footerMenus!: MenuItem[];
	load_geolocation: boolean = true;
	geolocation!: geolocation;
	load_trackingScripts: boolean = true;
	trackingScripts!: TrackingScript[];
	load_lpAppSetting: boolean = true;
	lpAppSetting!: LpAppSetting;
	load_promoLineBarViewModel: boolean = true;
	promoLineBarViewModel!: PromoLineBarViewModel;
	productWebPageModelStr: string = "womenswear/shirts/printed";
	productWebPageModel!: ProductWebPageModel;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private sharedService: SharedService,
		private defaultService: DefaultService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService,
		@Inject(PLATFORM_ID) private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	ngOnInit() {
		if (!isPlatformBrowser(this.platformId)) {

		}

		this.route.queryParams
			.subscribe(async (params) => {
				if (params) {

					console.log(this.getQueryParameters());

					var queryParams: any = this.getQueryParameters()

					if (queryParams.load_webPageVM) {
						this.load_webPageVM = queryParams.load_webPageVM == "true";
						this.load_userSettings = queryParams.load_userSettings == "true";
						this.load_siteContentDetail = queryParams.load_siteContentDetail == "true";
						this.load_label = queryParams.load_label == "true";
						this.load_headerMenus = queryParams.load_headerMenus == "true";
						this.load_footerMenus = queryParams.load_footerMenus == "true";
						this.load_geolocation = queryParams.load_geolocation == "true";
						this.load_trackingScripts = queryParams.load_trackingScripts == "true";
						this.load_lpAppSetting = queryParams.load_lpAppSetting == "true";
						this.load_promoLineBarViewModel = queryParams.load_promoLineBarViewModel == "true";
						this.productWebPageModelStr = (queryParams.load_productWebPageModel == null || queryParams.load_productWebPageModel == "undefined")
							? "" : queryParams.load_productWebPageModel;
					}

					if (this.load_webPageVM) {
						this.defaultService.getHomePage()
							.subscribe(data => {
								this.webPageVM = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_userSettings) {
						this.sharedService.getUserSettings()
							.subscribe(data => {
								this.userSettings = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_siteContentDetail) {
						this.sharedService.getSiteContentDetails()
							.subscribe(data => {
								this.siteContentDetail = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_label) {
						this.sharedService.getLabels()
							.subscribe(data => {
								this.label = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_headerMenus) {
						this.sharedService.getMenus()
							.subscribe(data => {
								this.headerMenus = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_footerMenus) {
						this.sharedService.getFooter()
							.subscribe(data => {
								this.footerMenus = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_geolocation) {
						this.sharedService.GeoLocation(1)
							.subscribe(data => {
								this.geolocation = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_trackingScripts) {
						let trackingVm = {} as TrackingVM;
						trackingVm.PageTypeDimension = PageTypeDimensionEnum.Home;

						this.defaultService.GetTrackingScripts(trackingVm)
							.subscribe(data => {
								this.trackingScripts = data;
							}, (error: Error) => this.handleError());
					}

					if (this.load_lpAppSetting) {
						this.lpAppSetting = await this.sharedService.getLpAppSettings()
					}

					if (this.load_promoLineBarViewModel) {
						this.sharedService.RenderPromoLineBar(null, PromoLineBarEnum.HeaderCheckout)
							.subscribe(data => {
								this.promoLineBarViewModel = data;
							}, (error: Error) => this.handleError());
					}

					if (this.productWebPageModelStr != "") {
						this.defaultService.getCategoryWebPage(this.productWebPageModelStr)
							.subscribe(data => {
								this.productWebPageModel = data;
							}, (error: Error) => this.handleError());
					}
				}
			});
	}

	updateUrl() {
		let routingUrl = "ApiTest?" +
			"load_webPageVM=" + this.load_webPageVM + "&" +
			"load_userSettings=" + this.load_userSettings + "&" +
			"load_siteContentDetail=" + this.load_siteContentDetail + "&" +
			"load_label=" + this.load_label + "&" +
			"load_headerMenus=" + this.load_headerMenus + "&" +
			"load_footerMenus=" + this.load_footerMenus + "&" +
			"load_geolocation=" + this.load_geolocation + "&" +
			"load_trackingScripts=" + this.load_trackingScripts + "&" +
			"load_lpAppSetting=" + this.load_lpAppSetting + "&" +
			"load_promoLineBarViewModel=" + this.load_promoLineBarViewModel + "&" +
			"load_productWebPageModel=" + this.productWebPageModelStr;

		this.webPageVM = null;
		this.userSettings = null;
		this.siteContentDetail = null;
		this.label = null;
		this.headerMenus = null;
		this.footerMenus = null;
		this.geolocation = null;
		this.trackingScripts = null;
		this.lpAppSetting = null;
		this.promoLineBarViewModel = null;
		this.productWebPageModel = null;

		this.router.navigateByUrl(routingUrl);
	}

	handleError() {
		this.router.navigateByUrl("pagenotfound");
	}

	getQueryParameters() {
		var prmstr = window.location.search.substr(1);
		return prmstr != null && prmstr != "" ? this.transformToAssocArray(prmstr) : {};
	}

	transformToAssocArray(prmstr) {
		var params = {};
		var prmarr = prmstr.split("&");

		for (var i = 0; i < prmarr.length; i++) {
			var tmparr = prmarr[i].split("=");
			if (!params[tmparr[0]]) {
				params[tmparr[0]] = tmparr[1];
			} else {
				params[tmparr[0]] = params[tmparr[0]] + "," + tmparr[1];
			}
		}

		return params;
	}
}
