import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Icons } from '../model/product-list';
import { ProductBadgeImage } from '../../product-details/models/product-detail';
import { GetProductBadgeImage } from 'src/app/shared/helper/utilities';

@Component({
  selector: 'product-icon',
  templateUrl: './product-icon.component.html',
  styleUrls: ['./product-icon.component.scss']
})
export class ProductIconComponent implements OnInit {
  @Input() icons: Icons[];
  badgeImage: ProductBadgeImage = null;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.badgeImage = GetProductBadgeImage(this.sanitizer, this.icons);
  }
}