import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/shared/service/cart.service';
import { AuthService } from '../../auth.service';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';

@Component({
	selector: 'app-sign-out',
	templateUrl: './sign-out.component.html',
	styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

	constructor(
		private router: Router,
		private authService: AuthService,
		private cartService: CartService,
		private attraqtService: AttraqtService
	) {}

	ngOnInit() {
		this.authService.signOut().subscribe(data => {
			if (data) {
				this.cartService.setShoppingBasket();
				localStorage.setItem("SignedOut", "true");
				this.attraqtService.removeLoggedOutUser();
				this.router.navigateByUrl('details', { skipLocationChange: true }).then(() => {
					this.router.navigate(['']);
				});
			}
		})

	}
}
