import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VideomodalComponent } from 'src/app/modules/Home/videomodal/videomodal.component';

@Directive({
  selector: '[dataopeninmodal]'
})
export class DataOpenInModalDirective {

  @Input('dataopeninmodal') dataopeninmodal: string;
  matDialogRef: MatDialogRef<VideomodalComponent>;
  constructor( private matDialog: MatDialog) { }


  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
  
    if(this.matDialogRef){
      this.matDialogRef.afterClosed().subscribe(res => {
          this.matDialogRef.close();
      });
    }
   
    if(this.dataopeninmodal && (!this.matDialogRef || (this.matDialogRef && !this.matDialogRef.componentInstance))){
      this.matDialogRef = this.matDialog.open(VideomodalComponent, {
        data: { videosource: this.dataopeninmodal },
        disableClose: true
      });
    }
  }
}
