import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';
import * as $ from 'jquery';
@Directive({
  selector: '[appGalleryItem]'
})
export class GalleryItemDirective implements AfterViewInit{

  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
    
  }
  @HostListener("click", ['$event'])
  onClick(event) {
   this.galleryItemClick(event);
  }
  galleryItemClick(e) {
      e.preventDefault();
    
      let target = e.target || e.srcElement;
      let currentItem = target.src ? target.parentNode : target;
    
      let links = $("#imageGallery a.image-social");
       //there is no package--------------blueimp
      // blueimp.Gallery(links, { index: currentItem, event: e });
    }
}
