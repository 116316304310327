export enum AppEventType {
    ClickedOnNotification = 'CLICKED_ON_NOTIFICATION',
    SocketEvent = 'SOCKET_EVENT',
    onOrderReferenceCreate = 'onOrderReferenceCreate',
    onAddressSelect = 'onAddressSelect',
    onReady = 'onReady',
    error = 'error',
    onAmazonPaymentsReady = 'onAmazonPaymentsReady',
    amazonpay = 'amazonpay',
    onAmazonReRenderWallet = 'onAmazonReRenderWallet',
    refreshShippingOption = 'refreshShippingOption',
    refreshbag = 'refreshbag'
  }