import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';
import * as $ from 'jquery';
@Directive({
  selector: '[appContentToggle]'
})
export class ContentToggleDirective implements AfterViewInit {
  el;
  @Input() parentSelector = '';
  @Input() action = '';
  constructor(el:ElementRef,private renderer2:Renderer2) {
    this.el = el;
  }
  ngAfterViewInit(){
    this.addClass( "toggle-"+this.action,this.el.nativeElement);
  }

  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  @HostListener("click", ['$event'])
  onClick(event) {
    let element = this.parentSelector === "" ? $(this.el.nativeElement).parent().parent()
    : $(this.el.nativeElement).closest(this.parentSelector);
    this.addClass("toggle-" + this.action,element);
    this.removeClass("toggle-" +this.action == "on" ? "off" : "on",element);
    event.preventDefault();
  }
}
