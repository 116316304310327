import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Label } from '../../models/Labels';
import { LabelService } from '../../service/label.service';

@Component({
  selector: 'app-mobile-add-bag',
  templateUrl: './mobile-add-bag.component.html',
  styleUrls: ['./mobile-add-bag.component.scss']
})
export class MobileAddBagComponent implements OnInit {
  label: Label;
  constructor(private labelService: LabelService,
    @Inject(MAT_DIALOG_DATA) public data, private dailogRef: MatDialogRef<MobileAddBagComponent>) { }

  ngOnInit() {
    this.getAllLabels();
  }
  getAllLabels() {
    this.label = this.labelService.getLabelData();
    if (!this.label) {
      this.labelService.getLabels().subscribe(data => {
        if (data) {
          this.label = data;
        }
      });
    }
   
  }
  closeModal(){
    this.dailogRef.close('true');
  }
}
