import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { filter } from "rxjs/operators";
import { Select } from '@ngxs/store';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { PromoLineBarEnum } from 'src/app/enums/promo-line-bar-enum';
import { WebPageTypeEnum } from 'src/app/enums/web-page-type-enum';
import { ContentPageRow } from 'src/app/model/content-page-rows';
import { LpAppSetting, UserSettings } from 'src/app/model/lpapp-setting';
import { ElementTypeEnum } from 'src/app/model/product-webpage-model';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { WebpageViewModel } from 'src/app/model/web-page-viewmodel';
import { WebPageVM } from 'src/app/model/webpage-viewmodel';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { IconBarModalService } from 'src/app/shared/service/iconbarmodel.service';
import { ScriptLocation, ScriptService } from 'src/app/shared/service/script.service';
import { ScrollService } from 'src/app/shared/service/scroll.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { onCLS, onFID, onLCP } from 'web-vitals';
import { AddDynanicScriptAndStyleToDOM, AddPostcodeKey, AddScriptStyleLinks, AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { CarouselComponent } from '../carousel/carousel.component';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import * as moment from 'moment';
import { CdkObserveContent } from '@angular/cdk/observers';
import { CookieService } from 'ngx-cookie';

@Component({
	selector: 'home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(CarouselComponent) child: CarouselComponent;
	@ViewChild('promotexthome') promotexthome: ElementRef;

	linkDescription = '';
	linkForBack = '';
	webPageViewModel: WebpageViewModel;
	isHamePageLoaded: Promise<boolean>;
	webPageRows: ContentPageRow[];
	layoutClasses: string[];
	wrapperClasses: string[];
	singleBlogPostPage: boolean;
	layoutClassesForWrapper = ["theme-primary", "custom-colour", "contained"];
	webPageVM: WebPageVM;
	categoryUrl: string;
	showProductView = false;
	categoryPage: [];
	webPageTypeEnum = WebPageTypeEnum;
	userSettings: UserSettings;
	userSettingObj: UserSettings;
	LpAppSetting: LpAppSetting;
	scriptContent = [];
	isLpAppSettingAfterviewinit: boolean = false;
	isWebpageVmNgAfterviewinit: boolean = false;
	isscriptContentNgAfterviewinit: boolean = false;
	siteContent: SiteContentDetail;
	promotextcolor: string = '';
	detail: any;
	wearWithProducts: RelatedProductVM = null;
	recommendedProducts: RelatedProductVM = null;
	elementType: number;
	IsEmeryCartUpdate: boolean = false;
	subs: any;
	urls = [];
	scrolledYPos: boolean = true;
	promoLineBarBodyHtml: string;
	promoLineBarBodyBgHexColour: string;
	promoLineBarBodyTextHexColour: string;
	isHomePage = true;

	facetFilterState: IFacetFilterState;
	facetFilterSubscriber: Subscription;

	private readonly globaleCookie: string = "GlobalE_Data";

	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private router: Router,
		private defaultService: DefaultService,
		private route: ActivatedRoute,
		private titleService: Title,
		private meta: Meta,
		private sharedService: SharedService,
		private siteContentService: SiteContentService,
		private renderer: Renderer2,
		private scrollService: ScrollService,
		private scriptService: ScriptService,
		private iconBarModalService: IconBarModalService,
		private ngZone: NgZone,
		private sanitizer: DomSanitizer,
		private cookieService: CookieService,
		@Inject(DOCUMENT) private dom,
		@Inject(PLATFORM_ID) private platformId: any,
		private attraqtService: AttraqtService,) {

		this.subs = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (isPlatformBrowser) {
					let temp = JSON.parse(localStorage.getItem('urls'));
					this.urls = temp ? temp : [];
					this.urls.push(event.url);
					localStorage.setItem('urls', JSON.stringify(this.urls));
				}
			});
	}

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			if (!localStorage.getItem('isScrollUpdated')) {
				this.scrollService.windowScrollTo(0, 0);
			}
		}
		this.getLpAppSetting();
		this.getSiteContentData();

		this.route.params.subscribe(params => {

			this.categoryUrl = params['url'];

			if (this.categoryUrl) {
				this.categoryUrl = this.categoryUrl.split('?')[0];
			} else {
				this.categoryUrl = "";
			}

			if (this.categoryUrl) {
				this.getCategoryWebPage(this.categoryUrl);
			} else {
				this.sharedService.menuItemSelected(window.location.pathname);
				this.getHomePage();
			}
		});
		this.subscribeToState();
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			// this.notifyFooterParent.emit(true);
			if (this.webPageVM && !this.isWebpageVmNgAfterviewinit) {
				//AddDynanicScriptAndStyleToDOM(this.webPageVM);
				setTimeout(AddDynanicScriptAndStyleToDOM, 0, this.webPageVM);
				this.isWebpageVmNgAfterviewinit = true;
				this.scrolledYPos = true;
			}

			this.loadScripts();

			if (this.LpAppSetting && !this.isLpAppSettingAfterviewinit) {
				//AddPostcodeKey(this.LpAppSetting)
				setTimeout(AddPostcodeKey, 0, this.LpAppSetting);
				setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
				this.isLpAppSettingAfterviewinit = true;
			}

			if (this.isscriptContentNgAfterviewinit && this.scriptContent && this.scriptContent.length > 0) {
				setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
				this.isscriptContentNgAfterviewinit = false;
			}

			if (this.webPageVM && this.promotexthome) {
				let style: string = `.home-promo-line {--promo-text-color : ${this.webPageVM.PromoLineBarPromoTextColor ? this.webPageVM.PromoLineBarPromoTextColor : ''}; }`;

				this.createStyle(style);
			}

			if (this.isWebpageVmNgAfterviewinit && this.isLpAppSettingAfterviewinit && localStorage.getItem('isScrollUpdated') && localStorage.getItem('isScrollUpdated') == 'false') {
				if (localStorage.getItem('isExternal') == 'true') {
					this.scrollService.windowScrollTo(0, parseInt(localStorage.getItem('scrollY')));
					this.scrollService.setScrollYPosition(parseInt(localStorage.getItem('scrollY')));
				} else {
					if (window.location.pathname == localStorage.getItem('originalUrl')) {
						this.scrollService.windowScrollTo(0, parseInt(localStorage.getItem('scrollY')));
						this.scrollService.setScrollYPosition(parseInt(localStorage.getItem('scrollY')));
					} else {
						this.scrollService.windowScrollTo(0, 0);
						this.scrollService.setScrollYPosition(0);
					}
				}
				localStorage.setItem('isScrollUpdated', 'true');
			}
		}
	}

	ngOnDestroy() {
		if (isPlatformBrowser(this.platformId)) {
			this.subs.unsubscribe();
			this.unloadScripts();
			if (this.facetFilterSubscriber) {
				this.facetFilterSubscriber.unsubscribe();
			}
		}		
	}

	@HostListener('window:popState ', ['$event'])
	popStateHandler(event) {
		if (isPlatformBrowser) {
			localStorage.setItem('isScrollUpdated', 'false')
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		if (this.LpAppSetting?.UserSettings) {
			this.userSettings = this.LpAppSetting.UserSettings;
		}
		if (this.LpAppSetting?.CurrentCulture) {
			this.renderer.setAttribute(document.querySelector('html'), 'lang', this.LpAppSetting.CurrentCulture);
		}
	}

	private getSiteContentData(): void {
		this.siteContentService.getSiteContent().subscribe((data) => {
			this.siteContent = data;
		})
	}

	breadComeLinks(params) {
		if (params && params.lastIndexOf('/')) {
			let lastIndexOfSlash = params.lastIndexOf('/');
			let tempLink = params.substr(0, lastIndexOfSlash);
			let tempIndex = tempLink.lastIndexOf('/');
			let text = tempLink.substr(tempIndex + 1);
			this.linkDescription = text;
			this.linkForBack = tempLink;
		}
	}

	getStyle(rowStyle): object {
		var rowStyleObj = {};

		if (rowStyle) {
			var style = rowStyle.split(';');

			if (style.length > 0) {
				for (var i = 0; i < style.length; i++) {

					if (style[i].trim() && style[i].trim().length > 0) {
						let rStyle = style[i].split(":");
						let key = rStyle[0].toString();
						key = key.trim();
						let value = rStyle[1].toString();

						if (key === '--promo-text-color') {
							key = 'color'
						}

						value = value.replace(';', '');
						if (key && key.length > 0 && value && value.length > 0) {
							rowStyleObj[key] = value;
						}
					}
				}

			}

			return rowStyleObj;
		}

		return null;
	}

	getCategoryWebPage(url) {

		this.defaultService.getCategoryWebPage(url).subscribe(response => {
			if (response) {

				this.webPageVM = null;
				this.sharedService.menuItemSelected(window.location.pathname);

				if (response.ElementType === ElementTypeEnum.WebPage) {
					if (response.WebPageVM) {

						this.elementType = response.ElementType;
						this.webPageVM = response.WebPageVM;

						if (this.webPageVM.WebPageTypeEnum == this.webPageTypeEnum.LandingPage) {
							this.getTrackingScript(PageTypeDimensionEnum.LandingPage);
						}

						if (this.webPageVM && this.webPageVM.MetaTitle) {
							this.setTitle(this.webPageVM.MetaTitle);
						}

						/*
						if (this.webPageVM) {
							this.setWebpageCanonicalUrls();
						}
						*/

						if (this.webPageVM && this.webPageVM.MetaDescription) {
							this.updateDescription(this.webPageVM.MetaDescription);
						}

						if (this.webPageVM && this.webPageVM.WebPageTypeEnum) {
							this.setBodyClass(this.webPageVM.WebPageTypeEnum)
						}

						if (this.webPageVM && this.webPageVM.RedirectUrl) {
							this.router.navigate([this.webPageVM.RedirectUrl]);
						}

						this.showProductView = false;
						this.isHamePageLoaded = Promise.resolve(true);
					}

				} else if (response.ElementType === ElementTypeEnum.Product) {
					this.elementType = response.ElementType;

					if (response.ProductPageViewModel) {
						this.detail = response.ProductPageViewModel;
						this.generateProductSchema();

						if (this.detail?.Product?.WearItWithProducts?.length) {
							this.wearWithProducts = {
								CarouselSlides: 4,
								CarouselDirective: 'carousel',
								IsHistoryView: false,
								Title: this.siteContent.WearWith,
								ProductSmallVMs: this.detail.Product.WearItWithProducts
							}
						}

						if (this.detail?.Product?.RecommendedProducts?.length) {
							this.recommendedProducts = {
								CarouselSlides: 4,
								CarouselDirective: 'carousel',
								IsHistoryView: false,
								Title: this.siteContent.YouMayAlsoLike,
								ProductSmallVMs: this.detail.Product.RecommendedProducts
							}
						}

						/*
						if (this.detail) {
							this.setProductCanonicalUrls();
						}
						*/

						this.isHamePageLoaded = Promise.resolve(true);

						if (this.facetFilterState.attraqtPLPEnabled) {
							const activityBuilder = new AttraqtActivityBuilder(AttraqtActivityActionEnum.View)
								.setTarget({ product: this.detail.Product.ProductId.toString() })
								.setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) || 'en_GB' });
							if (this.facetFilterState.sourceId) {
								activityBuilder.setSourceId(this.facetFilterState.sourceId);
							}
							const activity = activityBuilder.build();
							this.attraqtService.sendActivity(activity);
						}
					}
				}

				if (this.LpAppSetting && !this.isLpAppSettingAfterviewinit) {
					setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
					this.isLpAppSettingAfterviewinit = true;
				}

				onCLS(console.log);
				onFID(console.log);
				onLCP(console.log);

				if(this.webPageVM?.WebPageTypeEnum != null)
				{
					this.renderPromoLineBar(this.webPageVM.WebPageTypeEnum);
				}
			}
		}, () => {
			this.showProductView = true;

			if (this.urls[this.urls.length - 2] == '/pagenotfound') {
				this.router.navigateByUrl(this.urls[this.urls.length - 4]);
				this.urls.splice(this.urls.length - 2, 3);
				localStorage.setItem('urls', JSON.stringify(this.urls));
			} else {
				this.urls.push('/pagenotfound');
				localStorage.setItem('urls', JSON.stringify(this.urls));
				//this.router.navigateByUrl('pagenotfound');

				window.location.href = "/page-not-found";
			}
		});
	}

	setGlobalEParamsToSchema() {
		const createMeta = (itemProp: string, content: string) => {
			const meta = document.createElement('meta');
			meta.setAttribute('itemprop', itemProp);
			meta.setAttribute('content', content);
			return meta;
		};
		
		const convertToFixedPrice = (convertedPrice)=>{
			let numericPrice = parseFloat(convertedPrice.replace(/,/g, ''));
			return numericPrice.toFixed(2);
		}
		
		const schemaElement = document.querySelector('section[itemscope][itemtype="http://schema.org/Product"]');
		const salePriceElement = document.getElementById('ge_sale_price');
		const retailPriceElement = document.getElementById('ge_retail_price');
		if (schemaElement && salePriceElement && retailPriceElement) {
			const salePriceEleTextContent = salePriceElement.textContent.trim();
			const retailPriceEleTextContent = retailPriceElement.textContent.trim();

			const regex = /([^\d,]+)([\d,.]+)/;

			const [, currency, convertedSalePrice] = salePriceEleTextContent.match(regex);
			const [, currency2, convertedRetailPrice] = retailPriceEleTextContent.match(regex);

			const globaleCookieValue = this.cookieService.get(this.globaleCookie);
			let currencyCode = null;
			if(globaleCookieValue){
				const data = JSON.parse(globaleCookieValue);
				currencyCode = data.currencyCode;
			}

			const priceCurrencyMeta = schemaElement.querySelector('meta[itemprop="priceCurrency"]');
			if (priceCurrencyMeta) {
				priceCurrencyMeta.setAttribute('content', currencyCode ? currencyCode : currency.replace(/[\u200C]/g, '')); 
			}
			const priceMeta = schemaElement.querySelector('meta[itemprop="price"]');
			if (priceMeta) {
				priceMeta.setAttribute('content', Number(convertedSalePrice.replace(/,/g, '')) > 0 ? convertToFixedPrice(convertedSalePrice) : convertToFixedPrice(convertedRetailPrice));
			}
		}
	}

	generateProductSchema(): void {
		document.querySelectorAll('section[itemtype="http://schema.org/Product"]').forEach(node => node.remove());
		
		const createMeta = (itemProp: string, content: string, itemtype:any = false) => {
		  const meta = document.createElement('meta');
		  meta.setAttribute('itemprop', itemProp);
		  if(itemtype){
			meta.setAttribute('itemtype', itemtype);
		  }
		  meta.setAttribute('content', content);
		  return meta;
		};
	
		const createLink = (itemProp: string, href: string) => {
		  const link = document.createElement('link');
		  link.setAttribute('itemprop', itemProp);
		  link.setAttribute('href', href);
		  return link;
		};
	
		const section = document.createElement('section');
		section.setAttribute('itemscope', '');
		section.setAttribute('itemtype', 'http://schema.org/Product');
	
		section.appendChild(createMeta('sku', this.detail.Product.Code));
		section.appendChild(createMeta('name', this.detail.Product.Name));
		section.appendChild(createMeta('color', this.detail.Product.ColourName));
		section.appendChild(createMeta('description',this.detail.Product.DescriptionLong));
		if (this.findHighestResolutionPrimaryImage(this.detail.Product.PrimaryImage.ImageSizes)) {
		  section.appendChild(createMeta('image', this.findHighestResolutionPrimaryImage(this.detail.Product.PrimaryImage.ImageSizes)));
		}
		const offersDiv = document.createElement('div');
		offersDiv.setAttribute('itemprop', 'offers');
		offersDiv.setAttribute('itemscope', '');
		offersDiv.setAttribute('itemtype', 'http://schema.org/Offer');
	
		offersDiv.appendChild(createLink('url', this.detail.Product.ProductUrl));
		offersDiv.appendChild(createMeta('itemCondition', 'http://schema.org/NewCondition','http://schema.org/OfferItemCondition'));
		offersDiv.appendChild(createMeta('availability', 'http://schema.org/InStock'));
		offersDiv.appendChild(createMeta('price', this.detail.Product.SalePrice > 0 ? this.detail.Product.SalePrice : this.detail.Product.RetailPrice));
		offersDiv.appendChild(createMeta('priceCurrency', this.detail.Product.CurrencyCode));
		offersDiv.appendChild(createMeta('itemCondition', 'https://schema.org/NewCondition'));
		offersDiv.appendChild(createMeta('priceValidUntil',  moment().add(6, 'months').format('YYYY-MM-DD')));
	
		section.appendChild(offersDiv);
		document.body.appendChild(section);
	}

	findHighestResolutionPrimaryImage(images) {
		const keysToMatch = ["zoom", "large", "medium", "thumbnail"];
		const foundImages = {};
		for (const image of images) {
			if (keysToMatch.includes(image.Key) && !foundImages[image.Key]) {
				foundImages[image.Key] = image;
			}
		}
		for (const key of keysToMatch) {
			if (foundImages[key]) {
				return foundImages[key].CdnFullUrl;
			}
		}
		return null;
	}

	getHomePage(): void {
		this.webPageVM = null;

		this.defaultService.getHomePage().subscribe(webpage => {

			this.elementType = ElementTypeEnum.WebPage;
			this.webPageVM = webpage;
			this.getTrackingScript(PageTypeDimensionEnum.Home);

			if (this.webPageVM && this.webPageVM.MetaTitle) {
				this.setTitle(this.webPageVM.MetaTitle);
			}

			if (this.webPageVM && this.webPageVM.MetaDescription) {
				this.updateDescription(this.webPageVM.MetaDescription);
			}

			if (this.webPageVM) {
				this.setBodyClass(this.webPageVM.WebPageTypeEnum)
			}

			//this.setWebpageCanonicalUrls();
			this.isHamePageLoaded = Promise.resolve(true);
			if (this.webPageVM)
				this.renderPromoLineBar(this.webPageVM.WebPageTypeEnum);
		});
	}

	setBodyClass(webPageTypeEnum: number) {
		if (isPlatformBrowser(this.platformId)) {
			let classlist = "";

			if (this.webPageVM && this.webPageVM.CssClass) {
				classlist += this.webPageVM.CssClass;
			}

			if (this.webPageTypeEnum.None == webPageTypeEnum) {
				classlist += " fixed-header ";
			}
			else {
				classlist += " fixed-header mask-hide ng-scope ";
			}

			if (this.LpAppSetting) {
				if (!this.LpAppSetting.UserSettings.PromoLineBarHeader) {
					classlist += "no-promo-text ";
				}
			}

			$("body").removeClass();

			if (this.webPageTypeEnum.ProductListingPage == webPageTypeEnum) {
				classlist += "pt-productlistingpage";
			}

			if (this.webPageTypeEnum.LandingPage == webPageTypeEnum) {
				classlist += "pt-landingpage";
			}

			if (this.webPageTypeEnum.HomePage == webPageTypeEnum) {
				classlist += "pt-homepage";
			}

			if (this.webPageTypeEnum.WebPage == webPageTypeEnum) {
				classlist += "pt-webpage";
			}

			if (this.webPageTypeEnum.BlogPost == webPageTypeEnum) {
				classlist += "pt-blogpost";
			}

			$("body").addClass(classlist);
		}
	}

	getSettingValue(row: ContentPageRow): string {
		if (row.Settings == null) {
			return '';
		}
	}

	setValues(webpageviewModel: WebpageViewModel): void {

		if (webpageviewModel) {
			this.webPageRows = webpageviewModel.WebPageRows.filter(x => x.Active && !x.Controls.find(x => !x.Active || x.IsEmpty));
			this.singleBlogPostPage = webpageviewModel.WebPageType == WebPageTypeEnum.BlogPost && !webpageviewModel.IsSummary;

			if (this.webPageRows) {
				for (let i = 0; i < this.webPageRows.length; i++) {
					this.getSettingValue(this.webPageRows[i]);
				}
			}
		}
	}

	setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	}

	updateDescription(desc: string) {
		this.meta.updateTag({ name: 'description', content: desc });
	}

	unloadScripts() {
		this.scriptService
			.unload(
				ScriptLocation.Head,
				'iconbar',
				'home-scroll'
			)
			.then((data) => {
				console.debug('script unloaded ', data);
			})
			.catch((error) => console.debug(error));

		this.unloadIconBar();
	}

	loadScripts() {
		this.loadIconBar();

		this.scriptService.load(ScriptLocation.Head, 'home-scroll').then(data => {
			//console.debug('home-scroll script loaded ', data);
		}).catch(error => console.debug(error));
	}

	unloadIconBar() {
		window['angularIconBarReference'] = null;
	}

	loadIconBar() {
		this.scriptService.load(ScriptLocation.Head, 'iconbar').then(data => {
			//console.debug('iconbar script loaded ', data);
		}).catch(error => console.debug(error));

		window['angularIconBarReference'] = {
			component: this,
			zone: this.ngZone,
			loadAngularFunction: (text, title) => this.iconBarModalService.openIconBarModal(text, title)
		};
	}

	getTrackingScript(pageTypeDimension: PageTypeDimensionEnum) {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = pageTypeDimension;

			this.defaultService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.isscriptContentNgAfterviewinit = true;
				this.ngAfterViewInit();
			}, err => {

			});
		}
	}

	getPromoTextColour(bgColor, promoTxtColour) {
		if (promoTxtColour) {
			let styles = {
				'background-color': bgColor,
				'--promo-text-color': promoTxtColour
			};
			return styles;
		}
		return '';
	}

	/*
	setWebpageCanonicalUrls() {
		this.removeExistingCanonicalUrls();
		const headElement = this.dom.getElementsByTagName("head")[0];
		let canonicalUrl = null;

		if (this.webPageVM.CanonicalLinkRelations && this.webPageVM.CanonicalLinkRelations.length > 0) {
			canonicalUrl = this.webPageVM.CanonicalLinkRelations[0];

			let element: HTMLLinkElement = this.dom.querySelector(
				`link[rel='canonical']`
			);

			if (!element) {
				element = this.dom.createElement("link") as HTMLLinkElement;
				headElement.appendChild(element);
			}

			element.setAttribute("rel", "canonical");
			element.setAttribute("href", canonicalUrl.Href);
		}
		if (this.webPageVM.AlternateLinkRelations && this.webPageVM.AlternateLinkRelations.length > 0) {
			for (let i = 0; i < this.webPageVM.AlternateLinkRelations.length; i++) {

				const alternateLink = this.webPageVM.AlternateLinkRelations[i];
				const isDefault = canonicalUrl && alternateLink.HrefLang === canonicalUrl.HrefLang;
				const element = this.dom.createElement("link") as HTMLLinkElement;

				headElement.appendChild(element);
				element.setAttribute("rel", "alternate");
				element.setAttribute("href", alternateLink.Href);
				element.setAttribute("hreflang", alternateLink.HrefLang);

				if (isDefault) {
					const element = this.dom.createElement("link") as HTMLLinkElement;
					headElement.appendChild(element);
					element.setAttribute("rel", "alternate");
					element.setAttribute("href", alternateLink.Href);
					element.setAttribute("hreflang", "x-default");
				}
			}
		}
	}
	*/

	/*
	setProductCanonicalUrls() {
		this.removeExistingCanonicalUrls();
		const headElement = this.dom.getElementsByTagName("head")[0];
		let canonicalUrl = null;

		if (this.detail?.CanonicalLinkRelations && this.detail.CanonicalLinkRelations?.length > 0) {
			canonicalUrl = this.detail.CanonicalLinkRelations[0];

			let element: HTMLLinkElement = this.dom.querySelector(
				`link[rel='canonical']`
			);

			if (!element) {
				element = this.dom.createElement("link") as HTMLLinkElement;
				headElement.appendChild(element);
			}

			element.setAttribute("rel", "canonical");
			element.setAttribute("href", canonicalUrl.Href);
		}

		if (this.detail?.AlternateLinkRelations && this.detail.AlternateLinkRelations?.length > 0) {
			for (let i = 0; i < this.detail.AlternateLinkRelations.length; i++) {
				const alternateLink = this.detail.AlternateLinkRelations[i];
				const isDefault = canonicalUrl && alternateLink.HrefLang === canonicalUrl.HrefLang;
				const element = this.dom.createElement("link") as HTMLLinkElement;

				headElement.appendChild(element);

				element.setAttribute("rel", "alternate");
				element.setAttribute("href", alternateLink.Href);
				element.setAttribute("hreflang", alternateLink.HrefLang);

				if (isDefault) {
					const element = this.dom.createElement("link") as HTMLLinkElement;
					headElement.appendChild(element);
					element.setAttribute("rel", "alternate");
					element.setAttribute("href", alternateLink.Href);
					element.setAttribute("hreflang", "x-default");
				}
			}
		}
	}
	*/

	/*
	removeExistingCanonicalUrls() {
		const headElement = this.dom.getElementsByTagName('head')[0];
		var canonicalLinks = headElement.querySelectorAll(`link[rel='canonical']`) || null

		for (let i = 0; i < canonicalLinks.length; i++) {
			var canonicalLink = canonicalLinks[i];
			canonicalLink.remove();
		}

		var alternateLinks = headElement.querySelectorAll(`link[rel='alternate']`) || null

		for (let i = 0; i < alternateLinks.length; i++) {
			var alternateLink = alternateLinks[i];
			alternateLink.remove();
		}
	}
	*/

	createStyle(style: string): void {
		const styleElement = document.createElement('style');
		styleElement.appendChild(document.createTextNode(style));

		if (this.promotexthome && this.promotexthome.nativeElement) {
			this.promotexthome.nativeElement.appendChild(styleElement);
		}
	}

	async renderPromoLineBar(webPageType): Promise<void> {
		let param = window.location.search.substr(1);

		if (!this.LpAppSetting) {
			this.LpAppSetting = await this.sharedService.getLpAppSettings()
		}

		if (webPageType == WebPageTypeEnum.ProductListingPage) {
			this.promoLineBarBodyHtml = this.LpAppSetting.UserSettings.PromoLineBarBodyProductList;
			this.promoLineBarBodyBgHexColour = this.LpAppSetting.UserSettings.PromoLineBarBodyProductList_BgHexColour;
			this.promoLineBarBodyTextHexColour = this.LpAppSetting.UserSettings.PromoLineBarBodyProductList_TextHexColour;

			this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.BodyProductList).subscribe(response => {
				if (response && response.MarketingTag) {
					this.promoLineBarBodyHtml = response.MarketingTag.Content.PromoLineBarBodyProductList ?
						response.MarketingTag.Content.PromoLineBarBodyProductList : this.promoLineBarBodyHtml;
					this.promoLineBarBodyBgHexColour = response.MarketingTag.Content.PromoLineBarBodyProductList_BgHexColour ?
						response.MarketingTag.Content.PromoLineBarBodyProductList_BgHexColour : this.promoLineBarBodyBgHexColour;
					this.promoLineBarBodyTextHexColour = response.MarketingTag.Content.PromoLineBarBodyProductList_TextHexColour ?
						response.MarketingTag.Content.PromoLineBarBodyProductList_TextHexColour : this.promoLineBarBodyTextHexColour;
				}
			});
		}
		else {
			this.promoLineBarBodyHtml = this.LpAppSetting.UserSettings.PromoLineBarBody;
			this.promoLineBarBodyBgHexColour = this.LpAppSetting.UserSettings.PromoLineBarBody_BgHexColour;
			this.promoLineBarBodyTextHexColour = this.LpAppSetting.UserSettings.PromoLineBarBody_TextHexColour;

			this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.Body).subscribe(response => {
				if (response && response.MarketingTag) {
					this.promoLineBarBodyHtml = response.MarketingTag.Content.PromoLineBarBody ?
						response.MarketingTag.Content.PromoLineBarBody : this.promoLineBarBodyHtml;
					this.promoLineBarBodyBgHexColour = response.MarketingTag.Content.PromoLineBarBody_BgHexColour ?
						response.MarketingTag.Content.PromoLineBarBody_BgHexColour : this.promoLineBarBodyBgHexColour;
					this.promoLineBarBodyTextHexColour = response.MarketingTag.Content.PromoLineBarBody_TextHexColour ?
						response.MarketingTag.Content.PromoLineBarBody_TextHexColour : this.promoLineBarBodyTextHexColour;
				}
			});
		}
	}

	getContent(content: string) {
		if (content) {
			return this.sanitizer.sanitize(SecurityContext.HTML, content);
		}

		return content;
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
