interface Scripts {
	name: string;
	src: string;
}

export const ScriptStore: Scripts[] = [
	// Magic Zoom
	{ name: 'magic-zoom', src: '/assets/shared/plugins/magiczoomplus/magiczoomplus.js' },
	{ name: 'magic-thumb', src: '/assets/shared/plugins/magicthumb/magicthumb.js' }, 
	{ name: 'magic-scroll', src: '/assets/shared/plugins/magicscroll/magicscroll.js' },
	{ name: 'magic-zoom-settings', src: '/assets/shared/plugins/magiczoomplus/magiczoomsettings.js' },

	// Custom scripts (Akiko)
	{ name: 'iconbar', src: '/assets/shared/js/iconbar.js' },
	{ name: 'home-scroll', src: '/assets/shared/js/home-scroll.js' },
	{ name: 'accordian-item', src: '/assets/shared/js/accordian-item.js' },
	{ name: 'magic-zoom-video-handler', src: '/assets/shared/js/magiczoomvideohandler.js' },

	// Custom scripts (Yotpo)
	{ name: 'yotpoScripts', src: '/assets/shared/js/yotpo-script/yotpoSeoWidgetScripts.js' },
	{ name: 'yotpoPdpWidgetScripts', src: '/assets/shared/js/yotpo-script/yotpoPdpWidgetScripts.js' },
];
