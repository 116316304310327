import { Component, Input, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, Scroll } from '@angular/router';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AlgoliaProduct, ProductBadgeAttribute } from '../model/product-list';
import { QuickViewComponent } from '../quick-view/quick-view.component';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { ScrollService } from 'src/app/shared/service/scroll.service';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';

@Component({
	selector: 'product-view',
	templateUrl: './product-view.component.html',
	styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() pro: AlgoliaProduct;
	@Input() bannerImageUrl: string;
	@Input() OfferTokenCookie: string;
	@Input() isPreloading: boolean;
	@Input() fredhopperResponseId: string;

	matDialogRef: MatDialogRef<QuickViewComponent>;
	productId: string;
	isHoverVisible = false;
	siteContent: SiteContentDetail;
	currencyPrefix = "£";
	multiBuyText = "included in multibuy";
	isOutOfStock: boolean;
	showColour: boolean;
	image1: string;
	image2: string;
	noImageAvailableMessage: string;
	lpAppSetting: LpAppSetting;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	videoSource:object;
	badgeText: string | null = null;

	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private matDialog: MatDialog,
		private siteContentService: SiteContentService,
		private sharedService: SharedService,
		private router: Router,
		private scrollService: ScrollService,
		private attraqtService: AttraqtService,
		@Inject(DOCUMENT) private document: Document) {
			
	}
	getProductVideoData(){
		if(this.pro.Videos){
			return this.pro.Videos[0];
		}else{
			return {
				"VideoId": 0,
				"ProductId": 0,
				"SortOrder": 0,
				"AttributeValues": [
					{
						"AttributeValueId": 0,
						"AttributeGroupId": 0,
						"IsMultiLineGroup": false,
						"Name": "",
						"Order": 0,
						"CssClass": null,
						"AttributeValueContents": null
					},
				],
				"VideoSource": ""
			}
		}
	}

	ngOnInit() {
		this.subscribeToState();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();
		this.noImageAvailableMessage = this.siteContent?.NoImageAvailable;
		this.getImages();
		this.videoSource = this.getProductVideoData();
		this.badgeText = this.getBadgeText(this.pro.BadgeAttribute);
	}

	ngAfterViewInit() {
		if (this.scrollService.scrollYPosition) {
			this.scrollService.windowScrollTo(0, this.scrollService.scrollYPosition);
		}
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	getPromotionColour(promoTxtColour) {
		if (promoTxtColour) {
			return { "color": promoTxtColour };
		}

		return '';
	}

	toPriceDisplayString(bundlePrice: any): string {
		return this.pro.CurrencyPrefix + bundlePrice.toString()
	}

	getImages() {
		if (this.pro && this.pro.AlgoliaImage && this.pro.AlgoliaImage.length > 0) {
			this.pro.AlgoliaImage.forEach(element => {
				if (element.Image1) {
					this.image1 = element.Image1
				}
				if (element.Image2) {
					this.image2 = element.Image2;
				}
			});
		}
	}

	productQuickView(id) {
		this.productId = id;
		if (this.facetFilterState.attraqtPLPEnabled) {
			this.attraqtService.sendActivity(
				new AttraqtActivityBuilder(AttraqtActivityActionEnum.Click)
				  .setTarget({ product: this.pro.ProductId.toString() })
				  .setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) })
				  .setSourceId(this.fredhopperResponseId)
				  .build()
			);
		}
		this.matDialogRef = this.matDialog.open(QuickViewComponent, {
			data: { productId: id },
			disableClose: true
		});
		this.document.body.classList.add("modal-open", "modal-with-am-fade");

		this.matDialogRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				this.productId = undefined;
			}
			this.document.body.classList.remove("modal-open", "modal-with-am-fade")
		});
	}

	getPromotionTextArray(promotion) {
		let promotionDisplayWords = [];

		if (promotion) {
			promotionDisplayWords = promotion.split(" ");
		}

		return promotionDisplayWords;
	}

	isWordNumeric(promotion) {
		promotion = promotion.replace("£", "");
		promotion = promotion.replace("€", "");
		promotion = promotion.replace("$", "");

		return !isNaN(parseInt(promotion));
	}

	toPriceDisplayToString(price, currencyPrefix) {
		if (price % 1 == 0) {
			return currencyPrefix + price;
		} else {
			return currencyPrefix + price.toFixed(2);
		}
	}

	RemoveSpace(bannerUrl) {
		if (bannerUrl) {
			return bannerUrl.trim();
		}
	}

	navigateToDetail(url) {
		if (this.facetFilterState.attraqtPLPEnabled) {
			this.attraqtService.sendActivity(
				new AttraqtActivityBuilder(AttraqtActivityActionEnum.Click)
				  .setTarget({ product: this.pro.ProductId.toString() })
				  .setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) })
				  .setSourceId(this.fredhopperResponseId)
				  .build()
			);
		}
		this.router.navigate([url]);
		this.scrollService.setScrollYPosition(window.scrollY);
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}

	getBadgeText(badge: ProductBadgeAttribute): string | null {
		if(badge?.Name && badge?.Value) {
			return `${badge.Name} | ${badge.Value}`;
		}

		return null;
	}
}
