import { Component, Input, OnInit } from '@angular/core';
import { ButtonVM } from 'src/app/model/carousel-control';

@Component({
  selector: 'app-button,[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
@Input() buttonVM:ButtonVM;
  constructor() { }

  ngOnInit() {
  }

}
