import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { geolocation } from '../model/geolocationdetails';
import { UserSettings } from '../model/headerViewModel';
import { LpAppSetting } from '../model/lpapp-setting';
import { SizeChartContent } from '../model/size-chart-content';
import { TrackingScript } from '../model/tracking-script';
import { Account } from '../modules/auth/models/account';
import { EnvironmentService } from './environment.service';
import { Label } from './models/Labels';
import { RegExLibrary } from './models/regex-library';
import { ShippingOptions, ShippingVM } from './models/shippingoptions';
import { SiteContent, SiteContentDetail } from './models/siteContent';
import { PromoLineBarEnum } from '../enums/promo-line-bar-enum';
import { PromoLineBarViewModel } from '../model/promo-line-bar-viewmodel';
import { HeaderMenu, MenuItem } from './models/menuItem';
import { NAVIGATOR_LANGUAGE } from './constants/attraqt';

const CACHE_SIZE = 1;
const TimeInSeconds = 10000;

@Injectable({
	providedIn: 'root'
})
export class SharedService {

	private headerMenuCache$: Observable<HeaderMenu>;
	private footerMenuCache$: Observable<MenuItem[]>;
	private siteContentCache$: Observable<SiteContent>;
	private usersettingsCache$: Observable<UserSettings>;
	private siteContentDetailsCache$: Observable<SiteContentDetail>;
	private regExLibraryCache$: Observable<RegExLibrary>;
	private labelsCache$: Observable<Label>;
	private ShippingVMCache$: Observable<ShippingVM[]>;
	private LpApp$: any;
	public manuallyClicked = false;
	public isShoppingCartInitialized: boolean = false;
	public isEmersysCartUpdate: boolean = false;
	lpAppSettings: LpAppSetting;
	private _menuItemSelected = new Subject<any>();

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	private signInInfo = new BehaviorSubject('');

	currentStatus = this.signInInfo.asObservable();

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	menuItemSelected(event) {
		this._menuItemSelected.next(event);
	}

	get menuSelectEvents$() {
		return this._menuItemSelected.asObservable();
	}

	changeSigninInfo(message: string) {
		this.signInInfo.next(message);
	}

	getSize(id) {
		return this.httpClient.get<any>(this.environmentService.environment.siteUrl + '/getSize?id=' + id);
	}

	getMenus(): Observable<HeaderMenu> {
		if (!this.headerMenuCache$) {
			this.headerMenuCache$ = this.requestHeaderMenus().pipe(
				shareReplay({ bufferSize: 1, refCount: true })
			);
		}

		return this.headerMenuCache$;
	}

	getFooter(): Observable<MenuItem[]> {
		if (!this.footerMenuCache$) {

			this.footerMenuCache$ = this.requestFootermenus().pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this.footerMenuCache$;
	}

	getSiteContent(): Observable<SiteContent> {

		this.siteContentCache$ = this.requestSiteContent().pipe(
			shareReplay(CACHE_SIZE)
		);


		return this.siteContentCache$;
	}

	getSiteContentDetails(): Observable<SiteContentDetail> {

		this.siteContentDetailsCache$ = this.requestSiteContentDetails().pipe(
			shareReplay(CACHE_SIZE)
		);


		return this.siteContentDetailsCache$;
	}

	getShippingOptions(shippingModel: ShippingOptions): Observable<ShippingVM[]> {
		return this.httpClient.post<ShippingVM[]>(this.environmentService.environment.webserviceUrl + 'GetShippingOptions', shippingModel, this.httpOptions);
	}

	private requestShippingOptions(shippingModel: ShippingOptions): Observable<ShippingVM[]> {
		return this.httpClient.post<ShippingVM[]>(this.environmentService.environment.webserviceUrl + 'GetShippingOptions', shippingModel, this.httpOptions).pipe(
			map(response => response)
		);
	}

	getUserSettings(): Observable<UserSettings> {
		return this.httpClient.get<UserSettings>(this.environmentService.environment.siteUrl + 'usersettingdetails', this.httpOptions);
	}

	hasPlugin(webStoreId, pluginTypes): Observable<boolean> {
		if (webStoreId) {
			return this.httpClient.get<boolean>(this.environmentService.environment.accountUrl + 'HasPlugin?webStoreId=' + webStoreId + '&pluginTypes=' + pluginTypes, this.httpOptions).pipe(
				map(response => response)
			);
		}
	}

	private requestUserSettings(): Observable<UserSettings> {
		return this.httpClient.get<UserSettings>(this.environmentService.environment.siteUrl + 'usersettingdetails', this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestSiteContent(): Observable<SiteContent> {
		return this.httpClient.get<SiteContent>(this.environmentService.environment.siteUrl + 'getsitecontent', this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestSiteContentDetails(): Observable<SiteContentDetail> {
		return this.httpClient.get<SiteContentDetail>(this.environmentService.environment.siteUrl + 'sitecontentdetails', this.httpOptions).pipe(
			map(response => response)
		);
	}

	deliveryDetailSave(devileryDetails) {
		return this.httpClient.post<any>(this.environmentService.environment.checkoutUrl + 'deliverydetailsave', devileryDetails, this.httpOptions).pipe(
			map(response => response)
		);
	}

	submitCustomForm(data) {
		return this.httpClient.post<any>(this.environmentService.environment.siteUrl + 'ProcessForm', data, this.httpOptions).pipe(
			map(response => response)
		);
	}

	getLabels(): Observable<Label> {
		this.labelsCache$ = this.requestLables().pipe(
			shareReplay(CACHE_SIZE)
		);

		return this.labelsCache$;
	}

	private requestLables(): Observable<Label> {
		return this.httpClient.get<Label>(this.environmentService.environment.siteUrl + 'getlabel', this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestRegExLibrary(): Observable<RegExLibrary> {
		return this.httpClient.get<RegExLibrary>(this.environmentService.environment.siteUrl + 'getregexlibrary', this.httpOptions).pipe(
			map(response => response)
		);
	}

	getRegExLibrary(): Observable<RegExLibrary> {
		if (!this.regExLibraryCache$) {
			this.regExLibraryCache$ = this.requestRegExLibrary().pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this.regExLibraryCache$;
	}

	private requestFootermenus(): Observable<MenuItem[]> {
		return this.httpClient.get<MenuItem[]>(this.environmentService.environment.siteUrl + 'getfootermenus', this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestHeaderMenus(): Observable<HeaderMenu> {
		return this.httpClient.get<HeaderMenu>(this.environmentService.environment.siteUrl + 'getheadermenus', this.httpOptions).pipe(
			map(response => response)
		);
	}

	GeoLocation(id?: number): Observable<geolocation> {
		return this.httpClient.get<geolocation>(this.environmentService.environment.siteUrl + 'geolocation?current=' + id, this.httpOptions);
	}

	getSizeData(key): Observable<SizeChartContent> {
		return this.httpClient.get<SizeChartContent>(this.environmentService.environment.siteUrl + 'SizeChartkey?key=' + key, this.httpOptions).pipe(
			map(response => response)
		);
	}

	getSizes() {
		return this.httpClient.get<any>(this.environmentService.environment.siteUrl + 'SizeChart', this.httpOptions).pipe(
			map(response => response)
		);
	}

	getStoresDetails() {
		return this.httpClient.get<any>(this.environmentService.environment.storesUrl + 'GetStore', this.httpOptions).pipe(
			map(response => response)
		);
	}

	getStoreData(id) {
		return this.httpClient.get<any>(this.environmentService.environment.storesUrl + 'SearchStore?store=' + id, this.httpOptions).pipe(
			map(response => response)
		);
	}

	subscribeSite(data) {
		return this.httpClient.post<any>(this.environmentService.environment.siteUrl + 'Subscribe', data, this.httpOptions).pipe(
			map(response => response)
		);
	}

	isMobileDevice(): boolean {
		var isMobile = false;

		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
			isMobile = true;
		}
		return isMobile;
	}

	postAddressBookDetails(data, addressDetailsId) {
		let description = 'Delivery Address';
		let userData = JSON.parse(localStorage.getItem('userData'));
		let addAddressIdForUpdate = '';
		if (addressDetailsId) {
			addAddressIdForUpdate = '&AddressDetailId=' + addressDetailsId;
		}

		let UrlForSaveAndUpdate = this.environmentService.environment.accountUrl + 'AddressBook?FirstName=' + data.firstName + '&LastName=' + data.lastName +
			'&PhoneNumber=' + data.phoneNumber + '&County=' + data.county +
			'&AddressLine1=' + data.addressOne + '&AddressLine2=' + data.addressTwo +
			'&TownCity=' + data.townOrCity + '&PostZipCode=' + data.postCodeValue
			+ '&CountryId=' + data.country + '&Description=' + description + '&Title=' + data.title + '&PhoneCountryId=' +
			+'&IsDefaultShipping=true&IsDefaultBilling=false' +
			'&TitleId=' + data.title + addAddressIdForUpdate;

		return this.httpClient.post<any>(UrlForSaveAndUpdate, this.httpOptions);
	}

	getBrandContent() {
		return this.httpClient.get<any>(this.environmentService.environment.siteUrl + 'ProductListandDetailsByUrl?url=/heritage', this.httpOptions);
	}

	refreshPage() {
		return this.httpClient.get<any>('WebService/RefreshContent', this.httpOptions);
	}

	getDeliveryDetails(data): Observable<any> {
		return this.httpClient.get<any>(this.environmentService.environment.checkoutUrl + 'GetDeliveryDetails?iSGuest=' + data.isGuest, this.httpOptions);
	}

	addressCapture(param: any) {
		return this.httpClient.post<any>('https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws' + param, this.httpOptions);
	}

	retrieveAddress(param: any) {
		return this.httpClient.post<any>('https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws' + param, this.httpOptions);
	}

	getTrackingScripts() {
		return this.httpClient.get<TrackingScript[]>(this.environmentService.environment.siteUrl + 'TrackingScripts', this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestLpAppSettings() {
		return this.httpClient.get<LpAppSetting>(this.environmentService.environment.siteUrl + 'getapplicationsettings', this.httpOptions).pipe(
			map(response => { this.lpAppSettings = response; return response; })
		);
	}

	getLpAppSettings(): Promise<LpAppSetting> {
		if (!this.LpApp$) {
			this.LpApp$ = this.requestLpAppSettings().pipe(
				shareReplay({ bufferSize: 1, refCount: true })
			);
		}
		return this.LpApp$.toPromise();
	}

	getTitles() {
		return this.httpClient.get<Account>(this.environmentService.environment.accountUrl + 'GetTitles', this.httpOptions).pipe(
			map(response => response)
		);
	}

	importPOQBasket(parameters: string) {
		return this.httpClient.get<any>(this.environmentService.environment.basketUrl + 'ImportBasketPoq?' + parameters, this.httpOptions).pipe(
			map(response => response)
		);
	}

	GetTrackingScripts(webPageVM): Observable<TrackingScript[]> {
		return this.httpClient.post<TrackingScript[]>(this.environmentService.environment.siteUrl + 'TrackingScripts', webPageVM, this.httpOptions);
	}

	getLpAppSettingsData(): LpAppSetting {
		return this.lpAppSettings;
	}

	ParseScript(data: any) {
		var scriptContent = [];

		if (data && data.length > 0) {
			data.forEach(element => {

				var SciptCon = element.ScriptContent;

				var scriptList = [];
				if (SciptCon && SciptCon.length > 0) {
					while (SciptCon.length > 0) {
						if (SciptCon.indexOf("<script") > -1 && SciptCon.indexOf("</script>") > 0) {
							var cont = SciptCon.substring(SciptCon.indexOf("<script"), SciptCon.indexOf("</script>") + 9);
							SciptCon = SciptCon.substring(SciptCon.indexOf("</script>") + 9);
							if (cont && cont.length > 0) {
								//cont=cont+"</script>";
								scriptList.push(cont);

							}

						} else if (SciptCon.indexOf("<noscript") > -1 && SciptCon.indexOf("</noscript>") > 0) {
							var cont = SciptCon.substring(SciptCon.indexOf("<noscript"), SciptCon.indexOf("</noscript>") + 11);
							SciptCon = SciptCon.substring(SciptCon.indexOf("</noscript>") + 11);
							if (cont && cont.length > 0) {
								//cont=cont+"</script>";
								scriptList.push(cont);

							}
						} else {
							SciptCon = "";
						}


						SciptCon = SciptCon.trim();
					}
				}

				if (scriptList && scriptList.length > 0) {

					scriptList.forEach(scriptElement => {

						if (scriptElement && scriptElement.indexOf("<script") > -1) {
							//var scriptLement = scriptElement.substring(0, element.ScriptContent.lastIndexOf("</script>"));
							var scriptLement = scriptElement.replace('<script>', '');
							scriptLement = scriptLement.replace('<script type="text/javascript">', '');
							scriptLement = scriptLement.replace('</script>', '');
							if (scriptLement.indexOf("<script") > -1) {
								scriptLement = scriptLement + "</script>";
							}
							scriptLement = scriptLement.trim();
							if (scriptLement) {
								scriptContent.push({ name: 'script', content: scriptLement });

							}

						}
						if (scriptElement && scriptElement.indexOf("<noscript") > -1) {
							var startIndex = scriptElement.indexOf("<noscript>");
							var lastIndex = scriptElement.lastIndexOf("</noscript>");
							if (startIndex >= 0 && lastIndex > 0) {
								var scriptLement = scriptElement.substring(startIndex, lastIndex);
								scriptLement = scriptLement.replace('<noscript>', '');
								scriptLement = scriptLement.replace('</noscript>', '');
								scriptLement = scriptLement.trim();
								if (scriptLement) {
									scriptContent.push({ name: 'noscript', content: scriptLement });
								}
							}
						}
					})
				}
			});
		}

		return scriptContent;
	}

	RenderPromoLineBar(param: any, promoLineBarType: PromoLineBarEnum): Observable<PromoLineBarViewModel> {
		return this.httpClient.get<PromoLineBarViewModel>(this.environmentService.environment.siteUrl + 'RenderPromoLineBar?promoLineBarType=' + promoLineBarType + '&' + param, this.httpOptions);
	}

	siteVerifyV3(recaptchaToken: string) {
		return this.httpClient.get<any>(this.environmentService.environment.siteUrl + 'SiteVerifyV3?recaptchaToken=' + recaptchaToken, this.httpOptions).pipe(
			map(response => response)
		);
	}

	setWebStoreLanguage(): void {
		let navigatorLanguage = "en_GB";
		if (this.lpAppSettings.WebStoreId === 2) {
			navigatorLanguage = "de_DE";
		}
		if (this.lpAppSettings.WebStoreId === 4) {
			navigatorLanguage = "en_US";
		}
		if (this.lpAppSettings.WebStoreId === 5) {
			navigatorLanguage = "en_AU";
		}
		localStorage.setItem(NAVIGATOR_LANGUAGE, navigatorLanguage);
	}
}
