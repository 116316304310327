import { Component, OnInit } from '@angular/core';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { Label } from '../../models/Labels';
import { SiteContentDetail } from '../../models/siteContent';
import { StoresViewModel } from '../../models/stores-vm';
import { LabelService } from '../../service/label.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'app-stores',
	templateUrl: './stores.component.html',
	styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
	sitecontent: SiteContentDetail;
	label: Label;
	stores: StoresViewModel;
	LpAppSetting: LpAppSetting;
	classlist = "fixed-header mask-hide ng-scope ";

	constructor(
		private sharedService: SharedService, private labelService: LabelService,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.sharedService.menuItemSelected(window.location.pathname);
		this.getLpAppSetting();
		this.getAllLabels();
		this.getSiteContent();
		this.sharedService.getStoresDetails().subscribe(res => {
			this.stores = res;
		});
	}

	getAllLabels() {
		this.label = this.labelService.getLabelData();
		if (!this.label) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.label = data;
				}
			});
		}
	}

	getSiteContent() {

		this.sitecontent = this.siteContentService.getSiteContentData();
		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {

				this.sitecontent = data;
			})
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.addBodyClass();
	}

	addBodyClass() {
		//if (this.LpAppSetting) {
		//	if (this.LpAppSetting.UserSettings && !this.LpAppSetting.UserSettings.PromoLineBarHeader) {
		//		this.classlist += "no-promo-text ";
		//		$("body").removeClass();
		//		$("body").addClass(this.classlist);
		//	}

		//}
	}
}

