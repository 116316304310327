import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AuthService } from '../../auth.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	forgotPassForm: UntypedFormGroup;
	emailNotExist: boolean = false;
	sitecontent: SiteContentDetail;
	submitted: boolean = false;
	PasswordSentSuccess: boolean = false;
	constructor(private fb: UntypedFormBuilder, private authService: AuthService,
		private commonService: DefaultService, private sharedService: SharedService,
		private siteContentService: SiteContentService) { }

	ngOnInit() {
		this.sharedService.menuItemSelected(window.location.pathname);
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.initializeForm();
	}
	get f() { return this.forgotPassForm.controls; }
	initializeForm() {
		this.forgotPassForm = this.fb.group({
			emailAddress: ["", [Validators.required, Validators.email]]
		})
	}
	submitforgotPassForm() {

		this.submitted = true;
		this.emailNotExist = false;
		if (this.forgotPassForm.invalid) {
			return;
		}
		this.authService.postForgottenPassword(this.forgotPassForm.value).subscribe(res => {

			if (res) {
				this.PasswordSentSuccess = true;
			} else {
				this.emailNotExist = true;
			}
		})
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}

}
