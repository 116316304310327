import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartService } from '../../service/cart.service';
import { ShoppingBasket } from '../../../model/shopping-basket';
import { Basket, BasketPageViewModel } from '../../../model/basketpage-viewmodel';
import { RegExLibrary } from '../../models/regex-library';
import { SiteContentDetail } from '../../models/siteContent';
import { Label } from '../../models/Labels';
import { ShippingOptions, ShippingToClickAndCollect, ShippingVM } from '../../models/shippingoptions';
import { PromoCodeVM } from 'src/app/model/basket-viewmodel';
import { Router } from '@angular/router';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { InitialiseBasketTracking } from '../../../../assets/shared/gtm.js';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'basket-summary-dir,[basket-summary-dir]',
  templateUrl: './checkout-basket-summary.component.html',
  styleUrls: ['./checkout-basket-summary.component.scss']
})
export class CheckoutBasketSummaryComponent implements OnInit, OnChanges {

  @Input() sitecontent: SiteContentDetail;
  @Input() regExLibrary: RegExLibrary;
  @Input() label: Label;
  @Input() shippingToClickAndCollect: ShippingToClickAndCollect;
  @Input() shippingVM: ShippingVM[];
  @Input() selectedShippingId: number = 40;
  @Output() shippingIdChanged = new EventEmitter<any>();
  promocodeVM: PromoCodeVM;
  promocode: string;
  @Input() isDutyFree: boolean;
  LpAppSetting:LpAppSetting;
  constructor(private cartService: CartService, private sharedService:SharedService,
    private fb: UntypedFormBuilder, private router: Router) { }

  cartItems: any[];
  totalAmount = 0;
  promoCodeForm: UntypedFormGroup;
  showPromoCodeMessage: Boolean = false;
  messagesForPromoCode;
  shoppingBasket: ShoppingBasket;
  basketPageViewModel: BasketPageViewModel;
  hidePrice: boolean = false;
  @Input() basket: Basket;
  showdeliveryoption: boolean = true;
  canToggle: boolean = false;


  ngOnInit() {
    this.getLpAppSetting();
    this.initiatePromoForm();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  applyPromoCode() {

    if(!this.promocode){
      return;
     }
    this.promocodeVM = {} as PromoCodeVM;
    this.promocodeVM.PromoCode = this.promocode;

    this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {

      if (res) {
        if (res.Result && +res.Result === 2 || res.Result==2) {
          if (this.basket) {
            //this.basket.PromoCode = this.promocode;
            this.basket.PromoCode = this.promocode;
            this.cartService.GetToBasket().subscribe(data=>{
              if(data){
                this.basket=data;
                
                 //gmt start
                  if (data && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
                    InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data, false, false)
                  }
                //gmt end
               
              }
            })
          }
          this.showPromoCodeMessage = false;
          this.messagesForPromoCode = '';
        }else{
          this.messagesForPromoCode = res.Message;
          this.showPromoCodeMessage = true;
        }


        
        
      }
    })

  }

  private async getLpAppSetting(): Promise<void> {
    this.LpAppSetting = await this.sharedService.getLpAppSettings();
  }

  removePromoCode() {

    this.promocodeVM = {} as PromoCodeVM;

    this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {

      if (res) {
        if (res.Result && +res.Result === 0 || res.Result == 0) {
          if (this.basket) {
            this.basket.PromoCode = null;
            this.showPromoCodeMessage = false;
            this.messagesForPromoCode = res.Message;
          
            this.promocode = null;
            this.cartService.GetToBasket().subscribe(data=>{
              if(data){
                this.basket=data;
               
                 //gmt start
                  if (data && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
                    InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data, false, false)
                  }
                //gmt end
                
              }
            })

          }
        }

      }
    })
  }
  grandTotal() {
    let total = 0;
    if (this.basket) {
      total = this.basket.SubTotal - this.basket.ActualDiscount;
      //total = this.basket.SubTotal;
    }
    if (this.shippingToClickAndCollect) {
      if (!this.shippingToClickAndCollect.showdeliveryoption) {
        total += this.shippingToClickAndCollect.price;
        return total;
      }
    }
    if (this.shippingVM && this.shippingVM.length > 0) {
      let shippingprice = this.shippingVM.filter(x => x.Id == this.selectedShippingId);
      //let shippingprice=this.shippingVM.filter(x=>x.Selected===true);
      if (shippingprice && shippingprice.length > 0) {
        total += shippingprice[0].Price;
      }

    }
    return total;
  }
  initiatePromoForm() {
    this.promoCodeForm = this.fb.group({
      promoCodeText: ['', Validators.required]
    })
  }
  submitPromoCodeForm() {
    this.cartService.checkPromoCode(this.promoCodeForm.value).subscribe(res => {
      this.showPromoCodeMessage = true;
      this.messagesForPromoCode = res.Message;
    })
  }
  onOptionSelect(shippingId) {
    this.shippingIdChanged.emit(shippingId);
  }
}

