import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { MenuItem } from 'src/app/shared/models/menuItem';
import { SiteContentDetail, SocialLinkListItem } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../service/scroll.service';
import * as $ from 'jquery';

@Component({
	selector: 'default-footer',
	templateUrl: './default-footer.component.html',
	styleUrls: ['./default-footer.component.scss']
})
export class DefaultFooterComponent implements OnInit, AfterViewInit {

	menuItems: MenuItem[];
	socialLinkListItems$: Observable<SocialLinkListItem[]>;
	copyrightContent: string;
	year = new Date().getFullYear().toString();
	LpAppSetting: LpAppSetting;
	host: string;
	sitecontent: SiteContentDetail;
	isDataLoaded: Promise<boolean>;

	constructor(private sharedService: SharedService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.getLpAppSetting();

		this.getFooter();
	}

	getFooter(): void {
		this.sharedService.getFooter().subscribe(data => {
			this.menuItems = data;

			this.isDataLoaded = Promise.resolve(true);
		});
	}

	getLpAppSetting() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	scrollTop() {
		this.scrollService.windowScrollTo(0, 0);
	}

	ngAfterViewInit() {
		setTimeout(function () {
			$('.site-footer').removeClass('visibilityHidden');
			$('.site-footer').addClass('visibilityVisible');
		}, 2000);
	}

	getContentHTML(sitecontent) {
		return (
			this.year +
			" &copy; Copyright " +
			sitecontent.BrandCompanyName + ' - ' +
			sitecontent.HeadOfficeAddress + ' – Company No. 328 1481' +
			(sitecontent.VATNumber ? " – UK Vat No. " + sitecontent.VATNumber : "")
		);
	}
}
