import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared.service';
import { ScrollService } from '../../service/scroll.service';
@Component({
	selector: 'app-checkout-poq',
	templateUrl: './checkout-poq.component.html',
	styleUrls: ['./checkout-poq.component.scss']
})
export class CheckoutPOQComponent implements OnInit {
	constructor(@Inject(DOCUMENT) document,
		private router: Router,
		private sharedService: SharedService,
		private scrollService: ScrollService) {
	}

	ngOnInit() {
		let urlSplit = this.router.url.split('?');
		let parameters = urlSplit[1];

		this.importPOQBasket(parameters);
		this.scrollService.windowScrollTo(0, 0);
	}

	importPOQBasket(parameters: string) {
		this.sharedService.importPOQBasket(parameters).subscribe(data => {
			if (data.result == "Success") {
				this.redirectToCheckout();
			}
			else {
				this.redirectToBasket();
			}
		}, error => {
			console.error(error);
		});
	}

	redirectToCheckout() {
		this.router.navigate(["/checkout/welcome"]);
	}

	redirectToBasket() {
		this.router.navigate(["/checkout/shoppingbasket"]);
	}
}
