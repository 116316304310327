import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddBasket, DeleteBasketItem, PromoCodeVM } from 'src/app/model/basket-viewmodel';
import { Basket, BasketPageViewModel } from 'src/app/model/basketpage-viewmodel';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { EnvironmentService } from '../environment.service';
import { InitialiseBasketTracking, InitialiseAddToCartTracking } from 'src/assets/shared/gtm.js';

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 10000;

@Injectable()
export class CartService {
	public cartItems = [];
	public basketItems = {};

	public products = new Subject<BasketPageViewModel>();
	public totalAmount = 0;
	public displayCart: boolean;
	private basketData$: Observable<any>;

	private basketPageVM = {} as BasketPageViewModel;

	basketviewmodel = {} as BasketPageViewModel;
	public content = new BehaviorSubject<BasketPageViewModel>(this.basketviewmodel);
	public share = this.content.asObservable();

	private shoppingBasket: BehaviorSubject<BasketPageViewModel> = new BehaviorSubject(null);
	basketUpdated: boolean = false;

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json'
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	public getProductList(): Observable<any> {

		let productList = localStorage.getItem("basket");
		if (productList) {
			let product = JSON.parse(productList);
			return of(product);
		}
		return of();
	}

	getShoppingBasket(): Observable<BasketPageViewModel> {
		return this.shoppingBasket.asObservable();
	}

	setShoppingBasket(): void {
		this.shoppingBasket.next(null);
	}

	refreshLocalBasket(basket: any, addToBasket: boolean) {

		if (!this.basketPageVM) {
			this.basketPageVM = {} as BasketPageViewModel;
		}

		if (basket) {
			if (addToBasket) {


				if (this.basketPageVM) {
					this.basketPageVM.ShoppingBasketPage.Basket = basket.Basket;
					this.shoppingBasket.next(this.basketPageVM);
				}


			} else {

				this.basketPageVM = basket;
				this.shoppingBasket.next(basket);
			}
		}
	}

	getShoppingCart(shoppingBasket: ShoppingBasket) {
		let paypayCancelled = null;
		if (shoppingBasket && shoppingBasket.paypalCancelled) {
			paypayCancelled = shoppingBasket.paypalCancelled;
		}

		return this.http.get<any>(this.environmentService.environment.basketUrl + "ShoppingBasket?paypalCancelled=" + paypayCancelled, this.httpOptions);
	}

	updateItemFromBasket(deleteBasketItem: DeleteBasketItem) {
		return this.http.post<any>(this.environmentService.environment.basketUrl + "updateitemfrombasket", deleteBasketItem, this.httpOptions);
	}

	AddToBasket(addBasket: AddBasket): Observable<any> {
		this.basketUpdated = true;
		return this.http.post<any>(this.environmentService.environment.basketUrl + "AddToBasket", addBasket, this.httpOptions);
	}

	GetToBasket(): Observable<Basket> {
		return this.http.get<Basket>(this.environmentService.environment.basketUrl + "GetBasket", this.httpOptions);
	}

	private requestBasketData(shoppingBasket: ShoppingBasket): Observable<any> {
		return this.http.post<any>(this.environmentService.environment.basketUrl + "ShoppingBasket", shoppingBasket, this.httpOptions).pipe(
			map(response => response)
		);
	}

	checkPromoCode(promocodeVM: PromoCodeVM) {
		return this.http.post<any>(this.environmentService.environment.basketUrl + "AddPromoCode", promocodeVM, this.httpOptions).pipe(
			map(response => response)
		);
	}

	GetProductStock(sku: string): Observable<any> {
		return this.http.post<any>(this.environmentService.environment.webserviceUrl + "GetProductStock?sku=" + sku, this.httpOptions);
	}

	GetProductSizeInformation(sku: string): Observable<any> {
		return this.http.post<any>(this.environmentService.environment.webserviceUrl + "GetProductSizeInformation?sku=" + sku, this.httpOptions);
	}

	GetRegularSizes(sku: string): Observable<any> {
		return this.http.post<any>(this.environmentService.environment.webserviceUrl + "GetRegularSizesForCategoryBySKU?sku=" + sku, this.httpOptions);
	}

	trackAddToCart(basketData, addToCartData, lpAppSetting) {
		//console.log("Calling cart-service trackAddToCart -> " + JSON.stringify(addToCartData));

		InitialiseBasketTracking(lpAppSetting.CriteoSettings, basketData, false, true);

		InitialiseAddToCartTracking(addToCartData);
		
		if (basketData.ChangedQty < 0) {
			window['dataLayer'].push({
				"event": "remove_from_cart"
			});
		} else {
			window['dataLayer'].push({
				"event": "add_to_cart"
			});
		}
	}
}
