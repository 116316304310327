import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSafedata]'
})
export class SafedataDirective {

  @Input() value1:any;
  @Input() value2:any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
) {}

  ngOnInit() {
    
    let content="";
    if(this.value1){
      content=this.value1;
    }
    if(this.value2){
      content+=this.value2;
    }
    if(content){
      content=content.replace(new RegExp('href="#"', 'g'),"");
      content=content.replace(new RegExp('data-scrollto', 'g'),"datascrollto");
    }
    var line = document.createElement("div");
    line.innerHTML=content;

    this.renderer.appendChild(this.el.nativeElement, line.firstChild);

    //this.renderer.insertBefore(this.el.nativeElement, content);
    
  }

}
