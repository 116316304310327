import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { List } from 'src/app/model/country-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { HomeService } from 'src/app/modules/Home/home.service';
import { StateList } from '../../models/checkout-delivery.model';
import { AddressList } from '../../models/checkout-payment.model';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingOptions, ShippingVM, UpdateBasketShipping } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { SharedService } from '../../shared.service';

@Component({
	selector: 'address-entry,[address-entry]',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {

	@Input() sitecontent: SiteContentDetail;
	@Input() regExLibrary: RegExLibrary;
	@Input() label: Label;
	@Input() frmDelivery: UntypedFormGroup;
	@Input() submitted: boolean;
	@Input() stateList: StateList[] = [];
	@Input() currentCulture: string;
	@Input() validCountriesList: List[] = [];
	@Input() allCountries: boolean = false;
	@Output() countryChanged = new EventEmitter<ShippingOptions>();
	@Output() useThisAddressClicked = new EventEmitter<boolean>();

	// Used to set Billing Address for Adyen Payment Provider
	@Input() newAddress: boolean;
	@Output() addressChanged = new EventEmitter<any>();

	updateBasketShipping: UpdateBasketShipping;
	shippingVM: ShippingVM[];

	showSearchBox: boolean = false;
	countriesList: List[] = [];
	ukCountryId: number = 1;
	selectedCountryId: number;
	readonly: boolean = true;
	showSearchAgainLink: boolean = false;
	isGuest: boolean = true;
	LpAppSetting: LpAppSetting;
	loqateSupportedCountries = [
		{ name: 'United Kingdom', id: 1 },
		{ name: 'Australia', id: 7 },
		{ name: 'Germany', id: 2 },
		{ name: 'United States', id: 125 }
	];

	loqateEnabled: boolean = true;
	deliveryFormLoaded: Promise<boolean>;
	address: string;
	mySubscription: Subscription;
	address1: string;
	countryId: number = 1;
	keyword: string = 'Text';
	addressList: AddressList[] = [];
	PostCodeAnywhereSecurityKey: string;
	shippingModel: ShippingOptions;
	stateCode: string;
	countryLanguage: string;

	countryIso2: string;

	constructor(
		private fb: UntypedFormBuilder,
		private router: Router,
		private homeService: HomeService,
		private sharedService: SharedService) {
	}

	ngOnInit() {
		this.getLpSettings();
		this.getAllCountries();

		this.validateAddress(this.frmDelivery.controls['countryId'].value);

		if (this.frmDelivery && this.frmDelivery.controls) {
			this.selectedCountryId = this.frmDelivery.controls['countryId'].value;
			this.countryId = this.frmDelivery.controls['countryId'].value;
			if (this.selectedCountryId == 125) {
				this.frmDelivery.controls['stateCode'].setValidators([Validators.required]);
			} else {
				this.frmDelivery.controls['stateCode'].clearValidators();
			}
		}

		this.isGuest = !!localStorage.getItem('isGuestLogin');

		if (this.newAddress) {
			this.readonly = false;
			this.showSearchBox = !this.sharedService.manuallyClicked;
		} else {
			if (this.isGuest && !this.frmDelivery && !this.frmDelivery.controls && this.frmDelivery.controls['addressLine1'] && !this.frmDelivery.controls['addressLine1'].value) {
				this.readonly = false;
				this.showSearchBox = true;
			}

			if (this.frmDelivery && this.frmDelivery.controls['addressLine1'] && !this.frmDelivery.controls['addressLine1'].value) {
				this.readonly = false;
				this.showSearchBox = true;
			}
		}

		if (this.countryId) {
			this.onCountryChanged(this.countryId)
		}
	}

	ngOnChanges() {
		if (this.address1) {
		}
	}

	getLpSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();

		if (this.LpAppSetting && this.LpAppSetting.PostCodeAnywhereSecurityKey) {
			this.PostCodeAnywhereSecurityKey = this.LpAppSetting.PostCodeAnywhereSecurityKey;
		}
	}

	onSelect(result) {
		if (result) {
			if (result.Type !== 'Address') {
				this.getAddressFromPostcodeAnywhere(result.Text, result.Id);
			} else if (result.Type == 'Address') {
				this.retrieveAddress(result.Id);
			}
		}

		this.useThisAddressClicked.emit(true);
	}

	getAddressFromPostcodeAnywhere(value, Id) {
		var Key = this.PostCodeAnywhereSecurityKey;

		var IsMiddleware = false,
			Origin = '',
			Countries = 'GBR',
			Limit = '10',
			Language = 'en-gb';

		if (this.LpAppSetting && this.LpAppSetting.CurrentCulture) {
			let culture = this.LpAppSetting.CurrentCulture;

			if (this.loqateEnabled && this.countryLanguage) {
				culture = this.countryLanguage;
			}
			Language = culture.toLocaleLowerCase();

			let countryCode = culture.split('-');

			if (countryCode && countryCode.length > 1) {
				Countries = countryCode[1];
			}
		}

		var params = '';
		params += '?Key=' + encodeURIComponent(Key);
		params += '&Text=' + encodeURIComponent(value);
		params += '&IsMiddleware=' + encodeURIComponent(IsMiddleware);

		if (Id) {
			params += '&Container=' + encodeURIComponent(Id);
		} else {
			params += '&Container=' + encodeURIComponent('');
		}

		params += '&Origin=' + encodeURIComponent(Origin);
		params += '&Countries=' + encodeURIComponent(Countries);
		params += '&Limit=' + encodeURIComponent(Limit);
		params += '&Language=' + encodeURIComponent(Language);

		this.sharedService.addressCapture(params).subscribe(result => {
			if (result && result.Items && result.Items.length > 0) {
				this.addressList = result.Items;
			}
		});
	}

	setDataList(result) {
		if (result && result.Items && result.Items.length > 0) {
			this.addressList = result.Items;
		}
	}

	onChangeSearch(val: string) {
		this.getAddressFromPostcodeAnywhere(val, null);
	}

	retrieveAddress(Id) {

		var Key = this.PostCodeAnywhereSecurityKey;
		var Field1Format = '';
		var params = '';

		params += '?Key=' + encodeURIComponent(Key);
		params += '&Id=' + encodeURIComponent(Id);
		params += '&Field1Format=' + encodeURIComponent(Field1Format);
		this.sharedService.retrieveAddress(params).subscribe(result => {
			if (result && result.Items && result.Items.length > 0) {
				let postcodeAddress = result.Items[0];
				if (postcodeAddress) {
					{
						const { Company, Line1, Line2, Line3, Line4, Line5 } = postcodeAddress;
						const frmDeliveryControls = this.frmDelivery.controls;
						const addressParts = [Company, Line1, Line2, Line3, Line4, Line5].filter(part => part !== '');

						function getLineOne() {
							let firstLine = [];
							firstLine.push(addressParts.shift())
							if (Company !== '') {
								firstLine.push(addressParts.shift())
							}

							return firstLine.join(', ')
						}

						frmDeliveryControls['addressLine1'].setValue(getLineOne() || '');
						frmDeliveryControls['addressLine2'].setValue(addressParts.join(', '));
					}
					if (postcodeAddress.City) {
						this.frmDelivery.controls['townCity'].setValue(postcodeAddress.City);
					}
					if (postcodeAddress.ProvinceName) {
						this.frmDelivery.controls['county'].setValue(postcodeAddress.ProvinceName);
					}
					if (postcodeAddress.CountryName) {
						this.frmDelivery.controls['country'].setValue(postcodeAddress.CountryName);
					}
					if (postcodeAddress.PostalCode) {
						this.frmDelivery.controls['postZipCode'].setValue(postcodeAddress.PostalCode);
					}
					if (postcodeAddress.ProvinceCode) {
						this.frmDelivery.controls['stateCode'].setValue(postcodeAddress.ProvinceCode);
						this.stateCode = postcodeAddress.ProvinceCode;
					}
					if (postcodeAddress.StateCode) {
						this.frmDelivery.controls['StateCode'].setValue(postcodeAddress.StateCode);
					}
					if (postcodeAddress.CountryIso2) {
						this.countryIso2 = postcodeAddress.CountryIso2;
					}

					this.address = '';
					this.addressList = null;
					this.readonly = true;
					this.showSearchBox = false;
					let countryId = this.frmDelivery.controls['countryId'].value;
					this.shippingModel = {} as ShippingOptions;
					this.shippingModel.countryId = countryId;
					this.shippingModel.isClickAndCollect = false;
					this.shippingModel.postcode = postcodeAddress.PostalCode;
					this.getShippingOptions(this.shippingModel);
				}
			}

			this.addressChanged.emit(this.frmDelivery);
		});
	}

	isSearchEnable(countryId) {
		return this.loqateSupportedCountries.filter(function (x) { return x.id == countryId; }).length > 0;
	}

	onSearchAgain = function () {
		this.showSearchBox = true;
		this.showSearchAgainLink = false;

		if (this.frmDelivery && this.frmDelivery.controls) {
			this.frmDelivery.controls['postZipCode'].setValue('');
		}
	};

	getAllCountries() {
		this.homeService.getAllCountries(this.allCountries).subscribe(res => {
			this.countriesList = res.List;
		});
	}

	getAddressFromPostcode(value) {
		this.address1 = value;
	}

	useThisAddress() {
		// AR 20240812: Causing massive bug on Live site on first load (no idea why so commented it out)
		//this.useThisAddressClicked.emit(true);

		this.addressChanged.emit(this.frmDelivery);

		let addressLine1 = this.frmDelivery.controls['addressLine1'].value;
		let townCity = this.frmDelivery.controls['townCity'].value;
		//let county = this.frmDelivery.controls['county'].value;
		let postZipCode = this.frmDelivery.controls['postZipCode'].value;

		if (addressLine1 == null || addressLine1.trim() === '') {
			this.frmDelivery.controls['addressLine1'].setErrors({ 'incorrect': true });
		}

		if (townCity == null || townCity.trim() === '') {
			this.frmDelivery.controls['townCity'].setErrors({ 'incorrect': true });
		}

		//if (county == null || county.trim() === '') {
		//	this.frmDelivery.controls['county'].setErrors({ 'incorrect': true });
		//}

		if (postZipCode == null || postZipCode.trim() === '') {
			this.frmDelivery.controls['postZipCode'].setErrors({ 'incorrect': true });
		}

		if (!this.frmDelivery.valid) {
			return;
		}

		this.readonly = true;
		this.broadcastAddressUpdate();

		let countryId = this.frmDelivery.controls['countryId'].value;
		let postalcode = this.frmDelivery.controls['postZipCode'].value;
		this.shippingModel = {} as ShippingOptions;

		if (countryId && postalcode) {
			this.shippingModel.countryId = countryId;
			this.shippingModel.isClickAndCollect = false;
			this.shippingModel.postcode = postalcode;
			this.getShippingOptions(this.shippingModel);
		}
	}

	broadcastAddressUpdate() {
		this.showSearchAgainLink = this.showSearchBox === true;
		this.showSearchBox = false;
	}

	editAddress() {
		this.readonly = false;
		this.showSearchBox = false;
		this.showSearchAgainLink = true;
	}

	getDeliveryAddress() {
		let fullAddress = '';

		if (this.frmDelivery && this.frmDelivery.controls) {

			if (this.frmDelivery.controls['addressLine1'].value) {
				fullAddress += this.frmDelivery.controls['addressLine1'].value + ', ';
			}
			if (this.frmDelivery.controls['addressLine2'].value) {
				fullAddress += this.frmDelivery.controls['addressLine2'].value + ', ';
			}
			if (this.frmDelivery.controls['townCity'].value) {
				fullAddress += this.frmDelivery.controls['townCity'].value + ', ';
			}
			if (this.frmDelivery.controls['county'].value) {
				fullAddress += this.frmDelivery.controls['county'].value + ', ';
			}
			if (this.frmDelivery.controls['stateCode'].value) {
				fullAddress += this.frmDelivery.controls['stateCode'].value + ', ';
			}
			if (this.frmDelivery.controls['postZipCode'].value) {
				fullAddress += this.frmDelivery.controls['postZipCode'].value + ', ';
			}
			if (this.frmDelivery.controls['country'].value) {
				fullAddress += this.frmDelivery.controls['country'].value;
			}
		}

		return fullAddress;
	}

	getCountryName(country) {
		if (country) {
			this.frmDelivery.controls['countryId'].setValue(country.CountryId);
			this.frmDelivery.controls['country'].setValue(country.Name);
			return country.Name;
		}
	}

	get f() {
		return this.frmDelivery.controls;
	}

	getAddressDescription(adrs) {
		try {
			if (adrs && adrs.Description) {
				if (adrs.Description.indexOf('Addresses') > -1) {
					let res = adrs.Description.split('-');
					if (res && res.length > 1) {
						adrs.Results = res[1].replace('Addresses', 'Results');
						adrs.Description = res[0];
					}
				}
			}
			return adrs.Description;
		} catch (ex) {

		}
	}

	getShippingOptions(shippingModel: ShippingOptions) {
		this.countryChanged.emit(shippingModel);
	}

	updateShippingOption(shippingOptionsId, CountryId) {
		this.updateBasketShipping = {} as UpdateBasketShipping;
		this.updateBasketShipping.ShippingId = shippingOptionsId;
		this.updateBasketShipping.CountryId = +CountryId;

		if (shippingOptionsId) {
			this.frmDelivery.controls['selectedShippingId'].setValue(shippingOptionsId);
		}

		this.homeService.updateBasketShippingOption(this.updateBasketShipping).subscribe(res => {
		});
	}

	resetAddressForm() {
		this.frmDelivery.controls['addressLine1'].setValue('');
		this.frmDelivery.controls['addressLine2'].setValue('');
		this.frmDelivery.controls['townCity'].setValue('');
		this.frmDelivery.controls['county'].setValue('');
		this.frmDelivery.controls['postZipCode'].setValue('');
		this.frmDelivery.controls['stateCode'].setValue('');
	}

	onOptionsSelected(countryId) {
		this.selectedCountryId = countryId;
		this.onCountryChanged(countryId);

		if (this.selectedCountryId == 22) {
			this.frmDelivery.controls['county'].setValidators([Validators.required]);
		} else {
			this.frmDelivery.controls['county'].clearValidators();
		}

		if (this.selectedCountryId == 125) {
			this.frmDelivery.controls['stateCode'].setValidators([Validators.required]);
		} else {
			this.frmDelivery.controls['stateCode'].clearValidators();
		}

		this.frmDelivery.controls['county'].updateValueAndValidity();
		let previousCountryName;

		if (this.frmDelivery.controls['country']) {
			previousCountryName = this.frmDelivery.controls['country'].value;
		}

		if (countryId) {
			const cId = +countryId;

			if (this.countriesList && this.countriesList.length > 0) {
				const country = this.countriesList.filter(x => x.CountryId == cId);

				if (country) {
					this.frmDelivery.controls['country'].setValue(country[0].Name);

					if (country[0].Name != previousCountryName) {
						this.resetAddressForm();
						this.shippingModel = {} as ShippingOptions;
						this.shippingModel.countryId = cId;
						this.shippingModel.isClickAndCollect = false;
						this.shippingModel.postcode = '';
						this.getShippingOptions(this.shippingModel);
					}
				}
			}
		}
	}

	onCountryChanged(countryId) {
		let isCoutryExist = false;

		if (countryId && this.loqateSupportedCountries && this.loqateSupportedCountries.length > 0) {
			let id = +countryId;

			this.loqateSupportedCountries.forEach(x => {
				if (x.id === id) {
					isCoutryExist = true;
				}
			});

			if (isCoutryExist) {
				this.loqateEnabled = true;

				if (this.countriesList && this.countriesList.length > 0) {
					var country = this.countriesList.filter(x => x.CountryId === id);

					if (country && country.length > 0) {
						this.countryLanguage = country[0].Code;
					}
				}
			} else {
				this.loqateEnabled = false;
				this.showSearchBox = false;
			}
		}
	}

	validateAddress(countryId) {
		let isValid = false;
		let country;
		if (this.validCountriesList && this.validCountriesList.length > 0) {
			country = this.validCountriesList.filter(x => x.CountryId === countryId);
			if (country && country.length > 0) {
				isValid = true;
			}
		}
		if (!isValid) {
			this.resetAddressForm();
			let defaultCountries = this.validCountriesList.filter(x => x.Code == this.currentCulture);
			this.frmDelivery.controls['countryId'].setValue(defaultCountries[0].CountryId);
			this.frmDelivery.controls['county'].setValue(defaultCountries[0].Name);
		}
	}
}
