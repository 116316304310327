import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FitContentVM } from 'src/app/model/fitcontent-viewmodel';
import { SizeGuideComponent } from 'src/app/shared/components/size-guide/size-guide.component';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
  selector: '[fit-content],fit-content',
  templateUrl: './fit-content.component.html',
  styleUrls: ['./fit-content.component.scss']
})
export class FitContentComponent implements OnInit {
  @Input() fitContentVM: FitContentVM;
  availableSizes = [];
  matDialogRef: MatDialogRef<SizeGuideComponent>;
  siteContent: SiteContentDetail;
  constructor(private shared: SharedService,public dialog: MatDialog,
    private siteContentService: SiteContentService) {
  }

  ngOnInit() {
    this.siteContent = this.siteContentService.getSiteContentData();
    this.getSizes();
  }

  getSizes() {

    this.shared.getSize(0).subscribe(data => {
      this.availableSizes = data;
    });
  }
  getSizeGuide(sizeChartKeyValue) {


    this.matDialogRef = this.dialog.open(SizeGuideComponent, {
       height: 'auto',
       panelClass: 'my-dialog',
      data: { productKey: sizeChartKeyValue },
      disableClose: true
    });

    this.matDialogRef.afterClosed().subscribe(res => {
     
    });
    
  }
}
