import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { AddBasket } from 'src/app/model/basket-viewmodel';
import { Basket } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ElementTypeEnum } from 'src/app/model/product-webpage-model';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { MatchproductmodelComponent } from 'src/app/shared/components/matchproductmodel/matchproductmodel.component';
import { AddToCartData, CartModel } from 'src/app/shared/models/cart-model';
import { Label } from 'src/app/shared/models/Labels';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { CartService } from 'src/app/shared/service/cart.service';
import { LabelService } from 'src/app/shared/service/label.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ImageTypeKey } from '../../../enums/image-type-key-enum';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { ScrollService } from '../../../shared/service/scroll.service';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { DefaultService } from '../../layout/default.service';
import { Matchproductmodel, MixAndMatchImageUrl } from '../../product-details/models/matchproduct-model';
import { Image, InventoryProduct, ProductDetails, Property, SizesSelectList } from '../../product-details/models/product-detail';

@Component({
	selector: 'quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, AfterViewInit, OnDestroy {

	productdetail: ProductDetails;
	getProductData!: Observable<ProductDetails>;
	detail: ProductDetails;
	sizeData: SizesSelectList[];
	addToCartData: AddToCartData;
	images: Image[];
	items = [];
	currentRate = 0
	Property: Property[];
	name: number;
	imageGallery: string[];
	imageCount = 4;
	inventoryProduct: InventoryProduct[];
	cartModel: CartModel;
	addBasket: AddBasket;
	basket: Basket;
	jsEnabled: boolean = true;
	shoppingBasket: ShoppingBasket;

	label: Label;
	private inited;
	siteContent: SiteContentDetail;
	matchproductmodel: Matchproductmodel;
	ProductMatchedUrls: MixAndMatchImageUrl;
	matDialogRef2: MatDialogRef<MatchproductmodelComponent>;
	LpAppSetting: LpAppSetting;
	offerTokenCookie: string = '';
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	slideConfig = {
		"slidesToShow": 1,
		"slidesToScroll": 1,
		"arrows": true,
		"autoplay": true,
		"autoplaySpeed": 7000,
		"prevArrow": "<button  class='slick-prev slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>",
		"nextArrow": "<button class='slick-next slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block'><i></i><span></span></button>",
	};

	constructor(
		public dialog: MatDialog,
		private _mdr: MatDialogRef<QuickViewComponent>,

		@Inject(MAT_DIALOG_DATA) data: any,

		private defaultService: DefaultService,
		private router: Router,
		private cartService: CartService,
		private siteContentService: SiteContentService,
		private sharedService: SharedService,
		private scrollService: ScrollService,
		private labelService: LabelService) {

		this.name = data.productId;
		this.getData(data.productId)
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
		this.getAllImgaes();
		this.getLpAppSetting();
		this.getAllLabels();
		this.offerTokenCookie = this.getOfferTokenCookie();

		this._mdr.afterOpened().subscribe(() => {
			this.inited = true;
		})
	}

	ngAfterViewInit() {
		$('.quickview').on('click', function (event) {
			event.stopPropagation();
		});
	}

	ngOnDestroy() {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
	}

	@HostListener('window:click')
	onNoClick(): void {
		if (this.inited) {
			//  this._mdr.close();
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	getOfferTokenCookie(): string {
		let cookie = '';

		if (document.cookie && document.cookie !== '') {
			const split: Array<string> = document.cookie.split(';');
			for (let i = 0; i < split.length; i += 1) {
				const currentCookie: Array<string> = split[i].split('=');
				currentCookie[0] = currentCookie[0].replace(/^ /, '');
				if (currentCookie[0] && currentCookie[0].toLocaleLowerCase() === "offertoken") {
					cookie = decodeURIComponent(currentCookie[1]);
					break;
				}
			}
		}

		return cookie;
	}

	getAllLabels() {
		this.label = this.labelService.getLabelData();
		if (!this.label) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.label = data;
				}
			});
		}
	}

	getData(id): void {

		this.defaultService.getCategoryWebPage(id).subscribe(response => {
			if (response.ElementType === ElementTypeEnum.Product) {

				if (response.ProductPageViewModel) {
					this.detail = response.ProductPageViewModel;
					this.productdetail = response.ProductPageViewModel;
					this.sizeData = response.ProductPageViewModel.Product.SizesSelectList;
					this.images = response.ProductPageViewModel.Product.Images;
					this.Property = response.ProductPageViewModel.Product.Property;
					this.inventoryProduct = response.ProductPageViewModel.Product.InventoryProducts;
					this.getAllImgaes();
				}
			}
		});
	}

	getAllImgaes() {
		this.items = [];
		this.imageGallery = [];

		if (this.images) {
			this.images.forEach(image => {

				let largeImage;

				image.ImageSizes.forEach(imageSize => {
					if (imageSize.Key === ImageTypeKey.New2Large) {
						largeImage = imageSize.CdnFullUrl;
					}
				})

				if (largeImage) {
					this.imageGallery.push(largeImage);
				}
			})

			this.imageCount = this.imageGallery.length;
		}
	}

	getSalepercentage(retailPrice, salePrice) {
		if (retailPrice && salePrice) {
			return Math.round((retailPrice - salePrice) / retailPrice * 100);
		}
	}

	closeDialog() {
		this._mdr.close(false);
		this.scrollService.windowScrollTo(0, 0);
	}
}
