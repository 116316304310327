import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appHoverImage]'
})
export class HoverImageDirective implements AfterViewInit{
  @Input() hoverimage = '';
  @Input() src = '';
  nonHoverImage = '';
  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
    if (this.hoverimage === undefined || this.hoverimage === "") {
             return;
       }
      this.nonHoverImage = this.src;
     
  }
  mouseLeave() {
    this.el.attr("src", this.nonHoverImage);
   }
  mouseEnter() {
    this.el.attr("src", this.hoverimage);
  }
  @HostListener('mouseenter', ['$event'])
  onEnter(e) {
   this.mouseEnter();
 }

 @HostListener('mouseleave', ['$event']) 
 onLeave(e) {
  this.mouseLeave();
 }

 @HostListener('click', ['$event']) onClick( e: MouseEvent ) {
  
 }
}
