import { Component, Input, OnInit } from '@angular/core';
import { FitControl } from 'src/app/model/styleguide-viewmodel';

@Component({
  selector: '[fitchart],fitchart',
  templateUrl: './fitchart.component.html',
  styleUrls: ['./fitchart.component.scss']
})
export class FitchartComponent implements OnInit {
  @Input() fitControl: FitControl;
  constructor() { }

  ngOnInit() {
  }

}
