import { Location } from "@angular/common";
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubPageVM } from 'src/app/model/subpage-viewmodel';
import { WebPageSearchVM } from 'src/app/model/webpage-search-vm';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from "../../../shared/service/scroll.service";
import { DefaultService } from '../../layout/default.service';

@Component({
	selector: 'subpage-list,[subpage-list]',
	templateUrl: './subpage-list.component.html',
	styleUrls: ['./subpage-list.component.scss']
})
export class SubpageListComponent implements OnInit {

	@Input() subpagevm: SubPageVM;

	page: number = 1;
	itemsperpage: number = 6;
	subpageList: WebPageSearchVM;
	siteContent: SiteContentDetail;
	viewAll: boolean = false;
	locatonObj: any;

	constructor(
		private defaultService: DefaultService,
		private siteContentService: SiteContentService,
		private router: Router,
		private scrollService: ScrollService,
		private location: Location) {
	}

	ngOnInit() {
		this.locatonObj = this.location.subscribe(x => {
			this.getQueryParam();
			this.getSubpageData();
		});

		this.siteContent = this.siteContentService.getSiteContentData();
		this.getQueryParam();
		this.getSubpageData();
	}

	scrollTop() {
		this.scrollService.windowScrollTo(0, 0);
	}

	pageNumber(event) {
		if (this.subpagevm && this.subpagevm.WebPageSearchCriteria) {
			this.subpagevm.WebPageSearchCriteria.PageNumber = event;
		}
	}

	getwebPageList(webpagepist) {
		if (webpagepist) {
			let list = webpagepist.filter(x => x.WebSubPageControls.length > 0);
			return list;
		}
	}

	getQueryParam() {
		var params = this.getSearchParameters();
		this.page = 1;
		this.viewAll = false;
		if (this.subpagevm && this.subpagevm.WebPageSearchCriteria && this.subpagevm.WebPageSearchCriteria.PageNumber) {
			this.subpagevm.WebPageSearchCriteria.PageNumber = 1;
			this.subpagevm.WebPageSearchCriteria.PageSize = this.itemsperpage;
		}
		if (params) {
			if (params['page']) {
				this.page = +params['page'];
				this.subpagevm.WebPageSearchCriteria.PageNumber = this.page;
			}
			if (params['viewAll']) {
				this.viewAll = true;
				this.page = 1;
				this.subpagevm.WebPageSearchCriteria.PageNumber = 1;
				this.subpagevm.WebPageSearchCriteria.PageSize = 999;
			}
		}
	}

	SetPage(event) {
		if (this.subpagevm) {
			this.page = event;
			this.subpagevm.WebPageSearchCriteria.PageNumber = event;
		}

		this.setQueryParamToUrl();
		this.getSubpageData();
	}

	ViewAll() {
		this.viewAll = true;
		if (this.subpagevm) {
			this.subpagevm.WebPageSearchCriteria.PageNumber = 1;
			this.subpagevm.WebPageSearchCriteria.PageSize = 999;
			this.setQueryParamToUrl();
			this.getSubpageData();
		}
	}

	setQueryParamToUrl() {
		var params = this.getSearchParameters();

		if (!params) {
			params = {};
		}

		if (this.page > 1) {
			params['page'] = this.page;
		} else {
			this.page = 1;
			delete params['page'];
		}

		if (this.viewAll) {
			if (params['page']) {
				delete params['page'];
			}

			params['viewAll'] = true;
		}

		this.router.navigate(
			[],
			{

				queryParams: params,
			});
	}

	getSubpageData() {

		if (this.subpagevm) {

			this.defaultService.getSubPageList(this.subpagevm).subscribe(data => {
				this.subpageList = data;
				if (this.subpagevm) {
					if (this.subpagevm.WebPageSearchCriteria.PageSize == 3) {

						this.subpageList.TotalPages = 1;

					}
				}
			});
		}
	}

	getSearchParameters() {
		var prmstr = window.location.search.substr(1);
		return prmstr != null && prmstr != '' ? this.transformToAssocArray(prmstr) : {};
	}

	transformToAssocArray(prmstr) {
		var params = {};
		var prmarr = prmstr.split('&');
		for (var i = 0; i < prmarr.length; i++) {
			var tmparr = prmarr[i].split('=');

			if (!params[tmparr[0]]) {
				params[tmparr[0]] = tmparr[1];
			} else {
				params[tmparr[0]] = params[tmparr[0]] + ',' + tmparr[1];
			}

		}

		return params;
	}

	ngOnDestroy() {
		if (this.locatonObj) {
			this.locatonObj.unsubscribe();
		}
	}
}
