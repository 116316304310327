import { Component, OnInit } from '@angular/core';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
	selector: '[safe-password-tips],safe-password-tips',
	templateUrl: './safe-password-tips.component.html',
	styleUrls: ['./safe-password-tips.component.scss']
})
export class SafePasswordTipsComponent implements OnInit {

	sitecontent: SiteContentDetail;
	constructor(private sharedService: SharedService,
		private siteContentService: SiteContentService) { }

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
	}
}
