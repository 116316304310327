import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Splide } from '@splidejs/splide';
import * as $ from 'jquery';
import { FilterBannerVM } from 'src/app/model/filterbanner-viewmodel';
import { ScriptService } from 'src/app/shared/service/script.service';

@Component({
	selector: '[filter-banner],filter-banner',
	templateUrl: './filter-banner.component.html',
	styleUrls: ['./filter-banner.component.scss']
})
export class FilterBannerComponent implements AfterViewInit {
	@Input() filterBannerVM: FilterBannerVM;

	isLoading1 = true;
	isLoading2 = true;
	isLoading3 = true;
	isLoading4 = true;
	isLoading5 = true;
	isLoading6 = true;

	constructor(private route: ActivatedRoute, private router: Router, private scriptService: ScriptService) {
	}

	ngAfterViewInit() {

		// Only initialise Splide if Carousel required
		if (this.filterBannerVM.UseAsCarousel) {
			new Splide('#splide-' + this.filterBannerVM.RowControlId, {
				type: "loop",
				drag: "free",
				snap: true,
				arrows: true,
				pagination: false,
				autoWidth: false,
				flickPower: 200,
				gap: "0.5em",
				//padding: "3.5rem", // Space on the sides

				classes: {
					// Add classes for arrows
					arrows: "splide__arrows filter-banner-arrows",
					arrow: "splide__arrow filter-banner-arrow",
					prev: "splide__arrow--prev filter-banner-arrow-prev",
					next: "splide__arrow--next filter-banner-arrow-next",
				},

				perMove: 1,
				perPage: this.filterBannerVM.CarouselSlides,

				breakpoints: {
					// Desktop
					1100: { // Tablet
						perPage: 3,
						autoWidth: false,
					},
					840: { // Mobile
						perPage: 2,
						autoWidth: false,
					},
					550: {
						//perPage: 2,
						autoWidth: true,
						arrows: false,
					},
				}

			}).mount();
		}
	}

	readMore() {
		$('.filter-banner .readMoreIcon').toggleClass('readLessIcon');
		$('.filter-banner .banner-text').toggleClass('mobile-hidden');
	}
}
