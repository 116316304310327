import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  viewportHeight: number;
  
  constructor() { }

  getDeviceType() {
    var width = window.innerWidth;

    if (width <= 639) {
      return 'Mobile';

    }
    if (width <= 1099) {
      return 'Tablet';
    }

    return 'Desktop';

  }

  getViewportHeight() {
    return window.innerHeight;
  }
}
