import { HostListener, OnInit } from '@angular/core';
import { Component, Input, Inject } from '@angular/core';


import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { geolocation, WebStore } from 'src/app/model/geolocationdetails';
import { SiteContentDetail } from '../../models/siteContent';
import { SharedService } from '../../shared.service';
import * as $ from 'jquery'
import { WebsiteContentDetail } from '../../models/websiteContent';
import { WebsiteService } from '../../service/website.service';

@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit {

  geodetail: geolocation;
  countrydetails: WebStore[];
  sitecontent: SiteContentDetail;
  websiteContentDetail: WebsiteContentDetail;

  private inited;

  @HostListener('window:click')
  onNoClick(): void {
    if (this.inited) {
      this._mdr.close();
    }
  }

  ngAfterViewInit() {
    $('.country-select').on('click', function (event) {
      event.stopPropagation();
    });
  }

  constructor(private sharedService: SharedService,
    private websiteService: WebsiteService,
    private _mdr: MatDialogRef<GeoLocationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private router: Router
  ) {
    if (data && data.countrydetails) {
      this.countrydetails = data.countrydetails;
    }
    if (data && data.sitecontent) {
      this.sitecontent = data.sitecontent
    }

  }
  CloseDialog() {
    this._mdr.close(false)
  }

  getData(id): void {
    this.sharedService.GeoLocation(id).subscribe(data => {

      this.geodetail = data
      this.countrydetails = data.AllWebStores;
    });;
  }

  ngOnInit() {
    this.getWebSiteContent();
    this._mdr.afterOpened().subscribe(() => {
      this.inited = true;
    })
  }
  logoClicked() {
    this._mdr.close(false);
    this.router.navigate(['']);
  }
  getWebSiteContent() {
    this.websiteContentDetail = this.websiteService.getWebsiteData();
    if (!this.websiteContentDetail) {
      this.websiteService.getWebsites().subscribe(data => {
        if (data) {
          this.websiteContentDetail = data;
        }
      })
    }

  }
}
