import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Observable } from 'rxjs';
import { ContentPageRowControlVM } from 'src/app/model/content-page-row-control-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ProductFromSkus } from 'src/app/model/product-from-skus';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { FormFieldTypeEnum } from '../../../enums/control-type-enum';
import { SiteContentDetail } from '../../../shared/models/siteContent';
import { ScriptLocation, ScriptService } from '../../../shared/service/script.service';
import { ScrollService } from '../../../shared/service/scroll.service';

@Component({
	selector: 'sidebyside,[sidebyside]',
	templateUrl: './sidebyside.component.html',
	styleUrls: ['./sidebyside.component.scss'],
	encapsulation: ViewEncapsulation.None,

})
export class SidebysideComponent implements OnInit {
	@Input() contentPageRowControlVM: ContentPageRowControlVM;

	relatedProductVM: Observable<RelatedProductVM>;
	form: UntypedFormGroup;
	readMore = false;
	captchaValue = false;
	isFormSubmitted = false;
	FormFieldTypeEnum = FormFieldTypeEnum;
	@ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
	siteContent: SiteContentDetail;
	recaptchaActive: boolean;
	recaptchaV2SiteKey: string;
	doubleOptInEnabled: boolean;
	submitted = false;
	formId: string;
	LpAppSetting: LpAppSetting;

	constructor(
		private elementRef: ElementRef,
		private fb: UntypedFormBuilder,
		private defaultService: DefaultService,
		private sharedService: SharedService,
		private route: ActivatedRoute,
		private router: Router,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService,
		private scriptService: ScriptService) {
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
		this.contentPageRowControlVM.ControlVMs.forEach((item: any) => {
			if (item.IsFormControl) {
				this.formId = item.FormControlVM.FormControlModel.FormId;
				this.setFormControls(item);
			}
		});
		this.getApplicationSettings();
	}

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {

		if (!(event.target instanceof HTMLAnchorElement)) {
			return;
		}

		const target = <HTMLAnchorElement>event.target;

		if (target && target.pathname) {
			if (target.href.startsWith("mailto")) {
				location.href = target.href;
				return;
			} else {
				this.router.navigate([target.pathname]);
			}
		}

		if (target && target.pathname) {
			this.router.navigate([target.pathname]);
		}

		event.preventDefault();
	}

	onSubmit() {
		this.submitted = true;
		if (this.form.valid) {
			const temp = this.form.value;
			temp.captchaValid = this.captchaValue;
			temp.SubmissionTypeId = this.formId;
			this.sharedService.submitCustomForm(temp).subscribe(data => {
				this.isFormSubmitted = true;
				this.scrollService.windowScrollTo(0, 0);
			});
		}
	}

	get f() {
		return this.form.controls;
	}

	setFormControls(controlvm) {
		if (controlvm.IsFormControl && controlvm.FormControlVM && controlvm.FormControlVM.FormControlModel
			&& controlvm.FormControlVM.FormControlModel.Fields) {
			const obj = { recaptcha: ['', Validators.required] };
			controlvm.FormControlVM.FormControlModel.Fields.forEach(item => {
				const c = [];
				c.push('');
				if (item.IsRequired) {
					c.push(Validators.required);
				}
				obj[item.SaveName] = c;
			});
			this.form = this.fb.group(obj);
		}
	}

	isReadMoreShow(data) {
		return !(data.FullUrl === '/careers' || data.FullUrl === '/affiliates');
	}

	getApplicationSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
		if (this.LpAppSetting) {
			this.recaptchaActive = this.LpAppSetting.Recaptcha_Active;
			this.recaptchaV2SiteKey = this.LpAppSetting.Recaptcha_V2_SiteKey;
			this.doubleOptInEnabled = this.LpAppSetting.DoubleOptInEnabled;
		}

	}

	getInnerText(innerHtml) {
		innerHtml = innerHtml.changingThisBreaksApplicationSecurity;
		if (innerHtml && innerHtml.length > 250) {
			let returnString = innerHtml.slice(0, 200);
			returnString = returnString + `<span class="changeColor">...[${this.siteContent.ReadMore}]</span>`;
			return returnString;
		}
		return innerHtml;
	}

	showReadMore() {
		this.readMore = true;
	}

	getRelatedProduct(productFromSkus): Observable<RelatedProductVM> {
		return this.defaultService.getProductFromSkus(productFromSkus);
	}

	getProduct() {
		if (this.contentPageRowControlVM) {
			if (this.contentPageRowControlVM.ControlVMs) {
				for (let i = 0; i < this.contentPageRowControlVM.ControlVMs.length; i++) {
					if (this.contentPageRowControlVM.ControlVMs[i].IsSkuList) {
						this.relatedProductVM = this.defaultService.getProductFromSkus(this.contentPageRowControlVM.ControlVMs[i].ProductFromSkus);
					}
				}
			}
		}
	}

	IsHtmlView(IsHtml) {
		return IsHtml ? IsHtml : false;
	}

	IsScriptView(content: string) {
		if (content.startsWith('<script')) {

			return true;
		}

		return false;
	}

	getProductFromSkus(productFromSkus: ProductFromSkus): Observable<RelatedProductVM> {
		return this.defaultService.getProductFromSkus(productFromSkus);
	}

	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}
}
