import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../shared/environment.service';
import { Account } from './models/account';
import { AccountVM } from './models/account-vm';
import { AddressVM } from './models/addressVM';
import { OrderDetails } from './models/order-details';
import { OrdersViewModel } from './models/orderVM';
import { PersonalDetails } from './models/personal-detailVM';
import { ChangePasswordViewModel, ResetPasswordViewModel } from './models/reset-passwordVM';
import { ResultVM } from './models/result-vm';
import { SaveCardDetails } from './models/saved-cards';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	register(data): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.accountUrl + "AccountRegister", data, this.httpOptions);
	}

	postLogin(data): Observable<AccountVM> {
		return this.httpClient.post<AccountVM>(this.environmentService.environment.accountUrl + 'SignIn', data, this.httpOptions).pipe(
			map(response => response)
		);
	}

	welcomePageLogin(data): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.checkoutUrl + "Welcome",
			{
				"EmailAddress": data.EmailAddress,
				"Password": data.Password,
				"RememberMe": true,
			},
			this.httpOptions);
	}

	guestCheckoutLogin(data): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.checkoutUrl + "Welcome",
			{
				"GuestEmailAddress": data.EmailAddress,
				"CheckoutAsGuest": true,
			},
			this.httpOptions);
	}

	postForgottenPassword(data): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.accountUrl + "ForgottenPassword",
			{
				"EmailAddress": data.emailAddress,
			},
			this.httpOptions);
	}

	getTitles(): Observable<Account> {
		return this.httpClient.get<Account>(this.environmentService.environment.accountUrl + 'GetTitles', this.httpOptions).pipe(
			map(response => response)
		);
	}

	getSavedCards(): Observable<SaveCardDetails> {
		return this.httpClient.get<SaveCardDetails>(this.environmentService.environment.accountUrl + 'SavedCards', this.httpOptions);
	}

	updatePersonalDetails(data): Observable<PersonalDetails> {
		return this.httpClient.post<PersonalDetails>(this.environmentService.environment.accountUrl + "EditPersonalDetails", data, this.httpOptions);
	}

	deleteSavedCard(recurringDetailReference): Observable<any> {
		return this.httpClient.post<any>(this.environmentService.environment.accountUrl + `AdyenSavedCardDelete`,
			{
				"AdyenRecurringDetail": {
					"RecurringDetailReference": recurringDetailReference
				}
			},
			this.httpOptions);
	}

	updatePassword(data): Observable<ChangePasswordViewModel> {
		return this.httpClient.post<ChangePasswordViewModel>(this.environmentService.environment.accountUrl + "ChangePassword", data, this.httpOptions);
	}

	resetPassword(id): Observable<ResetPasswordViewModel> {
		return this.httpClient.get<ResetPasswordViewModel>(this.environmentService.environment.accountUrl + 'ResetPassword?id=' + id, this.httpOptions);
	}

	resetUpdatePassword(data) {
		return this.httpClient.post<any>(this.environmentService.environment.accountUrl + 'UpdatePassword', data, this.httpOptions);
	}

	postAddressBookDetails(data, addressDetailsId): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.accountUrl + 'AddressBook', data, this.httpOptions)
	}

	getAllSavedAddressBooks(): Observable<AddressVM[]> {
		return this.httpClient.get<AddressVM[]>(this.environmentService.environment.accountUrl + 'GetAddress', this.httpOptions);
	}

	deleteAddressBook(addressBookId): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.accountUrl + 'AddressDelete?addressDetailId=' + addressBookId, this.httpOptions);
	}

	saveDefaultAddressForShippingAndBilling(data): Observable<AddressVM> {
		return this.httpClient.post<AddressVM>(this.environmentService.environment.accountUrl + 'BillingandShippingAddress?SelectedDefaultBillingAddress=' + data.SelectedDefaultBillingAddress + '&SelectedDefaultShippingAddress=' + data.SelectedDefaultShippingAddress, this.httpOptions);
	}

	getWebOrders(): Observable<OrdersViewModel> {
		return this.httpClient.get<OrdersViewModel>(this.environmentService.environment.accountUrl + 'Orders', this.httpOptions);
	}

	getWebOrderDetailsById(orderId): Observable<OrderDetails> {
		return this.httpClient.get<OrderDetails>(this.environmentService.environment.accountUrl + 'Order?index=' + orderId, this.httpOptions);
	}

	signOut() {
		return this.httpClient.get<any>(this.environmentService.environment.accountUrl + "SignOut", this.httpOptions);
	}
}
