import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GalleryPopupVM } from 'src/app/model/gallerypopup-viewmodel';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { slickSliderRefresh } from '../../../../assets/shared/helper_scripts.js';
import { FitguidePopupComponent } from '../fitguide-popup/fitguide-popup.component';

@Component({
	selector: '[gallery-popup],gallery-popup',
	templateUrl: './gallery-popup.component.html',
	styleUrls: ['./gallery-popup.component.scss']
})
export class GalleryPopupComponent implements OnInit {
	@Input() galleryPopupVM: GalleryPopupVM;

	fitguidematDialogRef: MatDialogRef<FitguidePopupComponent>;
	siteContent: SiteContentDetail;

	constructor(
		private matDialog: MatDialog,
		private siteContentService: SiteContentService,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
	}

	ngAfterViewInit() {
		// Since we are running helper script which includes jQuery, run this only on Browser Side
		if (isPlatformBrowser(this.platformId)) {
			slickSliderRefresh();
		}
	}

	getCarousel(carousel) {

		let slideconfig = JSON.parse(carousel);

		if (slideconfig) {
			slideconfig.slidesToShow = 1;

			if (slideconfig.arrows) {
				slideconfig.nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.rightArrowText + "</span></button>";
				slideconfig.prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.leftArrowText + "</span></button>";
			}
		}

		return slideconfig;
	}

	getCarouselText(carousel) {
		let slideconfig = JSON.parse(carousel);

		if (slideconfig) {
			slideconfig.slidesToShow = 3;

		}

		return slideconfig;
	}

	fitguidemodal(texts: any, images: any, content: any, index: number) {
		let fitguide = { id: "fitGuide", texts: texts, images: images, content: content, index: index };

		this.fitguidematDialogRef = this.matDialog.open(FitguidePopupComponent, {
			data: { fitguide: fitguide },
			disableClose: true
		});

		this.fitguidematDialogRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				fitguide = undefined;
			}
		});
	}
}
