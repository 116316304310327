import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';

declare global {
	interface Window {
	}
}

@Injectable({
	providedIn: 'root'
})
export class PaypalService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	quickInitialise(origin): Observable<any> {
		return this.http.post(this.environmentService.environment.paypalUrl + `QuickInitialise?origin=${origin}`, {}, this.httpOptions);
	}

	quickConfirm(token, payerId, basketId): Observable<any> {
		return this.http.get(this.environmentService.environment.paypalUrl + `QuickConfirm?token=${token}&PayerID=${payerId}&BasketId=${basketId}&isAlreadyLoggedIn=false`, this.httpOptions);
	}

	quickCompletePayment(data): Observable<any> {
		return this.http.post(this.environmentService.environment.paypalUrl + `QuickCompletePayment`, data, this.httpOptions);
	}

	completePayment(token, payerID, orderId): Observable<any> {
		return this.http.get(this.environmentService.environment.paypalUrl + `CompletePayment?token=${token}&payerId=${payerID}&confirmed&orderId=${orderId}`, this.httpOptions);
	}
}
