import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appImageMap]'
})
export class ImageMapDirective implements AfterViewInit{

  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  
  ngAfterViewInit(){
    //need to add below one 
    // el.rwdImageMaps();
  }
}
