import { DomSanitizer } from '@angular/platform-browser';
import { Icon, ProductBadgeImage } from "src/app/modules/product-details/models/product-detail";

export const GetProductBadgeImage = (sanitizer: DomSanitizer, icons: Icon[] = []): ProductBadgeImage | null => {
    const icon = icons.find(x => x.IsActive && x.Filename);

    if (!icon) {
        return null;
    }

    return {
        Name: icon.Name,
        CssClass: icon.CssClass,
        ImageUrl: sanitizer.bypassSecurityTrustUrl(icon.Filename),
        SrcSets: [`${icon.Filename} 1x`, `${icon.Filename.replace('.png', '@2x.png')} 2x`]
    };
};