import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { List } from 'src/app/model/country-viewmodel';
import { HomeService } from 'src/app/modules/Home/home.service';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { SiteContentDetail } from '../../models/siteContent';

@Component({
	selector: 'name-phone-entry,[name-phone-entry]',
	templateUrl: './namephone-entry.component.html',
	styleUrls: ['./namephone-entry.component.scss']
})
export class NamephoneEntryComponent implements OnInit {

	displaymode: boolean = false;
	@Input() showphone: boolean;
	@Input() submitted: boolean;
	@Input() sitecontent: SiteContentDetail;
	@Input() regExLibrary: RegExLibrary;
	@Input() label: Label;
	@Input() frmDelivery: UntypedFormGroup;
	@Input() titleList: any[];
	@Input() countriesList: List[] = [];
	@Input() currentCulture: string;
	countryFlag: string;
	countryISD: string;
	isDropDownActive: boolean = false;

	constructor(private homeService: HomeService) { }

	ngOnInit() {
		let ans = this.titleList;
		this.validatePhoneNumber(this.frmDelivery.controls['phoneCountryId'].value);
		let phoneCountryId = this.frmDelivery.controls['phoneCountryId'].value;
		this.getPhoneCode(phoneCountryId);
	}
	get f() { return this.frmDelivery.controls; }
	getValueForPhoneCode(code) {
		let length = code.length - 2;
		let returnString = code.substr(length);
		return returnString;
	}
	getPhoneCode(phoneCountryId) {
		let country;
		if (this.countriesList && this.countriesList.length > 0) {
			if (phoneCountryId) {
				country = this.countriesList.filter(x => x.CountryId == phoneCountryId);
			} else {
				country = this.countriesList;
			}
			if (country && country.length > 0) {
				let code = country[0].Code;
				code = this.getValueForPhoneCode(code);

				this.countryFlag = "flagstrap-" + code.toLowerCase();
				this.countryISD = country[0].ISD;
			}
		}
	}
	setCountryPhoneCode(country) {

		if (country) {
			this.frmDelivery.controls['phoneCountryId'].setValue(country.CountryId);
			let code = this.getValueForPhoneCode(country.Code);
			this.countryFlag = "flagstrap-" + code.toLowerCase();
			this.countryISD = country.ISD;
		}
	}

	getClassList(name) {
		let str = this.getValueForPhoneCode(name);
		let returnString = "flagstrap-icon flagstrap-" + str.toLowerCase();
		return returnString;
	}

	validatePhoneNumber(phoneCountryId) {
		let isValid = false;
		let country;
		if (this.countriesList && this.countriesList.length > 0) {
			country = this.countriesList.filter(x => x.CountryId === phoneCountryId);
			if (country && country.length > 0) {
				isValid = true;
			}
		}
		if (!isValid) {
			let defaultCountries = this.countriesList.filter(x => x.Code == this.currentCulture);
			this.frmDelivery.controls['phoneNumber'].setValue('');
			this.frmDelivery.controls['phoneCountryId'].setValue(defaultCountries[0].CountryId);
		}
	}
}
