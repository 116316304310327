import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from '../enums/dayofweekEnum';
import { Store, StoreOpeningHour } from '../models/stores-vm';
import * as moment from 'moment';

@Pipe({
    name: 'storeOpeningTimefilter',
    pure: false
})
export class StoreOpeningTimeFilterPipe implements PipeTransform {
    transform(openingHours: StoreOpeningHour[],WebStoreId:number): string[] {
        var convertedOpeningHours = openingHours;

        if (convertedOpeningHours == null || convertedOpeningHours.length == 0) {
          return null;
        }
    
        var openingDays = [] as string[];
    
        if (convertedOpeningHours[0] != null && convertedOpeningHours[0].DayOfTheWeek == DayOfWeek.Sunday) {
          var index = convertedOpeningHours[0];
          convertedOpeningHours.push(index);
          // convertedOpeningHours.reduce(0);
          convertedOpeningHours.splice(0, 1);
        }
    
        let startDay : StoreOpeningHour;
        let i = 0;
    
        //for(var day in convertedOpeningHours)
        convertedOpeningHours.forEach(day => {
          i++;
          
          var nextDay = i >= convertedOpeningHours.length ? null : convertedOpeningHours[i];
    
          if (!startDay) {
            startDay = day;
          }
          let isOpen = false;
          if (day.ClosingTime && day.OpeningTime) {
            isOpen = true;
          }
          if (nextDay != null &&
            (day.OpeningTime != nextDay.OpeningTime || day.ClosingTime != nextDay.ClosingTime)) {
            openingDays.push((startDay.DayOfTheWeek == day.DayOfTheWeek
              ? DayOfWeek[day.DayOfTheWeek]
              : DayOfWeek[startDay.DayOfTheWeek] + " - " + DayOfWeek[day.DayOfTheWeek])
              + ": " +
              (isOpen
                ? (moment(day.OpeningTime)).format('HH:mm') + "-" + (moment(day.ClosingTime)).format('HH:mm')
                : (WebStoreId === 2) ? "Geschlossen" : "Closed"));
            startDay = null;
          }
    
          if (nextDay == null) {
            // we are at the last day
            openingDays.push((startDay.DayOfTheWeek == day.DayOfTheWeek
              ? DayOfWeek[day.DayOfTheWeek]
              : DayOfWeek[startDay.DayOfTheWeek] + " - " + DayOfWeek[day.DayOfTheWeek])
              + ": " +
              (isOpen
                ? (moment(day.OpeningTime)).format('HH:mm') + "-" + (moment(day.ClosingTime)).format('HH:mm')
                : (WebStoreId === 2) ? "Geschlossen" : "Closed"));
          }
        });
    
        return openingDays;
    }
}

