import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, OnInit, Output, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import algoliasearch from 'algoliasearch';
import { SearchClient, SearchIndex } from 'algoliasearch';
import * as $ from 'jquery';
import { AlgoliaSearchSettings } from 'src/app/shared/models/algolia-search-settings';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { SearchService } from '../search/search.service';
import { ViewportService } from 'src/app/shared/viewport.service';

@Component({
	selector: 'app-search-items,[app-search-items]',
	templateUrl: './search-items.component.html',
	styleUrls: ['./search-items.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SearchItemsComponent implements OnInit {

	@Output() closeSearch = new EventEmitter<boolean>();

	hits: any[];
	facets: any[];
	showDefault: boolean = true;
	showSearchDialog: boolean = false;
	innerWidth;
	searchBoxValue = '';
	suggestedSearch_MaxTerms: number;
	suggestedSearch_DynamicMaxTerms: number;
	trenddingSearch_MaxTerms: number;
	trenddingSearch_DynamicMaxTerms: number;
	algoliaSearchSettings: AlgoliaSearchSettings;
	SuggestedSearch_CustomTerms_Html: string[];
	TrenddingSearch_CustomTerms_Html: string[];
	TopSearch: string[];
	Product_Columns: number;
	Product_Rows: number;
	totalProducts: number;
	maxColumnClass: string;
	topSearchResult: any;
	algoliaClient: SearchClient;
	algoliaIndex: SearchIndex;
	imageKey: any;
	productIndexKey: string;
	siteContent: SiteContentDetail;

	apiKey: any = '';
	applicationId: any = '';
	index_key = '';
	client: any;
	index: any;

	dataS: any = {
		hitsPerPage: 30,
		maxValuesPerFacet: 10
	}

	suggestionResult;

	data = {
		Desktop_SuggestedSearch_CustomTerms_Html: '',
		Desktop_TrendingSearch_CustomTerms_Html: '',
		NoResults_Html: '',
		Mobile_NoResults_Html: '',
		WithResults_Html: ''
	}

	trendingSearchResults = [];
	deviceType: string;

	constructor(
		public searchService: SearchService,
		private router: Router,
		private sharedService: SharedService,
		private siteContentService: SiteContentService,
		private viewportService: ViewportService,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.siteContent = this.siteContentService.getSiteContentData();
			this.innerWidth = window.innerWidth;
			this.customSearchAlogolia();
			this.searchService.searchDialogSelectEvents$.forEach(event => {
				this.showSearchDialog = true;
				this.getTopSearches(event)
			});
			this.deviceType = this.viewportService.getDeviceType();
		}
	}

	@HostListener("click", ['$event'])
	onClick(event) {
		let target1;

		if (event.target.tagName == 'P') {
			this.onSearchTextChange(event.target.textContent);
			return;
		}

		if (event.target.tagName == 'A' && !event.target.pathname || event.target.tagName == "INPUT" || event.target.tagName == "DIV" || event.target.tagName == "I") {
			return;
		} else if (event.target.tagName == 'A' && event.target.pathname) {
			target1 = event.target.pathname;;
		} else {
			if (event.target && event.target.offsetParent) {
				target1 = event.target.offsetParent.pathname;
			}
		}

		// // Prevent page from reloading
		event.preventDefault();
		this.closeSearchModal();
		let target = <HTMLAnchorElement>event.target;
		let targetTwo = event.target;
		if (target1) {
			this.router.navigate([target1]);
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	searchCustom(searchValue) {
		this.client = algoliasearch(this.applicationId, this.apiKey);

		this.index = this.client.initIndex(this.index_key);

		if (searchValue) {
			this.index.search(searchValue).then(({ hits }) => {
				this.suggestionResult = hits;
			});
		}
	}

	closeSearchModal() {

		$(".serach-button").css("cursor", "pointer");
		$(".header-search").animate({ "max-height": 0 }, 400);
		$(".header-search").removeClass("open-search");
		$(".site-header").removeClass("withsearchform");
		$("body").removeClass("searchopen");
		$("#searchId").val("");
		$(".view-nav").css("z-index", "1004");
		$(".topsearch-button").removeClass("active");
		$(".custom-search").hide();
		$(".default-search").show();

		$("body").removeClass("mobile-overflow-hidden");
		this.showDefault = true;
	}

	changeRoutToSearchText(searchText) {
		this.searchBoxValue = searchText;
		this.redirect();
	}

	changeRouteToLink(routerLink) {
		this.closeSearchModal();
		this.router.navigate([routerLink]);
	}

	changeSearchtext(val) {
		this.searchBoxValue = val;
		this.onSearchTextChange(val);
	}

	changeRouteToDetailPage(hit) {

		this.closeSearchModal();

		// If a redirect page then use browser url otherwise internal angular call
		if (hit.redirectUrl) {
			window.location.href = hit.redirectUrl;
		} else {
			this.router.navigate([hit.fullUrl]);
		}
	}

	changeRoute(value) {
		this.closeSearchModal();
		this.router.navigate([value]);
	}

	onSearchTextChange(event) {
		let searchText;
		if (typeof event == 'string') {
			searchText = event;
		} else {
			searchText = event.target.value;
		}

		this.searchBoxValue = searchText;

		if (searchText) {
			this.showDefault = false;
			$(".custom-search").show();
			this.searchCustom(searchText);
		} else {
			this.showDefault = true;
		}

		let routingUrl = "search?q=" + searchText;

		if (event.keyCode === 13) {
			this.router.navigateByUrl(routingUrl);
		} else {
			if (searchText) {
				this.getProductListFromAlgolia(searchText);
			} else {
				this.hits = [];
				this.facets = [];
			}
		}
	}

	toPriceDisplayString(price: number, currencyPrefix: string) {
		if (price % 1 == 0) {
			return currencyPrefix + price;
		} else {
			return currencyPrefix + price.toFixed(2);
		}
	}

	getProductListFromAlgolia(searchtext) {

		if (searchtext) {
			this.algoliaIndex.search(searchtext).then(response => {

				// Remap as any to expose algoliaImage properties
				var hits = response.hits as any[];

				if (hits && hits.length > 0) {

					for (var i = 0; i < hits.length; i++) {

						if (hits[i].algoliaImage) {

							for (var j = 0; j < hits[i].algoliaImage.length; j++) {

								if (hits[i].algoliaImage[j].imageKey === this.imageKey) {
									if (hits[i].algoliaImage[j].image1) {
										hits[i].image1 = hits[i].algoliaImage[j].image1;
									}
									if (hits[i].algoliaImage[j].image2) {
										hits[i].image2 = hits[i].algoliaImage[j].image2;
									}

									break;
								}
							}
						}
					}

					this.hits = hits;
					this.getFacets();

				} else {
					this.hits = [];
				}

				if (response.renderingContent && response.renderingContent["redirect"] && response.userData) {
					this.hits.push(
						{
							name: response.userData[0].text,
							redirectText: response.userData[0].text,
							redirectUrl: response.renderingContent["redirect"].url,
						}
					);
				}
			});
		}
	}

	getFacets() {
		let facetResult = [];
		let unique = [];
		let res = this.hits;
		if (res) {
			for (var i = 0; i < res.length; i++) {
				if (res[i].sortOrders) {
					for (var j = 0; j < res[i].sortOrders.length; j++) {
						if (res[i].sortOrders[j].fullUrl) {
							facetResult.push(res[i].sortOrders[j]);
						}
					}

					if (facetResult && facetResult.length > 5) {
						break;
					}
				}
			}

			facetResult = facetResult.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
			facetResult = facetResult.sort((one, two) => (one.sortOrder > two.sortOrder ? -1 : 1))
		}

		this.facets = facetResult;
	}

	redirect() {
		if (this.searchBoxValue) {
			this.closeSearchModal();

			let routingUrl = "search?q=" + this.searchBoxValue;

			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigateByUrl(routingUrl);
			});
		}
	}

	maxColumn() {
		switch (this.Product_Columns) {
			case 1:
				this.maxColumnClass = 'one-column';
				break;
			case 2:
				this.maxColumnClass = 'two-column';
				break;
			case 3:
				this.maxColumnClass = 'three-column';
				break;
			case 4:
				this.maxColumnClass = 'four-column';
				break;
			case 5:
				this.maxColumnClass = 'five-column';
				break;
			default:
				this.maxColumnClass = 'six-column';
				break;
		}
	}

	getTopSearches(event) {
		if (event) {
			const clienttopsearch = algoliasearch(this.applicationId, this.apiKey);
			const indextopsearch = clienttopsearch.initIndex(this.algoliaSearchSettings.Indice_ProductSuggestions);

			indextopsearch.search(undefined).then(({ hits }) => {
				this.topSearchResult = hits;
			});
		}
	}

	getAlgoliaSearchSetting() {

		let suggestedHtml = '';
		let topCustomSearch = '';

		if (this.sharedService.isMobileDevice()) {
			this.suggestedSearch_MaxTerms = this.algoliaSearchSettings.Mobile_SuggestedSearch_MaxTerms;
			this.trenddingSearch_MaxTerms = this.algoliaSearchSettings.Mobile_TrendingSearch_MaxTerms;
			suggestedHtml = this.algoliaSearchSettings.Mobile_SuggestedSearch_CustomTerms_Html;
			topCustomSearch = this.algoliaSearchSettings.Mobile_TrendingSearch_CustomTerms_Html;
			this.Product_Columns = this.algoliaSearchSettings.Mobile_Product_Columns;
			this.Product_Rows = this.algoliaSearchSettings.Mobile_Product_Rows;
			this.imageKey = this.algoliaSearchSettings.Mobile_ImageKey;
		} else {
			this.suggestedSearch_MaxTerms = this.algoliaSearchSettings.Desktop_SuggestedSearch_MaxTerms;
			suggestedHtml = this.algoliaSearchSettings.Desktop_SuggestedSearch_CustomTerms_Html;
			this.trenddingSearch_MaxTerms = this.algoliaSearchSettings.Desktop_TrendingSearch_MaxTerms;
			topCustomSearch = this.algoliaSearchSettings.Desktop_TrendingSearch_CustomTerms_Html;
			this.Product_Columns = this.algoliaSearchSettings.Desktop_Product_Columns;
			this.Product_Rows = this.algoliaSearchSettings.Desktop_Product_Rows;
			this.imageKey = this.algoliaSearchSettings.Desktop_ImageKey;
		}

		if (this.Product_Columns && this.Product_Rows) {
			this.totalProducts = this.Product_Columns * this.Product_Rows;
		}

		this.maxColumn();

		if (suggestedHtml) {
			suggestedHtml = suggestedHtml.replace(/<\/p>/gm, '');
			suggestedHtml = suggestedHtml.replace(/<p>/gm, '');
			suggestedHtml = suggestedHtml.replace("&nbsp;", "");
			this.SuggestedSearch_CustomTerms_Html = suggestedHtml.split('\n');
		}

		if (this.SuggestedSearch_CustomTerms_Html && this.SuggestedSearch_CustomTerms_Html.length > 0) {
			this.suggestedSearch_DynamicMaxTerms = this.suggestedSearch_MaxTerms - this.SuggestedSearch_CustomTerms_Html.length;
		} else {
			this.suggestedSearch_DynamicMaxTerms = this.suggestedSearch_MaxTerms;
		}

		if (topCustomSearch) {
			topCustomSearch = topCustomSearch.replace(/<\/p>/gm, '');
			topCustomSearch = topCustomSearch.replace(/<p>/gm, '');
			topCustomSearch = topCustomSearch.replace("&nbsp;", "");
			this.TrenddingSearch_CustomTerms_Html = topCustomSearch.split('\n');
		}

		if (this.TrenddingSearch_CustomTerms_Html && this.TrenddingSearch_CustomTerms_Html.length > 0) {
			this.trenddingSearch_DynamicMaxTerms = this.trenddingSearch_MaxTerms - this.TrenddingSearch_CustomTerms_Html.length;
		} else {
			this.trenddingSearch_DynamicMaxTerms = this.trenddingSearch_MaxTerms;
		}
	}

	getCustomTopSearches() {
	}

	hasStringHyperLink(link: string): boolean {
		if (link.indexOf('</a>') >= 0) {
			return true;
		}

		return false;
	}

	customSearchAlogolia() {
		this.searchService.algoliaSettetings().subscribe(res => {
			if (res) {
				let data = res;
				this.algoliaSearchSettings = res;

				this.getAlgoliaSearchSetting();
				this.apiKey = data.ApiKey_Search;
				this.applicationId = data.ApplicationId;
				this.index_key = data.Indice_ProductSuggestions;

				this.data.Desktop_SuggestedSearch_CustomTerms_Html = data.Desktop_SuggestedSearch_CustomTerms_Html;
				this.data.Desktop_TrendingSearch_CustomTerms_Html = data.Desktop_TrendingSearch_CustomTerms_Html;
				this.data.NoResults_Html = data.NoResults_Html;
				this.data.WithResults_Html = data.WithResults_Html;
				this.data.Mobile_NoResults_Html = data.Mobile_NoResults_Html;

				let ele: any = document.createRange().createContextualFragment(this.data.Desktop_TrendingSearch_CustomTerms_Html);

				this.getCustomTopSearches();
				this.custmizeTrendingResults(ele);

				this.algoliaClient = algoliasearch(this.algoliaSearchSettings.ApplicationId, this.algoliaSearchSettings.ApiKey_Search);

				this.algoliaIndex = this.algoliaClient.initIndex(this.algoliaSearchSettings.Indice_Products);

				// Inform Search service that modal can be opened
				this.searchService.algoliaInitialised();
			}
		})
	}

	custmizeTrendingResults(ele) {
		for (let i = 0; i < ele.children.length; i++) {
			let dataSet: any = {
				link: '',
				value: ''
			}

			if (ele.children[i].children && ele.children[i].children.length) {
				dataSet.link = ele.children[i].children[0].pathname.substring(1);
				dataSet.value = ele.children[i].children[0].text.trim();
			} else {
				dataSet.value = ele.children[i].textContent
			}

			this.trendingSearchResults.push(dataSet);
		}
	}

	redirectSearchPage() {

		// If there is only one hit then go directly to that page
		if (this.hits.length == 1) {
			this.changeRouteToDetailPage(this.hits[0]);

			return;
		}

		this.redirect();
	}
}
