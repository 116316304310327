import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SizeChartContent } from "src/app/model/size-chart-content";
import { SharedService } from "../../shared.service";
import * as $ from "jquery";
import { SiteContentDetail } from "../../models/siteContent";
import { SiteContentService } from "../../sitecontent.service";

@Component({
  selector: "app-size-guide",
  templateUrl: "./size-guide.component.html",
  styleUrls: ["./size-guide.component.scss"],
})
export class SizeGuideComponent implements OnInit, AfterViewInit {
  productKey: string;
  sizeChartContent: SizeChartContent;
  isToggleOff: boolean = true;
  siteContent: SiteContentDetail;
  areRowsHavingValues: boolean = false;

  ngAfterViewInit() {
    $("#sizeguide").on("click", function (event) {
      event.stopPropagation();
    });
  }

  constructor(
    private sharedService: SharedService,
    private _mdr: MatDialogRef<SizeGuideComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private siteContentService: SiteContentService
  ) {
    if (data) {
      this.productKey = data.productKey ? data.productKey : "";
      if (data.siteContent) this.siteContent = data.siteContent;
      if (data.sizeChartContent) this.sizeChartContent = data.sizeChartContent;
    }
  }

  CloseDialog() {
    this._mdr.close(false);
  }

  ngOnInit() {
    if (!this.data.siteContent) {
      this.siteContent = this.siteContentService.getSiteContentData();
      this.sharedService.getSizeData(this.productKey).subscribe(
        (res) => {
          if (res) {
            this.sizeChartContent = res;
            this.checkRowsHavingValues();
          }
        },
        (err) => {
          window.location.reload();
        }
      );
    }
    this.checkRowsHavingValues();
  }

  private checkRowsHavingValues(): void {
    if (this.sizeChartContent.Rows?.length) {
      for (let i = 0; i < this.sizeChartContent.Rows.length; ++i) {
        const row = this.sizeChartContent.Rows[i];
        if (row.Columns.length) {
          this.areRowsHavingValues = row.Columns.some(({ Value }) => !!Value)
          if (this.areRowsHavingValues) {
            break;
          }
        }
      }
    }
  }
}
