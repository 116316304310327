
exports.InitialiseProductListingPageTracking = function InitialiseProductListingPageTracking(criteoSettings, products, searchViewModel, filterGroups, breadcrumbs) {
	try {
		var filters = [];

		$.each(filterGroups, function (idx, group) {
			$.each(group.Items, function (idx1, item) {
				if (item.IsSelected) {
					filters.push(group.Title + "=" + item.Name);
				}
			});
		});

		var pageDimension1 = "Category List";
		if (searchViewModel.Title) {
			pageDimension1 += ":" + searchViewModel.Title;
		}

		var joinedFilters = "";
		if (filters.length > 0) {
			joinedFilters += "|";
			joinedFilters += filters.join(":");
			joinedFilters = joinedFilters.replace("\"", "");
		}

		var pageDimension3 = products.length;
		if (searchViewModel.Title) {
			pageDimension3 += "|" + searchViewModel.Title;
		}
		pageDimension3 += joinedFilters;

		var data = {};
		data.dimension1 = pageDimension1;
		data.dimension3 = pageDimension3;
		data.ecommerce = {};
		data.ecommerce.impressions = [];

		$.each(products, function (idx, x) {
			// Skip if product banner
			if (x.HasBanner) return;

			var ecommerceProd = {};
			ecommerceProd.id = x.Code;
			ecommerceProd.list = "Category Page";
			ecommerceProd.category = searchViewModel.FullUrl;
			ecommerceProd.name = x.Name;
			ecommerceProd.price = Math.round(x.PriceAndStockKeyValues.SalePrice);
			ecommerceProd.position = idx;
			ecommerceProd.googleProductCategory = x.GoogleProductCategory;
			ecommerceProd.productCategory = x.ProductCategory;

			data.ecommerce.impressions.push(ecommerceProd);
		});

		//setTimeout(() => {
			hc_gtm.initialiseProductListingPageTracking(criteoSettings, data, searchViewModel, breadcrumbs);
		//}, 2000);
	}
	catch (ex) {

	}
}

exports.InitialiseProductDetailPageTracking = function InitialiseProductDetailPageTracking(gender, product, breadcrumbs) {
	try {
		//setTimeout(() => {
			hc_gtm.initialiseProductDetailPageTracking(gender, product, breadcrumbs);
		//}, 2000);
	}
	catch (ex) {

	}
}

function convertToJSONDate(strDate) {
	var dt = new Date(strDate);
	var year = dt.getFullYear().toString();
	if (year.length == 1) {
		year = "000" + year;
	} else if (year.length === 2) {
		year = "00" + year;
	}
	else if (year.length === 3) {
		year = "0" + year;
	}
	var month = (dt.getMonth() + 1).toString();
	if (month.length === 1) {
		month = "0" + month;
	}
	var date = dt.getDate().toString();
	if (date.length === 1) {
		date = "0" + date;
	}
	var newDate = year + "-" + month + "-" + date;
	return '/Date(' + Date.parse(newDate) + ')/';
}

exports.InitialiseBasketTracking = function InitialiseBasketTracking(criteoSettings, basket, legacy, addedToBag) {
	try {
		if (basket) {

			basket.Created = convertToJSONDate(basket.Created);
			basket.Updated = convertToJSONDate(basket.Updated);
		}

		//setTimeout(() => {
			hc_gtm.initialiseBasketTracking(criteoSettings, basket, legacy, addedToBag);
		//}, 2000);
	}
	catch (ex) {

	}
}

exports.InitialiseReceiptPageTracking = function InitialiseReceiptPageTracking(generalSettings, userSettings, receipt) {
	try {
		var orderTotalExPostageExVat = (receipt.Order.Total - receipt.Order.ShippingTotal) - receipt.Order.ProductTax;
		//setTimeout(() => {
			hc_gtm.initialiseReceiptPageTracking(generalSettings, userSettings, receipt, orderTotalExPostageExVat.toString());
		//}, 2000);
	}
	catch (ex) {

	}
}

exports.InitialiseAddToCartTracking = function InitialiseAddToCartTracking(data) {
	try {
		//setTimeout(() => {
		hc_gtm.initialiseAddToCartTracking(data);
		//}, 2000);
	}
	catch (ex) {

	}
};
