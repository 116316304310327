import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appInitModel]'
})
export class InitModelDirective {

  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
    // var value = this.el.length >= 1 && this.el[0].tagName == "SELECT" ? this.el.val() : attrs.value;
    // var parts = attrs.ngModel.split(".");

    // if (parts.length == 1) {
    //     scope[parts[0]] = value;

    // } else if (parts.length == 2) {
    //     scope[parts[0]][parts[1]] = value;

    // } else if (parts.length == 3) {
    //     scope[parts[0]][parts[1]][parts[2]] = value;

    // } else {
    //     console.error("initModel: the initModel directive doesn't support three levels of nesting");
    // }
  }
}
