import { Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { Basket } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { AppEventType } from '../../enums/event.type';
import { AppEvent } from '../../events/app.event.class';
import { EventQueueService } from '../../events/event.queue';
import { ErrorModel } from '../../models/error.model';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingOptions, ShippingToClickAndCollect, ShippingVM, UpdateBasketShipping } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { WebsiteContentDetail } from '../../models/websiteContent';
import { AmazonpayService } from '../../service/amazonpay.service';
import { CartService } from '../../service/cart.service';
import { CheckoutService } from '../../service/checkout.service';
import { LabelService } from '../../service/label.service';
import { WebsiteService } from '../../service/website.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { AddScriptStyleLinks } from '../../../../assets/shared/tracking';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { AttraqtService } from '../../service/attraqt.service';
import { OrderDetails, OrderItem } from 'src/app/modules/auth/models/order-details';
import { AttraqtActivityBuilder } from '../../builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from '../../enums/attraqt-activity-action.enum';
import resolveCurrencyBySymbol from '../../helper/resolve-currency-by-symbol';
import { NAVIGATOR_LANGUAGE } from '../../constants/attraqt';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';

@Component({
	selector: 'app-amazon-checkout',
	templateUrl: './amazon-checkout.component.html',
	styleUrls: ['./amazon-checkout.component.scss']
})
export class AmazonCheckoutComponent implements OnInit, OnDestroy {

	amazonReady: boolean = false;
	subTotal: number = 0.0;
	shippingValue: number = 0.0;
	confirmingPayment: boolean = false;
	selectedShippingId: number = 0;
	hasSummary: boolean;

	allowAddressChanges: boolean = true;

	shippingOptions: ShippingVM[] = [];
	sessionExpired: boolean = false;
	isGift: boolean = false;

	isConfirmOrder: boolean = false;

	accessToken: string = "";
	orderReference: string = "";
	authenticationStatus: string = "";

	basket: Basket;

	hasCustomSizes: boolean = false;
	prevCountryId: number = 0;
	prevPostCode: string = "";
	agreeTermsFirstLoad: boolean = true;
	amazonRef: string = "";
	agreeTerms: boolean = true;
	error: ErrorModel;
	shippingVM: ShippingVM[];
	shippingModel: ShippingOptions;
	sitecontent: SiteContentDetail;
	regExLibrary: RegExLibrary;
	labelData: Label;
	updateBasketShipping: UpdateBasketShipping;
	shippingToClickAndCollect: ShippingToClickAndCollect;
	shippingAddress: string = "";
	selectedShipping: ShippingVM;
	amazonClientId: string;
	amazonSellerId: string;
	amazonIsSandbox: string = "1";
	currencyCode: string = "";
	isDutyFree: boolean = false;
	websiteContentDetail: WebsiteContentDetail;
	LpAppSetting: LpAppSetting;
	isGermany: boolean = false;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private amazonService: AmazonpayService,
		private eventQueue: EventQueueService,
		private sharedService: SharedService,
		public zone: NgZone,
		private route: ActivatedRoute,
		private cartService: CartService,
		private injector: Injector,
		private checkoutService: CheckoutService,
		public sanitizer: DomSanitizer,
		private siteContentService: SiteContentService,
		private labelService: LabelService,
		private websiteService: WebsiteService,
		private router: Router,
		private authService: AuthService, 
		private attraqtService: AttraqtService,) {

		$("body").removeClass();
		$("body").addClass("checkout ng-scope");
	}

	ngOnInit() {

		// this.sitecontent = this.siteContentService.getSiteContentData();
		//this.getPaymentModel();
		this.subscribeToState();
		this.getAllLabels();
		this.getWebsiteData();
		this.getSiteContent();
		this.getLpAppSetting();
		//this.labelData = this.labelService.getLabelData();
		// this.websiteContentDetail = this.websiteService.getWebsiteData();
		this.route.queryParamMap.subscribe(queryParams => {
			this.authenticationStatus = queryParams.get("AuthenticationStatus");
			this.orderReference = queryParams.get("orderReference");
			this.accessToken = queryParams.get("access_token");
			if (this.orderReference) {
				this.amazonRef = this.orderReference;
			}
			if (this.authenticationStatus == "Success") {
				this.isConfirmOrder = true;
			}
		})

		this.initialiseAmazonCheckout();

		this.setError("", "");
		//this.getBasketData();

		this.getRegExLibrary();
		// this.translation = translation;

		if (this.isConfirmOrder) {
			this.isConfirmOrder = true;
			this.orderReference = "";
			this.amazonReady = true;
		}
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	getPaymentModel() {

		this.checkoutService.getPaymentMethods().subscribe(data => {
			if (data) {
				if (data.result) {

					if (data.returnUrl && data.returnUrl === "shopping") {
						this.router.navigate(['/checkout/shoppingbasket']);
						return;
					}
					if (data.returnUrl && data.returnUrl === "welcome") {
						this.router.navigate(['/checkout/welcome']);
						return;
					}
				}
				//if (data && data.Basket && data.Basket.AddressShipping) {
				//	this.setIsDutyFree(data.Basket.AddressShipping);
				//}
				if (data.Basket) {
					this.basket = data.Basket;
					this.basket['isReadOnly'] = true;
					if (this.basket) {
						this.hasCustomSizes = this.basket['BasketItems'].some(function (bi) {
							return bi.CustomSizes.length > 0;
						});
					}

				}

				//if (this.isConfirmOrder && this.amazonRef) {
				//	this.updateShippingMethods();
				//}
				if (data.result != 'Error') {
					this.amazonClientId = data.AmazonClientid;
					this.amazonSellerId = data.AmazonSellerid;
					this.amazonIsSandbox = data.AmazonIsSandbox ? "1" : "0";
					this.currencyCode = data.Basket.CurrencyCode;
					this.setAmazon();
				} else {
					if (data.returnUrl == 'shopping') {
						const routerService = this.injector.get(Router);
						const ngZone = this.injector.get(NgZone);
						ngZone.run(() => {
							routerService.navigate(['../../checkout/shoppingbasket', data.result.OrderId], { relativeTo: this.route });
						});
					}
				}
			}
		});
	}

	initialiseAmazonCheckout() {
		this.amazonService.amazonCheckout(this.accessToken, this.authenticationStatus, this.orderReference).subscribe(data => {

			/*
				AuthenticationStatus: "Success"
				Basket: { BasketTotalItemCount: 1, CurrencyPrefix: '£', CurrencyCode: 'GBP', CustomerId: 0, CustomerTempId: null, … }
				ClientId: "amzn1.application-oa2-client.1231234545656565656"
				IsConfirmOrder: true
				IsSandbox: true
				OrderReference: "null"
				SellerId: "A234234444444"
				SuccessMFA: true
				WebStoreId: 1
			*/

			if (data) {
				if (data && data.Basket && data.Basket.AddressShipping) {
					this.setIsDutyFree(data.Basket.AddressShipping);
				}

				if (data.Basket) {
					this.basket = data.Basket;
					this.basket['isReadOnly'] = true;

					if (this.basket) {
						this.hasCustomSizes = this.basket['BasketItems'].some(function (bi) {
							return bi.CustomSizes.length > 0;
						});
					}
				}

				//if (data.AuthenticationStatus) {
				//	this.isConfirmOrder = true;
				//}

				if (data.OrderReference) {
					this.amazonRef = data.OrderReference;
				}

				if (this.isConfirmOrder && this.amazonRef) {
					this.updateShippingMethods();
				}

				this.amazonClientId = data.ClientId;
				this.amazonSellerId = data.SellerId;
				this.amazonIsSandbox = data.IsSandbox ? "1" : "0";
				this.currencyCode = data.Basket.CurrencyCode;
				this.setAmazon();

				//if (this.isConfirmOrder) {
				//	this.amazonReady = true;
				//}
			}
		})
	}

	getWebsiteData() {
		this.websiteContentDetail = this.websiteService.getWebsiteData();

		if (!this.websiteContentDetail) {
			this.websiteService.getWebsites().subscribe(data => {
				this.websiteContentDetail = data;
			})
		}
	}

	getAllLabels() {
		this.labelData = this.labelService.getLabelData();

		if (!this.labelData) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.labelData = data;
				}
			});
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();

		if (!this.LpAppSetting) {
			this.LpAppSetting = await this.sharedService.getLpAppSettings();
			setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
		}
		this.isGermany = this.LpAppSetting.WebStoreId === 2 ? true : false;
		if (this.isGermany) {
			this.agreeTerms = false;
		}
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	addScriptTag(url, id) {
		var js, ref = document.getElementsByTagName("script")[0];

		if (!document.getElementById(id)) {
			js = document.createElement("script");
			js.id = id;
			js.async = true;
			js.src = url;
			ref.parentNode.insertBefore(js, ref);
		}
	}

	onAmazonPayUpdate(e, data) {
		// Additional actions not need: onReady and onPaymentSelect
		switch (data.action) {
			case "onReady":
				this.amazonReady = true;
				break;

			case "onOrderReferenceCreate":
				this.amazonRef = data.payload.orderReference;
				this.amazonService.initialiseOrder(this.amazonRef, this.accessToken).subscribe(data => { });
				break;

			case "onAddressSelect":
				this.updateShippingMethods();
				break;

			case "onError":
				this.setError(data.payload.code, "");
				break;

			default:
				break;
		}
	}

	updateShippingMethods() {
		this.amazonService.getShippingAddress(this.amazonRef)
			.subscribe((response) => {

				if (response.success) {
					this.basket['AddressShipping'] = response.address;
					this.createShippingAddress();
					this.refreshShippingOptions(response.address.CountryId, response.address.PostCode);
				} else {
					this.shippingOptions = [];
					this.selectedShippingId = 0;
				}

			});
	}

	refreshShippingOptions(countryId, postCode) {

		this.prevCountryId = countryId;
		let shippingOption: ShippingOptions = {
			countryId: countryId,
			isClickAndCollect: false,
			postcode: postCode
		}

		this.amazonService.getShippingOptions(shippingOption).subscribe(data => {
			if (data) {
				this.selectedShippingId = 0;

				if (this.hasCustomSizes) {
					data = data.filter((x) => {
						return x.ShippingTypeId != 2;
					});
				}

				this.shippingOptions = [...data]

				for (let index = 0; index < this.shippingOptions.length; index++) {
					if (this.shippingOptions[index].Selected) {
						this.selectedShippingId = this.shippingOptions[index].Id;
						this.selectedShipping = this.shippingOptions[index];
						break;
					}

					if (index == this.shippingOptions.length - 1 && this.selectedShippingId == 0) {
						this.selectedShippingId = this.shippingOptions[0].Id;
						this.selectedShipping = this.shippingOptions[0];
					}
				}

				this.shippingSelected(this.selectedShipping)

				this.zone.run(() => {
					this.shippingOptions = [...data];
					this.selectedShippingId = this.selectedShippingId
					this.shippingToClickAndCollect.price = this.selectedShipping.Price;
				})
			} else {
				this.zone.run(() => {
					this.shippingOptions = [];
				});
			}
		})
	}

	notifyBasket() {
		if (this.selectedShipping) {
			this.basket['Shipping'] = this.selectedShipping;
			this.eventQueue.dispatch(new AppEvent(AppEventType.refreshbag, '', { basket: this.basket }));
		}
	}

	shippingSelected(option) {
		// Cannot change shipping after ConfirmationOrder
		if (this.isConfirmOrder == true) return;

		this.amazonService.setShippingMethod(this.amazonRef, option.Id).subscribe(data => {
			if (data) {
				this.hasSummary = true;
				this.subTotal = data.subTotal;
				this.shippingValue = data.shippingValue;
				this.selectedShipping = option;
				this.shippingToClickAndCollect.price = this.shippingValue
				//Notify basket directive
				this.notifyBasket();
			}
		})

		// Inform any subscribers of shipping changes
		this.eventQueue.dispatch(new AppEvent(AppEventType.refreshShippingOption, '', {
			shippingValue: this.shippingValue,
			selectedShippingId: this.selectedShippingId,
			countryId: this.prevCountryId
		}));
	}

	callPaymentOrder() {
		if (!this.agreeTerms) {
			return;
		}

		if (this.isConfirmOrder) {
			this.confirmOrder();
		} else {
			// Register "OffAmazonPayments.initConfirmationFlow" to allow Amazon Pay to handle MFA
			window.OffAmazonPayments.initConfirmationFlow(this.amazonSellerId, this.amazonRef, (confirmationFlow) => {
				this.confirmPayment(confirmationFlow);
			});
		}
	}

	confirmPayment(confirmationFlow) {
		this.confirmingPayment = true;
		this.setError("", "");

		this.amazonService.confirmPayment(this.amazonRef, this.isGift).subscribe(data => {
			if (data && data.OrderId > 0) {

				// Continue Amazon Pay initConfirmationFlow
				confirmationFlow.success();
				return;
			}

			// Something went wrong, so report back
			// Cancel Amazon Pay initConfirmationFlow
			confirmationFlow.error();

			this.setError(data.ErrorCode, data.ErrorMessage);
			this.confirmingPayment = false;
		})
	}

	confirmOrder() {
		this.confirmingPayment = true;
		this.setError("", "");

		this.amazonService.confirmOrder(this.amazonRef).subscribe(data => {

			const routerService = this.injector.get(Router);
			const ngZone = this.injector.get(NgZone);

			if (data && data.OrderId > 0 && data.IsSuccess == true) {
				this.authService
					.getWebOrderDetailsById(data.OrderId)
					.subscribe((orderDetails: OrderDetails) => {
						if (orderDetails.Order.OrderItems.length && this.facetFilterState.attraqtPLPEnabled) {
							orderDetails.Order.OrderItems.forEach((orderItem: OrderItem) => {
								this.attraqtService.sendActivity(
									new AttraqtActivityBuilder(AttraqtActivityActionEnum.Purchase)
										.setTarget({ product: orderItem.ProductId.toString() })
										.setMetadata({
											orderId: data.OrderId.toString(),
											price: orderItem.TotalPrice * 100,
											currency: resolveCurrencyBySymbol(
												orderDetails.Order.CurrencySymbol
											),
											quantity: orderItem.Quantity,
											locale: localStorage.getItem(NAVIGATOR_LANGUAGE),
										})
										.build()
								);
							});
						}
					});

				ngZone.run(() => {
					routerService.navigate(['/checkout/receipt', data.OrderId], { relativeTo: this.route });
				});

				return;

			} else if (data.State === "Declined") {

				switch (data.Reason) {
					case "PaymentPlanNotSet":
					case "InvalidPaymentMethod":
					case "PaymentMethodNotAllowed":
						ngZone.run(() => {
							routerService.navigate(['/amazon/paymentfailed'], { queryParams: { reason: data.Reason } });
						});

						break;

					case "AmazonRejected":
						if (window && window.amazon && window.amazon.hasOwnProperty('Login') && window.amazon['login']) {
							window.amazon['Login'].logout();
						}

						ngZone.run(() => {
							routerService.navigate(['/amazon/paymentfailed'], { queryParams: { reason: data.Reason } });
						});

						break;
				}
			} else {
				this.setError(data.ErrorCode, data.ErrorMessage);
			}

			this.confirmingPayment = false;
		})
	}

	setError(errorCode, errorMessage) {
		var rerenderWallet = false;

		this.error = {
			hasError: errorCode !== "" && errorCode !== null,
			sessionExpired: errorCode === "BuyerSessionExpired",
			code: errorCode,
			message: errorCode
		}

		switch (errorCode) {
			case "BuyerSessionExpired":
				this.error.message = "Your session has expired, you can either sign in again or return to the basket to choose a different payment method";
				rerenderWallet = false;
				break;

			case "PaymentPlanNotSet":
				this.error.message = "No payment method selected. Please add a new payment method to your wallet or refresh the page";
				rerenderWallet = true;
				break;

			case "InvalidPaymentMethod":
			case "PaymentMethodNotAllowed":
				this.error.message = "The selected payment method is not available for this transaction. Please select another one or add a new payment method to your wallet";
				rerenderWallet = true;
				break;

			default:
				if (errorMessage !== "" && errorMessage !== null) {
					this.error.message = errorMessage;
				} else if (errorCode !== "") {
					this.error.message = "Error returned '" + errorCode + "'";
				}

				break;
		}
		this.zone.run(() => this.error);

		if (rerenderWallet) {
			this.allowAddressChanges = false;
			this.eventQueue.dispatch(new AppEvent(AppEventType.onAmazonReRenderWallet, '', { orderReference: this.amazonRef }));
		}
	}

	removeAgreeTermsFirstLoad() {
		this.agreeTermsFirstLoad = false;
	};

	getRegExLibrary() {
		this.sharedService.getRegExLibrary().subscribe(data => {
			if (data) {
				this.regExLibrary = data;
			}
		});
	}

	createShippingAddress() {
		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['FirstName']) {
			this.shippingAddress += this.basket['AddressShipping']['FirstName'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['LastName']) {
			this.shippingAddress += ' ' + this.basket['AddressShipping']['LastName'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['StreetAddress1']) {
			this.shippingAddress += '<br>' + this.basket['AddressShipping']['StreetAddress1'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['StreetAddress2']) {
			this.shippingAddress += '<br>' + this.basket['AddressShipping']['StreetAddress2'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['City']) {
			this.shippingAddress += '<br>' + this.basket['AddressShipping']['City'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['PostCode']) {
			this.shippingAddress += '<br>' + this.basket['AddressShipping']['PostCode'];
		}

		if (this.basket['AddressShipping'] && this.basket['AddressShipping']['Country']) {
			this.shippingAddress += '<br>' + this.basket['AddressShipping']['Country'];
		}
	}

	getBasketData() {
		this.cartService.GetToBasket().subscribe(data => {
			this.basket = data;
			this.basket['isReadOnly'] = true;

			this.hasCustomSizes = this.basket['BasketItems'].some(function (bi) {
				return bi.CustomSizes.length > 0;
			});

			if (this.isConfirmOrder && this.amazonRef) {
				this.updateShippingMethods();
			}
		})
	}

	setAmazon() {

		window.onAmazonLoginReady = () => {
			this.amazonService.initialiseLogin(this.amazonClientId, this.amazonIsSandbox === '1');
		};

		window.onAmazonPaymentsReady = () => {
			this.eventQueue.dispatch(new AppEvent(AppEventType.onAmazonPaymentsReady, '', {}));
		};

		this.eventQueue.on(AppEventType.amazonpay).subscribe(event => {
			this.onAmazonPayUpdate("", event);
		});

		if (this.amazonIsSandbox === '1') {
			this.addScriptTag("https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/sandbox/lpa/js/Widgets.js", "amazonpay-js");
		} else {
			this.addScriptTag("https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/lpa/js/Widgets.js", "amazonpay-js");
		}

		this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;
		this.shippingToClickAndCollect.price = 0;
		this.shippingToClickAndCollect.shippingText = "";
		this.shippingToClickAndCollect.showdeliveryoption = false;
	}

	setIsDutyFree(addressShipping) {
		// Assign Duty Free if country IsEU and not UK
		if (addressShipping) {
			this.isDutyFree = addressShipping.IsCountryEU && addressShipping.CountryId != 1;
		}
	}

	amazonLogin() {
		if ($('.amazon-custom-button') && $('.amazon-custom-button').onclick == null) {
			let ele = $('.amazon-custom-button');

			ele.on('click', () => {
				var loginOptions = {
					scope: "profile payments:widget payments:shipping_address payments:billing_address",
					popup: "true"
				};

				window.amazon['Login'].authorize(loginOptions, "/amazon/checkout");
			})
		}
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
