import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { AccountComponent } from './modules/auth/components/account/account.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot-password.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { HomePageComponent } from './modules/Home/home-page/home-page.component';
import { DefaultComponent } from './modules/layout/default/default.component';
import { ProductdetailsComponent } from './modules/product-details/productdetails/productdetails.component';
import { SearchProductsComponent } from './modules/product-list/search-products/search-products.component';
import { ShoppingBasketComponent } from './modules/shopping-basket/shopping-basket/shopping-basket.component';
import { StoreDetailsComponent } from './shared/components/store-details/store-details.component';
import { StoresComponent } from './shared/components/stores/stores.component';
import { SubscribeComponent } from './shared/components/subscribe/subscribe.component';
import { CheckoutComponent } from './shared/components/checkout/checkout.component';
import { CheckoutDeliveryComponent } from './shared/components/checkout-delivery/checkout-delivery.component';
import { CheckoutPaymentComponent } from './shared/components/checkout-payment/checkout-payment.component';
import { MenuItemsResolver } from './shared/components/header/default-header/menu-items-resolver';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ReceiptComponent } from './shared/components/receipt/receipt.component';
import { ProductListComponent } from './modules/product-list/product-list/product-list.component';
import { AmazonCheckoutComponent } from './shared/components/amazonpay/amazon-checkout.component';
import { SignInComponent } from './modules/auth/components/sign-in/sign-in.component';
import { MyAccountComponent } from './modules/auth/components/my-account/my-account.component';
import { PaypalQuickConfirmComponent } from './shared/components/paypal/paypal-quickconfirm/paypal-quickconfirm.component';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';
import { PaypalIcCompleteComponent } from './shared/components/paypal/paypal-ic-complete/paypal-ic-complete.component';
import { RegisterThanksComponent } from './shared/components/register-thanks/register-thanks.component';
import { LpAppSettingsResolver } from './shared/components/header/default-header/lpapp-settings-resolver';
import { SiteContentResolver } from './shared/components/header/default-header/site-content-resolver';
import { PaymentFailedComponent } from './shared/components/payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './shared/components/payment-cancelled/payment-cancelled.component';
import { SignOutComponent } from './modules/auth/components/sign-out/sign-out.component';
import { AmazonFailedComponent } from './shared/components/amazon-failed/amazon-failed.component';
import { CheckoutPOQComponent } from './shared/components/checkout-poq/checkout-poq.component';
import { ApiTestComponent } from './shared/components/_api-test/api-test.component';
import { GlobaleCheckoutComponent } from './shared/components/globale-checkout/globale-checkout.component';
import { ReviewsComponent } from './shared/components/reviews/reviews.component';

export function customMatcher(
	segments: UrlSegment[],
	group: UrlSegmentGroup,
	route: Route
) {
	const { length } = segments;
	const firstSegment = segments[0];
	let check = true;

	const idSegments = segments;

	const idPaths = idSegments.map(segment => segment.path);
	const mergedId = idPaths.join('/');
	const idSegment: UrlSegment = new UrlSegment(mergedId, { url: mergedId });
	let currentUrl = '/' + mergedId;
	//let clickedUrl = localStorage.getItem('currentUrl');

	if (length >= 1 && check) {
		return ({ consumed: segments, posParams: { url: idSegment } });
	}
	return null;

}

const routes: Routes = [

	// ###########################################################################################################################################
	// ## AR:	Any new first level paths should be whitelisted in _angularRoutesRegex inside LowProfile.Web.V2\Controllers\StaticController.cs ##
	// ##		This is to ensure Page Not Found is handled on the webserver itself rather than Angular application for Web Crawlers.           ##
	// ###########################################################################################################################################

	{ path: 'ApiTest', component: ApiTestComponent },

	{ path: 'amazon/checkout', component: AmazonCheckoutComponent },
	{ path: 'amazon/paymentfailed', component: AmazonFailedComponent },
	{ path: 'paypal/QuickConfirm', component: PaypalQuickConfirmComponent },
	{ path: 'paypal/ic_CompletePayment', component: PaypalIcCompleteComponent },

	{ path: 'checkout/ImportBasketPOQ', component: CheckoutPOQComponent },
	{ path: 'checkout/welcome', component: CheckoutComponent },
	{ path: 'checkout/delivery', component: CheckoutDeliveryComponent },
	{ path: 'checkout/payment', component: CheckoutPaymentComponent },
	{ path: 'checkout/paymentfailed', component: PaymentFailedComponent },
	{ path: 'checkout/paymentcancelled', component: PaymentCancelledComponent },
	{ path: 'checkout/receipt/:id', pathMatch: "full", component: ReceiptComponent },

	{ path: 'checkout/international', component: GlobaleCheckoutComponent },

	{ path: 'checkout/registerthanks/:webstoreid', component: RegisterThanksComponent },
	{
		path: '', component: DefaultComponent, resolve: { users: MenuItemsResolver, lpAppSetting: LpAppSettingsResolver, siteContent: SiteContentResolver },
		children: [
			{ path: '', component: HomePageComponent },
			{ path: 'search', component: SearchProductsComponent },
			{ path: 'checkout/shoppingbasket', component: ShoppingBasketComponent },
			{ path: 'stores', component: StoresComponent },
			{ path: 'stores/:id', component: StoreDetailsComponent },
			{ path: 'subscribe', component: SubscribeComponent },
			{ path: 'reviews', component: ReviewsComponent },
			{
				path: 'account', component: AccountComponent, children: [
					{ path: 'signin', component: SignInComponent },
					{ path: 'signout', component: SignOutComponent },
					{ path: 'register', component: RegisterComponent },
					{ path: 'forgottenpassword', component: ForgotPasswordComponent },
					{ path: 'resetpassword', component: ResetPasswordComponent },
					{ path: 'myaccount', component: MyAccountComponent },
					{ path: 'wishlist', component: MyAccountComponent },
					{ path: 'addressbook', component: MyAccountComponent },
					{ path: 'orders', component: MyAccountComponent },
					{ path: 'savedcards', component: MyAccountComponent },
					{ path: 'order/:orderId', component: MyAccountComponent },

				]
			},
			{ path: 'pagenotfound', component: PageNotFoundComponent },
			{ matcher: customMatcher, component: HomePageComponent },

			{ path: '**', redirectTo: 'pagenotfound' }
		]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		enableTracing: false,

		// No longer using enabledBlocking since this breaks APP_INITIALIZER and guards.
		// Especially for EnvironmentService which requires initialisation before any API calls are made.
		initialNavigation: 'enabledNonBlocking',
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
