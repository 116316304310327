import { Component, HostListener, Injector, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BannerVM } from 'src/app/model/banner-viewmodel';
import { OpenModalService } from 'src/app/shared/service/open-modal.service';
import { datascroll } from '../../../../assets/shared/helper_scripts';
import { DefaultService } from '../../layout/default.service';

@Component({
	selector: 'banner,[banner]',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
	@Input() bannerVm: BannerVM;
	@Input() isCarousel:boolean = false;

	constructor(
		private router: Router,
		private defaultService: DefaultService,
		private injector: Injector,
		private route: ActivatedRoute,
		private openModalService: OpenModalService,
		private ngZone: NgZone) {
	}

	ngOnInit() {
	}

	@HostListener("click", ['$event'])
	onClick(event) {

		event.preventDefault();
		event.stopPropagation();

		if (event.target.tagName == 'A') {
			let url = event.target.getAttribute('href');

			if (!url) {
				let dataModel = event.target.getAttribute('data-open-in-modal');

				if (dataModel) {
					this.openModalService.openVideoModal(dataModel);
				}

				datascroll(event);

				return;
			}

			url = url.replace("#", '');

			if (url.includes("?")) {
				let previousUrl = this.router.url;
				let previousMainUrl = previousUrl;

				if (previousUrl.includes("?")) {
					previousMainUrl = previousUrl.substring(0, previousUrl.indexOf("?"));
				}

				let currentUrl = url;
				let currentMainUrl = currentUrl.substring(0, currentUrl.indexOf("?"));

				if (previousMainUrl === currentMainUrl) {
					this.defaultService.bannerUrlEvent(currentUrl);
					return;
				}
			}

			if (url && (url.startsWith('https://') || url.startsWith('http://'))) {
				window.location.href = url;
			} else {
				this.router.navigate([url]);
			}
		}
	}

	isCarouselUpdated() {
		// Ignore this if banner is not part of carousel
		if (this.bannerVm.CarouselUpdateEnd == null && this.bannerVm.CarouselUpdateStart == null) {
			return true;
		}

		return (this.bannerVm.CarouselUpdateEnd >= this.bannerVm.CarouselUpdateStart) // Finished Updating
			&& (moment().diff(this.bannerVm.CarouselUpdateEnd) > 200); // Ensure delay since carousel could still be updating
	}

	navigateUrl(destinationUrl) {
		window.location.href = destinationUrl;
	}

	contentClicked(event) {
		const element: HTMLElement = event.target;
		if (element.nodeName === 'A') {
			event.preventDefault();
			const link = element.getAttribute('href');
			if (link) {
				this.router.navigate([link]);
			}
		}
	}

	isNullOrWhitespace(input): boolean {

		if (typeof input === 'undefined' || input == null) return true;

		return input.replace(/\s/g, '').length < 1;
	}

	RemoveSpace(bannerUrl) {
		if (bannerUrl) {
			return bannerUrl.trim();
		}
	}

	isContainsVideo(source: string): boolean {
		return source.startsWith('https://player.vimeo.com') || source.endsWith('.mp4') || source.endsWith('.avi') || source.endsWith('.webm');
	}

	isHtmlPlayer(source: string): boolean {
		return source.endsWith('.m4v') || source.startsWith('https://player.vimeo.com');
	}

	insideContent(bannerVm) {
		let insidecontent;
		if (bannerVm) {
			insidecontent = bannerVm.HasTitleOutside ? bannerVm.Content : bannerVm.Title;
		}
		return insidecontent;
	}

	outsideContent(bannerVm) {
		let outsidecontent;
		if (bannerVm) {
			outsidecontent = bannerVm.HasTitleOutside ? bannerVm.Title : bannerVm.Content;
		}
		return outsidecontent;
	}

	isIncludeHyperLink(content) {
		let isHyperLink = false;
		if (content && content.includes("<a") && content.includes("</a>")) {
			isHyperLink = true;
		}
		return isHyperLink;
	}

	getHyperLink(destinationUrl) {

		if (destinationUrl) {
			return "<a routerLink='" + destinationUrl + "'></a>"
		}

		return "<a routerLink></a>"
	}

	getCurrentUrl() {
		return this.router.url;
	}

	concateContent(destinationUrl, content) {
		let htmlContent = "";

		if (destinationUrl) {
			htmlContent = "<a class='linkwrap' ng-reflect-router-link='" + destinationUrl + "' href='" + destinationUrl + "'>";
		}

		if (content) {
			htmlContent += content + "</a>";
		}
		return htmlContent;
	}
}
