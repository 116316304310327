import {
	Component,
	Input,
	EventEmitter,
	OnInit,
	Output,
	SimpleChanges,
	HostListener,
	OnChanges,
	OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';

import { LoaderService } from '../../../shared/components/loader/loader.service';
import {
	FacetedNavigationGroup,
	FacetedNavigationItem,
} from '../model/product-list';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { Product } from '../model/algolia-product-viewmodel';
import {
	MAX_FILTERS,
	MAX_SCREEN_WIDTH,
} from '../../../shared/constants/facet-filter';
import decodeParamsFromQuery from '../../../shared/helper/decode-params-from-query';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import {
	IFacetFilterState,
	ISelectedFilter,
} from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';

@Component({
	selector: 'attraqt-facet-filter, [attraqt-facet-filter]',
	templateUrl: './attraqt-facet-filter.component.html',
	styleUrls: ['./attraqt-facet-filter.component.scss'],
})
export class AttraqtFacetFilterComponent implements OnInit, OnChanges, OnDestroy {
	@Input() facetGroups: FacetedNavigationGroup[];
	@Input() products: Product[];
	@Input() fredhopperResponseId: string;
	@Input() isPreloading: boolean;
	@Output() filterValueChanged = new EventEmitter();
	@Output() filterHidden = new EventEmitter();
	@Output() sortChanged = new EventEmitter();

	showFilter = true;
	innerWidth: number;
	showSmallScreenFilterOn = false;
	showAllGroups = false;
	showMoreFilterButton = false;
	ClearFiltersOnTop = false;
	currentScreenWidth: number;
	hideDesktop = false;
	hideSmallDesktop = false;
	hideTablet = false;
	group: FacetedNavigationGroup;
	selectedTotal = 0;
	lpAppSetting: LpAppSetting;
	siteContent: SiteContentDetail;
	MAX_FILTERS_COUNT = MAX_FILTERS;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	queryParams: any;
	queryParamsSubscriber: Subscription;
	selectedFilters: ISelectedFilter[] = [];
	selectedSort = false;
	sortBy: string;
	sortDescending?: boolean = null;
	showSort = false;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		private sharedService: SharedService,
		private siteContentService: SiteContentService,
		private attraqtService: AttraqtService
	) {}

	ngOnInit() {
		this.subscribeToState();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.innerWidth = window.innerWidth;
		this.currentScreenWidth = window.innerWidth;
		this.getLpAppSetting();
		this.setGroupVisibility();
		this.queryParamsSubscriber = this.route.queryParams.subscribe(
			(params) => (this.queryParams = params)
		);
	}

	ngOnDestroy() {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
		if (this.queryParamsSubscriber) {
			this.queryParamsSubscriber.unsubscribe();
		}
	}

	ngOnChanges(_changes: SimpleChanges) {
		if (this.products) {
			this.setGroupVisibility();
		}
		if (!this.isPreloading && this.facetGroups.length) {
			this.setSelectedFilters();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.currentScreenWidth = window.innerWidth;
		this.showMobileFilter(false);
	}

	showFilterContent() {
		this.showFilter = true;
	}

	hideSmallScreenFilter() {
		this.showSmallScreenFilterOn = false;
	}

	showSmallScreenFilter() {
		this.showSmallScreenFilterOn = true;
	}

	showMoreFilter() {
		this.showAllGroups = !this.showAllGroups;
		this.setGroupVisibility();
		this.toggleFilterGroup(null);
	}

	setFilter(groupItem: FacetedNavigationItem, group: FacetedNavigationGroup) {
		this.loaderService.isLoading.next(true);
		if(!groupItem.UrlParams)
		{
			this.selectedFilters = [];
			this.router.navigate([]);
			this.filterValueChanged.emit();
		}
		else
		{
			this.filterValueChanged.emit(groupItem.UrlParams);
			this.router.navigate([], {
				queryParams: decodeParamsFromQuery(groupItem.UrlParams),
			});
		}

		groupItem.IsSelected = !groupItem.IsSelected;
		if (groupItem.IsSelected) {
			if (this.facetFilterState.attraqtPLPEnabled) {
				this.sendFacetClickedActivity(group.GroupId, groupItem.Value);
			}
		}
	}

	setGroupVisibility() {
		const groupsCount = this.facetGroups.length;
		if (this.showAllGroups) {
			this.hideDesktop = false;
			this.hideSmallDesktop = false;
			this.hideTablet = false;
		} else {
			this.hideDesktop = groupsCount > MAX_FILTERS.DESKTOP;
			this.hideSmallDesktop = groupsCount > MAX_FILTERS.SMALL_DESKTOP;
			this.hideTablet = groupsCount > MAX_FILTERS.TABLET;
		}
		this.showMoreFilterButton = this.resolveShowMoreFiltersValue(groupsCount);
	}

	toggleFilterGroup(facetGroup: FacetedNavigationGroup) {
		if (this.facetGroups.length) {
			for (let i = 0; i < this.facetGroups.length; ++i) {
				if (!facetGroup) {
					this.facetGroups[i].IsActive = false;
				} else if (facetGroup?.GroupId !== this.facetGroups[i].GroupId) {
					this.facetGroups[i].IsActive = false;
				} else {
					this.facetGroups[i].IsActive = !this.facetGroups[i].IsActive;
				}
			}
		}
	}

	clearFilters(group: FacetedNavigationGroup) {
		if (!group) {
			this.selectedFilters = [];
			this.router.navigate([]);
			this.filterValueChanged.emit();
		} else {
			const queryParams = { ...this.queryParams };
			const foundSelectedGroupIndex = this.selectedFilters.findIndex(
				({ groupId }) => groupId === group.GroupId
			);
			if (queryParams) {
				if (queryParams[group.Key]) {
					delete queryParams[group.Key];
				}
			}

			const queryString = this.resolveSearchQueryString(queryParams);

			this.router.navigate([], { queryParams });
			this.selectedFilters.splice(foundSelectedGroupIndex, 1);
			this.filterValueChanged.emit(queryString);	
		}
	}

	showMobileFilter(isShow: boolean) {
		if (isShow) {
			document.querySelector('body').classList.add('filter-on');
		} else {
			document.querySelector('body').classList.remove('filter-on');
		}
	}

	getLpAppSetting() {
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	setSort(sortby: string, sortDescending?: boolean): void {
		this.selectedSort = !this.selectedSort;
		if (this.sortBy === sortby && this.sortDescending === sortDescending) {
			return;
		}
		this.sortBy = sortby;
		this.sortDescending = sortDescending;
		this.sortChanged.emit([sortby, sortDescending]);
		this.showSort = false;
		const queryParams = { ...this.queryParams };

		//reset pagination 
		if (queryParams?.hasOwnProperty('page')) {
			delete queryParams['page'];
		}

		if (queryParams?.hasOwnProperty('sortBy')) {
			if (!this.sortBy) {
				delete queryParams['sortBy'];
			} else {
				queryParams['sortBy'] = this.sortBy;
			}
		}
		if (!queryParams?.hasOwnProperty('sortBy') && this.sortBy) {
			queryParams['sortBy'] = this.sortBy;
		}
		if (queryParams?.hasOwnProperty('sortDescending')) {
			if (!this.sortDescending) {
				delete queryParams['sortDescending'];
			} else {
				queryParams['sortDescending'] = this.sortDescending;
			}
		}
		if (!queryParams?.hasOwnProperty('sortDescending') && this.sortDescending) {
			queryParams['sortDescending'] = this.sortDescending;
		}
		this.router.navigate([], { queryParams });
		const queryString = this.resolveSearchQueryString(queryParams);
		this.filterValueChanged.emit(queryString);
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}

	private setSelectedFilters(): void {
		this.selectedFilters = [];
		for (let i = 0; i < this.facetGroups.length; ++i) {
			if (this.facetGroups[i].IsSelected) {
				const {
					GroupId: groupId,
					Key: key,
					Title: title,
					SelectedItems: selectedItems,
				} = this.facetGroups[i];
				const selected: ISelectedFilter = {
					groupId,
					title,
					key,
					index: i,
					items: selectedItems.map(
						({ Name: name, Value: value, UrlParams: urlParams }) => ({
							name,
							value,
							urlParams,
						})
					),
				};
				this.selectedFilters.push(selected);
			}
		}
		this.calculateTotalFiltersCount(this.selectedFilters);
	}

	private resolveShowMoreFiltersValue(groupCount: number): boolean {
		if (
			this.currentScreenWidth >= MAX_SCREEN_WIDTH.DESKTOP &&
			groupCount > MAX_FILTERS.DESKTOP
		) {
			return true;
		}
		if (
			this.currentScreenWidth >= MAX_SCREEN_WIDTH.SMALL_DESKTOP &&
			this.currentScreenWidth <= MAX_SCREEN_WIDTH.DESKTOP &&
			groupCount > MAX_FILTERS.SMALL_DESKTOP
		) {
			return true;
		}
		if (
			this.currentScreenWidth >= MAX_SCREEN_WIDTH.TABLET &&
			this.currentScreenWidth <= MAX_SCREEN_WIDTH.SMALL_DESKTOP &&
			groupCount > MAX_FILTERS.TABLET
		) {
			return true;
		}
		return false;
	}

	private calculateTotalFiltersCount(selectedFilters: ISelectedFilter[]): void {
		this.selectedTotal = selectedFilters.reduce(
			(acc: number, current: ISelectedFilter) => {
				if (current.items.length) {
					acc += current.items.length;
				}
				return acc;
			},
			0
		);
	}

	private resolveFhLocationParam(
		fhLocation: string,
		groupKey: string,
		groupItemValue?: any
	): string {
		const startIndex = fhLocation.lastIndexOf(`/${groupKey}`);
		const endIndex = fhLocation.lastIndexOf(`/`);
		if (!groupItemValue) {
			if (startIndex === endIndex) {
				return fhLocation.substring(0, startIndex);
			} else {
				let count = -1;
				for (let i = 0; i < fhLocation.length; ++i) {
					count += 1;
					if (count > startIndex && fhLocation[i] === '/') {
						break;
					}
				}
				return (
					fhLocation.substring(0, startIndex) + fhLocation.substring(count)
				);
			}
		}
		const prefix = fhLocation.substring(0, startIndex + 1);
		const suffix =
			startIndex === endIndex
				? ''
				: fhLocation.substring(endIndex, fhLocation.length);
		const queryParamWithValues =
			startIndex === endIndex
				? fhLocation.substring(startIndex + 1)
				: fhLocation.substring(startIndex + 1, endIndex);
		const index1 = queryParamWithValues.indexOf(`;${groupItemValue}`);
		const index2 = queryParamWithValues.indexOf(`${groupItemValue};`);
		const index3 = queryParamWithValues.indexOf(`;${groupItemValue};`);
		let middle = '';
		if (index1 === -1 && index3 === -1 && index2 > -1) {
			middle =
				queryParamWithValues.substring(
					0,
					queryParamWithValues.indexOf(`${groupItemValue};`)
				) +
				queryParamWithValues.substring(
					queryParamWithValues.indexOf(`${groupItemValue};`) +
						groupItemValue.toString().length +
						1
				);
		}
		if (index1 > -1 && index2 > -1 && index3 > -1) {
			middle =
				queryParamWithValues.substring(
					0,
					queryParamWithValues.indexOf(`${groupItemValue};`)
				) +
				queryParamWithValues.substring(
					queryParamWithValues.indexOf(`;${groupItemValue};`) +
						groupItemValue.toString().length +
						2
				);
		}
		if (index1 > -1 && index2 === -1 && index3 === -1) {
			middle =
				queryParamWithValues.substring(
					0,
					queryParamWithValues.indexOf(`;${groupItemValue}`)
				) + '}';
		}
		return prefix + middle + suffix;
	}

	private resolveSearchQueryString(queryParams: any): string {
		const length = Object.keys(queryParams).length;
		const queryParam = Object.entries(queryParams).reduce(
			(acc: string, current: any, index) => {
				const [key, value] = current;
				if (index === length - 1) {
					acc += `${key}=${value}`;
				} else {
					acc += `${key}=${value}&`;
				}
				return acc;
			},
			''
		);
		return encodeURIComponent(queryParam);
	}

	private sendFacetClickedActivity(facetId: string, facetValue: string): void {
		this.attraqtService.sendActivity(
			new AttraqtActivityBuilder(AttraqtActivityActionEnum.Click)
				.setTarget({ facet: facetId, [facetId]: facetValue })
				.setSourceId(this.fredhopperResponseId)
				.setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) })
				.build()
		);
	}
}
