import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteContentDetail } from '../../models/siteContent';
import { SiteContentService } from '../../sitecontent.service';

@Component({
  selector: 'app-maps-modal',
  templateUrl: './maps-modal.component.html',
  styleUrls: ['./maps-modal.component.scss']
})
export class MapsModalComponent implements OnInit {
  lang: number;
  lat: number;
  selectedStore;
  store: any;
  storeList: any[];
  selectedStoreName = '';

  activeMarkerIcon = '/assets/maps/maps_marker_active.png';
  inactiveMarkerIcon = "/assets/maps/maps_marker_inactive.png";
  siteContent: SiteContentDetail;
  previous;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dailogRef: MatDialogRef<MapsModalComponent>,
  private siteContentService: SiteContentService
  ) {
    
  }

  @Input() storesInfo;

  ngOnInit() {
    this.siteContent = this.siteContentService.getSiteContentData();

    // this.store = this.data.storeDetails.filter(store => store.StoreReference == this.data.ref);
    // this.lat =this.selectedStore[0].Latitude;
    // this.lang = this.selectedStore[0].Longitude;
    // this.selectedStoreName = this.selectedStore[0].StoreName;
    this.storeList = [];
    if (this.data) {
      this.store = this.data.storeDetails;
      this.storeList = this.data.storeList;
    }


  }

  closeModal() {
    this.dailogRef.close('true');
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
