import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Label } from '../../models/Labels';
import { SiteContentDetail } from '../../models/siteContent';
import { Store, StoresViewModel } from '../../models/stores-vm';
import { MapModalComponent } from '../map-modal/map-modal.component';
import * as $ from 'jquery';

@Component({
	selector: 'store-single-page,[store-single-page]',
	templateUrl: './store-single-page.component.html',
	styleUrls: ['./store-single-page.component.scss']
})
export class StoreSinglePageComponent implements OnInit {
	@Input() sitecontent: SiteContentDetail;
	@Input() label: Label;
	@Input() stores: StoresViewModel;
	matDialogRef: MatDialogRef<MapModalComponent>;
	constructor(private matDialog: MatDialog) {
		$("body").removeClass();
		$("body").addClass("ng-scope mask-hide");
	}

	ngOnInit() {
		if (this.stores) {

			this.stores.Regions = [""];
			this.stores.Stores.filter(x => {
				if (x.Region) {
					return true;
				}
			}).forEach(y => y.Region.RegionName = '');
		}


		//Model.Stores.Where(x => x.Region != null).ForEach(x => x.Region.RegionName = string.Empty);
	}
	OpenMapModal(store) {
		let videosource = store;
		this.matDialogRef = this.matDialog.open(MapModalComponent, {
			data: { store: store },
			disableClose: true
		});
		this.matDialogRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				videosource = undefined;
			}
		});
	}
	getAddress(store: Store, includeCountry: boolean) {

		let excludeUk = true;
		let includePhone = false;
		let includeCustomerName = true
		let sb = '';
		if (store && store.Address) {
			//var sb = new StringBuilder();


			if (includeCustomerName && store.Address.FirstName || store.Address.LastName) {
				sb = store.Address.FirstName + store.Address.LastName + "<br/>";
			}

			if (store.Address.StoreName) {
				sb += store.Address.StoreName + "<br/>";
			}

			if (store.Address.StreetAddress1) {

				sb += store.Address.StreetAddress1 + "<br/>";
			}

			if (store.Address.StreetAddress2) {

				sb += store.Address.StreetAddress2 + "<br/>";
			}

			if (store.Address.City) {

				sb += store.Address.City + "<br/>";
			}

			if (store.Address.County) {

				sb += store.Address.County + "<br/>";
			}

			if (store.Address.StateCode) {

				sb += store.Address.StateCode + "<br/>";
			}

			if (store.Address.PostCode) {
				sb += store.Address.PostCode + "<br/>";
			}

			if (includeCountry && store.Address.Country) {
				if (!(excludeUk && store.Address.CountryId == 1)) {

					sb += store.Address.Country + "<br/>";
				}
			}

			if (includePhone && store.Address.Phone) {
				sb += store.Address.Phone + "<br/>";
			}

			return sb;
		}
	}
	IsStoresExist() {
		if (this.stores && this.stores.Stores && this.stores.Stores.length > 0) {
			let srorelist = this.stores.Stores.filter(x => x.Active);
			if (srorelist && srorelist.length > 0) {
				return true;
			}
		}
		return false;
	}

}
