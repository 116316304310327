/* tslint:disable:member-ordering */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appAmazonpayLogout]'
})
export class AmazonpayLogoutDirective {

    el;
    constructor(el: ElementRef) {
        this.el = el;
    }

    @HostListener("click", ['$event'])
    onClick(event) {
        window.amazon['Login'].logout();
        window.location.href = "/checkout/shoppingbasket";
    }
}