import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SharedService } from './shared/shared.service';
import { LpAppSetting } from './model/lpapp-setting';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { ScrollService } from './shared/service/scroll.service';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { FacetFilter } from './shared/state/facet-filter/facet-filter.action';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	LpAppSetting: LpAppSetting;

	constructor(
		private sharedService: SharedService,
		private router: Router,
		viewportScroller: ViewportScroller,
		private scrollService: ScrollService,
		private attraqtService: AttraqtService,
		private store: Store
	) {
		router.events
			.pipe(filter((e): e is Scroll => e instanceof Scroll))
			.subscribe((e) => {
				if (e.position) {
					// backward navigation
					if (e.position && e.position.length > 0) {
						this.scrollService.setScrollYPosition(e.position[1]);
					}
				} else if (e.anchor) {
					// anchor navigation
					if (e.anchor && e.anchor.length > 0) {
						this.scrollService.setScrollYPosition(e.anchor[1]);
					}
				} else {
					// forward navigation
					this.scrollService.setScrollYPosition(0);
				}
			});
	}

	ngOnInit() {
		this.getLpAppSetting();
	}

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngAfterViewInit() {}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings()
		this.ngAfterViewInit();
		this.initAttraqtOnAppSettings();
	}

	private initAttraqtOnAppSettings(): void {
		if (
			this.LpAppSetting?.Attraqt?.TrackerKey &&
			this.LpAppSetting?.Attraqt?.IsAttraqtPLPEnabled
		) {
			this.store.dispatch(new FacetFilter.SetAttraqtPLPEnabled(true));
			this.attraqtService.initXO(this.LpAppSetting.Attraqt.TrackerKey);
		} else {
			this.store.dispatch(new FacetFilter.SetAttraqtPLPEnabled(false));
		}
	}
}
