import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SiteContentDetail } from '../../models/siteContent';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	siteContent: SiteContentDetail;

	constructor(
		@Inject(DOCUMENT)
		private document,
		private r: Renderer2,
		private siteContentService: SiteContentService) {

		this.r.removeClass(document.body, 'pt-productlistingpage');
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
	}
}
