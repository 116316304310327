import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';
import { PrimeAISettings } from '../../../../assets/shared/helper_scripts.js';
import { BundleProductItem, ProductDetails } from '../models/product-detail';

@Component({
	selector: '[productwithbundles],productwithbundles',
	templateUrl: './productwithbundles.component.html',
	styleUrls: ['./productwithbundles.component.scss']
})
export class ProductwithbundlesComponent implements OnInit, AfterViewInit {

	@Input() get productdetail(): ProductDetails {
		return this._productdetail;
	}

	private _productdetail: ProductDetails;

	set productdetail(value: ProductDetails) {
		this._productdetail = value;
	}

	@Output() reviewEvent = new EventEmitter();

	LpAppSetting: LpAppSetting;
	bundleProduct: ProductDetails;
	isPrimeAiScrict: boolean;

	constructor(private sharedService: SharedService) {
	}

	ngOnInit() {
		this.getLpAppSetting();
	}

	ngAfterViewInit() {
		if (!this.isPrimeAiScrict && this.LpAppSetting && this.LpAppSetting.PrimeAI_Enable && this.productdetail && this.productdetail.BundleProductItems) {
			this.isPrimeAiScrict = true;
			PrimeAISettings(this.productdetail.BundleProductItems, null);
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.ngAfterViewInit();
	}

	revieEventClicked(event) {
		this.reviewEvent.emit(false);
	}

	getBundleProduct(bundleItem) {
		if (this.productdetail) {
			this.bundleProduct = {} as ProductDetails;

			if (this.productdetail.BundleProductItems) {
				this.bundleProduct.BundleProductItems = [] as BundleProductItem[];
				this.bundleProduct.BundleProductItems = this.productdetail.BundleProductItems;
			}

			this.bundleProduct.Product = bundleItem;
			this.bundleProduct.IsBundledItem = true;

			return this.bundleProduct;
		}
	}

	getImageUrl(Images, key) {
		try {
			if (Images && Images.length > 0 && Images[0].ImageSizes && key) {
				for (var i = 0; i < Images[0].ImageSizes.length; i++) {
					if (Images[0].ImageSizes[i].Key == key) {
						return Images[0].ImageSizes[i].CdnFullUrl;
					}
				}
			}
		} catch (ex) {

		}
	}
}
