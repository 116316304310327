import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Directive, ElementRef, HostListener, Inject, Input, NgZone, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Directive({
	selector: '[appAccordianV1]'
})
export class AccordianV1Directive implements AfterViewInit {
	el;
	innerWidth = 0;
	defaultIndex = 0;
	oneTimeScroll = true;

	accordian = {
		items: [],
		onlyOneOpen: true
	};
	accordianItems = [];

	constructor(
		el: ElementRef,
		private renderer2: Renderer2,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: any) {

		this.el = el;

		if (isPlatformBrowser(this.platformId)) {
			this.innerWidth = innerWidth;
		}		
	}

	ngAfterViewInit() {

		if (isPlatformBrowser(this.platformId)) {

			let se = this;

			document.onkeydown = function (e) {
				if (window.innerWidth > 750 && se.router.url == "/heritage") { }

				switch (e.keyCode) {
					case 38:
						se.move(false)
						break;
					case 40:
						se.move(true)
						break;
				}
			};

			this.accordianItems = [];
			this.el = $('.heritage-parent').children();

			$('.heritage-parent').addClass('onepage-wrapper');
			$('.heritage-parent').css('position', "relative");

			let self = this;
			let topValue = 0;
			let tVal = 0;

			this.el.each(function (index, value) {
				let indexValue = parseInt(index) + 1;
				let id = 'section' + indexValue;

				$(value).attr('id', id);

				let data = {
					index: index,
					value: value
				}

				tVal = topValue;
				$(value).css('position', "absolute");
				$(value).css('left', '0px')
				$(value).css('top', tVal + '%');
				topValue += 100;

				self.accordianItems.push(data);
			});

			if (self.accordianItems.length) {
				self.addClass('active', self.accordianItems[this.defaultIndex].value);
				$('.heritage-menu')[this.defaultIndex].querySelector('a').classList.add('active');
			}

			window.addEventListener("wheel", event => {

				if (this.innerWidth > 750 && this.router.url == "/heritage") {
					let isDown = event.deltaY > 0 ? true : false;

					if (this.oneTimeScroll) {
						this.oneTimeScroll = false;
						this.move(isDown);
					}

					let self = this;

					setTimeout(function () {
						self.setOneScroll();
					}, 2000)
				}
			});
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {

		if (this.router.url == "/heritage") {
			this.innerWidth = innerWidth;
			this.defaultIndex = 0;
			this.updateParentPosition(this.defaultIndex);
			this.removeActiveClassForImage();
			this.addActiveClass(this.defaultIndex);
			this.removeActiveLinks();
		}
	}

	@HostListener('click', ['$event'])
	onClick(event) {

		let indexVal;

		event.preventDefault();

		if (event.target.tagName == "A" && event.target && event.target.dataset && !event.target.dataset.scrollto) {

			this.removeActiveClassFromLinks(event);
			let index = event.target.hash.substr(1);
			indexVal = parseInt(index) - 1;
			this.defaultIndex = indexVal;
			this.updateParentPosition(indexVal);
			this.removeActiveClassForImage();
			this.addActiveClass(indexVal);

		} else if ((event.target.tagName == "A" || event.target.tagName == "DIV") && event.target && event.target.dataset && event.target.dataset.scrollto) {

			let scrollTarget = event.target.dataset.scrollto;
			let container = $("html,body");
			let scrollMove = $(scrollTarget);

			container.animate({
				scrollTop: scrollMove.offset().top - container.offset().top +
					container.scrollTop(), scrollLeft: 0
			}, 1000);
		}
	}

	addClass(className: string, element: any) {
		this.renderer2.addClass(element, className);
	}

	removeClass(className: string, element: any) {
		this.renderer2.removeClass(element, className);
	}

	setOneScroll() {
		this.oneTimeScroll = true;
	}

	updateParentPosition(ind) {
		let val = -(ind * 100);

		$('.heritage-parent').css('position', "relative");
		$('.heritage-parent').css('transform', 'translate3d(0px, ' + val + '%, 0px)');
		$('.heritage-parent').css('transition', "all 1000ms ease 0s");
	}

	removeActiveClassForImage() {
		this.accordianItems.filter(item => {
			this.removeClass('active', item.value);
		});
	}

	addActiveClass(indexValue) {
		this.accordianItems.filter((item, ind) => {
			$(item.value).css('position', "absolute");

			if (indexValue == ind) {
				this.addClass('active', item.value);
				$(item.value).css('position', "absolute");

				return
			}
		})
	}

	addActiveClassForLinks(event) {
		event.target.classList.add('active');

		var url = event.target.href;

		if (!url.includes('#')) {
			window.location.href = url;
		}
	}

	removeActiveClassFromLinks(event) {
		if (event
			&& event.target && event.target.parentElement
			&& event.target.parentElement.parentElement
			&& event.target.parentElement.parentElement.children
			&& event.target.parentElement.parentElement.children.length) {

			let data = event.target.parentElement.parentElement.children;

			for (let i = 0; i < data.length; i++) {
				if (data[i] != null && data[i].querySelector('a') != null && data[i].querySelector('a').classList != null)
					data[i].querySelector('a').classList.remove('active');
			}

			this.addActiveClassForLinks(event)
		}
	}

	move(isDown) {
		let ind = 0;

		if (this.defaultIndex == 0 && isDown) {
			ind = this.defaultIndex + 1;
		}

		if (this.defaultIndex && isDown) {
			ind = this.defaultIndex + 1;
		}

		if (this.defaultIndex && !isDown) {
			ind = this.defaultIndex - 1;
		}

		if (this.defaultIndex <= this.accordianItems.length && this.defaultIndex != 0 && !isDown) {
			ind = this.defaultIndex - 1;
		}

		if (ind >= this.accordianItems.length) {
			return;
		}

		this.defaultIndex = ind;
		this.updateParentPosition(ind);
		this.removeActiveClassForImage();
		this.addActiveClass(ind);
		this.removeActiveLinks();
	}

	removeActiveLinks() {
		let data = $('.heritage-menu')[0].querySelectorAll('a');

		for (let i = 0; i < data.length; i++) {
			data[i].classList.remove('active');
		}

		if (this.accordianItems.length - 1 > this.defaultIndex) {
			data[this.defaultIndex].classList.add('active');
		}
	}
}
