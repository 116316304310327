import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { Label } from '../models/Labels';
//import { Label } from './models/Labels';
const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root'
})
export class LabelService {

	labels: Label;

	private labels$: any;

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	private requestLables() {
		return this.httpClient.get<Label>(this.environmentService.environment.siteUrl + 'getlabel', this.httpOptions).pipe(
			map(response => { this.labels = response; return response; })
		);
	}

	getLabels(): Observable<Label> {

		this.labels$ = this.requestLables().pipe(
			shareReplay(CACHE_SIZE)
		);
		return this.labels$;
	}

	setLabel() {
		this.getLabels().subscribe(data => {
			this.labels = data;
		})
	}

	getLabelData(): Label {
		return this.labels;
	}
}
