import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, DoCheck, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { MenuItem } from 'src/app/shared/models/menuItem';
import { SharedService } from 'src/app/shared/shared.service';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';

@Component({
	selector: 'header-menu-sublist,[header-menu-sublist]',
	templateUrl: './header-menu-sublist.component.html',
	styleUrls: ['./header-menu-sublist.component.scss']
})
export class HeaderMenuSublistComponent implements DoCheck, OnInit, OnDestroy {
	@Input() menuItem: MenuItem;
	@Output() onDatePicked = new EventEmitter<any>();

	value = '';
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private router: Router,
		private sharedService: SharedService,
		private defaultService: DefaultService,
		@Inject(DOCUMENT) private document,
		@Inject(PLATFORM_ID) private platformId: any,
		private attraqtService: AttraqtService) {
	}

	ngOnInit() {
		this.subscribeToState();
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	ngDoCheck() {
		if (isPlatformBrowser(this.platformId)) {
			if ($(this.value).hasClass("hideHover")) {
				setTimeout(() => {
					$(this.value).removeClass("hideHover");
				}, 3000)
			}
		}
	}

	@HostListener('click', ['$event'])
	onClick(event) {

		event.preventDefault();
		event.stopPropagation();

		if (event.target.tagName === 'A') {
			let url = event.target.getAttribute('href');
			if (url === "#") {
				return;
			}

			let previousUrl = this.router.url;
			let previousMainUrl = previousUrl;

			if (previousUrl.includes("?")) {
				previousMainUrl = previousUrl.substring(0, previousUrl.indexOf("?"));
			}

			let currentUrl = url;
			let currentMainUrl = url;

			if (currentUrl.includes("?")) {
				currentMainUrl = currentUrl.substring(0, currentUrl.indexOf("?"));
			}

			if (previousMainUrl === currentMainUrl) {
				this.defaultService.bannerUrlEvent(currentUrl);
				return;
			}

			if (url && (url.startsWith('https://') || url.startsWith('http://'))) {
				window.location.href = url;
			} else {
				if (this.facetFilterState.attraqtPLPEnabled) {
					const { protocol, host } = window.location
					this.attraqtService.sendActivity(
						new AttraqtActivityBuilder(AttraqtActivityActionEnum.View)
						  .setTarget({ pathToPage: `${protocol}//${host}${url}` })
						  .setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) || 'en_GB' })
						  .build()
					);
				}
				this.router.navigate([url]);
			}

		} else {
			return;
		}
	}

	scrollTop() {
		if (isPlatformBrowser(this.platformId)) {
			this.document.documentElement.scrollTop = 0;
		}
	}

	navigateMenuUrl(url) {
		this.router.navigateByUrl('url', { skipLocationChange: true });
	}

	startWithHttps(url) {
		return url.startsWith("https:/");
	}

	setCurrentUrl(menuItemUrl) {
		localStorage.setItem('currentUrl', menuItemUrl);
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}

	navigateByUrl(url: string) {
		if (url.startsWith('/')) {
			this.router.navigateByUrl(url);
		} else {
			window.location.href = url;
		}
	}
}
