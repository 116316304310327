import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Basket } from 'src/app/model/basketpage-viewmodel';
import { CountryVM } from 'src/app/model/country-viewmodel';
import { UpdateBasketShipping } from 'src/app/shared/models/shippingoptions';
import { EnvironmentService } from '../../shared/environment.service';
import { ResultVM } from '../auth/models/result-vm';

@Injectable({
	providedIn: 'root'
})
export class HomeService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	getAllCountries(all: boolean = false): Observable<CountryVM> {
		return this.httpClient.get<CountryVM>(this.environmentService.environment.accountUrl + 'GetLocalizedCountries?all=' + all, this.httpOptions);
	}

	getPostcodeDetails(data) {
		return this.httpClient.get<any>(this.environmentService.environment.checkoutUrl + 'CollectFromStore?postcode=' + data.postcode, this.httpOptions);
	}

	saveClickAndCollectAddress(data): Observable<ResultVM> {
		return this.httpClient.post<ResultVM>(this.environmentService.environment.checkoutUrl + 'CollectFromStoreSave', data, this.httpOptions);
	}

	getPostCode() {
		return this.httpClient.get<any>('https://services.postcodeanywhere.co.uk/CapturePlus/Interactive/Find/v2.10/json3ex.ws?Key=YX74-RJ29-KW49-ZW88&Country=GBR&SearchTerm=s&LanguagePreference=en&LastId=&SearchFor=Everything&OrderBy=UserLocation&$block=true&$cache=true', this.httpOptions)
	}

	updateBasketShippingOption(updateBasketShipping: UpdateBasketShipping): Observable<Basket> {
		return this.httpClient.post<Basket>(this.environmentService.environment.basketUrl + 'UpdateBasketShippingOption', updateBasketShipping, this.httpOptions);
	}
}
