import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AuthService } from '../../auth.service';
import { List } from '../../models/account';

@Component({
	selector: 'app-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
	@Input() refreshTab;
	@Input() addressBooksList;
	@Output() changeTabToAddressBook = new EventEmitter<string>();
	@Input() resetPersonalDetailsCompnenet: Subject<boolean> = new Subject<boolean>();
	@Input() userInfo;
	billingAddressDefault;
	shippingAddressDefault;
	showSuccessMessage: boolean = false;
	showSuccessMessageForChangePassword: boolean = false;
	sitecontent: SiteContentDetail;
	titleList: List[];
	userDetails = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		sessionId: ''
	}

	showMainData: boolean = true;
	showEditPage: boolean = false;
	showChangePassData: boolean = false;
	passwordMatch: boolean = false;
	personalDetailsEditForm: UntypedFormGroup;
	changePasswordForm: UntypedFormGroup;
	changePasswordStatus: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private route: Router,
		private authService: AuthService,
		private sharedService: SharedService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) { }

	get changePassowrdFormControls() { return this.changePasswordForm.controls; }
	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.getTitles();
		if (!this.addressBooksList || (this.addressBooksList && this.addressBooksList.length > 0)) {
			this.billingAddressDefault = [];
			this.shippingAddressDefault = [];
		}
		this.initializeEditForm();
		this.initializeChangePasswordForm();
		this.resetPersonalDetailsCompnenet.subscribe(response => {
			if (response) {
				this.showMainData = true;
				this.showChangePassData = false;
				this.showEditPage = false;
				this.showSuccessMessage = false;
				this.showSuccessMessageForChangePassword = false;
			}
		});
	}
	ngOnChanges() {
		if (this.addressBooksList && this.addressBooksList.length > 0) {

			this.billingAddressDefault = this.addressBooksList.filter(i => i.IsDefaultBilling);
			this.shippingAddressDefault = this.addressBooksList.filter(i => i.IsDefaultShipping);
		}
	}
	pwdMatchValidator() {
		let password = this.changePassowrdFormControls.PasswordNew;
		let PasswordConfirm = this.changePassowrdFormControls.PasswordConfirm;

		if (!password.errors && !PasswordConfirm.errors) {
			if (password.value != PasswordConfirm.value) {
				this.passwordMatch = false;
			} else {
				this.passwordMatch = true;
			}
		} else {
			this.passwordMatch = true;
		}
	}
	initializeEditForm() {


		if (this.userInfo) {
			this.personalDetailsEditForm = this.fb.group({
				TitleId: [this.userInfo.titleId, [Validators.required]],
				FirstName: [this.userInfo.firstName, [Validators.required]],
				LastName: [this.userInfo.lastName, [Validators.required]],
				EmailAddress: [this.userInfo.email, [Validators.required, Validators.email]],

			});
		}

	}
	initializeChangePasswordForm() {
		this.changePasswordForm = this.fb.group({
			PasswordOld: ['', [Validators.required, Validators.minLength(5)]],
			PasswordNew: ['', [Validators.required, Validators.minLength(5)]],
			PasswordConfirm: ['', [Validators.required, Validators.minLength(5)]],
		});
	}

	editpersonalDetails() {
		this.showMainData = false;
		this.showChangePassData = false;
		this.showEditPage = true;
		this.showSuccessMessage = false;
	}
	cancelPage() {
		this.showMainData = true;
		this.showChangePassData = false;
		this.showEditPage = false;
		this.showSuccessMessageForChangePassword = false;
	}
	showChangePassword() {
		this.showMainData = false;
		this.showChangePassData = true;
		this.showEditPage = false;
		this.changePasswordForm.reset();
	}

	editAddress() {
		this.route.navigate(['/account/addressbook'])
	}

	savePersonalEditedDetails() {

		if (this.personalDetailsEditForm.status == "INVALID") {
			return;
		}
		this.authService.updatePersonalDetails(this.personalDetailsEditForm.value).subscribe(res => {
			if (res.SessionId) {

				this.scrollService.windowScrollTo(0, 0);
				this.userInfo.firstName = res.FirstName;
				this.userInfo.lastName = res.LastName;
				this.userInfo.email = res.EmailAddress;
				this.userInfo.titleId = res.TitleId;

				localStorage.setItem('userData', JSON.stringify(this.userInfo))
				this.showSuccessMessage = true;
			}


		})

	}
	removeErrorMessage() {
		this.changePasswordStatus = false;
	}
	changePasswordFormSubmit() {
		this.changePasswordStatus = false;
		if (this.changePasswordForm.status == "INVALID") {
			return;
		}
		this.authService.updatePassword(this.changePasswordForm.value).subscribe(res => {

			if (res && res.IsSaved) {
				this.showSuccessMessageForChangePassword = true;
				this.showChangePassData = false;
			} else {
				this.changePasswordStatus = true;
				this.changePasswordForm.reset();
			}
		}), err => {

		}

	}
	getTitles() {
		this.authService.getTitles().subscribe(res => {
			if (res && res.Titles && res.Titles.List) {
				this.titleList = res.Titles.List;
			}
		})
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}
	getGeneratedCompareMessage(toReplace1, toReplace2, text) {
		return text.replace('{0}', toReplace1).replace('{1}', toReplace2);
	}
}
