import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AuthService } from '../../auth.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	changePasswordForm: UntypedFormGroup;
	changePasswordStatus: boolean = false;
	showSuccessMessageForChangePassword: boolean = false;
	showChangePassData: boolean = false;
	passwordNew: string;
	passwordNewConfirmation: string;
	sitecontent: SiteContentDetail;
	customerId: any;
	passwordMatch: boolean = false;
	validGuid: boolean = false


	get changePassowrdFormControls() { return this.changePasswordForm.controls; }

	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,
		private authService: AuthService, private sharedService: SharedService,
		private siteContentService: SiteContentService) { }

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.initializeChangePasswordForm();

		if (this.route.snapshot.queryParams['id']) {
			this.customerId = this.route.snapshot.queryParams['id'];
		}

		this.resetPwd();

	}

	resetPwd() {
		this.authService.resetPassword(this.customerId).subscribe(res => {
			this.validGuid = res.ValidGuid;
		}), err => { }
	}

	initializeChangePasswordForm() {
		this.changePasswordForm = this.fb.group({
			newPassword: ['', [Validators.required, Validators.minLength(5)]],
			confirmPassword: ['', [Validators.required, Validators.minLength(5)]],
			id: ['']
		});
	}

	resetPasswordFormSubmit() {
		this.changePasswordStatus = false;
		if (this.changePasswordForm.status == "INVALID") {
			return;
		}
		this.changePasswordForm.controls['id'].setValue(this.customerId);
		this.authService.resetUpdatePassword(this.changePasswordForm.value).subscribe(res => {

			if (res.ChangedPassword) {
				this.showSuccessMessageForChangePassword = true;
				this.showChangePassData = false;
			} else {
				this.changePasswordStatus = true;
				this.changePasswordForm.reset();
			}
		}), err => { }

	}

	pwdMatchValidator() {
		let password = this.changePassowrdFormControls.newPassword;
		let confirmPassword = this.changePassowrdFormControls.confirmPassword;

		if (!password.errors && !confirmPassword.errors) {
			if (password.value != confirmPassword.value) {
				this.passwordMatch = false;
			} else {
				this.passwordMatch = true;
			}
		} else {
			this.passwordMatch = true;
		}
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}
	getGeneratedCompareMessage(toReplace1, toReplace2, text) {
		return text.replace('{0}', toReplace1).replace('{1}', toReplace2);
	}
}
