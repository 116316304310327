export enum FormFieldTypeEnum {
	None = 0,
	Text = 1,
	Textarea = 2,
	Checkbox = 3,
	Dropdown = 4,
	Radio = 5,
	Email = 6,
	Recaptcha = 7,
	Date = 8
}

export enum ControlTypeEnum {
	Other = 0,
	Label = 1,
	DropDownList = 2,
	Image = 3,
	HyperLink = 4,
	BackgroundImage = 5,
	Button = 6,
	Action = 7,
	Submit = 8,
	RadioButton = 9,
	TitleContent = 10,
	Banner = 11,
	TextBanner = 12,
	StaticPage = 13,
	SimpleImage = 14,
	SkuList = 15,
	Fit = 16,
	TitleContentLink = 17,
	PeeriusWidget = 18,
	Html = 19,
	Script = 20,
	ProductList = 21,
	CarouselItem = 22,
	Form = 23,
	Video = 24,
	AttributeSelector = 25,
	SubPage = 26,
	Menu = 27,
	PinterestGallery = 28,
	InstagramGallery = 29,
	Gallery = 30,
	Countdown = 31,
	AccordianItem = 32,
	ProductListCarousel = 33,
	FitChart = 34,
	GalleryPopup = 35,
	FitContent = 36,
	PromoStripBanner = 37,
}
