import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoVM } from 'src/app/model/video-viewmodel';
import { DefaultService } from '../../layout/default.service';
import VimeoPlayer from '@vimeo/player';

@Component({
	selector: '[app-video],app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
	private player: VimeoPlayer;
	iconName:string = 'fa fa-volume-off';

	@Input() videoVM: VideoVM;
	showControlOnHover: boolean = false;
	constructor(private sanitizer: DomSanitizer, private defaultService: DefaultService,private elementRef: ElementRef) {
	}

	ngOnInit() {
		
	}
	ngAfterViewInit(){
		this.player = new VimeoPlayer(this.elementRef.nativeElement.querySelector('#vimeoPlayer'));
		this.player.play();
		this.player.setVolume(0);
	}

	toggleMute(): void {
		this.player.getVolume().then((volume: number) => {
			if (volume > 0) {
				this.iconName = 'fa fa-volume-off';
				this.player.setVolume(0); // Mute
				this.player.play();
			} else {
				this.iconName = 'fa fa-volume-up';
				this.player.setVolume(1); // Unmute
				this.player.play();
			}
		}).catch((error: any) => {
			console.error('Error toggling mute state:', error);
		});

	}
	
}
