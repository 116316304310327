import { Component, Input, OnInit } from '@angular/core';
import { CountdownVM } from 'src/app/model/carousel-control';
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {
@Input() countdownVM:CountdownVM;
countdownActive:boolean=false;
startDate:Date;
target:Date;
counter:any;
  constructor() { }

  ngOnInit() {
    if(this.countdownVM){
      this.initialise(this.countdownVM.StartTime, this.countdownVM.EndTime);
    }
  }
   initialise(startTime, endTime) {
    this.startDate = new Date(startTime.replace(/-/g, "/"));
    this.target = new Date(endTime.replace(/-/g, "/"));

    if ( this.startDate <= new Date() && this.target >= new Date()) {
        this.counter = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

      //  this.updateRemaining();
       // setInterval(this.updateRemaining, 1000, 0);
        this.countdownActive = true;
    }
}

// updateRemaining() {
//   let datenow=new Date();
//     let secondsLeft = (this.target. - datenow) / 1000;

//     if (secondsLeft < 0) {
//         this.countdownActive = false;
//         return;
//     }

//     var minute = 60;
//     var hour = minute * 60;
//     var day = hour * 24;

//     this.counter.days = Math.floor(secondsLeft / day);
//     this.counter.hours = Math.floor((secondsLeft % day) / hour);
//     this.counter.minutes = Math.floor((secondsLeft % hour) / minute);
//     this.counter.seconds = Math.floor(secondsLeft % minute);
// }
}
