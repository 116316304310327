import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-cookie-law',
  templateUrl: './global-cookie-law.component.html',
  styleUrls: ['./global-cookie-law.component.scss']
})
export class GlobalCookieLawComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
