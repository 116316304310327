import { Component, Input, OnInit } from '@angular/core';
import { SiteContentDetail } from '../../models/siteContent';

@Component({
  selector: 'contact-us,[contact-us]',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
@Input() sitecontent: SiteContentDetail;
@Input() WebStoreId: number;
  constructor() { }

  ngOnInit() {
  }

}
