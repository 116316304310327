import { AfterViewInit, Component, Input } from '@angular/core';
import { AccordianItemVM } from 'src/app/model/web-page-viewmodel';
import {
	ScriptLocation,
	ScriptService,
} from 'src/app/shared/service/script.service';

@Component({
	selector: 'accordian-item,[accordian-item]',
	templateUrl: './accordian-item.component.html',
	styleUrls: ['./accordian-item.component.scss'],
})
export class AccordianItemComponent implements AfterViewInit {
	@Input() accordianItemVM: AccordianItemVM;
	isOpen = false;

	constructor(private scriptService: ScriptService) {}

	toggleNavbar() {
		this.isOpen = !this.isOpen;
	}

	ngAfterViewInit() {
		this.scriptService
			.load(ScriptLocation.Head, "accordian-item")
			.then((data) => {
				console.debug("script loaded ", data);
			})
			.catch((error) => console.debug(error));
	}
}
