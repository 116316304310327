import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StyleGuideVM } from 'src/app/model/styleguide-viewmodel';
import { FitguidePopupComponent } from '../fitguide-popup/fitguide-popup.component';
import { VideomodalComponent } from '../videomodal/videomodal.component';

@Component({
  selector: '[style-guide],style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent implements OnInit {
  @Input() styleGuideVM: StyleGuideVM;
  matDialogRef: MatDialogRef<VideomodalComponent>;
  fitguidematDialogRef: MatDialogRef<FitguidePopupComponent>;
  modelFit: boolean = false;
  fitId: number = 1;
  page: number = 1;
  constructor(private matDialog: MatDialog) {

  }

  ngOnInit() {

  }
  getCarousel(carousel) {

    let slideconfig = JSON.parse(carousel);
    if (slideconfig) {
      slideconfig.slidesToShow = 1;

      if (slideconfig.arrows) {

        slideconfig.nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.rightArrowText + "</span></button>";
        slideconfig.prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.leftArrowText + "</span></button>";
        slideconfig.asNavFor = ".fit-nav1";
      }

    }
    return slideconfig;
  }
  IsPageEqual(index) {
    if (index === 0) {
      this.page = 1;
    }
    return true;
  }
  getCarouselText(carousel) {

    let slideconfig = JSON.parse(carousel);
    if (slideconfig) {
      if (slideconfig.arrows) {

        slideconfig.nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.rightArrowText + "</span></button>";
        slideconfig.prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + slideconfig.leftArrowText + "</span></button>";
      }
      slideconfig.asNavFor = ".fit-frame1";
    }
    return slideconfig;
  }
  isContainVideo(source) {

    if (source) {
      if (source.startsWith("https://player.vimeo.com") || source.indexOf('.blob.core.windows.net/videos') !== -1) {
        return true;

      }
    }
    return false;

  }
  videomodal(source) {
    let videosource = source;
    this.matDialogRef = this.matDialog.open(VideomodalComponent, {
      data: { videosource: source },
      disableClose: true
    });
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        videosource = undefined;
      }
    });
  }
  fitguidemodal(texts: any, images: any, content: any, index: number) {
    let fitguide = { id: "fitGuide", texts: texts, images: images, content: content, index: index };
    this.fitguidematDialogRef = this.matDialog.open(FitguidePopupComponent, {
      data: { fitguide: fitguide },
      disableClose: true
    });
    this.fitguidematDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        fitguide = undefined;
      }
    });
  }
}
