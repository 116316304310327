import { AttraqtActivityActionEnum } from '../../enums/attraqt-activity-action.enum';
import { IAttraqtActivityMetadata } from '../../interfaces/attraqt/activity-metadata.interface';
import { IAttraqtActivityTarget } from '../../interfaces/attraqt/activity-target.interface';
import { IAttraqtActivity } from '../../interfaces/attraqt/activity.interface';
import { IBuilder } from '../../interfaces/builder.interface';

export class AttraqtActivityBuilder implements IBuilder {
  private activity: IAttraqtActivity;

  constructor(action: AttraqtActivityActionEnum) {
    this.activity = { action };
  }

  setTarget(target: IAttraqtActivityTarget): AttraqtActivityBuilder {
    this.activity.target = target;
    return this;
  }

  setUser(): AttraqtActivityBuilder {
    // this.activity.user = user;
    return this;
  }

  setSourceId(sourceId: string): AttraqtActivityBuilder {
    this.activity.sourceId = sourceId;
    return this;
  }

  setMetadata(metadata: IAttraqtActivityMetadata): AttraqtActivityBuilder {
    this.activity.metadata = metadata;
    return this;
  }

  setSegments(segments: Array<string>): AttraqtActivityBuilder {
    this.activity.segments = segments;
    return this;
  }

  setTimestamp(timestamp: string): AttraqtActivityBuilder {
    this.activity.timestamp = timestamp;
    return this;
  }

  build<IAttraqtActivity>(): IAttraqtActivity {
    return this.activity as unknown as IAttraqtActivity;
  }
}
