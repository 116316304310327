import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { from, map, Observable, of } from 'rxjs';
import { LpAppSetting } from "../model/lpapp-setting";
import { SharedService } from "./shared.service";
import { CookieService } from 'ngx-cookie';

@Injectable({
	providedIn: 'root'
})
export class CheckoutSwitcherService {

	private LpAppSetting: LpAppSetting;
	private readonly internationalCheckoutUrl: string = "/checkout/international";
	private readonly internalCheckoutUrl: string = "/checkout/delivery";
	private readonly globaleCookie: string = "GlobalE_Data";

	constructor(
		private router: Router,
		private cookieService: CookieService,
		private sharedService: SharedService) {

		this.getLpAppSetting();
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	isGlobalEEnabled(): Observable<boolean> {
		if (!this.LpAppSetting) {
			return from(this.getLpAppSetting()).pipe(
				map(() => this.LpAppSetting.GlobaleSettings.Enabled)
			);
		}
		return of(this.LpAppSetting.GlobaleSettings.Enabled);
	}

	isInternationalCheckout() {
		if (this.router.url.startsWith(this.internationalCheckoutUrl)) {
			return true;
		} else {
			return false;
		}
	}

	isOperatedByGlobalE() {
		// If Global-E confirms with us that they are enabled on this webstore via currency and shipping
		var globaleCookieValue = this.cookieService.get(this.globaleCookie);

		if (globaleCookieValue) {
			var globaleData = JSON.parse(globaleCookieValue);

			if (globaleData && globaleData.isOperatedByGlobalE) {
				return true;
			}
		}

		return false;
	}

	gotoCheckoutPage(replaceUrl) {
		this.router.navigate([this.getCheckoutUrl()]);

		//if (replaceUrl) {
		//	this.router.navigate([this.getCheckoutUrl()]);
		//} else {
		//	this.router.navigate([this.getCheckoutUrl()], { replaceUrl: replaceUrl });
		//}
	}

	getCheckoutUrl() {
		// If Global-E is enabled on this webstore then goto that checkout instead of our own.
		if (this.isOperatedByGlobalE()) {
			return this.internationalCheckoutUrl;
		} else {
			return this.internalCheckoutUrl;
		}
	}
}
