import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Carousel2 } from 'src/app/model/carousel-viemmodel';
import { slickSliderRefresh } from '../../../../assets/shared/helper_scripts.js';

@Component({
	selector: 'carousel-two,[carousel-two]',
	templateUrl: './carousel2.component.html',
	styleUrls: ['./carousel2.component.scss']
})
export class Carousel2Component implements OnInit {
	@Input() carouselModel: Carousel2;

	casUrl: string;
	casBtn: boolean = true;

	slideConfigText: any = {};
	slideConfigImage: any = {};

	parUrl = '';
	isShopBtn: boolean = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	ngOnInit() {

		this.activatedRoute.params
			.subscribe(
				(params: Params) => {
					this.parUrl = params.url
				}
			);

		this.slideConfigText = JSON.parse(this.carouselModel.LinkedCarouselOptions);
		this.slideConfigImage = JSON.parse(this.carouselModel.Carousel);

		if (this.slideConfigImage && this.slideConfigImage.arrows) {
			this.slideConfigImage.nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + this.slideConfigImage.leftArrowText + "</span></button>";
			this.slideConfigImage.prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span>" + this.slideConfigImage.rightArrowText + "</span></button>";
		}

		if (this.slideConfigText && this.slideConfigText.showDots) {
			this.slideConfigText.dots = true;
		}

		this.setupOptions(this.slideConfigText);
		this.setupOptions(this.slideConfigImage);
	}

	ngAfterViewInit() {
		// Since we are running helper script which includes jQuery, run this only on Browser Side
		if (isPlatformBrowser(this.platformId)) {
			slickSliderRefresh();
		}
	}

	@HostListener("click", ['$event'])
	onClick(event) {
		let target1;

		if (event.target.tagName == 'A' && event.target.pathname) {
			target1 = event.target.pathname;
		} else {
			return;
		}

		event.preventDefault();

		this.router.navigate([target1]);
	}

	routeShpBtn() {
		if (this.parUrl === 'menswear/accessories') {
			this.isShopBtn = true;
			this.casBtn = false;
		}

		if (this.parUrl === 'weekend_collection') {
			this.casBtn = true;
			this.isShopBtn = false;
		}
	}

	setupOptions(options) {

		if (options.autoplaySpeed == null || options.autoplaySpeed === "") {
			options.autoplaySpeed = "7000";
		}

		if (options.slidesToShow == null || options.slidesToShow === "") {
			options.slidesToShow = 1;
		}

		if (options.autoplaySpeed == "0") {
			options.autoplay = false;
		}

		if (options.autoplay == null) {
			options.autoplay = true;
		}

		options.dots = options.showDots;

		if (options.slidesToShow != null) {

			if (options.filterBannerResponsive) {
				// If the carousel is part of the filter banners then 
				options.responsive =
					[
						{ breakpoint: 1100, settings: { slidesToShow: 3, slidesToScroll: 1, } },
						{ breakpoint: 840, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
					];
			}
			else {
				// Standard behavior
				switch (parseInt(options.slidesToShow)) {
					case 1:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { cssEase: "ease" } }
							];
						break;

					case 4:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 4, slidesToScroll: 1, } },
								{ breakpoint: 840, settings: { slidesToShow: 3, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;

					case 8:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 6, slidesToScroll: 1, } },
								{ breakpoint: 769, settings: { slidesToShow: 5, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 4, slidesToScroll: 4, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 3, slidesToScroll: 3, cssEase: "ease" } }
							];
						break;

					//This case is only for carousel-style-7 - Fit guide Page carousel with banners control, not fit control with carousel plus-icon 
					case 999:
						options.infinite = false;
						options.slidesToShow = 4;
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;

					//This case is for settings dynamics breakpoints and slideToShow. Example Gallery-Popup Control
					case 998:
						if (options.responsive != null && options.responsive.length > 0) {
							options.responsive = options.responsive;
						}
						break;
					default:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 3, slidesToScroll: 1 } },
								{ breakpoint: 769, settings: { slidesToShow: 2, slidesToScroll: 2, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;
				}
			}
		}
	}
}
