import { FacetedNavigationGroup } from '../../modules/product-list/model/product-list';

const buildFacetGroupsFromAttraqtFacets = (facets: Array<any>) => {
	const facetGroups: FacetedNavigationGroup[] = [];
	for (let i = 0; i < facets.length; ++i) {
		const { GroupId, Title, Items, Key, Selected: isGroupSelected } = facets[i];
		const facetGroupItems = [];
		if (Items.length) {
			for (let j = 0; j < Items.length; ++j) {
				const { Name, Value, UrlParams, Selected: isItemSelected } = Items[j];
				facetGroupItems.push({
					Id: `${i}${j}`,
					GroupId,
					Order: j,
					Name,
					ToggleName: Name,
					NumProducts: 0,
					IsSelected: isItemSelected,
					IsAvailable: true,
					IsVisible: true,
					IsSizeAttribute: false,
					CssClass: '',
					IsShow: false,
					Value,
					UrlParams,
					Selected: isItemSelected,
				});
			}
		}
		const facetGroupSelectedItems = facetGroupItems.filter(
			({ IsSelected }) => IsSelected
		);
		const facetGroup = {
			GroupId,
			Title,
			Order: i,
			Key,
			Label: Title,
			ToggleLabel: Title,
			Items: facetGroupItems,
			SelectedItems: facetGroupSelectedItems,
			HasSelectedItems: facetGroupSelectedItems.length > 0,
			VisibleIndex: 0,
			IsActive: false,
			IsVisible: true,
			IsSelected: isGroupSelected,
			CanToggle: false,
			SelectedFilter: facetGroupSelectedItems.length,
			Index: i,
			Selected: isGroupSelected,
		};
		facetGroups.push(facetGroup);
	}
	return facetGroups;
};

export default buildFacetGroupsFromAttraqtFacets;
