import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
import { FitGuide } from 'src/app/model/fitguide';
import { Splide } from '@splidejs/splide';

@Component({
	selector: 'fitguide-popup',
	templateUrl: './fitguide-popup.component.html',
	styleUrls: ['./fitguide-popup.component.scss']
})
export class FitguidePopupComponent implements OnInit {

	fitguide: FitGuide;
	images: string[];
	slideConfig: any;
	textAndContent: any[];
	slideTextConfig: any;


	private inited;

	@HostListener('window:click')
	onNoClick(): void {
		if (this.inited) {
			this._mdr.close();
		}
	}

	ngAfterViewInit() {
		$('.fit-guide-popup').on('click', function (event) {
			event.stopPropagation();
		});
		
		let imgSplide = new Splide('#splide-img', {
			perPage: 1,
			start: this.fitguide.index,
			pagination: false,
			arrows: true,
			speed: 0,
			autoplay: false,
			slideFocus: true,
			type: 'fade',
			rewind: true,
			perMove: 1,
			classes: {
				arrow: "slick-arrow",
				prev: "slick-prev",
				next: "slick-next",
			},
		}).mount();

		let textSplide = new Splide('#splide-text', {

			perPage: 1,
			start: this.fitguide.index,
			pagination: true,
			arrows: false,
			speed: 0,
			autoplay: false,
			slideFocus: true,
			type: 'fade',
			rewind: true,
			perMove: 1,
			classes: {
				pagination: 'slick-dots',
				page: ' ',
			},
		});

		textSplide.on('pagination:updated', function (data) {
			let j = 0;
			data.items.forEach(function (item) {
				if (item.button.classList.contains('is-active')) {
					data.list.children[j].classList.add('slick-active');
					item.button.classList.add('slick-active');
					item.button.classList.remove('is-active');
				}
				else {
					if (item.button.classList.contains('slick-active')) {
						item.button.classList.remove('slick-active');
						if (data.list.children[j].classList.contains('slick-active')) {
							data.list.children[j].classList.remove('slick-active');
						}
					}
				}
				j++;
			});
		});
		textSplide.mount();

		imgSplide.sync(textSplide).mount();
	}


	constructor(private _mdr: MatDialogRef<FitguidePopupComponent>,
		@Inject(MAT_DIALOG_DATA) data: any) {

		this.fitguide = data.fitguide;
		this.getImages();
		this.getTextAndContents();
	}
	getTextAndContents() {
		this.textAndContent = [];
		if (this.fitguide) {
			let content;
			let texts;
			if (this.fitguide.texts) {

				texts = this.fitguide.texts.split("|");

			}
			if (this.fitguide.texts) {
				content = this.fitguide.content.split("|");
			}
			if (texts && texts.length > 0) {
				let index = 0;
				texts.forEach(element => {
					this.textAndContent.push({ text: texts[index], content: content[index] });
					index++;
				});
			}
		}
	}
	getImages() {

		if (this.fitguide && this.fitguide.images) {
			this.images = this.fitguide.images.split("|");
		}
		if (this.fitguide) {
			this.slideConfig = {
				"slidesToShow": "1",
				"initialSlide": this.fitguide.index,
				"showDots": false,
				"arrows": true,
				"fade": false,
				"speed": 0,
				"autoplay": false,
				"focusOnSelect": true,
				"asNavFor": ".ca-text",
				"prevArrow": "<button  class='slick-prev slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>",
				"nextArrow": "<button class='slick-next slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block'><i></i><span></span></button>",
			}
			this.slideTextConfig = {
				"slidesToShow": "1",
				"initialSlide": this.fitguide.index,
				"showDots": true,
				"dots": true,
				"arrows": false,
				"fade": false,
				"speed": 0,
				"autoplay": false,
				"focusOnSelect": true,
				"asNavFor": ".ca-image",

			}
		}

	}
	ngOnInit() {
		this._mdr.afterOpened().subscribe(() => {
			this.inited = true;
		})
	}
	CloseDialog() {
		this._mdr.close(false);
	}

}
