import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
	selector: 'app-custom-size-confirmation',
	templateUrl: './custom-size-confirmation.component.html',
	styleUrls: ['./custom-size-confirmation.component.css']
})
export class CustomSizeConfirmationComponent implements OnInit {
	siteContent: SiteContentDetail;

	constructor(
		public dialogRef: MatDialogRef<CustomSizeConfirmationComponent>,
		private siteContentService: SiteContentService) { }

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
	}

	confirm() {
		this.dialogRef.close(true);
	}

	cancel() {
		this.dialogRef.close(false);
	}
}