import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { DefaultService } from '../../layout/default.service';
import * as productCategory from '../../product-details/models/category-description';
@Component({
  selector: 'readmorecontent,[readmorecontent]',
  templateUrl: './read-more-content.component.html',
  styleUrls: ['./read-more-content.component.scss']
})
export class ReadMoreContentComponent implements OnInit {

  @Input() productCategoryUrl: string;
  productCategory: productCategory.ProductCategory.Category;
  content: string;
  showShortDesciption = false;
  siteContent: SiteContentDetail;

  constructor(private router: Router, private defaultService: DefaultService,
    private siteContentService: SiteContentService) { }

  ngOnInit() {
    this.siteContent = this.siteContentService.getSiteContentData();
  }

  ngOnChanges() {
    if (this.productCategoryUrl) {
      this.getProductCategoryDescription(this.productCategoryUrl);
    }

  }

  getProductCategoryDescription(url: string) {

    this.defaultService.getProductCategoryDescriptionByUrl(url).subscribe(data => {
      this.productCategory = JSON.parse(data);

      if (this.productCategory && this.productCategory.Webpage && this.productCategory.Webpage.WebPageRows) {
        this.productCategory.Webpage.WebPageRows.forEach(element => {
          if (element.RowStylePartialView === "TitleAndContentReadMore") {
            if (element.Controls && element.Controls.length > 0) {
              element.Controls.forEach(control => {
                if (control && control.Properties && control.Properties.length > 0) {
                  control.Properties.forEach(pro => {
                    if (pro.ControlTypeElementKey === "Content") {
                      this.content = pro.Value
                    }

                  });
                }
              });
            }
          }
        });
      }
    });
  }


  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
  }

}
