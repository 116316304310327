import { Directive } from '@angular/core';

@Directive({
  selector: '[appDummy]'
})
export class DummyDirective {

  constructor() { }

}

