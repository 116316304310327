import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbItem } from 'src/app/model/bread-crum-item';
import { SiteContentDetail } from '../../models/siteContent';
import { SiteContentService } from '../../sitecontent.service';

@Component({
  selector: 'app-breadcrumbs,[app-breadcrumbs]',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs:BreadcrumbItem[];
  sitecontent: SiteContentDetail;
  absolutePath=window.location.pathname;
  constructor(private siteContentService:SiteContentService) { }

  ngOnInit() {
    this.getSiteContent();
    this.getBreadcrumbs();

  }
  getBreadcrumbs(){
    let breadcrumbList=[] as BreadcrumbItem[];
    if(this.breadcrumbs && this.breadcrumbs.length>3){
      breadcrumbList.push(this.breadcrumbs[0]);
      breadcrumbList.push(this.breadcrumbs[1]);
      breadcrumbList.push(this.breadcrumbs[this.breadcrumbs.length-1]);
      this.breadcrumbs=breadcrumbList;
    }

  }
  getSiteContent(){
    
    this.sitecontent = this.siteContentService.getSiteContentData();
    if(!this.sitecontent){
      this.siteContentService.getSiteContent().subscribe(data=>{
        
        this.sitecontent=data;
      })
    }
  }
}
