import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js'; // Included with Angular CLI.
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	console.log("Browser Startup - Production Mode");
} else {
	console.log("Browser Startup - Develop Mode");
}

document.addEventListener('DOMContentLoaded', () => {
	platformBrowserDynamic().bootstrapModule(AppModule).catch(err => {
		return console.error(err);
	});
});
