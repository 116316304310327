import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appAnimate]'
})
export class AnimateDirective implements AfterViewInit{
  hasAnimated = false;
  el;
  constructor(el:ElementRef,private renderer2:Renderer2) {
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
          this.applyChanges();
  }
  applyChanges(){
    if (this.hasAnimated) {
       return;
    }
    if (this.isElementInView(this.el)) {
        this.addClass("animation-element",this.el.nativeElement);
        this.hasAnimated = true;
   }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.applyChanges();
  }

  isElementInView(el) {
    let winTop = window.pageYOffset;
    let winBottom = winTop + window.innerHeight;

    let elTop = el.nativeElement.offsetTop;
    let elBottom = elTop + el.nativeElement.offsetHeight;

    // Is the element within the viewport
    return elBottom >= winTop && elTop <= winBottom;
}
}
