import { Component, Input, OnInit } from '@angular/core';
import { MenuVM } from 'src/app/model/content-page-row-viewmodel';
import { MenuList } from 'src/app/model/menu-list';
import { DefaultService } from '../../layout/default.service';

@Component({
  selector: '[menu-list],menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {
  @Input() menuVM: MenuVM;
  menuList: MenuList;
  isMenuItemClicked: boolean = false;
  constructor(private defaultService: DefaultService) {
  }

  ngOnInit() {
    this.getMenuData();
  }
  isTopLevel(menulist) {
    if (menulist) {
      let isTopLevel = menulist.filter(x => x.Level == 0);
      if (isTopLevel) {
        return true;
      }
    }
    return false;
  }
  getMenuData() {

    if (this.menuVM) {
      this.menuVM.RequestPath = "/blog";
      this.defaultService.getMenuList(this.menuVM).subscribe(data => {
        this.menuList = JSON.parse(data);
      
      });

    }
  }
}
