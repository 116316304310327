import { DatePipe } from '@angular/common';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AuthService } from '../../auth.service';
import { SaveCardDetails } from '../../models/saved-cards';

@Component({
	selector: 'app-saved-cards',
	templateUrl: './saved-cards.component.html',
	styleUrls: ['./saved-cards.component.scss'],
	providers: [DatePipe]
})
export class SavedCardsComponent implements OnInit {

	@Input() resetSavedCardsCompnenet: Subject<boolean> = new Subject<boolean>();
	saveCardDetails: SaveCardDetails;
	sitecontent: SiteContentDetail;
	selectedForDelete = null;

	constructor(
		private authService: AuthService,
		private sharedService: SharedService,
		private datepipe: DatePipe,
		public zone: NgZone,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.getCardDetails();
		this.resetSavedCardsCompnenet.subscribe(response => {
			if (response) {
			}
		});
	}

	getCardDetails() {
		this.authService.getSavedCards().subscribe(data => {
			if (data) {
				this.saveCardDetails = data;
				this.zone.run(() => { this.selectedForDelete = null; })
			}
		})
	}

	getExpiryDate(ExpiryDate, Expiry) {
		if (ExpiryDate) {
			let date = this.datepipe.transform(ExpiryDate, 'yyyy/MM/dd')
			const _date = new Date(date);
			return _date.getMonth() + "/" + _date.getFullYear();
		}

		return Expiry;
	}

	getcardClass(CardType) {
		let classname = '';

		switch (CardType) {
			case "visa":
				classname = "fa-cc-visa";
				break;
			case "mc":
				classname = "fa-cc-mastercard";
				break;
			case "amex":
				classname = "fa-cc-amex";
				break;
			default:
				classname = "fa-credit-card";
				break;
		}
		return classname;
	}

	getCardName(CardType) {
		if (CardType) {
			if (CardType.toLowerCase() == "mc") {
				return "Mastercard";
			}

			return CardType.toLowerCase();
		}
	}

	deleteSavedCard(savedCard) {
		this.authService.deleteSavedCard(savedCard.RecurringDetailReference).subscribe(data => {
			if (data && data.cardDeleted && data.cardDeleted == '[detail-successfully-disabled]') {
				this.getCardDetails();
			}
		})
	}

	showDeleteConfirmation(savedCard) {
		this.zone.run(() => { this.selectedForDelete = savedCard; })
	}
}
