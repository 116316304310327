import { Component, OnInit, HostListener } from '@angular/core';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-heritage',
  templateUrl: './heritage.component.html',
  styleUrls: ['./heritage.component.scss']
})
export class HeritageComponent implements OnInit {
  data = '';
  constructor(private sharedService:SharedService) { }

  ngOnInit() {
    this.sharedService.getBrandContent().subscribe(res => {
      
       let jsonData = JSON.parse(res.desc);

       this.data = jsonData.WebPageRows[0].Controls[0].Properties[0].Value;
    })
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    
        if(event.target.tagName == 'A'){
           event.preventDefault();

        }
    }
}
