import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '../models/stores-vm';
//import { Store } from 'src/app/model/store';

@Pipe({
    name: 'storefilter',
    pure: false
})
export class StoreFilterPipe implements PipeTransform {
    transform(stores: Store[],region:string): Store[] {
        let storesList=[] as Store[];
  if(stores && stores.length>0){
    storesList=stores.filter(x=>x.Active && x.Region && x.Region.RegionName=== region).sort((n1,n2) => {
      if (n1.OrderBy > n2.OrderBy) {
          return 1;
      }
  
      if (n1.OrderBy < n2.OrderBy) {
          return -1;
      }
  
      return 0;
  }).sort((n1,n2) => {
    if (n1.StoreName > n2.StoreName) {
        return 1;
    }

    if (n1.StoreName < n2.StoreName) {
        return -1;
    }

    return 0;
});
  }
return storesList;
    }
}