import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appAddMargin]'
})
export class AddMarginDirective implements AfterViewInit{
  nextSibling;
  el;
  constructor(el:ElementRef,private renderer2:Renderer2) {
    this.el = el;
  }
  ngAfterViewInit() {
    this.nextSibling = this.el && this.el.nativeElement && this.el.nativeElement.children.length ? this.el.nativeElement.children : null;
     
    if (this.nextSibling == null) {
        return;
    }
    this.setTopMargin(this.el,this.nextSibling);
  }
 
  setTopMargin(el, nextElement){
    let height = nextElement[0].offsetHeight;
    this.renderer2.setStyle(nextElement[0], 'marginTop', height+20+"px");
   
  }
@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTopMargin(this.el, this.nextSibling);
  }
}
