import { Component, Input } from '@angular/core';

import { AccordianVM } from 'src/app/model/content-page-row-viewmodel';
import { accordianTriggerToggle } from '../../../../assets/shared/helper_scripts.js';

@Component({
	selector: '[app-accordian],app-accordian',
	templateUrl: './accordian.component.html',
	styleUrls: ['./accordian.component.scss'],
})
export class AccordianComponent {
	@Input() accordianVM: AccordianVM;
	@Input() rowindex: number;

	constructor() {}
	accordianTrigger: boolean = false;
	accordianItem: string = "0";

	accordianItemTrigger(id: number) {
		this.accordianTrigger = !this.accordianTrigger;
		this.accordianItem = this.rowindex + "" + id;
		accordianTriggerToggle(this.accordianItem);
	}
}
