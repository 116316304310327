import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { AddBasket } from 'src/app/model/basket-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { InitialiseReceiptPageTracking } from '../../../../assets/shared/gtm.js';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { BasketItem } from '../../../model/basketpage-viewmodel.js';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { AttraqtActivityBuilder } from '../../builder/attraqt/activity-builder';
import { NAVIGATOR_LANGUAGE } from '../../constants/attraqt';
import { AttraqtActivityActionEnum } from '../../enums/attraqt-activity-action.enum';
import { PaymentStatus, PaymentTypes } from '../../enums/payemt-status';
import { MustMatch } from '../../helper/must-match.validator';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { SiteContentDetail } from '../../models/siteContent';
import { AttraqtService } from '../../service/attraqt.service';
import { CartService } from '../../service/cart.service';
import { LabelService } from '../../service/label.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { ReceiptService } from './receipt.service';

@Component({
	selector: 'app-receipt',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, AfterViewInit, OnDestroy {
	orderId: any;
	receiptModel: any;
	sitecontent: SiteContentDetail;
	regExLibrary: RegExLibrary;
	PaymentStatus = PaymentStatus;
	PaymentTypes = PaymentTypes;
	label: Label;
	createAccountForm: UntypedFormGroup;
	submitted = false;
	isGuest: boolean;
	LpAppSetting: LpAppSetting;
	scriptContent = [];
	isscriptContentNgAfterviewinit: boolean = false;
	isDutyFree: boolean = false;

	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private sharedService: SharedService,
		private receiptService: ReceiptService,
		private route: ActivatedRoute,
		private titleService: Title,
		private router: Router,
		private meta: Meta,
		private cartService: CartService,
		private formBuilder: UntypedFormBuilder,
		private injector: Injector,
		private siteContentService: SiteContentService,
		private labelService: LabelService,
		private attraqtService: AttraqtService,

		@Inject(PLATFORM_ID)
		private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	ngOnInit() {
		this.subscribeToState();
		this.getSiteContent();
		this.getRegExLibrary();

		this.route.params.subscribe(params => {
			this.orderId = params['id'];

			this.receiptService.getReceipt(this.orderId).subscribe(data => {
				if (data) {
					if (data && data.Basket && data.Basket.AddressShipping) {
						this.setIsDutyFree(data.Basket.AddressShipping);
					}

					this.receiptModel = data;
					this.getTrackingScript();

					if (this.receiptModel && this.receiptModel.Order && this.receiptModel.Order.OrderItems && this.receiptModel.Order.OrderItems.length) {
						this.receiptModel.Order.OrderItems.forEach(item => {
							let addToBasket = {} as AddBasket;
							addToBasket.quantity = -1;
							addToBasket.inventoryProduct = item.InventoryProductId;
							this.cartService.refreshLocalBasket(null, false);
						});
					}

					if (this.receiptModel.Basket.BasketItems.length && this.facetFilterState.attraqtPLPEnabled) {
						this.receiptModel.Basket.BasketItems.forEach((basketItem: BasketItem) => {
							this.attraqtService.sendActivity(
								new AttraqtActivityBuilder(AttraqtActivityActionEnum.Purchase)
									.setTarget({ product: basketItem.ProductId.toString() })
									.setMetadata({
										orderId: this.receiptModel.Order.OrderId.toString(),
										price: basketItem.TotalPrice / basketItem.Quantity * 100,
										currency: this.receiptModel.CurrencyCode,
										quantity: basketItem.Quantity,
										locale: localStorage.getItem(NAVIGATOR_LANGUAGE),
									})
									.build()
							);
						})
					}
				}
			})
		});

		this.isGuest = !!localStorage.getItem('isGuestLogin');

		if (this.isGuest) {

			this.createAccountForm = this.formBuilder.group({
				OrderId: [+this.orderId],
				Password: ['', Validators.required],
				PasswordConfirm: ['', Validators.required],

			}, {
				validator: MustMatch('Password', 'PasswordConfirm')
			});
		}

		this.titleService.setTitle('');
		this.meta.updateTag({ name: 'description', content: '' });
	}

	async ngAfterViewInit() {
		await this.getLpAppSetting();
		//gtm start
		InitialiseReceiptPageTracking(this.LpAppSetting.GeneralSettings, this.LpAppSetting.UserSettings, this.receiptModel);
		//gtm end

		if (this.scriptContent && this.scriptContent.length > 0 && !this.isscriptContentNgAfterviewinit) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			this.isscriptContentNgAfterviewinit = true;
		}
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	getRegExLibrary() {
		this.sharedService.getRegExLibrary().subscribe(data => {
			if (data) {
				this.regExLibrary = data;
				this.getAllLabels();
			}
		});
	}

	getAllLabels() {
		this.label = this.labelService.getLabelData();

		if (!this.label) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.label = data;
				}
			});
		}
	}

	getTrackingScript() {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = PageTypeDimensionEnum.CheckoutReceipt;
		trackingVm.ReceiptPage = this.receiptModel;

		this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
			this.scriptContent = this.sharedService.ParseScript(data);
			this.ngAfterViewInit();
		});
	}

	get f() { return this.createAccountForm.controls; }

	onSubmit() {
		this.submitted = true;

		if (this.createAccountForm.invalid) {
			return;
		}

		if (this.receiptModel) {
			this.receiptModel.OrderId = +this.createAccountForm.get('OrderId').value;
			this.receiptModel.Password = this.createAccountForm.get('Password').value;
			this.receiptModel.PasswordConfirm = this.createAccountForm.get('PasswordConfirm').value;
		}

		this.receiptService.postReceipt(this.receiptModel).subscribe(res => {
			if (res) {
				this.router.navigate(["checkout/registerthanks", this.receiptModel.WebStoreId]);
			}
		})
	}

	setIsDutyFree(addressShipping) {
		// Assign Duty Free if country IsEU and not UK
		if (addressShipping) {
			this.isDutyFree = addressShipping.IsCountryEU && addressShipping.CountryId != 1;
		}
	}

	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}

	getGeneratedCompareMessage(toReplace1, toReplace2, text) {
		return text.replace('{0}', toReplace1).replace('{1}', toReplace2);
	}

	format(text: string, ...args: string[]): string {
		return text.replace(/{(\d+)}/g, (match, num) => {
			return typeof args[num] !== 'undefined' ? args[num] : match;
		});
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
