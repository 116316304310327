import { WITH_CREDENTIALS_EXCLUDE_HOSTS } from '../constants/with-credentials-exclude-hosts';

const shouldIncludeWithCredentials = (requestURL: string) => {
	const found = WITH_CREDENTIALS_EXCLUDE_HOSTS.find(
		(host: string) => requestURL.indexOf(host) > -1
	);
	return !found;
};

export default shouldIncludeWithCredentials;
