import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VideomodalComponent } from 'src/app/modules/Home/videomodal/videomodal.component';

@Injectable({
  providedIn: 'root'
})
export class OpenModalService {

  matDialogRef: MatDialogRef<VideomodalComponent>;
  constructor( private matDialog: MatDialog) { }


  openVideoModal(url) {
    if(this.matDialogRef){
      this.matDialogRef.afterClosed().subscribe(res => {
          this.matDialogRef.close();
      });
    }
   
    if(url && (!this.matDialogRef || (this.matDialogRef && !this.matDialogRef.componentInstance))){
      this.matDialogRef = this.matDialog.open(VideomodalComponent, {
        data: { videosource: url },
        disableClose: true,
        autoFocus: false, 
        restoreFocus: false
      });
    }

  }
}
