import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'iconmodelpopup',
  templateUrl: './iconmodelpopup.component.html',
  styleUrls: ['./iconmodelpopup.component.scss']
})
export class IconmodelpopupComponent implements OnInit {
  videoSource: string;
  title:string;
  dialogRef:any

  private inited;

  @HostListener('window:click')
   onNoClick(): void {
     if (this.inited) {
       this.dialogRef.close();
     }
   }

  constructor(private _mdr: MatDialogRef<IconmodelpopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private rd: Renderer2, private router: Router) {
      this.dialogRef = _mdr
      if(data){
        this.title=data.title;
        this.videoSource = data.videosource;
      }
  } 

  ngOnInit() { 
    this.dialogRef.afterOpened().subscribe(() => {
      this.inited = true;
    })
  }

  CloseDialog() {
    this._mdr.close();
  }

  ngAfterViewInit() {
    $('.iconbarmodal').on('click',function(event){
      event.stopPropagation();     
    });
  }
  

}
