import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentApiPaths, EnvironmentApiUrl, EnvironmentConfig } from './models/environment-config.model';

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService {
	private envConfig: EnvironmentConfig;
	public environment: EnvironmentApiPaths;

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(
		@Inject(DOCUMENT) // Document abstraction
		private document: any,
		private httpClient: HttpClient) {

		// Can be cleaned up to use "" later.
		var apiUrl = this.document.location.origin;
		console.log(this.getTimestamp(), `Api Url set as: ${apiUrl}`);

		this.environment = {
			accountUrl: apiUrl + '/api/Account/',
			adyenUrl: apiUrl + '/api/Adyen/',
			amazonUrl: apiUrl + '/api/Amazon/',
			basketUrl: apiUrl + '/api/Basket/',
			checkoutUrl: apiUrl + '/api/Checkout/',
			paypalUrl: apiUrl + '/api/PayPal/',
			siteUrl: apiUrl + '/api/Site/',
			storesUrl: apiUrl + '/api/Stores/',
			webserviceUrl: apiUrl + '/api/WebService/',
		}
	}

	getTimestamp() {
		return `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`;
	}

	// 20231205: This bit of code is no longer required since we will not be using SSR mode.
	// This code has been refactored and moved to the constructor.
	async loadEnvironmentConfig(configPath: string): Promise<void> {

		// Locally while testing this.document.location.origin will have two different values
		// Server: http://localhost:XXXXX (e.g 58901)
		// Browser: http://localhost:4200

		// We need to append both Server and Browser origins since they are different from each other.
		// The original configPath is just a relative path.
		var origin = this.document.location.origin;
		configPath = origin + configPath;

		console.log(this.getTimestamp(), `Loading environment config from: ${configPath}`);

		this.envConfig = await lastValueFrom(this.httpClient.get<EnvironmentConfig>(configPath))
			.catch((error: HttpErrorResponse) => {
				console.warn("Unable to obtain EnvironmentConfig. This could be due to incorrectly formatted json file or cannot find the location on server.");
				console.warn("Exception: ", error);
			}).then(response => {
				return response as EnvironmentConfig;
			});

		console.log("Loaded environment config");

		var hostname = this.document.location.hostname;
		var host = this.document.location.host;

		console.log(`Current host: ${host}`); // e.g localhost:60002
		console.log(`Current hostname: ${hostname}`); // e.g localhost

		var apiHostnames: EnvironmentApiUrl[];

		// Try matching via host (to include port)
		apiHostnames = this.envConfig.apiHostnames.filter(x => x.webHost == host);

		// Otherwise match against generic hostname (non-port)
		if (apiHostnames.length != 1) {
			apiHostnames = this.envConfig.apiHostnames.filter(x => x.webHost == hostname);
		}

		var apiUrl = "";

		if (apiHostnames.length != 1) {
			console.log(`No matching Api Hostname found for ${hostname}`);
			console.log(`Using origin instead ${origin}`);

			// All else then use origin
			apiUrl = origin;
		}
		else {
			var apiHostname = apiHostnames[0].apiHost;
			console.log(`Found matching Api Hostname: ${apiHostname}`);

			apiUrl = `${this.document.location.protocol}//${apiHostname}`;
		}

		console.log(this.getTimestamp(), `Api Url set as: ${apiUrl}`);

		this.environment = {
			accountUrl: apiUrl + '/api/Account/',
			adyenUrl: apiUrl + '/api/Adyen/',
			amazonUrl: apiUrl + '/api/Amazon/',
			basketUrl: apiUrl + '/api/Basket/',
			checkoutUrl: apiUrl + '/api/Checkout/',
			paypalUrl: apiUrl + '/api/PayPal/',
			siteUrl: apiUrl + '/api/Site/',
			storesUrl: apiUrl + '/api/Stores/',
			webserviceUrl: apiUrl + '/api/WebService/',
		}
	}
}
