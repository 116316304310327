import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'UniqueRecord',
    pure: false
})
export class UniqueRecordPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        // Remove the duplicate elements
        var art = value.map(x=>{
            return x.SizeValues.map(y=>{ return y.Name;});;
        }).reduce((acc,ele,i)=>{
            acc = acc.concat(ele);
            return acc;
        });
        return new Set(art);
    }
}