import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { HomeService } from 'src/app/modules/Home/home.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AddTrackingScriptToDOM } from '../../../../../assets/shared/tracking';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AuthService } from '../../auth.service';
@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, AfterViewInit {
	selectedIndex: number = 0;
	userData;
	resetPersonalDetailsCompnenet: Subject<boolean> = new Subject<boolean>();
	resetAddressBookCompnenet: Subject<boolean> = new Subject<boolean>();
	resetWebOrdersCompnenet: Subject<boolean> = new Subject<boolean>();
	resetSavedCardsCompnenet: Subject<boolean> = new Subject<boolean>();
	addressBooksList = [];
	currentScreenWidth: number;
	showOnlyTabIcons: boolean = false;
	countriesList = [];
	sitecontent: SiteContentDetail;
	scriptContent = [];
	isscriptContentNgAfterviewinit: boolean = false;
	LpAppSetting: LpAppSetting;

	constructor(
		private authService: AuthService,
		private homeService: HomeService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private sharedService: SharedService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) {

		let classlist = "fixed-header mask-hide ng-scope ";
		$("body").removeClass();
		$("body").addClass(classlist);
	}

	navLinks = [
		{ path: '/account/myaccount', label: 'Personal Details' },
		{ path: '/account/orders', label: 'Web Orders History' },
		{ path: '/account/addressbook', label: 'Address Book' },
		{ path: '/account/wishlist', label: 'wish List' },
		{ path: '/account/savedcards', label: 'Saved Cards' },

	]
	activeLink = '';
	orderId = 0;

	ngOnInit() {
		this.sharedService.menuItemSelected(window.location.pathname);
		this.getLpAppSetting();
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.currentScreenWidth = window.innerWidth;
		this.getTrackingScript();
		this.navLinks.filter((item, i) => {
			if (item.path == this.router.url) {
				this.activeLink = this.navLinks[i].path;
				return;
			}
		});
		this.activatedRoute.params
			.subscribe(
				(params) => {
					this.orderId = +params['orderId'];
					let currentpath = window.location.pathname;

					this.activeLink = this.activeLink && !this.orderId ? this.activeLink : 'orderInfo';
				}
			);

		this.userData = JSON.parse(localStorage.getItem('userData'));
		if(!this.userData) {
			this.signout();
		}
		if (localStorage.getItem('isSignedIn') != "true") {
			this.router.navigate(['account/signin']);
		}
		localStorage.removeItem('filteredList');
		localStorage.removeItem('filterFacestsData');
		this.getAllAddressBooks();
		this.getAllCountries();
		let getIndiex = localStorage.getItem("selectIndex");
		this.selectedIndex = getIndiex ? parseInt(getIndiex) : 0;
		this.scrollService.windowScrollTo(0, 0);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.currentScreenWidth = window.innerWidth;
		if (this.currentScreenWidth < 770) {
			this.showOnlyTabIcons = true;
		}
	}
	signout() {
		//localStorage.setItem('isSignedIn', "false");
		localStorage.clear();
		this.sharedService.changeSigninInfo("")
		// this.router.navigateByUrl('details', { skipLocationChange: true }).then(() => {
		//   this.router.navigate(['']);
		// });
		//this.isDisplayDetails=false;
		$('body').removeClass('mask-show');
		//this.router.navigate(['']);
	}

	changeTab(e) {
		let prevIndex = this.selectedIndex;
		this.selectedIndex = e;
		this.triggerChildForRefresh(prevIndex);
		localStorage.setItem("selectIndex", this.selectedIndex.toString());
	}
	triggerChildForRefresh(refreshcomponent) {
		if (refreshcomponent == 0) {
			this.resetPersonalDetailsCompFromParent();
		} else if (refreshcomponent == 1) {
			this.resetWebOrdersCompFromParent();
		} else if (refreshcomponent == 2) {
			this.resetAddressbookCompFromParent();
		} else if (refreshcomponent == 3) {
			this.resetSavedCardsCompFromParent();
		}
	}
	changeTabValueToAddresBook() {
		this.selectedIndex = 2;
	}
	resetPersonalDetailsCompFromParent() {
		this.resetPersonalDetailsCompnenet.next(true);
	}
	resetAddressbookCompFromParent() {
		this.resetAddressBookCompnenet.next(true);
	}
	resetWebOrdersCompFromParent() {
		this.resetWebOrdersCompnenet.next(true);
	}
	resetSavedCardsCompFromParent() {
		this.resetSavedCardsCompnenet.next(true);
	}
	selectTab(link) {
		this.activeLink = link.path;
		localStorage.setItem('accountTab', this.activeLink)
	}
	getAllAddressBooks() {
		this.authService.getAllSavedAddressBooks().subscribe(res => {
			if (res.length) {
				this.addressBooksList = res;
			}
		}),
			err => console.log(err);
	}
	reloadComponent() {
		this.ngOnInit();
	}

	getAllCountries() {
		this.homeService.getAllCountries(true).subscribe(res => {
			this.countriesList = res.List;
		});
	}
	getClassBadsedOnTab() {
		if (this.activeLink == this.navLinks[1].path) {
			return "clearfix order-history";
		} else if (this.activeLink == this.navLinks[2].path) {
			return "address-book";
		} else if (this.activeLink == this.navLinks[3].path) {
			return "wishlist";
		} else if (this.activeLink == this.navLinks[4].path) {
			return "saved-cards";
		} else if (this.activeLink === 'orderInfo') {
			return "wrapper clearfix order-view"
		}
	}

	getTrackingScript() {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = PageTypeDimensionEnum.None;

		this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {

			this.scriptContent = this.sharedService.ParseScript(data);
			this.ngAfterViewInit();

		}, err => {

		});
	}

	ngAfterViewInit() {
		if (this.scriptContent && this.scriptContent.length > 0 && !this.isscriptContentNgAfterviewinit) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			this.isscriptContentNgAfterviewinit = true;

		}
	}
	
	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}
}
