import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
//import { Label } from "src/app/shared/models/Labels";
import { LabelService } from "src/app/shared/service/label.service";
import { Label } from "src/app/shared/models/Labels";
//import { LabelService } from "src/app/shared/label.service";

@Injectable()
export class LabelResolver {

  constructor(
     
    private restRequest:LabelService

  ){

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Label> {
     // In my case i am using custom service for getting rest calls but you can use simple http.get()...
    return this.restRequest.getLabels();

  }
}