export const MAX_FILTERS = {
	DESKTOP: 6,
	SMALL_DESKTOP: 5,
	TABLET: 4,
};

export const MAX_SCREEN_WIDTH = {
	DESKTOP: 1439,
	SMALL_DESKTOP: 1299,
	TABLET: 1099,
};
