import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CarouselControl } from 'src/app/model/carousel-control';
import { CarouselVM } from 'src/app/model/carousel-viemmodel.js';
import { OpenModalService } from 'src/app/shared/service/open-modal.service';
import { slickSliderRefresh } from '../../../../assets/shared/helper_scripts.js';

@Component({
	selector: 'carousel,[carousel]',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
	@Input() carouselControl: CarouselControl[];
	@Input() fromCarouselStyle: CarouselVM;

	@ViewChild('slickModal') slickModal: SlickCarouselComponent;

	sizeGuid: boolean = false;
	newArrivals: boolean = false;

	innerWidth;
	slideConfig: any = {};

	constructor(
		private router: Router,
		public sanitizer: DomSanitizer,
		private openModalService: OpenModalService,
		private ngZone: NgZone,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	ngOnInit() {
		window['angularComponentReference'] = {
			component: this,
			zone: this.ngZone,
			loadAngularFunction: (url) => this.openModalService.openVideoModal(url)
		};

		this.innerWidth = window.innerWidth;
		this.sizeGuid = this.carouselControl && this.carouselControl[0] && this.carouselControl[0].CarouselSlideNextClass == 'hidden';

		if (this.fromCarouselStyle && this.fromCarouselStyle.CarouselDefault && this.fromCarouselStyle.CarouselDefault.Carousel) {

			this.slideConfig = JSON.parse(this.fromCarouselStyle.CarouselDefault.Carousel);

			if (this.carouselControl && this.carouselControl.length === 1) {
				this.slideConfig.slidesToShow = 1;
			}

			this.setupOptions(this.slideConfig)
			this.slideConfiguarion();

			if (this.fromCarouselStyle.CarouselDefault.RowClass
				&& (this.fromCarouselStyle.CarouselDefault.RowClass.includes("carousel-style-4") || this.fromCarouselStyle.CarouselDefault.RowClass.includes("carousel-style-8"))) {
				this.slideConfig.centerMode = true;
			}

		}
	}

	ngAfterViewInit() {
		// Since we are running helper script which includes jQuery, run this only on Browser Side
		if (isPlatformBrowser(this.platformId)) {
			slickSliderRefresh();
		}
	}

	next() {
		this.slickModal.slickNext();
	}

	prev() {
		this.slickModal.slickPrev();
	}

	isContainsVideo(source: string): boolean {
		return source.startsWith('https://player.vimeo.com') || source.endsWith('.mp4') || source.endsWith('.avi') || source.endsWith('.webm');
	}

	isHtmlPlayer(source: string): boolean {
		return source.endsWith('.m4v') || source.startsWith('https://player.vimeo.com');
	}

	contentClicked(event) {
		const element: HTMLElement = event.target;
		if (element.nodeName === 'A') {
			event.preventDefault();
			const link = element.getAttribute('href');
			if (link) {
				this.router.navigate([link]);
			}
		}
	}

	navigateUrl(destinationUrl) {

		if (destinationUrl) {
			this.router.navigate([destinationUrl]);
		}

	}

	RemoveSpace(bannerUrl) {
		if (bannerUrl) {
			return bannerUrl.trim();
		}
	}

	slideConfiguarion() {
		if (this.slideConfig) {


			if (this.slideConfig.fade != true) {
				this.slideConfig.cssEase = "cubic-bezier(0.950, 0.000, 0.590, 1.000)";
			}

			// This is a fix for the text delay issue, but it breaks the transistion above
			//options.useCSS = 1;

			this.slideConfig.prevArrow = "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false'><i></i><span>" + (this.slideConfig.leftArrowText != null ? this.slideConfig.leftArrowText : "") + "</span></button>";
			this.slideConfig.nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false'><i></i><span>" + (this.slideConfig.rightArrowText != null ? this.slideConfig.rightArrowText : "") + "</span></button>";
			// Lazy loading causes issues with the carousel
		}
	}

	beforeChange(e: any) {
		this.carouselControl.forEach(x => {
			x.BannerVM.CarouselUpdateStart = moment();
		});
	}

	afterChange(e: any) {
		this.carouselControl.forEach(x => {
			x.BannerVM.CarouselUpdateEnd = moment();
		});
	}

	setupOptions(options) {

		if (options.autoplaySpeed == null || options.autoplaySpeed === "") {
			options.autoplaySpeed = "7000";
		}

		if (options.slidesToShow == null || options.slidesToShow === "") {
			options.slidesToShow = 1;
		}

		if (options.autoplaySpeed == "0") {
			options.autoplay = false;
		}

		if (options.autoplay == null) {
			options.autoplay = true;
		}

		options.dots = options.showDots;

		if (options.slidesToShow != null) {

			if (options.filterBannerResponsive) {
				// If the carousel is part of the filter banners then 
				options.responsive =
					[
						{ breakpoint: 1100, settings: { slidesToShow: 3, slidesToScroll: 1, } },
						{ breakpoint: 840, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
					];
			}
			else {
				// Standard behavior
				switch (parseInt(options.slidesToShow)) {
					case 1:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { cssEase: "ease" } }
							];
						break;

					case 4:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 4, slidesToScroll: 1, } },
								{ breakpoint: 840, settings: { slidesToShow: 3, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;

					case 8:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 6, slidesToScroll: 1, } },
								{ breakpoint: 769, settings: { slidesToShow: 5, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 4, slidesToScroll: 4, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 3, slidesToScroll: 3, cssEase: "ease" } }
							];
						break;

					//This case is only for carousel-style-7 - Fit guide Page carousel with banners control, not fit control with carousel plus-icon 
					case 999:
						options.infinite = false;
						options.slidesToShow = 4;
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 1, cssEase: "ease" } },
								{ breakpoint: 641, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;

					//This case is for settings dynamics breakpoints and slideToShow. Example Gallery-Popup Control
					case 998:
						if (options.responsive != null && options.responsive.length > 0) {
							options.responsive = options.responsive;
						}
						break;
					default:
						options.responsive =
							[
								{ breakpoint: 1100, settings: { slidesToShow: 3, slidesToScroll: 1 } },
								{ breakpoint: 769, settings: { slidesToShow: 2, slidesToScroll: 2, cssEase: "ease" } },
								{ breakpoint: 550, settings: { slidesToShow: 1, slidesToScroll: 1, cssEase: "ease" } }
							];
						break;
				}
			}
		}

		this.slideConfig = options;
	}
}
