import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { QuickViewComponent } from 'src/app/modules/product-list/quick-view/quick-view.component';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { ImageTypeKey } from '../../../enums/image-type-key-enum';
import { ProductListForView } from '../../product-list/model/product-list';

@Component({
	selector: '[related-product],related-product',
	templateUrl: './related-product.component.html',
	styleUrls: ['./related-product.component.scss']
})
export class RelatedProductComponent implements OnInit {

	@Input() relatedProduct: RelatedProductVM;
	@Input() relatedProductView: ProductListForView;
	@Input() siteContent: SiteContentDetail;

	matDialogRef: MatDialogRef<QuickViewComponent>;
	productId: number;

	slideConfig = {
		"slidesToShow": 4,
		"slidesToScroll": 1,
		"prevArrow": "<button  class='slick-prev slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block; left:-20px;'><i></i><span></span></button>",
		"nextArrow": "<button class='slick-next slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block; right:-20px;'><i></i><span></span></button>",
		"infinite": true,
		"arrows": true,
		"focusOnSelect": true,
	};

	constructor(
		private matDialog: MatDialog,
		private router: Router,
		private sharedService: SharedService) {
	}

	ngOnInit() {
		if (this.sharedService.isMobileDevice()) {
			this.slideConfig.slidesToShow = 1;
			this.slideConfig.nextArrow = "<button class='slick-next slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>";
			this.slideConfig.prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>";
			this.slideConfig.arrows = true;
		}
	}

	onClick(related: Observable<RelatedProductVM>) { }

	productQuickView(id) {
		this.productId = id;
		this.matDialogRef = this.matDialog.open(QuickViewComponent, {
			data: { productId: id },
			disableClose: true
		});

		this.matDialogRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				this.productId = undefined;
			}
		});
	}

	viewTheProduct(productId) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate([productId]);
		});
	}

	getImage(imageSizes) {
		for (let i = 0; i < imageSizes.length; i++) {
			if (imageSizes[i].Key == ImageTypeKey.New2Medium) {

				return imageSizes[i];
			}
		}
	}
}
