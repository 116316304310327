import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AttributeTypeEnum } from 'src/app/enums/attribute-types-enum';
import { videoData } from '../model/product-list';

@Component({
	selector: 'lazy-image',
	templateUrl: './lazy-image.component.html',
	styleUrls: ['./lazy-image.component.scss']
})

export class LazyImageComponent implements OnInit {
	@Input() imageUrl: string;
	@Input() altText: string;
	@Input() imageUrl2: string;
	@Input() productUrl: string;
	@Input() noImageAvailableMessage: string;
	@Input() isPreloading: boolean;
	@Input() videoData: videoData;
	@Input() seoImageAttributes: boolean = false;

	hasPlpVideo: boolean = false;
	hasPlpHoverVideo: boolean = false;
	videoResourceUrl: SafeResourceUrl = '';

	isLoading = true;

	constructor(
		private sanitizer: DomSanitizer) {
	}

	ngOnInit() {
		if (!this.imageUrl) {
			this.isLoading = false;
		}
		if (this.videoData?.AttributeValues) {
			this.checkVideoAttribute();
		}

		if (this.hasPlpVideo) {
			this.videoData.VideoSource
		}
	}

	hideLoader() {
		this.isLoading = false;
	}

	checkVideoAttribute() {
		if (this.videoData.AttributeValues.find(x => x.Name === AttributeTypeEnum.ShowonPLP)) {
			this.hasPlpVideo = true;
			this.videoResourceUrl = this.getSafeVideoUrl();
		}

		if (this.videoData.AttributeValues.find(x => x.Name === AttributeTypeEnum.ShowonHoverPLP)) {
			this.hasPlpHoverVideo = true;
			this.hasPlpVideo = false;
			this.videoResourceUrl = this.getSafeVideoUrl();
		}
	}

	getSafeVideoUrl(): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoData.VideoSource);
	}
}
