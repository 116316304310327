import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
// import {RestRequestService} from "../../Services/rest-request.service";
import { SharedService } from "src/app/shared/shared.service";
import { LpAppSetting } from "src/app/model/lpapp-setting";
import { SiteContentDetail } from "src/app/shared/models/siteContent";
import { SiteContentService } from "src/app/shared/sitecontent.service";

@Injectable()
export class SiteContentResolver {

  constructor(
     
    private restRequest:SiteContentService

  ){

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SiteContentDetail> {
     // In my case i am using custom service for getting rest calls but you can use simple http.get()...
    return this.restRequest.getSiteContent();

  }
}