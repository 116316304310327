import { Injectable, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgAisModule } from 'angular-instantsearch';
import { ShoppingBasketComponent } from 'src/app/modules/shopping-basket/shopping-basket/shopping-basket.component';
import { DefaultFooterComponent } from 'src/app/shared/components/footer/default-footer/default-footer.component';
import { DefaultHeaderComponent } from 'src/app/shared/components/header/default-header/default-header.component';
import { LabelResolver } from 'src/app/shared/components/header/default-header/label-resolver';
import { LpAppSettingsResolver } from 'src/app/shared/components/header/default-header/lpapp-settings-resolver';
import { MenuItemsResolver } from 'src/app/shared/components/header/default-header/menu-items-resolver';
import { SiteContentResolver } from 'src/app/shared/components/header/default-header/site-content-resolver';
import { WebsiteResolver } from 'src/app/shared/components/header/default-header/website-resolver';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { HomeModule } from '../Home/home.module';
import { ProductDetailModule } from '../product-details/product-detail.module';
import { ProductListModule } from '../product-list/product-list.module';
import { DefaultComponent } from './default/default.component';


@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
	overrides = {
		pinch: { enable: false },
		rotate: { enable: false }
	};
}

@NgModule({
	declarations: [
		DefaultComponent,
		ShoppingBasketComponent
	],
	imports: [
		//NgAisModule.forRoot(),
		MatInputModule,
		MatButtonModule,
		MatCardModule,
		MatFormFieldModule,
		MatDialogModule,
		BrowserAnimationsModule,
		NgbModule,
		RouterModule,
		HomeModule,
		ProductListModule,
		ProductDetailModule,
		SharedModule, AuthModule,
	],
	exports: [
		DefaultHeaderComponent, DefaultFooterComponent
	],
	providers: [{
		provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig
	},
		MenuItemsResolver, LpAppSettingsResolver, SiteContentResolver, LabelResolver, WebsiteResolver
	]
})
export class DefaultModule { }
