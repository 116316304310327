import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

	constructor(
		private transferState: TransferState,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.method === 'GET') {
			const key = makeStateKey<string>(req.url);
			const storedResponse = this.transferState.get<string>(key, null);
			if (storedResponse) {
				const response = new HttpResponse({ body: storedResponse, status: 200 });
				return of(response);
			}
		}

		return next.handle(req);
	}
}
