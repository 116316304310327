import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormFieldTypeEnum } from 'src/app/enums/control-type-enum';
import { FormControlVM } from 'src/app/model/control-viewmodel';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../shared/service/scroll.service';
@Component({
  selector: 'app-form,[app-form]',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
 
})
export class FormComponent implements OnInit {
  @Input() formControlVM: FormControlVM;
  siteContent: SiteContentDetail;
  FormFieldTypeEnum = FormFieldTypeEnum;
  @ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;

  recaptchaActive: boolean;
  recaptchaV2SiteKey: string;
  submitted = false;
  formId: number;
  form: UntypedFormGroup;
  readMore = false;
  captchaValue = false;
	isFormSubmitted = false;

	constructor(
		private fb: UntypedFormBuilder,
		private sharedService: SharedService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) { }

  ngOnInit() {
    this.siteContent=this.siteContentService.getSiteContentData()
    if(this.formControlVM){
     this.recaptchaActive=this.formControlVM.RecaptchaActive;
     this.recaptchaV2SiteKey=this.formControlVM.RecaptchaSiteKey;
        
          this.formId = this.formControlVM.FormControlModel.FormId;
          this.setFormControls(this.formControlVM);
       
     
    }
   
  }


  get f() {
    return this.form.controls;
  }
  setFormControls(FormControlVM:FormControlVM) {
    if (FormControlVM && FormControlVM.FormControlModel
      && FormControlVM.FormControlModel.Fields) {
      const obj = {recaptcha: ['', Validators.required]};
      FormControlVM.FormControlModel.Fields.forEach(item => {
        const c = [];
        c.push('');
        if (item.IsRequired) {
          c.push(Validators.required);
        }
        obj[item.SaveName] = c;
      });
      this.form = this.fb.group(obj);
    }
  }
  getGeneratedMessage(toReplace, text) {
    return text.replace('{0}', toReplace);
  }
  onSubmit() {
    
    this.submitted = true;
    if (this.form.valid) {
      const temp = this.form.value;
      temp.captchaValid = this.captchaValue;
      temp.SubmissionTypeId = this.formId;
      this.sharedService.submitCustomForm(temp).subscribe(data => {
        this.isFormSubmitted = true;
		  this.scrollService.windowScrollTo(0, 0);
      });
    }
  }
}
