import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VideomodalComponent } from 'src/app/modules/Home/videomodal/videomodal.component';
import { Store } from '../../models/stores-vm';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss']
})
export class MapModalComponent implements OnInit {

  
  store: Store;

  private inited;

  constructor(private _mdr: MatDialogRef<MapModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private rd: Renderer2, private router: Router) {

    this.store = data.store;
  }

  ngOnInit() {
    this._mdr.afterOpened().subscribe(() => {
      this.inited = true;
    })
  }
  CloseDialog() {

    this._mdr.close();
  }
  getAddress(store: Store, includeCountry: boolean) {

    let excludeUk = true;
    let includePhone = false;
    let includeCustomerName = true
    let sb = '';
    if (store && store.Address) {


      if (includeCustomerName && store.Address.FirstName || store.Address.LastName) {
        sb = store.Address.FirstName + store.Address.LastName + "<br/>";
      }

      if (store.Address.StoreName) {
        sb += store.Address.StoreName + "<br/>";
      }

      if (store.Address.StreetAddress1) {

        sb += store.Address.StreetAddress1 + "<br/>";
      }

      if (store.Address.StreetAddress2) {

        sb += store.Address.StreetAddress2 + "<br/>";
      }

      if (store.Address.City) {

        sb += store.Address.City + "<br/>";
      }

      if (store.Address.County) {

        sb += store.Address.County + "<br/>";
      }

      if (store.Address.StateCode) {

        sb += store.Address.StateCode + "<br/>";
      }

      if (store.Address.PostCode) {
        sb += store.Address.PostCode + "<br/>";
      }

      if (includeCountry && store.Address.Country) {
        if (!(excludeUk && store.Address.CountryId == 1)) {

          sb += store.Address.Country + "<br/>";
        }
      }

      if (includePhone && store.Address.Phone) {
        sb += store.Address.Phone + "<br/>";
      }


      return sb;
    }
  }

  getFormatedPhoneNumber(store){
    let phoneNumber = store.PhoneNumber;

    
    if(phoneNumber.length >= 10){
      phoneNumber = phoneNumber.substr(0,3)+" "+phoneNumber.substr(3,4)+""+phoneNumber.substr(7);
      return phoneNumber;
    }
    return phoneNumber;
  }

}
