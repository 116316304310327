import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ScrollService {

	public scrollYPosition = 0;
	//private window: Window;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: any) {

		//this.window = this.document.defaultView;
	}

	setScrollYPosition(scrollY) {
		this.scrollYPosition = scrollY ? scrollY : 0;
	}

	windowScrollTo(x: number, y: number): void {
		if (isPlatformBrowser(this.platformId)) {
			window.scrollTo(x, y);
		}
	}
}
