import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { HomeService } from 'src/app/modules/Home/home.service';
import { DefaultService } from 'src/app/modules/layout/default.service';
//import { LabelService } from 'src/app/shared/label.service';
import { Label } from 'src/app/shared/models/Labels';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { LabelService } from 'src/app/shared/service/label.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AuthService } from '../../auth.service';
import { List } from '../../models/account';

@Component({
	selector: 'app-addressbook',
	templateUrl: './addressbook.component.html',
	styleUrls: ['./addressbook.component.scss']
})
export class AddressbookComponent implements OnInit {
	@Input() resetAddressBookCompnenet: Subject<boolean> = new Subject<boolean>();
	@Input() addressBookList;
	@Input() countryList;
	sitecontent: SiteContentDetail;
	@Output() refreshParent = new EventEmitter<string>();
	showMainData: boolean = false;
	showAddressLists: boolean = true;
	showDeleteItem: boolean = false;
	addressBookId;
	deleteAddressBookId;
	isAddressSaved = false;
	deleteAddressdata;
	titleList: List[];
	addingBook = {
		Description: '',
		StreetAddress1: '',
		StreetAddress2: '',
		Phone: '',
		City: '',
		County: '',
		PostCode: '',
		Country: '',
		AddressDetailId: '',
		Titel: ''
	}
	defaultSelectedBillingAddress = "";
	defaultSelectedShippingAddress = "";
	selectedShippingId = 0;
	selectedBillingId = 0;
	addressBookForm: UntypedFormGroup;
	showManualAddressForm: boolean = false;
	defaultAddressForm: UntypedFormGroup;
	addressList: any;
	isSave: Boolean = false;
	LpAppSetting: LpAppSetting;
	PostCodeAnywhereSecurityKey: string;
	countryLanguage: string;
	labelData: Label;
	loqateSupportedCountries = [{ name: 'United Kingdom', id: 1 }, { name: 'Australia', id: 7 }, { name: 'Germany', id: 2 }, {
		name: 'United States',
		id: 125
	}];
	loqateEnabled: boolean = true;

	constructor(
		private fb: UntypedFormBuilder,
		private homeService: HomeService,
		private commonService: DefaultService,
		private sharedService: SharedService,
		private authService: AuthService,
		private siteContentService: SiteContentService,
		private scrollService: ScrollService,
		private labelService: LabelService) { }

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.labelData = this.labelService.getLabelData();
		this.scrollService.windowScrollTo(0, 0);
		this.getTitles();
		this.getLpSettings();

		if (this.addressBookList.length) {
			this.showAddressLists = true;
			this.showMainData = false;
			this.selectDefaultBillingandShippingIds();
		} else {
			this.showAddressLists = false;
			this.showMainData = true;
		}

		this.resetAddressBookCompnenet.subscribe(response => {
			if (response) {
				if (this.addressBookList.length) {
					this.showAddressLists = true;
					this.showMainData = false;
				} else {
					this.showAddressLists = false;
					this.showMainData = true;
				}
				this.showDeleteItem = false;
			}
		});
		if (localStorage.getItem('addressBookClicked') == 'true') {
			this.showMainData = false;
			this.showAddressLists = false;
			this.showDeleteItem = false;
		}
		if (localStorage.getItem('clickedDeleteItem') == 'true') {
			this.showMainData = false;
			this.showAddressLists = false;
			this.showDeleteItem = true;
			this.deleteThisAddress('');
		}
		if (localStorage.getItem('clickedEditItem') == 'true') {
			this.showMainData = false;
			this.showAddressLists = false;
			this.showDeleteItem = false;
			this.editThisAddress('');
		}
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.addressBookList) {
			this.addressBookList = changes.addressBookList.currentValue;
			if (this.addressBookList.length) {
				this.showAddressLists = true;
				this.showMainData = false;
				this.selectDefaultBillingandShippingIds();
			} else {
				this.showAddressLists = false;
				this.showMainData = true;
			}
		}
		if (changes.countryList) {
			this.countryList = changes.countryList.currentValue;
		}
	}

	getTitles() {
		this.authService.getTitles().subscribe(res => {
			if (res && res.Titles && res.Titles.List) {
				this.titleList = res.Titles.List;

				this.initiateAddressBookForm();
			}
		})
	}

	initialDefaultAddress(data) {
		this.defaultAddressForm = this.fb.group({
			SelectedDefaultBillingAddress: data.billingId,
			SelectedDefaultShippingAddress: data.shippingId
		})
	}
	addAddressBook() {
		this.scrollService.windowScrollTo(0, 0);
		this.initiateAddressBookForm();
		this.showMainData = false;
		this.showAddressLists = false;
		localStorage.setItem('addressBookClicked', 'true');
	}

	cancelPage() {
		document.getElementById('my-account').classList.remove('wrapper');
		document.getElementById('my-account').classList.remove('clearfix');
		document.getElementById('my-account').classList.remove('create');
		document.getElementById('my-account').classList.add('address-book');
		this.scrollService.windowScrollTo(0, 0);
		localStorage.removeItem('clickedEditItem');
		localStorage.removeItem('clickedDeleteItem');
		localStorage.removeItem('deleteAddressId');
		localStorage.removeItem('addressBookClicked');
		localStorage.removeItem('editAddressBook');
		if (this.addressBookList.length) {
			this.showAddressLists = true;
			this.showMainData = false;
		} else {
			this.showAddressLists = false;
			this.showMainData = true;
		}
		this.showDeleteItem = false;

	}
	manualAddressFields() {
		this.showManualAddressForm = true;
	}
	initiateAddressBookForm() {
		this.addressBookForm = this.fb.group({
			description: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
			titleId: [1, [Validators.required]],
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			phoneNumber: ['', [Validators.required,
			Validators.minLength(8),
			Validators.maxLength(15),
			Validators.pattern('[0-9]*')]],
			countryId: [1, [Validators.required]],
			addressOne: ['', [Validators.required]],
			addressTwo: [''],
			townCity: ['', [Validators.required]],
			county: [''],
			searchPostCode: [''],
			postZipCode: ['', [Validators.required]],
			isDefaultBilling: [false],
			isDefaultShipping: [false]
		})
	}
	getPostCodeOnSearch() {
		this.homeService.getPostCode().subscribe(res => {
		})
	}
	saveAddressBookInfo() {
		this.isSave = true;

		if (!this.addressBookForm.valid) {
			this.commonService.validateAllFormFields(this.addressBookForm);
			return;
		}

		let address = this.addressBookForm.value || {};

		let titleName = this.getTitle(address.titleId);

		let requestObj = {
			"FirstName": address.firstName,
			"LastName": address.lastName,
			"PhoneNumber": parseInt(address.phoneNumber),
			"AddressLine1": address.addressOne,
			"AddressLine2": address.addressTwo,
			"TownCity": address.townCity,
			"PostZipCode": address.postZipCode,
			"CountryId": parseInt(address.countryId),
			"Description": address.description,
			"Title": titleName,
			"IsDefaultShipping": address.IsDefaultShipping ? 1 : 0,
			"IsDefaultBilling": address.isDefaultBilling ? 1 : 0,
			"AddressDetailId": this.addressBookId
		}

		if (localStorage.getItem('clickedEditItem') == 'true') {
			this.authService.postAddressBookDetails(requestObj, this.addAddressBook).subscribe(res => {

				if (res.result == "Success") {
					this.scrollService.windowScrollTo(0, 0);

					localStorage.removeItem('clickedEditItem');
					localStorage.removeItem('editAddressBook');
					this.showDeleteItem = false;
					this.showAddressLists = true;
					this.showMainData = false;
					this.refreshParentComponent();
					this.updateClasses();
				}
			})
		} else {
			this.authService.postAddressBookDetails(requestObj, 0).subscribe(res => {
				if (res.result == "Success") {
					this.scrollService.windowScrollTo(0, 0);
					localStorage.removeItem('addressBookClicked');
					this.showDeleteItem = false;
					this.showAddressLists = true;
					this.showMainData = false;
					this.refreshParentComponent();
					this.updateClasses();

				}
			})
		}
	}

	editThisAddress(book) {

		if (!book) {

			book = localStorage.getItem('editAddressBook') ? JSON.parse(localStorage.getItem('editAddressBook')) : ''
			if (!book) {
				return;
			}
		}

		let isCoutryExist = false;
		if (book && book.CountryId && this.loqateSupportedCountries && this.loqateSupportedCountries.length > 0) {
			let id = +book.CountryId;
			this.loqateSupportedCountries.forEach(x => {
				if (x.id === id) {
					isCoutryExist = true;
				}
			});
			if (isCoutryExist) {
				this.loqateEnabled = true;
			} else {
				this.loqateEnabled = false;
				this.showManualAddressForm = true;
			}
		}

		this.updateClasses();
		this.isSave = false;
		this.addressBookId = book.AddressDetailId;

		let titleId = this.titleList.find(x => x.Name == book.Title)?.TitleId;

		if (titleId == null) {
			titleId = 1;
		}

		this.scrollService.windowScrollTo(0, 0);
		localStorage.setItem('clickedEditItem', 'true');
		localStorage.setItem('editAddressBook', JSON.stringify(book));

		this.addressBookForm = this.fb.group({
			description: [book.Description, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
			titleId: [titleId, [Validators.required]],
			firstName: [book.FirstName, [Validators.required]],
			lastName: [book.LastName, [Validators.required]],
			phoneNumber: [book.Phone, [Validators.required,
			Validators.minLength(8),
			Validators.maxLength(15),
			Validators.pattern('[0-9]*')]],
			countryId: [book.CountryId, [Validators.required]],
			addressOne: [book.StreetAddress1, [Validators.required]],
			addressTwo: [book.StreetAddress2],
			townCity: [book.City, [Validators.required]],
			county: [book.County],
			searchPostCode: [''],
			postZipCode: [book.PostCode, [Validators.required]],
			isDefaultBilling: [book.IsDefaultBilling],
			isDefaultShipping: [book.IsDefaultShipping]
		});
		this.showDeleteItem = false;
		this.showAddressLists = false;
		this.showMainData = false;
	}

	deleteThisAddress(book) {
		if (!book) {
			book = localStorage.getItem('deleteAddressBook') ? JSON.parse(localStorage.getItem('deleteAddressBook')) : ''
			if (!book) {
				return;
			}
		}
		this.deletePageUpdateClasses();
		this.deleteAddressdata = book;
		this.scrollService.windowScrollTo(0, 0);
		this.showDeleteItem = true;
		this.showAddressLists = false;
		this.showMainData = false;
		localStorage.setItem('clickedDeleteItem', 'true');
		localStorage.setItem('deleteAddressBook', JSON.stringify(book));
	}
	deleteAddress(addressId) {
		this.authService.deleteAddressBook(addressId).subscribe(res => {
			if (res.result == "Success") {
				this.addressBookList = this.addressBookList ? this.addressBookList.filter(item => item.AddressDetailId != addressId) : [];
				localStorage.removeItem('clickedDeleteItem');
				localStorage.removeItem('deleteAddressBook');
				this.scrollService.windowScrollTo(0, 0);
				if (this.addressBookList.length) {
					this.showAddressLists = true;
					this.showMainData = false;
				} else {
					this.showAddressLists = false;
					this.showMainData = true;
				}
				this.showDeleteItem = false;
			}
		}, err => console.log(err))

	}
	saveDefaultAddress() {

		this.defaultAddressForm.setValue({
			SelectedDefaultBillingAddress: this.selectedBillingId,
			SelectedDefaultShippingAddress: this.selectedShippingId
		});

		this.authService.saveDefaultAddressForShippingAndBilling(this.defaultAddressForm.value).subscribe(res => {
			this.isAddressSaved = true;
			this.scrollService.windowScrollTo(0, 0);
		})
	}

	getTitle(id) {
		if (this.titleList) {
			return this.titleList.find(x => x.TitleId == id)?.Name;
		} else {
			return null;
		}
	}

	refreshParentComponent() {
		this.refreshParent.emit('true');
	}

	selectDefaultBillingandShippingIds() {
		let data = {
			shippingId: 0,
			billingId: 0
		}
		this.addressBookList.filter(address => {
			if (address.IsDefaultBilling) {
				data.billingId = address.AddressDetailId;
				this.selectedBillingId = address.AddressDetailId;
			}
			if (address.IsDefaultShipping) {
				data.shippingId = address.AddressDetailId;
				this.selectedShippingId = address.AddressDetailId;
			}
		});
		this.initialDefaultAddress(data);
	}

	updateBillingAddress(billingId) {
		this.selectedBillingId = billingId;
	}
	updateShippingAddress(shippingId) {
		this.selectedShippingId = shippingId;
	}
	updateClasses() {
		document.getElementById('my-account').classList.add('wrapper');
		document.getElementById('my-account').classList.add('clearfix');
		document.getElementById('my-account').classList.remove('address-book');
	}
	deletePageUpdateClasses() {
		document.getElementById('my-account').classList.add('wrapper');
		document.getElementById('my-account').classList.add('clearfix');
		document.getElementById('my-account').classList.add('create');
	}
	changeDefault() {
	}
	getAddressFromPostcodeAnywhere(value, Id) {

		var Key = "",
			IsMiddleware = false,
			Origin = "",
			Countries = "GBR",
			Limit = "10",
			Language = "en-gb";

		Key = this.PostCodeAnywhereSecurityKey;
		if (this.LpAppSetting && this.LpAppSetting.CurrentCulture) {
			let culture = this.LpAppSetting.CurrentCulture;
			if (this.loqateEnabled && this.countryLanguage) {
				culture = this.countryLanguage;
			}
			Language = culture.toLocaleLowerCase();
			let countryCode = culture.split('-');
			if (countryCode && countryCode.length > 1) {
				Countries = countryCode[1];
			}

		}
		var params = '';
		params += "?Key=" + encodeURIComponent(Key);
		params += "&Text=" + encodeURIComponent(value);
		params += "&IsMiddleware=" + encodeURIComponent(IsMiddleware);
		if (Id) {
			params += "&Container=" + encodeURIComponent(Id);
		} else {
			params += "&Container=" + encodeURIComponent('');
		}

		params += "&Origin=" + encodeURIComponent(Origin);
		params += "&Countries=" + encodeURIComponent(Countries);
		params += "&Limit=" + encodeURIComponent(Limit);
		params += "&Language=" + encodeURIComponent(Language);

		this.sharedService.addressCapture(params).subscribe(result => {
			if (result && result.Items && result.Items.length > 0) {

				this.addressList = result.Items;
			}
		});
	}
	getLpSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
		if (this.LpAppSetting && this.LpAppSetting.PostCodeAnywhereSecurityKey) {
			this.PostCodeAnywhereSecurityKey = this.LpAppSetting.PostCodeAnywhereSecurityKey;

		}

	}
	getAddressDescription(adrs) {
		try {
			if (adrs && adrs.Description) {

				if (adrs.Description.indexOf('Addresses') > -1) {
					let res = adrs.Description.split("-");
					if (res && res.length > 1) {
						adrs.Results = res[1].replace('Addresses', 'Results');
						adrs.Description = res[0];
					}
				}
			}
			return adrs.Description;
		}
		catch (ex) {

		}
	}

	onSelect(result) {
		if (result) {
			if (result.Type !== 'Address') {
				this.getAddressFromPostcodeAnywhere(result.Text, result.Id)
			} else if (result.Type == 'Address') {
				this.retrieveAddress(result.Id);
			}
		}
	}
	retrieveAddress(Id) {

		var Key = "";
		var Field1Format = "";
		var params = '';
		Key = this.PostCodeAnywhereSecurityKey;
		params += "?Key=" + encodeURIComponent(Key);
		params += "&Id=" + encodeURIComponent(Id);
		params += "&Field1Format=" + encodeURIComponent(Field1Format);
		this.sharedService.retrieveAddress(params).subscribe(result => {

			if (result && result.Items && result.Items.length > 0) {

				let postcodeAddress = result.Items[0];
				if (postcodeAddress) {

					if (postcodeAddress.Line1) {
						this.addressBookForm.controls['addressOne'].setValue(postcodeAddress.Line1);
					}
					if (postcodeAddress.Line2) {
						this.addressBookForm.controls['addressTwo'].setValue(postcodeAddress.Line2);
					}
					if (postcodeAddress.City) {
						this.addressBookForm.controls['townCity'].setValue(postcodeAddress.City);
					}
					if (postcodeAddress.ProvinceName) {
						this.addressBookForm.controls['county'].setValue(postcodeAddress.ProvinceName);
					}

					if (postcodeAddress.PostalCode) {
						this.addressBookForm.controls['postZipCode'].setValue(postcodeAddress.PostalCode);
					}
					this.addressBookForm.controls['searchPostCode'].setValue('');

					this.addressList = null;
					this.showManualAddressForm = true;
				}
			}
		});
	}

	onCountryChanged(countryId) {

		let isCoutryExist = false;
		if (countryId && this.loqateSupportedCountries && this.loqateSupportedCountries.length > 0) {
			let id = +countryId;
			this.loqateSupportedCountries.forEach(x => {
				if (x.id === id) {
					isCoutryExist = true;
				}
			});
			if (isCoutryExist) {
				this.loqateEnabled = true;
				if (this.countryList && this.countryList.length > 0) {
					var country = this.countryList.filter(x => x.CountryId === id);
					if (country && country.length > 0) {
						this.countryLanguage = country[0].Code;
					}
				}

			} else {
				this.loqateEnabled = false;

				this.showManualAddressForm = true;
			}
		}
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}
}
