import { Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PromoStripBannerVM } from 'src/app/model/web-page-viewmodel';

@Component({
	selector: 'promo-strip-banner,[promo-strip-banner]',
	templateUrl: './promo-strip-banner.component.html',
	styleUrls: ['./promo-strip-banner.component.scss'],
})
export class PromoStripBannerComponent {
	@Input() promoStripBannerVM: PromoStripBannerVM;

	constructor(private sanitizer: DomSanitizer) {}

	getContent(content: string): SafeHtml | null {
		if (content) {
			return this.sanitizer.bypassSecurityTrustHtml(content);
		}
		return null;
	}
}
