import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AlgoliaSearchSettings } from 'src/app/shared/models/algolia-search-settings';
import { EnvironmentService } from '../../../environment.service';

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	private _searchClicked = new Subject<any>();

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	private searchSource = new BehaviorSubject<any>('');
	currentSearchValue = this.searchSource.asObservable();

	private openSearchModalSource = new Subject<boolean>();
	openSearchModal$ = this.openSearchModalSource.asObservable();

	private algoliaInitialisedSource = new Subject<boolean>();
	algoliaInitialised$ = this.algoliaInitialisedSource.asObservable();

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	searchDialogSelected(event) {
		this._searchClicked.next(event);
	}

	get searchDialogSelectEvents$() {
		return this._searchClicked.asObservable();
	}

	newSearchValue(message: any) {
		this.searchSource.next(message);
	}

	searchdataFromAlogolia() {
		return
	}

	openSearchModal() {
		this.openSearchModalSource.next(true);
	}

	algoliaInitialised() {
		this.algoliaInitialisedSource.next(true);
	}

	algoliaSettetings(): Observable<AlgoliaSearchSettings> {
		return this.httpClient.get<any>(this.environmentService.environment.siteUrl + 'AlgoliaSearchDetails', this.httpOptions);
	}
}
