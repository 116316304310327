import { Component, Input, OnInit } from '@angular/core';
import { PriceAndStockKeyValues } from '../model/product-list';
import { GetProductBadgeImage } from 'src/app/shared/helper/utilities';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductBadgeImage } from '../../product-details/models/product-detail';

@Component({
  selector: 'product-icon-h-c',
  templateUrl: './product-icon-h-c.component.html',
  styleUrls: ['./product-icon-h-c.component.scss']
})
export class ProductIconHCComponent implements OnInit {
  @Input() PriceAndStockKeyValues: PriceAndStockKeyValues
  @Input() WebstoreId: number;
  @Input() ISOnSale: boolean;
  @Input() QuickViewEnabled: boolean;
  @Input() OfferTokenCookie: string;

  private promoToImage: { [key: string]: string; } = {};

  promoIconFilename: string = '';
  promoIconTitle: string = '';
  ShowBadge: boolean = false;
  badgeImage: ProductBadgeImage = null;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.Initialze();
  }

  Initialze() {
    this.badgeImage = GetProductBadgeImage(this.sanitizer, this.PriceAndStockKeyValues?.Icons);

    // Populate icon images
    if (this.PriceAndStockKeyValues && this.PriceAndStockKeyValues.Promotion) {
      if (this.WebstoreId === 1) {

        this.promoToImage["2 for £13"] = "uk_2for13_navy.png";
        this.promoToImage["2 for £16"] = "uk_2for16_navy.png";
        this.promoToImage["2 for £24"] = "uk_2for24_navy.png";
        this.promoToImage["2 for £49"] = "uk_2for49_navy.png";
        this.promoToImage["2 for £50"] = "uk_roundel_2for50_20200424110456.png";
        this.promoToImage["2 from £50"] = "multibuy.png";
        this.promoToImage["2 for £55"] = "2for55pounds.png";
        this.promoToImage["2 for £60"] = "uk_roundel_2for60_20200424110457.png";
        this.promoToImage["2 for £70"] = "2for70pounds.png";
        this.promoToImage["2 for £80"] = "uk_2for80_navy.png";

        this.promoToImage["3 for £12"] = "uk_3for12_navy.png";
        this.promoToImage["3 shirts for £50"] = "3for50pounds.png";
        this.promoToImage["3 for £60"] = "3for60pounds.png";
        this.promoToImage["3 for £69"] = "mixandmatch.png";
        this.promoToImage["3 for £75"] = "mixandmatch.png";
        this.promoToImage["3 from £75"] = "multibuy.png";
        this.promoToImage["3 for £90"] = "multy_buy.png";

        this.promoToImage["4 for £80"] = "4for80pounds.png";
        this.promoToImage["4 for £75"] = "4for75pounds.png";
        this.promoToImage["4 for £99"] = "uk_roundel_4for99_20200424110457.png";
        this.promoToImage["4 for £100"] = "4for100pounds.png";
        this.promoToImage["4 for £110"] = "uk_4from110_navy.png";
        this.promoToImage["4 from £110"] = "multibuy.png";
        this.promoToImage["4 for £129"] = "uk_4for129_navy.png";

        this.promoToImage["5 for £100"] = "5for100pounds.png";

        this.promoToImage["Save £10"] = "multy_buy.png";
        this.promoToImage["Save £20"] = "uk_save_20_navy.png";
        this.promoToImage["Save £25"] = "uk_save_25_red.png";
        this.promoToImage["Save £30"] = "uk_save_30_navy.png";
        this.promoToImage["Save £70"] = "uk_save_70_navy.png";
        this.promoToImage["Save £100"] = "uk_save_100_navy.png";

        this.promoToImage["Mix&Match"] = "shirt_matcher_20200114160133.png";
        this.promoToImage["Sale"] = "sale.png";
      }
      else if (this.WebstoreId === 4) {
        this.promoToImage["2 for $79"] = "usa_2for79_navy.png";
        this.promoToImage["2 for $80"] = "usa_roundel_2for80.png";
        this.promoToImage["2 from $89"] = "multibuy.png";
        this.promoToImage["2 for $100"] = "usa_roundel_2for100.png";
        this.promoToImage["2 for $120"] = "2for120dollars.png";
        this.promoToImage["2 for $129"] = "aus_roundel_2for129.png";
        this.promoToImage["2 for $139"] = "usa_2for139_navy.png";

        this.promoToImage["3 for $21"] = "usa_3for21_navy.png";
        this.promoToImage["3 for $111"] = "mixandmatch.png";
        this.promoToImage["3 for $129"] = "usa_roundel_3for129.png";
        this.promoToImage["3 for $120"] = "mixandmatch.png";
        this.promoToImage["3 from $159"] = "multibuy.png";

        this.promoToImage["4 for $165"] = "4for165dollars.png";
        this.promoToImage["4 for $195"] = "4for195dollars.png";

        this.promoToImage["Save $39"] = "usa_save_39_red.png";

        this.promoToImage["Sale"] = "sale.png";
      }
      else if (this.WebstoreId === 5) {
        this.promoToImage["2 for $89"] = "aus_2for89_navy.png";
        this.promoToImage["2 for $99"] = "aus_roundel_2for99.png";
        this.promoToImage["2 from $100"] = "aus_2from100_navy.png";
        this.promoToImage["2 for $119"] = "aus_roundel_2for119.png";
        this.promoToImage["2 for $129"] = "aus_roundel_2for129.png";
        this.promoToImage["2 for $140"] = "aus_2for140_navy.png";
        this.promoToImage["2 for $159"] = "aus_2for159_navy.png";

        this.promoToImage["3 for $21"] = "aus_3for21_navy.png";
        this.promoToImage["3 for $144"] = "mixandmatch.png";
        this.promoToImage["3 for $150"] = "mixandmatch.png";
        this.promoToImage["3 for $159"] = "aus_roundel_3for159.png";
        this.promoToImage["3 from $180"] = "multibuy.png";

        this.promoToImage["Save $49"] = "aus_save_49_red.png";

        this.promoToImage["Sale"] = "sale.png";
      }

      if (this.WebstoreId !== 2) {
        // English speaking icons
        this.promoToImage["3 for €99"] = "3for99_germany_multibuy_20200408160459.png";

        this.promoToImage["20% Off"] = "20_percent_off_green.png";
        this.promoToImage["25% Off"] = "25_percent_off_red.png";
        this.promoToImage["30% Off"] = "30_percent_off_navy.png";
        this.promoToImage["34% Off"] = "34_percent_off_red.png";
        this.promoToImage["35% Off"] = "35_percent_off_red.png";
        this.promoToImage["39% Off"] = "39_percent_off_red.png";
        this.promoToImage["40% Off"] = "40_percent_off_red.png";
        this.promoToImage["43% Off"] = "43_percent_off_red.png";
        this.promoToImage["47% Off"] = "47_percent_off_red.png";
        this.promoToImage["48% Off"] = "48_percent_off_red.png";
        this.promoToImage["50% Off"] = "50_percent_off_red.png";
        this.promoToImage["54% Off"] = "54_percent_off_red.png";
        this.promoToImage["57% Off"] = "57_percent_off_red.png";
        this.promoToImage["59% Off"] = "59_percent_off_red.png";
        this.promoToImage["60% Off"] = "60_percent_off_red.png";
        this.promoToImage["63% Off"] = "63_percent_off_red.png";
        this.promoToImage["65% Off"] = "65_percent_off_red.png";
        this.promoToImage["66% Off"] = "66_percent_off_red.png";
        this.promoToImage["67% Off"] = "67_percent_off_red.png";
        this.promoToImage["68% Off"] = "68_percent_off_red.png";

        this.promoToImage["Up to 50% Off"] = "roundel_up_to_50_percent_off.png";
        this.promoToImage["Up to 60% Off"] = "roundel_up_to_60_percent_off.png";

        if (this.PriceAndStockKeyValues.Promotion.toLowerCase().indexOf("included in mix & match") > -1 || this.PriceAndStockKeyValues.Promotion.toLowerCase().indexOf("included in mix &amp; match") > -1) {
          this.promoToImage[this.PriceAndStockKeyValues.Promotion] = "mixandmatch.png";
        }
      }

      this.promoToImage["Extra 20% Off"] = "extra20percent.png";
      this.promoToImage["20% applied at checkout"] = "extra20percent.png";
      this.promoToImage["20% Extra Rabatt"] = "extra20percent-de.png";
      this.promoToImage["20% Rabatt"] = "20percentoff-de.png";
    }

    // TO BE REMOVED
    // Set Promo Icon (this needs to be removed as its Hardcoded url)
    if (this.PriceAndStockKeyValues && this.PriceAndStockKeyValues.Promotion && this.promoToImage[this.PriceAndStockKeyValues.Promotion]) {
      this.promoIconFilename = "https://handcmediastorage.blob.core.windows.net/etc/badges/" + this.promoToImage[this.PriceAndStockKeyValues.Promotion];
      this.promoIconTitle = this.PriceAndStockKeyValues.Promotion;
    }

    else if (this.WebstoreId !== 2 && this.PriceAndStockKeyValues && Math.round((this.PriceAndStockKeyValues.RetailPrice - this.PriceAndStockKeyValues.SalePrice) / this.PriceAndStockKeyValues.RetailPrice) === 0.2) {
      this.promoIconFilename = "https://handcmediastorage.blob.core.windows.net/etc/badges/20percentoff-green.png";
      this.promoIconTitle = "20% off";
    }

    if (this.PriceAndStockKeyValues) {
      // Set Sale Percentage if override Badge is present or session has Offer Token (TEMPORARY FOR HATICE 20210713)
      if ((this.PriceAndStockKeyValues.BadgeText == "SALE-OVERRIDE" || this.OfferTokenCookie) && this.PriceAndStockKeyValues.SalePrice && this.PriceAndStockKeyValues.SalePrice < this.PriceAndStockKeyValues.RetailPrice) {
        var salePercentage = Math.round((this.PriceAndStockKeyValues.RetailPrice - this.PriceAndStockKeyValues.SalePrice) / this.PriceAndStockKeyValues.RetailPrice * 100);

        if (this.WebstoreId === 2) {
          this.PriceAndStockKeyValues.BadgeText = salePercentage + "% Rabatt";
        } else {
          this.PriceAndStockKeyValues.BadgeText = salePercentage + "% off";
        }

        this.ShowBadge = true;
        this.PriceAndStockKeyValues.BadgeTextColour = "#000000";
      }
    }
  }
}