import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { CartService } from 'src/app/shared/service/cart.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AddTrackingScriptToDOM } from '../../../../../assets/shared/tracking';
import { AuthService } from '../../auth.service';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
	@Output() createFormClick = new EventEmitter<string>();
	@Output() forgotFormClick = new EventEmitter<string>();
	incorrectEmailAndPassword: boolean = false;
	shoppingBasket: ShoppingBasket;
	signInForm: UntypedFormGroup;
	sitecontent: SiteContentDetail;
	submitted: boolean = false;
	LpAppSetting: LpAppSetting;
	scriptContent = [];
	billingAddress = {
		addressOne: '',
		addressTwo: '',
		city: '',
		county: '',
		postCode: ''
	};

	shippingAddress = {
		addressOne: '',
		addressTwo: '',
		city: '',
		county: '',
		postCode: ''
	};

	userDetails = {
		customerId: 0,
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		sessionId: 0,
		titleId: 1,
	}
	facetFilterState: IFacetFilterState;
	facetFilterSubscriber: Subscription;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;


	constructor(
		private fb: UntypedFormBuilder,
		private authService: AuthService,
		private router: Router,
		private sharedService: SharedService,
		private cartService: CartService,
		private siteContentService: SiteContentService,
		private titleService: Title,
		private attraqtService: AttraqtService) { }

	ngOnInit() {
		this.sharedService.menuItemSelected(window.location.pathname);
		this.getLpAppSetting();
		this.sitecontent = this.siteContentService.getSiteContentData();
		localStorage.removeItem("isSignedIn");
		localStorage.removeItem("selectIndex");
		this.getTrackingScript();
		this.titleService.setTitle(this.sitecontent.MyAccount);
		this.subscribeToState();
	}
	ngOnDestroy(): void {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
	}
	get f() { return this.signInForm.controls; }
	initiateForm() {
		this.signInForm = this.fb.group({
			emailAddress: ['', [Validators.required, Validators.email, Validators.pattern(this.LpAppSetting.RegexLib.Email)]],
			password: ["", [Validators.required]]
		})
	}

	submitSignInForm() {

		this.submitted = true;
		if (this.signInForm.invalid) {
			return;
		}
		this.authService.postLogin(this.signInForm.value).subscribe(res => {

			if (!res.WebStoreId) {
				this.incorrectEmailAndPassword = true;
				this.signInForm.controls['password'].setValue('');
				this.submitted = false;

			} else {
				localStorage.clear();
				localStorage.setItem("isSignedIn", "true");
				this.getLpAppSetting();

				this.sharedService.changeSigninInfo("Hello from Second Component");

				this.userDetails.customerId = res.CustomerId;
				this.userDetails.firstName = res.FirstName;
				this.userDetails.lastName = res.LastName;
				this.userDetails.email = res.Email;
				this.userDetails.phoneNumber = res.ShippingAddress && res.ShippingAddress.Phone ? res.ShippingAddress.Phone : '';
				this.userDetails.sessionId = res.SessionId;

				if (res.TitleId) {
					this.userDetails.titleId = res.TitleId;
				}

				if (res.BillingAddress) {
					this.billingAddress.addressOne = res.BillingAddress.StreetAddress1;
					this.billingAddress.addressTwo = res.BillingAddress.StreetAddress2;
					this.billingAddress.city = res.BillingAddress.City;
					this.billingAddress.postCode = res.BillingAddress.PostCode;
					this.billingAddress.county = res.BillingAddress.County;
				}
				if (res.ShippingAddress) {
					this.shippingAddress.addressOne = res.ShippingAddress.StreetAddress1;
					this.shippingAddress.addressTwo = res.ShippingAddress.StreetAddress2;
					this.shippingAddress.city = res.ShippingAddress.City;
					this.shippingAddress.postCode = res.ShippingAddress.PostCode;
					this.shippingAddress.county = res.ShippingAddress.County;
				}
				if (res.CustomerId && this.facetFilterState.attraqtPLPEnabled) {
					this.attraqtService.setLoggedUser(res.CustomerId);
				}
				this.updateCartItemsFromDB();
				localStorage.setItem('billingDefault', JSON.stringify(this.billingAddress ? this.billingAddress : 'false'));
				localStorage.setItem('shippingDefault', JSON.stringify(this.shippingAddress ? this.shippingAddress : 'false'))

				localStorage.setItem('userData', JSON.stringify(this.userDetails));

				this.router.navigateByUrl('details', { skipLocationChange: true }).then(() => {
					this.router.navigate(['account/myaccount']);
				});

			}
		}), err => {
			this.incorrectEmailAndPassword = true;

		}
	}
	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.sharedService.setWebStoreLanguage();
		this.initiateForm();
	}
	private updateCartItemsFromDB() {
		this.shoppingBasket = {} as ShoppingBasket;

		this.cartService.getShoppingCart(this.shoppingBasket).subscribe(data => {
			if (data && data.desc) {
				this.cartService.refreshLocalBasket(data.desc, false);


			}

		});
	}
	createForm() {
		this.createFormClick.emit("clicked");
	}
	clickForgotPassword() {
		this.forgotFormClick.emit("forgotClicked")
	}
	removeIncorrectMessage() {
		this.incorrectEmailAndPassword = false;
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}

	getTrackingScript() {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = PageTypeDimensionEnum.None;
		//let signInPageViewModel = {} as AccountSignInPage;
		//trackingVm.SignInPageViewModel = signInPageViewModel;

		this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
			this.scriptContent = this.sharedService.ParseScript(data);
			if (this.scriptContent && this.scriptContent.length > 0) {
				setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			}
		}, err => {

		});
	}
	
	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
