import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID, SecurityContext, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import * as $ from 'jquery';
import { Select } from '@ngxs/store';
import { AddBasket, CustomSize } from 'src/app/model/basket-viewmodel';
import { Basket } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { MatchproductmodelComponent } from 'src/app/shared/components/matchproductmodel/matchproductmodel.component';
import { SizeGuideComponent } from 'src/app/shared/components/size-guide/size-guide.component';
import { AddToCartData, CartModel } from 'src/app/shared/models/cart-model';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { CartService } from 'src/app/shared/service/cart.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { PrimeAISettings } from '../../../../assets/shared/helper_scripts.js';
import { SizeChartContent } from '../../../model/size-chart-content';
import { DefaultService } from '../../layout/default.service';
import { ProductListForView } from '../../product-list/model/product-list';
import { CustomSizeConfirmationComponent } from '../custom-size-confirmation/custom-size-confirmation.component';
import { Matchproductmodel, MixAndMatchImageUrl } from '../models/matchproduct-model';
import { InventoryProduct, ProductAtrribute, ProductDetails, SizeValue } from '../models/product-detail';
import { ProductRegularSize } from '../models/product-regular-size';
import { ProductSize, SizeVM } from '../models/sizing-vm';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';

@Component({
	selector: 'sizeselector,[sizeselector]',
	templateUrl: './sizeselector-handc.component.html',
	styleUrls: ['./sizeselector-handc.component.scss']
})
export class SizeselectorHandcComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

	private _productdetail: ProductDetails;

	@Input() get productdetail(): ProductDetails {
		return this._productdetail;
	}

	set productdetail(value: ProductDetails) {
		this._productdetail = value;
	}

	@Input() ignoreMatchedProduct: boolean;

	@Output() reviewEvent = new EventEmitter();

	@Output() addToCartEvent = new EventEmitter();

	showReviewModal = false;
	cartModel: CartModel;
	addToCartData: AddToCartData;

	availableQuantity = 0;
	isSingleProductAdded = false;
	isProductAdded = false;
	items = [];
	inventoryProduct: InventoryProduct[];
	productAttribute: ProductAtrribute[] = [];
	colorSize: SizeValue[];
	sleeveLength: SizeValue[];
	selectedColorSize: number;
	selectedSleeveLength: number;
	LpAppSetting: LpAppSetting;
	SizeTypeName1: string;
	SizeTypeName2: string;
	productData: ProductListForView;
	addBasket: AddBasket;
	basket: Basket;
	shoppingBasket: ShoppingBasket;

	jsEnabled: boolean = true;
	isSubmitted: boolean = false;
	inventoryProductId: number = 0;
	quantity: number = 1;
	maxQuantity: number = 0;
	sizeSelected: boolean = false;
	addingToBag: boolean = false;
	addedToBag: boolean = false;
	regularSizes: ProductRegularSize[];
	customSizing: any[] = [];
	ignoreRequiredCustomSizing = false;
	regularSizeForSelectedCollarSize: string = "";
	bundleProductIds: number[] = [];
	stock: any[] = [];
	productSize: ProductSize;
	sizing: any;

	prodSizing: any;
	selectedSize: any;
	addFailed: boolean = false;
	siteContent: SiteContentDetail;
	matchproductmodel: Matchproductmodel;
	ProductMatchedUrls: MixAndMatchImageUrl;
	matDialogRef2: MatDialogRef<MatchproductmodelComponent>;

	matDialogRef: MatDialogRef<SizeGuideComponent>;
	matDialogConfirmationRef: MatDialogRef<CustomSizeConfirmationComponent>;
	isPrimeAiScrictAdded: boolean = false;
	showSizeGuide: boolean = false;
	sizeGuideData: any;
	currentSizeChartKey: string;
	selectedVal = "";
	selectedFilter = "";
	version = 2;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private route: ActivatedRoute,
		private matDialog: MatDialog,
		private _location: Location,
		private router: Router,
		private defaultService: DefaultService,
		private cartService: CartService,
		private sharedService: SharedService,
		public dialog: MatDialog,
		private sanitizer: DomSanitizer,
		private siteContentService: SiteContentService,
		private attraqtService: AttraqtService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	ngOnInit() {
		this.subscribeToState();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.getLpAppSetting();
	}

	ngOnDestroy() {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges) {

		for (let propName in changes) {
			if (propName === "productdetail") {

				var proDetail = changes[propName];

				if (proDetail && proDetail.currentValue) {
					if (proDetail.previousValue) {
						if (proDetail.currentValue.Product && proDetail.currentValue.Product.Code && proDetail.previousValue.Product && proDetail.previousValue.Product.Code) {
							if (proDetail.currentValue.Product.Code !== proDetail.previousValue.Product.Code) {
								this.initialise();
							}
						}
					} else {
						this.initialise();
					}
				}

				this.productdetail.Product.Code
			}
		}
	}

	ngAfterViewInit() {

		if (!this.isPrimeAiScrictAdded && this.LpAppSetting && this.LpAppSetting.PrimeAI_Enable) {
			if (this._productdetail && (!this._productdetail.BundleProductItems || this._productdetail.BundleProductItems.length === 0)) {

				this.isPrimeAiScrictAdded = true;
				PrimeAISettings(null, this._productdetail.Product.Code);
			}
		}
	}

	//new functionalities start
	initialise() {

		if (isPlatformBrowser(this.platformId)) {

			this.sizeSelected = false;
			this.addedToBag = false;
			this.maxQuantity = 0;

			if (this.productdetail && this.productdetail.Product && !this.ignoreMatchedProduct) {
				this.GetMatchedProductImageUrl(this.productdetail.Product.ProductId)
			}

			if (this.productdetail) {
				if (this.productdetail.BundleProductItems && this.productdetail.IsBundledItem) {

					this.bundleProductIds = [];
					this.productdetail.BundleProductItems.forEach(x => {
						this.bundleProductIds.push(x.ProductId);
					});

					localStorage.setItem("bundleProductIds", JSON.stringify(this.bundleProductIds));
				} else {
					localStorage.setItem("bundleProductIds", null);
				}

				this.cartService.GetProductSizeInformation(this.productdetail.Product.Code)
					.subscribe(response => {

						// GetProductSizeInformation returns back stock information so no need to use GetProductStock
						this.stock = [];
						for (var i = 0; i <= response.Stock.length - 1; i++) {
							this.stock[response.Stock[i].Id] = response.Stock[i];
						}

						this.getProductSizesCallback(response);
					});

				this.cartService.GetRegularSizes(this.productdetail.Product.Code)
					.subscribe(response => {
						if (response && response.regularSizes) {
							this.regularSizes = response.regularSizes;
						}
					});
			}
		}
	}

	getProductSizesCallback(response) {

		// ***********************************************************************
		// *** BEGIN - SPECIAL OVERRIDE FOR Suit Jacket SIZE (SizeTypeId == 6) ***
		// ***********************************************************************
		if (response && response.Sizing && response.Sizing.length > 0 && response.Sizing[0].SizeTypeId == 6) {

			var origSizingValues = response.Sizing[0].Values;

			var shortSizes = [];
			var regularSizes = [];
			var longSizes = [];

			var sizeMapper = {};

			origSizingValues.forEach(origSize => {
				if (origSize.ValueName.includes("short ")) {
					shortSizes.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("short ", "");
				} else if (origSize.ValueName.includes("long ")) {
					longSizes.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("long ", "");
				} else {
					regularSizes.push(origSize.ValueId);
				}

				if (!sizeMapper[origSize.ValueName]) {
					sizeMapper[origSize.ValueName] = [];
				}

				sizeMapper[origSize.ValueName].push(origSize);
			})

			// Create the new Sizes combined with Short, Regular and Long
			var sizing = [];
			let firstSizing = {} as SizeVM;

			firstSizing.SizeTypeId = 1;
			firstSizing.SizeTypeName = "Jacket Size";
			firstSizing.Values = [];

			// Loop through Properties/Attributes
			Object.values(sizeMapper).forEach(mapping => {
				firstSizing.Values.push(mapping[0]);
			});

			sizing.push(firstSizing);

			let secondSizing = {} as SizeVM;
			secondSizing.SizeTypeId = 2;
			secondSizing.SizeTypeName = "Length";
			secondSizing.Values = [];

			if (shortSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1000, ValueName: "Short" });
			}
			if (regularSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1001, ValueName: "Regular" });
			}
			if (longSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1002, ValueName: "Long" });
			}

			sizing.push(secondSizing);

			// Amend Response Data
			response.Sizing = sizing;

			// Amend ProductSizing Response Data and add new Short, Regular and Long ValueIds
			this.productSize = {} as ProductSize;

			response.ProductSizing.forEach(productsize => {
				this.productSize = productsize;
				let psize = productsize;

				if (shortSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1000);
				}

				if (regularSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1001);
				}

				if (longSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1002);
				}

				let isFirst = true;

				// Switch the Jacket Size with the SizeMapper one
				$.each(sizeMapper, function (idx, mapping: any) {

					var matches = mapping.filter(function (sizeValue) {
						return sizeValue.ValueId === psize.SizeValueIds[0];
					});

					if (matches.length > 0) {
						if (!productsize || isFirst) {
							productsize = psize;
							isFirst = false;
						}

						productsize.SizeValueIds[0] = mapping[0].ValueId;
						return;
					}
				});
			});
		}
		// *********************************************************************
		// *** END - SPECIAL OVERRIDE FOR Suit Jacket SIZE (SizeTypeId == 6) ***
		// *********************************************************************

		// ***************************************************************************
		// *** BEGIN - SPECIAL OVERRIDE FOR Trouser SIZE (SizeTypeId == 14) AND No CustomSizing ***
		// ***************************************************************************
		if (response && response.Sizing && response.Sizing.length > 0 && response.Sizing[0].SizeTypeId == 14 && response.CustomSizing.length == 0) {

			var origSizingValues = response.Sizing[0].Values;

			var shortSizes = [];
			var regularSizes = [];
			var longSizes = [];

			var sizeMapper = {};

			origSizingValues.forEach(origSize => {
				if (origSize.ValueName.includes("short ")) {
					shortSizes.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("short ", "");
				} else if (origSize.ValueName.includes("long ")) {
					longSizes.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("long ", "");
				} else {
					regularSizes.push(origSize.ValueId);
				}

				if (!sizeMapper[origSize.ValueName]) {
					sizeMapper[origSize.ValueName] = [];
				}

				sizeMapper[origSize.ValueName].push(origSize);
			})

			// Create the new Sizes combined with Short, Regular and Long
			var sizing = [];
			let firstSizing = {} as SizeVM;

			firstSizing.SizeTypeId = 1;
			firstSizing.SizeTypeName = "Waist Size";
			firstSizing.Values = [];

			// Loop through Properties/Attributes
			Object.values(sizeMapper).forEach(mapping => {
				firstSizing.Values.push(mapping[0]);
			});

			sizing.push(firstSizing);

			let secondSizing = {} as SizeVM;
			secondSizing.SizeTypeId = 2;
			secondSizing.SizeTypeName = "Finished Length";
			secondSizing.Values = [];

			if (shortSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1000, ValueName: "Short" });
			}
			if (regularSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1001, ValueName: "Regular" });
			}
			if (longSizes.length > 0) {
				secondSizing.Values.push({ ValueId: 1002, ValueName: "Long" });
			}

			sizing.push(secondSizing);

			// Amend Response Data
			response.Sizing = sizing;

			// Amend ProductSizing Response Data and add new Short, Regular and Long ValueIds
			this.productSize = {} as ProductSize;

			response.ProductSizing.forEach(productsize => {
				this.productSize = productsize;
				let psize = productsize;

				if (shortSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1000);
				}

				if (regularSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1001);
				}

				if (longSizes.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1002);
				}

				let isFirst = true;

				// Switch the Jacket Size with the SizeMapper one
				$.each(sizeMapper, function (idx, mapping: any) {

					var matches = mapping.filter(function (sizeValue) {
						return sizeValue.ValueId === psize.SizeValueIds[0];
					});

					if (matches.length > 0) {
						if (!productsize || isFirst) {
							productsize = psize;
							isFirst = false;
						}

						productsize.SizeValueIds[0] = mapping[0].ValueId;
						return;
					}
				});
			});
		}
		// ******************************************************************
		// *** END - SPECIAL OVERRIDE FOR Trouser SIZE (SizeTypeId == 14) AND No CustomSizing) ***
		// ******************************************************************

		// ***************************************************************************
		// *** BEGIN - SPECIAL OVERRIDE FOR Trouser Hemmed SIZE (SizeTypeId == 16) ***
		// ***************************************************************************
		if (response && response.Sizing && response.Sizing.length > 0 && response.Sizing[0].SizeTypeId == 16) {

			// AR 20240514: In order for CustomSizes to match correctly for these sizes, the -UNHEM must be at the top of the list in response.Sizing
			// This can be done by assigning the correct SizeValueOrder

			var origSizingValues = response.Sizing[0].Values;

			var size28s = [];
			var size29s = [];
			var size30s = [];
			var size31s = [];
			var size32s = [];
			var size33s = [];
			var size34s = [];
			var size35s = [];
			var sizeUnHems = [];

			var sizeMapper = {};

			origSizingValues.forEach(origSize => {
				if (origSize.ValueName.includes("-28\"")) {
					size28s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-28\"", "") + "\"";
				} else if (origSize.ValueName.includes("-29\"")) {
					size29s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-29\"", "") + "\"";
				} else if (origSize.ValueName.includes("-30\"")) {
					size30s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-30\"", "") + "\"";
				} else if (origSize.ValueName.includes("-31\"")) {
					size31s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-31\"", "") + "\"";
				} else if (origSize.ValueName.includes("-32\"")) {
					size32s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-32\"", "") + "\"";
				} else if (origSize.ValueName.includes("-33\"")) {
					size33s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-33\"", "") + "\"";
				} else if (origSize.ValueName.includes("-34\"")) {
					size34s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-34\"", "") + "\"";
				} else if (origSize.ValueName.includes("-35\"")) {
					size35s.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-35\"", "") + "\"";
				} else if (origSize.ValueName.includes("-UNHEM")) {
					sizeUnHems.push(origSize.ValueId);
					origSize.ValueName = origSize.ValueName.replace("-UNHEM", "") + "\"";
				}

				if (!sizeMapper[origSize.ValueName]) {
					sizeMapper[origSize.ValueName] = [];
				}

				sizeMapper[origSize.ValueName].push(origSize);
			})

			// Create the new Sizes combined with Finished Lengths
			var sizing = [];
			let firstSizing = {} as SizeVM;

			firstSizing.SizeTypeId = 1;
			firstSizing.SizeTypeName = "Waist Size";
			firstSizing.Values = [];

			// Loop through Properties/Attributes
			Object.values(sizeMapper).forEach(mapping => {
				firstSizing.Values.push(mapping[0]);
			});

			sizing.push(firstSizing);

			let secondSizing = {} as SizeVM;
			secondSizing.SizeTypeId = 2;
			secondSizing.SizeTypeName = "Finished Length";
			secondSizing.Values = [];

			if (size28s.length > 0) {
				secondSizing.Values.push({ ValueId: 1000, ValueName: "28\"" });
			}
			if (size29s.length > 0) {
				secondSizing.Values.push({ ValueId: 1001, ValueName: "29\"" });
			}
			if (size30s.length > 0) {
				secondSizing.Values.push({ ValueId: 1002, ValueName: "30\"" });
			}
			if (size31s.length > 0) {
				secondSizing.Values.push({ ValueId: 1003, ValueName: "31\"" });
			}
			if (size32s.length > 0) {
				secondSizing.Values.push({ ValueId: 1004, ValueName: "32\"" });
			}
			if (size33s.length > 0) {
				secondSizing.Values.push({ ValueId: 1005, ValueName: "33\"" });
			}
			if (size34s.length > 0) {
				secondSizing.Values.push({ ValueId: 1006, ValueName: "34\"" });
			}
			if (size35s.length > 0) {
				secondSizing.Values.push({ ValueId: 1007, ValueName: "35\"" });
			}
			if (sizeUnHems.length > 0) {
				secondSizing.Values.push({ ValueId: 2000, ValueName: "36\" (Unfinished)" });
			}

			sizing.push(secondSizing);

			// Amend Response Data
			response.Sizing = sizing;

			// Amend ProductSizing Response Data and add new Finished Lengths ValueIds
			this.productSize = {} as ProductSize;

			response.ProductSizing.forEach(productsize => {
				this.productSize = productsize;
				let psize = productsize;

				if (size28s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1000);
				}

				if (size29s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1001);
				}

				if (size30s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1002);
				}

				if (size31s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1003);
				}

				if (size32s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1004);
				}

				if (size33s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1005);
				}

				if (size34s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1006);
				}

				if (size35s.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(1007);
				}

				if (sizeUnHems.includes(productsize.SizeValueIds[0])) {
					productsize.SizeValueIds.push(2000);
				}

				let isFirst = true;

				// Switch the Jacket Size with the SizeMapper one
				$.each(sizeMapper, function (idx, mapping: any) {

					var matches = mapping.filter(function (sizeValue) {
						return sizeValue.ValueId === psize.SizeValueIds[0];
					});

					if (matches.length > 0) {
						if (!productsize || isFirst) {
							productsize = psize;
							isFirst = false;
						}

						productsize.SizeValueIds[0] = mapping[0].ValueId;
						return;
					}
				});
			});

			// Since this is part of the hacked/override UI for CustomSizing then ignore required CustomSizings since Unfinished is already an option here
			this.ignoreRequiredCustomSizing = true;
		}
		// *************************************************************************
		// *** END - SPECIAL OVERRIDE FOR Trouser Hemmed SIZE (SizeTypeId == 16) ***
		// *************************************************************************

		if (response.IsOneSize) {
			this.inventoryProductId = response.ProductSizing[0].InventoryProductId;
			this.setSelectedStock();

			return;
		}

		this.sizing = response.Sizing;

		this.customSizing = response.CustomSizing.map(ele => {
			ele.collapse = true;
			return ele;
		});

		this.prodSizing = response.ProductSizing;

		//this one is set in Product.cshtml and is being showed only for FormatShirts. For others we are hiding it...
		//may be in future version we need to enable when we have sizing guide for women apparal
		if (this.sizing != undefined && (this.sizing.length > 1 && this.productdetail.ProductCategoryId === 64) ||
			(this.sizing.length == 1 && this.sizing[0].Values.length > 0 && this.productdetail.ProductCategoryId === 85 && this.productdetail.DefaultCategory.Id != 422)) {
			$('#shirtSizeGuideOnly').show();
		} else {
			$('#shirtSizeGuideOnly').hide();
		}

		for (var t = 0; t <= this.sizing.length - 1; t++) {
			for (var v = 0; v <= this.sizing[t].Values.length - 1; v++) {
				this.sizing[t].Values[v].enabled = true;
				this.sizing[t].Values[v].outOfStock = this.areAllSizesOutOfStock(this.sizing[t].Values[v].ValueId);
			}
		}
	}

	areAllSizesOutOfStock(sizeValueId) {
		for (var ps = 0; ps <= this.prodSizing.length - 1; ps++) {
			if (this.prodSizing[ps].SizeValueIds.indexOf(sizeValueId) > -1 && this.stock[this.prodSizing[ps].InventoryProductId].Qty > 0) {
				return false;
			}
		}

		return true;
	}

	areAnyInStock(invProdIds) {
		if (invProdIds.length == 0) {
			return false;
		}

		for (var i = 0; i <= invProdIds.length - 1; i++) {
			if (this.stock[invProdIds[i]].Qty > 0) {
				return true;
			}
		}

		return false;
	}

	setSelectedStock() {
		if (this.inventoryProductId > 0) {
			this.maxQuantity = Math.max(this.stock[this.inventoryProductId].Qty, 0);
			this.sizeSelected = true;
			this.addFailed = false;
		}
	}

	isRegularSizeForProduct(selectedSizeValueId, sizeValueId) {

		for (var v = 0; v < this.regularSizes.length; v++) {
			if (this.regularSizes[v].SelectedSizeValueId == selectedSizeValueId) {
				return (this.regularSizes[v].RegularSizeValueId == sizeValueId);
			}
		}

		return false;
	}

	singleSizeOptionClicked(e, typeId, sval) {
		this.addedToBag = false;

		var valueId = sval.ValueId;
		this.selectedSize = null;
		this.maxQuantity = 0;
		this.sizeSelected = false;

		// Set selected value on clicked on size type
		for (var t = 0; t <= this.sizing.length - 1; t++) {
			if (typeId == this.sizing[t].SizeTypeId) {
				// First click sets, second unsets
				if (this.sizing[t].selectedValueId != null && this.sizing[t].selectedValueId == valueId) {
					valueId = 0;
					this.selectedFilter = "";
					this.selectedVal = "";
				} else {
					this.selectedSize = sval;
					this.selectedVal = sval.ValueName
				}

				this.sizing[t].selectedValueId = valueId;
				this.sizing[t].selectedValueName = sval.ValueName;

				break;
			}
		}

		if (this.selectedSize) {
			for (var i = 0; i <= this.prodSizing.length - 1; i++) {
				if (this.prodSizing[i].SizeValueIds.indexOf(this.selectedSize.ValueId) > -1) {
					this.inventoryProductId = this.prodSizing[i].InventoryProductId;
					break;
				}
			}

			this.selectedVal = '';

			this.sizing.forEach((size) => {
				if (size.selectedValueName) {
					if (!this.selectedVal) {
						this.selectedVal = size.selectedValueName.split(' (')[0];
					} else {
						if (valueId) {
							this.selectedFilter = size.selectedValueName.split(' (')[0];
						}
					}
				}
			})

			this.setSelectedStock();
		} else {
			this.inventoryProductId = 0;
			this.sizeSelected = false;
		}

		// Any size selection changes should remove Custom Sizing selection
		this.customSizing.forEach(x => {
			x.selectedSize = null;
		});

		if (e) {
			e.preventDefault();
		}
	}

	doubleSizeOptionClicked(e, typeId, sval) {
		this.isSubmitted = false;
		this.addedToBag = false;

		var valueId = sval.ValueId;
		var selectedValueIds = [];
		this.maxQuantity = 0;
		this.sizeSelected = false;

		//Coller size
		// Set selected value on clicked on size type
		for (var t = 0; t <= this.sizing.length - 1; t++) {
			if (typeId == this.sizing[t].SizeTypeId) {
				// First click sets, second unsets
				if (this.sizing[t].selectedValueId != null && this.sizing[t].selectedValueId == valueId) {
					if (this.sizing[t].SizeTypeId == typeId) {
						if (typeId == 1) {
							valueId = 0;
						} else {
							valueId = 0;
							this.selectedFilter = "";
						}
					}
				}

				this.sizing[t].selectedValueId = valueId;
				this.sizing[t].selectedValueName = valueId ? sval.ValueName : "";

				break;
			}
		}

		// this.selectedVal = '';
		this.sizing.forEach((size) => {
			if (size.selectedValueName) {
				if (!this.selectedVal && size.SizeTypeId == 1) {
					if (typeId == 1) {
						this.selectedVal = sval.ValueName;
					} else {
						this.selectedVal = size.Values[0].ValueName;
					}
				} else {
					if (valueId && typeId == 2 && size.SizeTypeId == 2) {
						this.selectedFilter = size.selectedValueName.split(' (')[0];
					} else if (valueId && typeId == 1 && size.SizeTypeId == 1) {
						this.selectedVal = sval.ValueName;
					}
				}
			} else {
				if (!size.selectedValueName && size.SizeTypeId == typeId && typeId == 1) {
					this.selectedVal = size.selectedValueName;
				} else if (!size.selectedValueName && size.SizeTypeId == typeId && typeId == 2) {
					this.selectedFilter = size.selectedValueName;
				}
			}
		})

		//sleeve length
		for (var t2 = 0; t2 <= this.sizing.length - 1; t2++) {
			if (typeId != this.sizing[t2].SizeTypeId) {
				for (var v = 0; v <= this.sizing[t2].Values.length - 1; v++) {
					var invProdIds = this.getProductIdForSize(valueId, this.sizing[t2].Values[v].ValueId);
					var isRegularSize = this.isRegularSizeForProduct(valueId, this.sizing[t2].Values[v].ValueId);

					this.sizing[t2].Values[v].outOfStock = !this.areAnyInStock(invProdIds);
					this.sizing[t2].Values[v].enabled = invProdIds.length > 0;
					this.sizing[t2].Values[v].regularSize = isRegularSize;

					if (isRegularSize) {
						this.regularSizeForSelectedCollarSize = this.sizing[t2].Values[v].ValueName;
					}
				}
			}

			// Get all the selected values
			if (this.sizing[t2].selectedValueId != null && this.sizing[t2].selectedValueId > 0) {
				selectedValueIds.push(this.sizing[t2].selectedValueId);
			}
		}

		if (selectedValueIds.length == this.sizing.length) {
			// All are selected, so find the inventory product id
			var ipIds = this.getProductIdForSize(selectedValueIds[0], selectedValueIds[1]);

			if (ipIds.length > 0) {
				this.inventoryProductId = ipIds[0];
				this.setSelectedStock();
			}
		} else {
			this.inventoryProductId = 0;
			this.sizeSelected = false;
		}

		// If first size selector then applicable for Custom Sizing
		if (typeId = 1) {
			this.selectedSize = sval;
		}

		// Any size selection changes should remove Custom Sizing selection
		this.customSizing.forEach(x => {
			x.selectedSize = null;
		});

		if (e) {
			e.preventDefault();
		}
	}

	getProductIdForSize(valueId1, valueId2) {
		var matchedIds = [];

		for (var ps = 0; ps <= this.prodSizing.length - 1; ps++) {
			var hasValue1 = valueId1 == 0 || this.prodSizing[ps].SizeValueIds.indexOf(valueId1) > -1;
			var hasValue2 = valueId2 == 0 || this.prodSizing[ps].SizeValueIds.indexOf(valueId2) > -1;

			if (hasValue1 && hasValue2) {
				matchedIds.push(this.prodSizing[ps].InventoryProductId);
			}
		}

		return matchedIds;
	}

	customSizeOptionClicked(e, customSize, sval) {

		customSize.selectedSize = (customSize.selectedSize == sval ? null : sval);

		this.sizeSelectChange();

		e.preventDefault();
	}

	sizeSelectChange() {
		this.addedToBag = false;

		if (this.selectedSize == null || this.selectedSize.ValueId == "") {
			this.inventoryProductId = 0;
			this.sizeSelected = false;
			return;
		}

		// SPECIAL OVERRIDE FOR Trouser Hemmed SIZE (SizeTypeId == 16), to ensure unfinished inventory product is selected
		var ipIds = this.getProductIdForSize(this.sizing[0].selectedValueId, 2000);

		if (ipIds.length > 0) {
			this.inventoryProductId = ipIds[0];
		}

		this.setSelectedStock();
	}

	qtyChange(changeBy) {
		this.addedToBag = false;
		this.quantity += changeBy;
	}

	changeCollapse(customSize) {
		let customSizing = this.customSizing.map(ele => {
			if (ele.CustomSizeId == customSize.CustomSizeId) {
				ele.collapse = !ele.collapse;
			}
			return ele;
		})
	}

	getCustomSizeOptions(customSize) {

		// SPECIAL OVERRIDE FOR Trouser Hemmed SIZE (SizeTypeId == 16), to ensure Custom Sizes explicitly don't show for property IgnoreCustomSizing, or when unfinished OOS
		var unfinishedSize = this.sizing[1] ? this.sizing[1].Values.filter(x => x.ValueId == 2000)[0] : null;
		var showCustomSizing = unfinishedSize ? !unfinishedSize.outOfStock : true;

		var selectedValue = this.sizing[1] ? this.sizing[1].Values.filter(x => x.ValueId == this.sizing[1].selectedValueId)[0] : null;
		if ((selectedValue && selectedValue.ValueId != 2000) || this.sizing[0].selectedValueId == 0) {
			return null;
		}

		if (this.selectedSize && showCustomSizing) {
			var selectedSizeValueId = this.selectedSize.ValueId;

			var customSizeValuesForSelected = customSize.SizeValues.filter(function (sizeValues) {
				return sizeValues.SizeValueId === selectedSizeValueId;
			});

			// From selected product (this.inventoryProductId) get selectedSizeValueId
			var inventoryProduct = this.prodSizing.filter(x => x.InventoryProductId == this.inventoryProductId)[0];

			if (inventoryProduct && inventoryProduct.SizeValueIds[1] == 2000) {
				selectedSizeValueId = inventoryProduct.SizeValueIds[0];

				customSizeValuesForSelected = customSize.SizeValues.filter(function (sizeValues) {
					return sizeValues.SizeValueId === selectedSizeValueId;
				});
			}

			if (customSizeValuesForSelected[0]) {
				// SPECIAL OVERRIDE FOR Trouser Hemmed SIZE (SizeTypeId == 16)
				if (this.sizing[1] && unfinishedSize) {
					var availableFinishedSizes = this.sizing[1].Values.filter(x => x.enabled && !x.outOfStock);

					customSizeValuesForSelected[0].Options.forEach(x => {
						var match = availableFinishedSizes.some(y => { return x.Value == y.ValueName });

						if (match) {
							x.outOfStock = true;
						}
					});
				}

				return customSizeValuesForSelected[0].Options;
			}
		}

		return null;
	}

	//new functionalities end

	getLpAppSetting() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	async getSizeGuide(sizeChartKeyValue: any, isFromSubitem: boolean) {
		try {
			const currentSizeChartKey =
				sizeChartKeyValue && isFromSubitem
					? sizeChartKeyValue
					: this.productdetail.Product.SizeChartKey;
			if (!this.siteContent) {
				this.siteContent = this.siteContentService.getSiteContentData();
			}
			if (this.currentSizeChartKey !== currentSizeChartKey) {
				this.currentSizeChartKey = currentSizeChartKey;
				const data = await this.getSizeData(this.currentSizeChartKey);
				this.sizeGuideData = {
					productKey: this.currentSizeChartKey,
					sizeChartContent: data,
					siteContent: this.siteContent,
				};
			}
			if (this.sizeGuideData?.sizeChartContent) {
				this.showSizeGuide = true;
				const { sizeChartContent } = this.sizeGuideData;
				if (sizeChartContent.hasOwnProperty("Version")) {
					this.version = sizeChartContent["Version"];
					if (this.version === 1) {
						this.matDialogRef = this.dialog.open(SizeGuideComponent, {
							height: "auto",
							panelClass: "my-dialog",
							data: this.sizeGuideData,
							disableClose: true,
						});
					}
					this.document.body.classList.add("modal-open", "modal-with-am-fade");
				}
			}
		} catch (error) {
			console.error(error);
			window.location.reload();
		}
	}

	private async getSizeData(productKey: string): Promise<SizeChartContent> {
		return new Promise((resolve, reject) => {
			this.sharedService
				.getSizeData(productKey)
				.subscribe({ next: (res) => resolve(res), error: (e) => reject(e) });
		});
	}

	openReviewModal() {
		this.reviewEvent.emit(false);
	}

	DisplayFindYourFit() {
		try {

			if (this.productdetail) {
				if ((this.productdetail.Product.InventoryProducts[0].SizeValues.length > 1 &&
					this.productdetail.ProductCategoryId === 64) ||
					(this.productdetail.Product.InventoryProducts[0].SizeValues.length == 1 && this.productdetail.Product.InventoryProducts[0].SizeValues.length > 0
						&& this.productdetail.ProductCategoryId === 85 && this.productdetail.DefaultCategory.Id != 422)) {
					return true;
				}
			}
			return false;
		} catch (ex) {

		}
	}

	askForCustomSizeConfirmation() {
		this.matDialogConfirmationRef = this.dialog.open(CustomSizeConfirmationComponent, {
			height: 'auto',
			panelClass: 'my-dialog',
			disableClose: true
		});

		this.matDialogConfirmationRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				this.callAddToCart();
			}
		});
	}

	addToCart() {

		this.addFailed = false;

		if (this.inventoryProductId == 0 || (!this.ignoreRequiredCustomSizing && this.disableAddToBasketForCustomSizing())) {
			this.addFailed = true;
			return;
		}

		var customSizingSelected = this.getCustomSizingSelected();

		if (customSizingSelected && customSizingSelected.length > 0) {
			this.askForCustomSizeConfirmation();
		}
		else {
			this.callAddToCart();
		}
	}

	disableAddToBasketForCustomSizing() {

		var customSizing = this.customSizing.filter(x => {

			var selectedSizeValueId = this.selectedSize.ValueId;

			var customSizeValuesForSelected = x.SizeValues.filter(sizeValues => {
				return sizeValues.SizeValueId === selectedSizeValueId && sizeValues.Options;
			});

			return x.IsRequired && customSizeValuesForSelected[0] && !x.selectedSize;
		});

		if (customSizing.length == 0) {
			return false;
		}

		return true;
	}

	isCustomSizingSelected() {
		if (this.customSizing && this.customSizing.length > 0) {
			if (this.customSizing[0].selectedSize != null) {
				return true;
			}
		}

		return false;
	}

	getCustomSizingSelected(): CustomSize[] {
		// Get selected custom sizes and build up a KVP to send to addToBasket
		// Ignore any selected which are marked as Ignore since these are placeholder only
		let customSizingWithSelected = this.customSizing.filter(x => {
			return x.selectedSize && !x.selectedSize.Ignore;
		});

		let customSizes: CustomSize[] = [];

		customSizingWithSelected.forEach(x => {
			let customSize = {} as CustomSize;

			customSize.Id = x.CustomSizeId;
			customSize.Value = x.selectedSize.Value;

			customSizes.push(customSize);
		});

		return customSizes;
	}

	callAddToCart() {
		this.isSubmitted = true;
		if (!this.sizeSelected) return;
		this.cartModel = {} as CartModel;
		this.cartModel.quantity = this.quantity;
		this.cartModel.productId = +this.inventoryProductId;

		this.addToCartData = {} as AddToCartData;
		this.addToCartData.productCode = this._productdetail.Product.Code;
		//this.addToCartData.ean = this._productdetail.Product.InventoryProducts.filter(x => x.Id == +this.inventoryProductId)[0].Ean;
		this.addToCartData.ean = this.stock.filter(x => x.Id == +this.inventoryProductId)[0].Ean;
		this.addToCartData.quantity = this.quantity;
		this.addToCartData.productName = this._productdetail.Product.Name;
		this.addToCartData.currencyCode = this._productdetail.Product.CurrencyCode;
		this.addToCartData.retailPrice = this._productdetail.Product.RetailPrice;
		this.addToCartData.salePrice = this._productdetail.Product.SalePrice;

		let isBasketItem = false;
		this.addBasket = {} as AddBasket;
		let userSignedIn = localStorage.getItem("isSignedIn");
		this.addBasket.quantity = this.quantity;
		this.addBasket.inventoryProduct = +this.inventoryProductId;

		// Populate Custom Sizing details to AddBasket request
		var customSizingSelected = this.getCustomSizingSelected();

		if (customSizingSelected && customSizingSelected.length > 0) {
			this.addBasket.customSizes = customSizingSelected
		}

		this.cartService.AddToBasket(this.addBasket).subscribe(data => {

			if (data && data.result) {
				this.cartService.displayCart = true;
				this.cartService.refreshLocalBasket(data.result, true);

				this.cartService.trackAddToCart(data.result, this.addToCartData, this.LpAppSetting);

				this.addedToBag = true;
				if (this.facetFilterState.attraqtPLPEnabled) {
					this.sendActivityToAttraqt(data.result);
				}

				this.addToCartEvent.emit(false);
			}
		});

		if (this.isProductAdded || this.isSingleProductAdded) {
			this.router.navigate(["/checkout/shoppingbasket"]);
		}
	}

	displayCartPopUp(data) {

		this.stock[this.inventoryProductId].Qty -= this.quantity;
		this.setSelectedStock();
		this.addingToBag = false;
		this.addedToBag = true;

		let showCartPopup = true;
		let bundleProductIds = JSON.parse(localStorage.getItem("bundleProductIds"));
		if (bundleProductIds && bundleProductIds.length > 0) {

			if (data.ShoppingBasketPage && data.ShoppingBasketPage.Basket && data.ShoppingBasketPage.Basket.BasketItems) {
				bundleProductIds.forEach(bundleProductId => {
					var matches = data.ShoppingBasketPage.Basket.BasketItems.filter(function (basketItem) {
						return basketItem.ProductId === bundleProductId;
					});

					if (matches.length == 0) {
						showCartPopup = false;
					}
				})
			}
		}

		if (showCartPopup) {
			this.cartService.displayCart = true;
		}
	}

	getContent(content) {
		if (content) {
			return this.sanitizer.sanitize(SecurityContext.HTML, content);
		}
	}

	GetMatchedProductImageUrl(productId: number) {
		this.defaultService.GetMatchedProductImageUrl(productId).subscribe(data => {
			if (data) {
				this.ProductMatchedUrls = data;
			}
		})
	}

	getProductMatchedModal() {

		if (this.productdetail && this.productdetail.HasMatchedProducts && this.siteContent) {
			this.matchproductmodel = {} as Matchproductmodel;
			this.matchproductmodel.Free = this.siteContent.Free;
			this.matchproductmodel.AddToBasket = this.siteContent.AddToBasket;
			this.matchproductmodel.AddedToBasket = this.siteContent.AddedToBasket;
			this.matchproductmodel.Title = this.siteContent.MixAndMatchTitle;
			this.matchproductmodel.Cufflink = this.siteContent.MixAndMatchCufflink;
			this.matchproductmodel.FreeTie = this.siteContent.MixAndMatchFreeTie;

			if (this.productdetail.Product) {
				this.matchproductmodel.ProductId = this.productdetail.Product.ProductId;
			}

			if (this.productdetail.MatchedProducts) {
				this.matchproductmodel.MatchedProducts = this.productdetail.MatchedProducts;
			}

			if (this.ProductMatchedUrls) {
				this.matchproductmodel.MixAndMatchMainProductImageUrl = this.ProductMatchedUrls.mixAndMatchMainProductImageUrl;
				this.matchproductmodel.MixAndMatchProductImageUrl = this.ProductMatchedUrls.mixAndMatchProductImageUrl;
			}

			this.matDialogRef2 = this.dialog.open(MatchproductmodelComponent, {
				data: { matchproductmodel: this.matchproductmodel },
				disableClose: true
			});

			this.matDialogRef2.afterClosed().subscribe(res => {
				if ((res == true)) {

				}
			});
		}
	}

	getFitGuideUrl(productCategoryId): string {
		let url = '';
		if (this.LpAppSetting && this.LpAppSetting.WebStoreId === 2) {
			url = productCategoryId == 85 ? '/blusen-guide' : productCategoryId != 85 ? '/hemden-guide' : '';
		} else {
			url = productCategoryId == 85 ? '/womens-fit-guide' : productCategoryId != 85 ? '/shirt-fit-guide' : '';
		}
		return url;
	}

	setCurrentUrl(productCategoryId) {
		let url = '';
		if (this.LpAppSetting && this.LpAppSetting.WebStoreId === 2) {
			url = productCategoryId == 85 ? '/blusen-guide' : productCategoryId != 85 ? '/hemden-guide' : '';
		} else {
			url = productCategoryId == 85 ? '/womens-fit-guide' : productCategoryId != 85 ? '/shirt-fit-guide' : '';
		}

		localStorage.setItem('currentUrl', url);
	}

	numberOnly(value): void {

		let qtyValue = parseInt(value)

		if (!Number.isInteger(qtyValue)) {
			this.quantity = 1;
		}
		else {
			this.quantity = qtyValue;
		}

		if (!qtyValue) {
			this.quantity = 1;
		}

		if (qtyValue) {
			if (qtyValue === 0) {
				this.quantity = 1;
			}

			if (qtyValue > this.maxQuantity) {
				this.quantity = this.maxQuantity
			}
		}
	}

	onChangeSizeValue = (event) => {
		this.updateFilters(event, 1);
	}

	onChangeFilterValue = (event) => {
		this.updateFilters(event, 2);
		this.selectedFilter = event;
	}

	updateFilters = (selectedVal, sizeType) => {
		if (sizeType == 2 && (this.selectedFilter != selectedVal) || (this.selectedVal != selectedVal)) {

			if (this.sizing && this.sizing.length == 1) {
				let selectedSizeVal = this.sizing[0].Values.filter(val => {
					return val.ValueName.toLocaleLowerCase().trim().startsWith(selectedVal.toLocaleLowerCase().trim());
				})

				this.singleSizeOptionClicked(null, this.sizing[sizeType - 1].SizeTypeId, selectedSizeVal[0]);
			} else if (this.sizing && this.sizing.length == 2) {
				let selectedSizeVal = this.sizing[sizeType - 1].Values.filter(val => {
					return val.ValueName.toLocaleLowerCase().trim().startsWith(selectedVal.toLocaleLowerCase().trim());
				})

				if (selectedSizeVal.length || this.sizing.length == 2) {
					let typeId = this.sizing[0].SizeTypeId;

					if (!selectedSizeVal.length) {
						selectedSizeVal = this.sizing[sizeType - 1].Values.filter(val => {
							return val.ValueName.toLocaleLowerCase().trim().startsWith(selectedVal.toLocaleLowerCase().trim());
						})

						if (selectedSizeVal.length) {
							typeId = this.sizing[sizeType - 1].SizeTypeId;
						}
					}

					if (sizeType == 1) {
						this.selectedVal = selectedVal;
					}

					if (selectedSizeVal.length && typeId) {
						this.doubleSizeOptionClicked(null, this.sizing[sizeType - 1].SizeTypeId, selectedSizeVal[0]);
					}
				}
			}
		}
	}

	onChangeShowSizeGuide() {
		this.showSizeGuide = false;
	}

	private sendActivityToAttraqt(data: any): void {
		this.attraqtService.sendActivity(
			new AttraqtActivityBuilder(AttraqtActivityActionEnum.AddToCart)
				.setTarget({ product: data.ChangedProductId.toString() })
				.setSourceId(this.facetFilterState.sourceId)
				.setMetadata({
					quantity: data.ChangedQty,
					locale: localStorage.getItem(NAVIGATOR_LANGUAGE),
				})
				.build()
		);
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
