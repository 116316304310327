//import { ControlPosition, MapTypeControlOptions, MapTypeId } from '@agm/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { forkJoin, map } from 'rxjs';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { currentStore } from 'src/app/model/store';
import { SiteContentDetail } from '../../models/siteContent';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { countries } from '../../../shared/constants/country'
import * as moment from 'moment';

@Component({
	selector: 'app-store-details',
	templateUrl: './store-details.component.html',
	styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit, OnDestroy {
	openingHours: any;
	closingHours: any;
	satCloseTimings: any;
	satOpenTimings: any;
	sunOpenTimings: any;
	sunCloseTimings: any;
	addressTitle: string;
	numTitle: string;
	openTimeTitle: string;
	nearTitle: string;
	webstoreId: number;
	siteContent: SiteContentDetail;
	seasonalOpeningTimes: string;
	previous;

	@ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow

	@Input() otherStores: any[] = [];
	constructor(private activatedRoute: ActivatedRoute, 
		private sharedService: SharedService, 
		private router: Router,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(DOCUMENT) private document) {
		//$("body").removeClass();
		//$("body").addClass(this.classlist);
	}
	id: any;
	datehours: any;
	storeData;
	storeTimings;
	storesMapData;
	activeMarkerIcon = "/assets/maps/maps_marker_active.png";
	inactiveMarkerIcon = "/assets/maps/maps_marker_inactive.png";
	currentStore: currentStore;
	selectedStore: currentStore;
	description: string;
	adderss = {
		street: '',
		city: '',
		postCode: ''
	};
	phoneNumber: string;
	clickAndCollect: boolean;
	nearLocation: string;
	long: number;
	lat: number;
	name: string;
	currentStoreData;
	LpAppSetting: LpAppSetting;
	currentStoreInfo;

	storeCoordinates: google.maps.LatLngLiteral;
	options: google.maps.MapOptions = {
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		mapTypeControlOptions: { mapTypeIds: [google.maps.MapTypeId.ROADMAP] },
		zoomControl: true,
		scrollwheel: true,
		disableDoubleClickZoom: true,
		maxZoom: 15,
		minZoom: 8,
	};
	zoom = 13;
	center: google.maps.LatLngLiteral;

	markerOptions: google.maps.MarkerOptions;

	classlist = "fixed-header mask-hide ng-scope ";
	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
		//this.getLpAppSetting();
		this.scrollService.windowScrollTo(0, 0);
		this.activatedRoute.params
			.subscribe(
				(params: Params) => {
					this.id = params['id'];

					forkJoin({
						currentStoreData: this.sharedService.getStoreData(this.id),
						otherStoreDetails: this.sharedService.getStoresDetails(),
					})
						.pipe(
							map(response => {
								const currentStoreData = response.currentStoreData;
								const otherStoreDetails = response.otherStoreDetails;
								return { currentStoreData: currentStoreData, otherStoreDetails: otherStoreDetails };
							})
						)
						.subscribe({ next: (data) => {
							this.processStoreData(data.currentStoreData);
							this.processStoreDetails(data.otherStoreDetails);

							this.currentStoreInfo = this.otherStores.find(otherStore => otherStore.store.StoreId == this.currentStore.StoreId);
							this.otherStores = this.otherStores.filter(otherStore => otherStore.store.StoreId != this.currentStore.StoreId);
						}, error: (error: HttpErrorResponse) => {
							if (error.status === 500) {
								this.router.navigate(['stores']);
							}
						}});
				}
			);
	}

	ngOnDestroy(): void {
		this.removeStoreScript();
	}

	processStoreData(res: any) {
		this.storeTimings = res.Stores

		let data = JSON.parse(res);
		if (data && data.WebStoreId) {
			this.webstoreId = data.WebStoreId;
		}


		this.currentStore = data.StoresJson.filter(item => item.FullUrl.substr(1) == this.id)[0];

		this.storeCoordinates = {
			lat: this.currentStore.Latitude,
			lng: this.currentStore.Longitude,
		};

		this.markerOptions = {
			position: this.storeCoordinates,
			title: 'Marker title ',
			icon: this.activeMarkerIcon
		}

		this.center = this.storeCoordinates;

		this.name = this.currentStore.Name;
		if (this.name != null) {
			this.addressTitle = 'Address'
		}

		//Details
		this.currentStoreData = data.Stores.filter(item => item.StoreId == this.currentStore.StoreId);

		this.clickAndCollect = this.currentStoreData[0].ClickAndCollect;
		this.nearLocation = this.currentStoreData[0].NearestStation;
		if (this.nearLocation != null) {
			this.neartitle = 'Nearest Station';

		}
		this.adderss.street = this.currentStoreData[0].Address.StreetAddress1;
		this.adderss.city = this.currentStoreData[0].Address.City;
		this.adderss.postCode = this.currentStoreData[0].Address.PostCode;
		this.phoneNumber = this.currentStoreData[0].Address.Phone;
		if (this.phoneNumber != null) {
			this.numTitle = 'Phone Number'
		}

		this.description = data.Description;
		this.storeData = data.StoresJson;
		{
			let openDays = []
			this.currentStoreData[0].StoreOpeningHours.forEach(day => {
				if (day != null && day.OpeningTime !== null) {
				const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day.DayOfTheWeek];
			
				openDays.push({
					weekTitle: dayOfWeek,
					openTimings: new Date(day.OpeningTime),
					closeTimings: new Date(day.ClosingTime)
				});
				}
			});
			openDays.sort((a, b) => {
				if (a.weekTitle === 'Sunday') return 1;
				if (b.weekTitle === 'Sunday') return -1;
				return 0;
			  });
			this.openingHours = openDays;
		}
		
		this.seasonalOpeningTimes = this.currentStoreData[0].SeasonalOpeningTimes;
		this.generateStoreScript();
	}

	processStoreDetails(res: any) {
		this.storesMapData = res.StoresJson;

		this.storesMapData.map(store => {
			this.otherStores.push({
				position: {
					lat: store.Latitude,
					lng: store.Longitude,
				},
				store: store,
				options: { icon: this.inactiveMarkerIcon }
			});
		})
	}

	neartitle;

	getWeekendTimings() {
		this.storeTimings.forEach((d: any) => {
			if (d.Stores.StoreOpeningHours.DayOfTheWeek === 0) {
				this.satOpenTimings = d.openingHours;
				this.satCloseTimings = d.closingHours
			}
			if (d.Stores.StoreOpeningHours.DayOfTheWeek === 1) {
				this.sunOpenTimings = new Date(d.openingHours);
				this.sunCloseTimings = new Date(d.closingHours)
			}

		});
	}

	getTime(datetime) {
		var time = new Date(datetime);

		// var hours = time.getHours()
		// var minutes = time.getMinutes()
		// var sec = time.getSeconds()
		// var day = time.getDay()
		return time;
		// return day+':'+ hours+':'+minutes+':'+sec;
	}

	getFullAddress(store) {
		let address = store.FullAddress.trim().replace(/(\r\n|\n|\r)/gm, "<br/>");
		return address;
	}

	getDateFromHours(time) {
		console.log(time)
		time = time.split(':');

		let now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time);
	}

	getFormatedPhoneNumber(store) {
		let phoneNumber = store.PhoneNumber;

		if (phoneNumber.length >= 10) {
			phoneNumber = phoneNumber.substr(0, 3) + " " + phoneNumber.substr(3, 4) + "" + phoneNumber.substr(7);
			return phoneNumber;
		}

		return phoneNumber;
	}

	clickedMarker(infowindow) {
		if (this.previous) {
			this.previous.close();
		}

		this.previous = infowindow;
	}

	addBodyClass() {
		if (this.LpAppSetting) {
			if (this.LpAppSetting.UserSettings && !this.LpAppSetting.UserSettings.PromoLineBarHeader) {
				this.classlist += "no-promo-text ";
				$("body").removeClass();
				$("body").addClass(this.classlist);
			}
		}
	}

	openInfo(marker: MapMarker, store: currentStore) {
		this.selectedStore = store;
		this.infoWindow.open(marker);
	}

	isBrowser() {
		if (isPlatformBrowser(this.platformId)) {
			return true;
		}

		return false;
	}

	private resolveOpeningHoursSpecification(
		storeOpeningHours: Array<{
			StoreId: number;
			DayOfTheWeek: number;
			OpeningTime: string;
			ClosingTime: string;
		}>
	): any {
		// Sort opening hours to move Sunday entries to the end
		const sortedOpeningHours = storeOpeningHours.sort((a, b) => {
			if (a.DayOfTheWeek === 0) return 1;
			if (b.DayOfTheWeek === 0) return -1;
			return 0;
		});
		// Modify Date time entries HH:mm
		const timesModifiedOpeningHours = sortedOpeningHours.reduce(
			(
				accumulator: Array<{
					StoreId: number;
					DayOfTheWeek: number;
					OpeningTime: string;
					ClosingTime: string;
				}>,
				current
			) => {
				const { StoreId, DayOfTheWeek, OpeningTime, ClosingTime } = current;
				if (
					OpeningTime &&
					OpeningTime !== "" &&
					ClosingTime &&
					ClosingTime !== ""
				) {
					accumulator.push({
						StoreId,
						DayOfTheWeek,
						OpeningTime: moment(OpeningTime).format("HH:mm"),
						ClosingTime: moment(ClosingTime).format("HH:mm"),
					});
				}
				return accumulator;
			},
			[]
		);
		const days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		const openHoursSpecifications = timesModifiedOpeningHours.reduce(
			(
				accumulator: Array<{
					"@type": string;
					dayOfWeek: Array<string>;
					opens: string;
					closes: string;
				}>,
				current
			) => {
				const { DayOfTheWeek, OpeningTime, ClosingTime } = current;
				const foundIndexInAccumulator = accumulator.findIndex(
					({ opens, closes }) => {
						return opens === OpeningTime && closes === ClosingTime;
					}
				);
				if (foundIndexInAccumulator > -1) {
					accumulator[foundIndexInAccumulator].dayOfWeek.push(
						days[DayOfTheWeek]
					);
				} else {
					accumulator.push({
						"@type": "OpeningHoursSpecification",
						dayOfWeek: [days[DayOfTheWeek]],
						opens: OpeningTime,
						closes: ClosingTime,
					});
				}
				return accumulator;
			},
			[]
		);
		return openHoursSpecifications;
	}

	private resolveStoreScriptData(): any {
		const {
			Name: storeName,
			Latitude: latitude,
			Longitude: longitude,
		} = this.currentStore;
		const {
			Address: {
				StreetAddress1: streetAddress,
				City: addressLocality,
				PostCode: postalCode,
				CountryId,
				Phone: telephone,
			},
			StoreOpeningHours: storeOpeningHours,
			HasStoreOpeningHours: hasStoreOpeningHours,
		} = this.currentStoreData[0];
		const { code: addressCountry, currencySymbol } = countries.find(
			({ id }) => id === CountryId
		);
		const data: any = {
			"@context": "https://schema.org",
			"@type": "ClothingStore",
			name: storeName,
			address: {
				"@type": "PostalAddress",
				streetAddress,
				addressLocality,
				postalCode,
				addressCountry,
			},
			geo: {
				"@type": "GeoCoordinates",
				latitude,
				longitude,
			},
			url: window.location.href,
			telephone,
			priceRange: `${currencySymbol}${currencySymbol}`,
		};
		if (hasStoreOpeningHours) {
			data.openingHoursSpecification =
				this.resolveOpeningHoursSpecification(storeOpeningHours);
		}
		return data;
	}

	private generateStoreScript(): void {
		const data = this.resolveStoreScriptData();
		const script = document.createElement('script');
		script.type = 'application/ld+json';
		script.textContent = JSON.stringify(data)
		this.document.body.appendChild(script);
	}

	private removeStoreScript(): void {
		const bodyScripts = this.document.body.getElementsByTagName("script");
		if (bodyScripts.length) {
			for (let i = 0; i < bodyScripts.length; ++i) {
				if (bodyScripts[i].getAttribute("type") === "application/ld+json") {
					bodyScripts[i].parentNode.removeChild(bodyScripts[i]);
				}
			}
		}
	}
}
