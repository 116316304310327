import { Component, Input, OnInit } from '@angular/core';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { SiteContentDetail } from '../../models/siteContent';

@Component({
  selector: 'receipt-summary',
  templateUrl: './receipt-summary.component.html',
  styleUrls: ['./receipt-summary.component.scss']
})
export class ReceiptSummaryComponent implements OnInit {

  @Input() sitecontent: SiteContentDetail;
  @Input() regExLibrary: RegExLibrary;
  @Input() label: Label;
  @Input() basket: any;
  @Input() order: any;
  @Input() isDutyFree: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
