import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ScrollService } from '../../service/scroll.service';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

	private requests: HttpRequest<any>[] = [];
	urlsToNotUse: Array<string>;

	constructor(
		private loaderService: LoaderService,
		private router: Router,
		private scrollService: ScrollService) {

		this.urlsToNotUse = [
			"services.postcodeanywhere.co.uk",
		];
	}

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);

		if (i >= 0) {
			this.requests.splice(i, 1);
		}

		// Ensures that the loader will always set itself to true on these specific pages which means it will wait for
		// any existing outbound API requests to be completed
		if (document.body.classList.contains("pt-productlistingpage")
			|| document.body.classList.contains("checkout")) {
			setTimeout(() => {
				this.loaderService.isLoading.next(this.requests.length > 0);
			}, 1000);
		} else {
			this.loaderService.isLoading.next(this.requests.length > 0);
		}
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (req.url.indexOf("services.postcodeanywhere.co.uk/Capture/Interactive/Find") > -1) {
			let modifiedRequest = req.clone({
				setHeaders: {
					skip: "true"
				}
			});

			return next.handle(modifiedRequest);
		}

		this.requests.push(req);

		// Ignore these API calls from the loader
		if (req.url.toLowerCase().includes("/api/adyen/logselectedpayment")
			|| req.url.toLowerCase().includes("/api/adyen/logcheckout")) {

			this.loaderService.isLoading.next(false);

			// Remove these from the request list
			this.requests.pop();

		} else {
			this.loaderService.isLoading.next(true);
		}

		return Observable.create(observer => {
			const subscription = next.handle(req)
				.subscribe(
					event => {
						if (event instanceof HttpResponse) {
							this.removeRequest(req);
							observer.next(event);
						}
					},
					err => {
						this.removeRequest(req);
						this.loaderService.isLoading.next(false);
						observer.error(err);
					},
					() => {
						this.removeRequest(req);
						this.loaderService.isLoading.next(false);
						observer.complete();
					});

			return () => {
				this.removeRequest(req);
				this.loaderService.isLoading.next(false);
				subscription.unsubscribe();
			};
		});
	}
}
