import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash'; 
@Pipe({
    name: 'filterUnique',
    pure: false
  })
  export class FilterUniquePipe implements PipeTransform {
  
    // transform(value: any): any{
    //     if(value!== undefined && value!== null){
    //         return _.uniqBy(value, 'name');
    //     }
    //     return value;
    // }

    transform(value: any,param?:any): any{
     // alert(JSON.stringify(value))
      //alert(JSON.stringify(param))
      if(value!== undefined && value!== null){
          return _.uniqBy(value, param);
      }
      return value;
  }
  
  }