import { isPlatformBrowser } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, SecurityContext, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { WebpageViewModel } from 'src/app/model/web-page-viewmodel';
import { Label } from 'src/app/shared/models/Labels';
import { HeaderMenu, MenuItem } from 'src/app/shared/models/menuItem';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { UserSettings } from 'src/app/shared/models/user-settings';
import { CartService } from 'src/app/shared/service/cart.service';
import { LabelService } from 'src/app/shared/service/label.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { PromoLineBarEnum } from '../../../../enums/promo-line-bar-enum';
import { ScrollService } from '../../../service/scroll.service';
import { CartComponent } from '../../cart/cart.component';
import { GeoLocationComponent } from '../../geo-location/geo-location.component';
import { MobileNavBarNewComponent } from '../mobile-nav-bar-new/mobile-nav-bar-new.component';
import { SearchService } from '../search/search.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { ViewportService } from 'src/app/shared/viewport.service';
import { SlickPromoLineBarHeader } from 'src/assets/shared/helper_scripts.js';
import { Splide } from '@splidejs/splide';

@Component({
	selector: 'default-header',
	templateUrl: './default-header.component.html',
	styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

	@ViewChild('promotext', { static: false }) promotext: ElementRef<HTMLInputElement>;
	@ViewChild(MobileNavBarNewComponent, { static: false }) mobileNavChild: MobileNavBarNewComponent;
	@ViewChild(CartComponent, { static: false }) cartComponent: CartComponent;

	// @Output() notifyParent = new EventEmitter();
	cartShowNotifications = false;
	cartCountShow = false;
	menuItems: MenuItem[];
	mobileMenu: MenuItem[];
	headerMenu: HeaderMenu;
	userSettings: UserSettings;
	userSettingObj: UserSettings;
	webPageViewModel: WebpageViewModel;
	isDataLoaded: Promise<boolean>;
	isDisplayCartItems = false;
	classTotal: number;
	cartCount = 0;
	matDialogRef: MatDialogRef<GeoLocationComponent>;
	name: string;
	isSignedIn = '';
	innerWidth;
	smallScreenMenuOpened: boolean = false;
	isDisplayDetails: boolean = false;
	showDetailsInfo: boolean = false;
	detailsShow: boolean = false;
	storeselected = false;
	searchOpenDialog = false;
	shoppingBasket: ShoppingBasket;
	appSettings: LpAppSetting;
	countrydetails: any;
	searchVisible: boolean = false;
	labelData: Label;
	accountUrl: string;
	promoLineBarHeaderHtml: string;
	promoLineBarHeaderBgHexColour: string;
	promoLineBarHeaderTextHexColour: string;
	sitecontent: SiteContentDetail;
	facetFilterState: IFacetFilterState;
	facetFilterSubscriber: Subscription;
	deviceType: string;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private cdref: ChangeDetectorRef,
		private sharedService: SharedService,
		public dialog: MatDialog,
		private searchService: SearchService,
		private cartService: CartService,
		private router: Router,
		private siteContentService: SiteContentService,
		private labelService: LabelService,
		private scrollService: ScrollService,
		private sanitizer: DomSanitizer,
		private attraqtService: AttraqtService,
		public viewportService: ViewportService,
		@Inject(PLATFORM_ID) private platformId: any) {
	}

	async ngOnInit() {
		//this.getUserSettings();
		await this.getApplicationSetting();
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.subscribeToState();
		this.getAllLabels();
		this.sharedService.menuSelectEvents$.forEach(event => { this.menuItemselectEvent(event) });
		this.getMenus();

		this.updateCartItemsFromDB();
		this.getCartProduct();
		this.getData();

		this.innerWidth = window.innerWidth;

		this.sharedService.currentStatus.subscribe(res => this.isSignedIn = res);
		if (!this.isSignedIn) {
			this.sharedService.changeSigninInfo("true")
			this.isSignedIn = localStorage.getItem('isSignedIn') ? localStorage.getItem('isSignedIn') : '';
			const storedUserData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
			if (storedUserData) {
				const userData = JSON.parse(storedUserData);
				if (userData?.customerId && this.facetFilterState.attraqtPLPEnabled) {
					this.attraqtService.setLoggedUser(userData.customerId);
				}
			}
		}
		this.renderPromoLineBar();
		this.getDeviceType();
	}

	ngAfterViewInit() {

		this.searchService.openSearchModal$.subscribe(data => {
			this.searchService.algoliaInitialised$.subscribe(data => {
				this.openSearch();
			});
		});
		this.createStyle();
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();

		if (this.cartCount >= 1) {
			this.cartCountShow = true;
		} else {
			this.cartCountShow = false;
		}
	}

	ngOnDestroy(): void {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
	}

	getDeviceType() {
		this.deviceType = this.viewportService.getDeviceType();
	}

	sendNotification() {
		// this.notifyParent.emit(true);
	}

	renderPromoLineBar(): void {
		this.promoLineBarHeaderHtml = this.appSettings.UserSettings.PromoLineBarHeader;
		this.promoLineBarHeaderBgHexColour = this.appSettings.UserSettings.PromoLineBarHeader_BgHexColour;
		this.promoLineBarHeaderTextHexColour = this.appSettings.UserSettings.PromoLineBarHeader_TextHexColour;

		let param = window.location.search.substr(1);
		this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.Header).subscribe(response => {
			if (response) {
				if (response.MarketingTag) {
					this.promoLineBarHeaderHtml = response.MarketingTag.Content.PromoLineBarHeader ? response.MarketingTag.Content.PromoLineBarHeader : this.promoLineBarHeaderHtml;
					this.promoLineBarHeaderBgHexColour = response.MarketingTag.Content.PromoLineBarHeader_BgHexColour ? response.MarketingTag.Content.PromoLineBarHeader_BgHexColour : this.promoLineBarHeaderBgHexColour;
					this.promoLineBarHeaderTextHexColour = response.MarketingTag.Content.PromoLineBarHeader_TextHexColour ? response.MarketingTag.Content.PromoLineBarHeader_TextHexColour : this.promoLineBarHeaderTextHexColour;
				}
				SlickPromoLineBarHeader();
				this.splidePromoLineBarHader();
			}
		});
	}

	splidePromoLineBarHader() {
		new Splide('.splide-carousel-promobar', {
			type: "loop",
			arrows: false,
			pagination: false,
			autoplay: true,
		}).mount();
	}

	getAllLabels() {
		this.labelData = this.labelService.getLabelData();
		if (!this.labelData) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.labelData = data;
				}
			});
		}
	}

	getPromoTextColour(bgColor, promoTxtColour) {
		if (promoTxtColour) {
			let styles = {
				'background-color': bgColor,
				'--promo-text-color': promoTxtColour
			};
			return styles;
		}
		return '';
	}

	openMenu() {
		if (!this.mobileNavChild) {
			return;
		}
		
		this.mobileNavChild.toggleMobileMenu();

		if (!this.smallScreenMenuOpened) {
			$('html').addClass('mobilenavopen');
			$('body').addClass('mobinavopen');
			$('#navIcon').addClass('selected');
			this.smallScreenMenuOpened = true;
		} else {
			this.smallScreenMenuOpened = false;
			$('html').removeClass('mobilenavopen');
			$('body').removeClass('mobinavopen');
			$('#navIcon').removeClass('selected');
		}
	}

	closemenu() {
		this.smallScreenMenuOpened = false;

		$('html').removeClass('mobilenavopen');
		$('body').removeClass('mobinavopen');
		$('#navIcon').removeClass('selected');
	}

	displayCartItems(isShow) {
		let self = this;
		if (self.cartCount === null || self.cartCount == 0) isShow = false;
		self.isDisplayDetails = false;
		self.detailsShow = false;
		setTimeout(() => {
			self.cartComponent.showCart(isShow);
		}, 100);
	}

	hideCartItems() {
		this.cartComponent.fadeFromParent(this.isDisplayDetails);
	}

	private async getApplicationSetting(): Promise<void> {
		this.appSettings = await this.sharedService.getLpAppSettings();
		this.sharedService.setWebStoreLanguage();
	}

	getCartProduct() {
		try {

			this.cartCount = 0;
			this.cartShowNotifications = false;
			this.cartService.getShoppingBasket().subscribe(data => {
				this.cartCount = 0;
				this.cartShowNotifications = false;
				if (data && data.ShoppingBasketPage && data.ShoppingBasketPage.Basket) {

					if (data.ShoppingBasketPage.Basket.BasketTotalItemCount) {
						this.cartCount = data.ShoppingBasketPage.Basket.BasketTotalItemCount;
						this.cartShowNotifications = true;
					}
				}
			});
		} catch (ex) {

		}
	}

	private updateCartItemsFromDB() {

		if (this.router.url != '/checkout/shoppingbasket') {
			this.shoppingBasket = {} as ShoppingBasket;
			this.cartService.getShoppingCart(this.shoppingBasket).subscribe(data => {
				this.cartService.refreshLocalBasket(data.desc, false);
			});
		}
	}

	OpenModal() {

		this.matDialogRef = this.dialog.open(GeoLocationComponent, {
			data: { name: this.name, countrydetails: this.countrydetails, sitecontent: this.sitecontent },
			disableClose: true
		});

		this.matDialogRef.afterClosed().subscribe(res => {
			if ((res == true)) {
				this.name = "";
			}
		});
	}

	getData(): void {
		this.sharedService.GeoLocation(1).subscribe(data => {

			//this.geodetail = data
			this.countrydetails = data.AllWebStores;
		});;
	}

	getMenus(): void {

		this.sharedService.getMenus().subscribe(data => {
			this.sendNotification();
			this.headerMenu = data;
			if (this.headerMenu) {
				this.menuItems = this.headerMenu.DesktopMenu;
				this.mobileMenu = this.headerMenu.MobileMenu;
			}

			this.isDataLoaded = Promise.resolve(true);
		});
	}

	openSearch() {
		this.searchOpenDialog = !this.searchOpenDialog;
		if (this.searchOpenDialog) {
			this.searchService.searchDialogSelected(this.searchOpenDialog);
		}

		if ($(".header-search").hasClass("open-search")) {
			this.closeSearch();
			return;
		}
		$(".header-search").animate({ "max-height": "100%" }, 500);
		$(".header-search").addClass("open-search");
		$(".site-header").addClass("withsearchform");
		$("body").addClass("searchopen");
		$(".view-nav").css("z-index", "1002");

		// Auto focus on search input once search is opened (so ready to type)
		$("#searchId").focus();

		// Hides scrollbar on mobile view to fix scrolling issues on search
		$("body").addClass("mobile-overflow-hidden");
	}

	closeSearch() {
		if (isPlatformBrowser(this.platformId)) {
			$(".serach-button").css("cursor", "pointer");
			$(".header-search").animate({ "max-height": 0 }, 400);
			$(".header-search").removeClass("open-search");
			$(".site-header").removeClass("withsearchform");
			$("body").removeClass("searchopen");
			$("#searchId").val("");
			$(".view-nav").css("z-index", "1004");
			$(".topsearch-button").removeClass("active");
			$(".custom-search").hide();
			$(".default-search").show();

			$("body").removeClass("mobile-overflow-hidden");
			this.scrollService.windowScrollTo(0, 0);
		}
	}

	showDetails() {
		this.showDetailsInfo = true;
		this.isDisplayDetails = true;
		this.detailsShow = true;
	}

	hideDetails() {
		let self = this;
		setTimeout(() => {
			if (!self.detailsShow && self.isDisplayDetails) {
				this.isDisplayDetails = false;
				$('body').removeClass('mask-show');
			}
		}, 100);
		this.detailsShow = false;
	}

	closeMenuFromDetails(val) {
		let self = this;
		setTimeout(function () {
			$('body').removeClass('mask-show');
			self.isDisplayDetails = false;
			self.detailsShow = false;
		}, 100);

		// if(val == 1){
		//   this.router.navigate(['/account/orders']);
		// }else if(val == 2){
		//   this.router.navigate(['/account/addressbook']);
		// }else{
		//   this.router.navigate(['/account/myaccount']);
		// }

	}

	showDetailsPopup() {
		this.changeToSignIn();
		this.showDetailsInfo = false;
		$('body').removeClass('mask-show');
		this.cartComponent.isCartPopUp = false;
		if (this.isSignedIn || localStorage.getItem('isSignedIn') == 'true') {
			let self = this;
			setTimeout(() => {

				self.isDisplayDetails = true;
				$('body').addClass('mask-show');
			}, 100)
		}
	}

	hideDetailsMenu() {
		let self = this;
		setTimeout(() => {
			if (!self.showDetailsInfo) {
				self.hideDetails();
			}
		}, 100)
	}

	signOut() {

		localStorage.clear();
		this.sharedService.changeSigninInfo("")
		this.getApplicationSetting();
		// this.router.navigateByUrl('details', { skipLocationChange: true }).then(() => {
		//   this.router.navigate(['']);
		// });
		this.isDisplayDetails = false;
		$('body').removeClass('mask-show');
		this.cartService.products.next(null);
	}

	changeToSignIn() {
		this.closeSearch();
		if (localStorage.getItem('isSignedIn') != 'true') {


			localStorage.removeItem('createForm');
			localStorage.removeItem('forgotForm');
			this.accountUrl = "/account/signin";
			// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			//   this.router.navigate(['account/signin']);
			// });
		} else if (this.isSignedIn || localStorage.getItem('isSignedIn') == 'true') {
			// this.accountUrl="";
			// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			//   this.router.navigate(['account/myaccount']);
			// });
			this.accountUrl = "/account/myaccount";
		}
	}

	changeToFindStore() {
		this.storeselected = this.router.url != '/stores' ? true : false;
		this.closeSearch();
	}

	redirectCart() {
		this.closeSearch();
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['checkout/shoppingbasket']);
			this.cartComponent.forceHideCart();
		});
	}

	menuItemselectEvent(url) {
		this.closeSearch();
		if (this.menuItems) {

			this.menuItems.forEach(menu => {

				menu.IsSelected = false;

				if (menu.Url === url) {

					menu.IsSelected = true;
				}
				if (menu.HasChildren) {
					menu.Children.forEach(child => {

						if (child.Url === url) {
							child.IsSelected = true;
							menu.IsSelected = true;
						}
						if (child.HasChildren) {
							child.Children.forEach(grandChild => {
								grandChild.IsSelected = false;
								if (grandChild.Url === url) {
									grandChild.IsSelected = true;
									menu.IsSelected = true;
								}
							})
						}
					})
				}


			})
		}

	}

	onSearchTextChange(event) {
		let searchText = event.target.value;

		let routingUrl = "search?q=" + searchText;

		if (event.keyCode === 13) {
			this.router.navigateByUrl(routingUrl);
		}
	}

	private async createStyle(): Promise<void> {
		if (!this.appSettings) {
			await this.getApplicationSetting();
		}
		const style = `.promo-line {--promo-text-color :${this.appSettings.UserSettings.PromoLineBarBody_TextHexColour ? this.appSettings.UserSettings.PromoLineBarBody_TextHexColour : ''}; }`;
		const styleElement = document.createElement('style');
		styleElement.appendChild(document.createTextNode(style));
		if (this.promotext && this.promotext.nativeElement)
			this.promotext.nativeElement.appendChild(styleElement);
	}

	getContent(content: string) {
		if (content) {
			return this.sanitizer.sanitize(SecurityContext.HTML, content);
		}
		return content;
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
