import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteContentDetail } from '../../models/siteContent';
import { SiteContentService } from '../../sitecontent.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-payment-cancelled',
  templateUrl: './payment-cancelled.component.html',
  styleUrls: ['./payment-cancelled.component.scss']
})
export class PaymentCancelledComponent implements OnInit {
  siteContent:SiteContentDetail;
  constructor(private route: ActivatedRoute,private siteContentService: SiteContentService) { 
    $("body").removeClass();
    $("body").addClass("checkout ng-scope");
  }
  resultObj;
  ngOnInit() {
    this.getSiteContent();

    this.route.queryParams
      .subscribe(params => {
        if (params) {
          
         
          if(!this.resultObj){
            this.resultObj={} as any;
          }
         this.resultObj.Error={} as any;
          this.resultObj.Error.result = "error";
          this.resultObj.Error.statusDetail = "";
          this.resultObj.ProgressStep = 2;
          this.resultObj.ShowPaymentButton = true;
          if (params.orderId) {

            this.resultObj.Error.orderId = params.orderId;
          }
          if (params.message) {
            
            this.resultObj.Error.message = params.message;
          }

        }
      }
      );
  }

  getSiteContent(){
    if(!this.resultObj){
      this.resultObj={} as any;
    }
    
    this.siteContent = this.siteContentService.getSiteContentData();
    if(this.siteContent){
      this.resultObj.Title = this.siteContent.PaymentCancelledTitle;
      this.resultObj.Message=this.siteContent.PaymentCancelledDescription.replace(/\\/gi,"");
      this.resultObj.TryAnotherPaymentOption = this.siteContent.TryAnotherPaymentOption;
      
    }
    if(!this.siteContent){
      this.siteContentService.getSiteContent().subscribe(data=>{
       if(data){
        this.resultObj.Title = data.PaymentCancelledTitle;
        this.resultObj.Message=data.PaymentCancelledDescription.replace(/\\/gi,"");
        this.resultObj.TryAnotherPaymentOption = data.TryAnotherPaymentOption;
       }
       
        
      })
    }
  }
}
