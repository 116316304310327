import { Component, Input, OnInit } from '@angular/core';
import { SimpleImageVM } from 'src/app/model/content-page-row-viewmodel';

@Component({
  selector: '[simple-image],simple-image',
  templateUrl: './simple-image.component.html',
  styleUrls: ['./simple-image.component.scss']
})
export class SimpleImageComponent implements OnInit {
  @Input() simpleImage: SimpleImageVM;
  constructor() { }

  ngOnInit() {
  }

}
