import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { SiteContentDetail } from '../../models/siteContent';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'app-subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
	userEmail: string;
	submitted: boolean = false;
	signUpNewsLetter: UntypedFormGroup;
	needToSubcribe: boolean = true;
	existEmail: boolean = false;
	emailValidationFailed: boolean = false;
	regexEmailValidation: RegExp;
	recaptcha_Active: boolean;
	recaptcha_V2_SiteKey: string;
	doubleOptInEnabled: boolean;
	sitecontent: SiteContentDetail;
	LpAppSetting: LpAppSetting;
	@ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
	scriptContent = [];
	isscriptContentNgAfterviewinit: boolean = false;

	get f() { return this.signUpNewsLetter.controls; }

	constructor(private router: Router, private fb: UntypedFormBuilder, private sharedService: SharedService,
		private scrollService: ScrollService,
		private commonService: DefaultService, private siteContentService: SiteContentService) {


	}

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.submitted = false;
		this.getApplicationSettings();
		this.scrollService.windowScrollTo(0, 0);
	}

	initiateForm() {
		this.signUpNewsLetter = this.fb.group({
			FirstName: "",
			LastName: "",
			Email: [this.userEmail, [Validators.required, Validators.email, Validators.pattern(this.LpAppSetting.RegexLib.Email)]],
			recaptcha: ['', Validators.required]
		});
	}

	checkEmail() {
		this.userEmail = localStorage.getItem("userEmail");
		if (this.userEmail) {
			if (this.regexEmailValidation.test(this.userEmail)) {

			} else {
				this.emailValidationFailed = true;
			}
		}
	}

	onSubmit() {

		if (this.signUpNewsLetter && this.signUpNewsLetter.valid) {
			this.emailValidationFailed = false;
		}
		this.submitted = true;
		this.sharedService.subscribeSite(this.signUpNewsLetter.value).subscribe(res => {
			if (res == "false") {
				this.needToSubcribe = true;
				this.existEmail = false;
			}
			if (res.result) {
				if (res.result == "Success") {
					this.needToSubcribe = false;
					localStorage.removeItem("userEmail");
					this.getTrackingScript();
				} else {
					this.needToSubcribe = true;
					this.existEmail = true;
				}
			}

		});
	}

	getApplicationSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
		if (this.LpAppSetting) {
			this.recaptcha_Active = this.LpAppSetting.Recaptcha_Active;
			this.recaptcha_V2_SiteKey = this.LpAppSetting.Recaptcha_V2_SiteKey;
			this.doubleOptInEnabled = this.LpAppSetting.DoubleOptInEnabled;
			this.regexEmailValidation = new RegExp(this.LpAppSetting.RegexLib.Email)
			this.checkEmail();
			this.initiateForm();
		}

	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}

	getTrackingScript() {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = PageTypeDimensionEnum.UserSubscribed;

		this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
			this.scriptContent = this.sharedService.ParseScript(data);
			this.ngAfterViewInit();
		});
	}

	ngAfterViewInit() {
		if (this.scriptContent && this.scriptContent.length > 0 && !this.isscriptContentNgAfterviewinit) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			this.isscriptContentNgAfterviewinit = true;
		}
	}

}
