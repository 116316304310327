import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';

@Injectable({
	providedIn: 'root'
})
export class AdyenService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	logCheckout(success) {
		return this.http.get(this.environmentService.environment.adyenUrl + "LogCheckout?success=" + success, this.httpOptions);
	}

	logSelectedPayment(paymentType) {
		return this.http.get(this.environmentService.environment.adyenUrl + "LogSelectedPayment?paymentType=" + paymentType, this.httpOptions);
	}

	processPayment(paymentMethodDetails, isGift, orderId): Observable<any> {
		return this.http.post(this.environmentService.environment.adyenUrl + "ProcessPayment",
			{
				"Type": paymentMethodDetails.Type,
				"CheckoutAttemptId": paymentMethodDetails.CheckoutAttemptId,

				"Brand": paymentMethodDetails.Brand,
				"HolderName": paymentMethodDetails.HolderName,
				"EncryptedCardNumber": paymentMethodDetails.EncryptedCardNumber,
				"EncryptedExpiryMonth": paymentMethodDetails.EncryptedExpiryMonth,
				"EncryptedExpiryYear": paymentMethodDetails.EncryptedExpiryYear,
				"EncryptedSecurityCode": paymentMethodDetails.EncryptedSecurityCode,

				"GooglePayCardNetwork": paymentMethodDetails.GooglePayCardNetwork,
				"GooglePayToken": paymentMethodDetails.GooglePayToken,

				"ApplePayToken": paymentMethodDetails.ApplePayToken,

				"StorePaymentMethod": paymentMethodDetails.StorePaymentMethod,
				"StoredPaymentMethodId": paymentMethodDetails.StoredPaymentMethodId,
				"BrowserInfoJson": paymentMethodDetails.BrowserInfoJson,
				"Origin": paymentMethodDetails.Origin,
				"ShippingPostCode": paymentMethodDetails.ShippingPostCode,
				"IsGift": isGift,

				"RecaptchaToken": paymentMethodDetails.RecaptchaToken,
			}, this.httpOptions);
	}

	paymentProcessed(orderId, detailsRequestJson): Observable<any> {
		return this.http.post(this.environmentService.environment.adyenUrl + "PaymentProcessed?orderId=" + orderId + "&detailsRequestJson=" + detailsRequestJson, this.httpOptions);
	}
}
