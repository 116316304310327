/* tslint:disable:member-ordering */
import { Directive, ElementRef, Input } from '@angular/core';
import { AppEventType } from '../enums/event.type';
import { AppEvent } from '../events/app.event.class';
import { EventQueueService } from '../events/event.queue';

@Directive({
    selector: '[appAmazonpayWallet]'
})
export class AmazonpayWalletDirective {
    @Input() sellerId: string = '';
    @Input() isReadOnly: string = '';
    @Input() currency: string = '';

    el;
    constructor(el: ElementRef, private eventQueue: EventQueueService) {
        this.el = el;
    }

    ngAfterViewInit() {

        let containerId = this.el.id;
        let currencyCode = this.currency;

        if (containerId === undefined || containerId === "") {
            containerId = "walletWidgetDiv";
            this.el.nativeElement.id = containerId;
        }

        if (currencyCode === undefined || currencyCode === "") {
            currencyCode = "GBP";
        }
        this.eventQueue.on(AppEventType.onAmazonPaymentsReady).subscribe(event => {
            this.renderWallet(containerId, currencyCode);
        });

        this.eventQueue.on(AppEventType.onAmazonReRenderWallet).subscribe(data => {
            this.renderWallet(containerId, currencyCode, data['orderReference']);
        });

    }
    renderWallet = (containerId, currencyCode, orderReferenceId?) => {
        var widgetVars = {
            sellerId: this.sellerId,
            amazonOrderReferenceId: orderReferenceId,
            presentmentCurrency: currencyCode,
            onPaymentSelect: (orderReference) => {
                // this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onPaymentSelect', orderReference));
            },
            design: {
                designMode: 'responsive'
            },
            onError: (error) => {
                this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onError', {
                    code: error.getErrorCode(),
                    message: error.getErrorMessage()
                }));
            }
        };

        // Assign read only mode if set to Confirm Order on page (past MFA)
        if (this.isReadOnly === "1") {
            widgetVars['displayMode'] = "Read";
        }

        return new window.OffAmazonPayments.Widgets.Wallet(widgetVars).bind(containerId);
    }
}
