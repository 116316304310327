import { Directive } from '@angular/core';

@Directive({
  selector: '[appFlagStrap]'
})
export class FlagStrapDirective {

  constructor() { }

}
