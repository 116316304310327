import { MatchedProducts } from "./product-detail";

export interface Matchproductmodel {
	ProductId: number;
	MixAndMatchMainProductImageUrl: string;
	MixAndMatchProductImageUrl: string;
	MatchedProducts: MatchedProducts[];
	Free: string,
	AddToBasket: string;
	AddedToBasket: string;
	Title: string;
	Cufflink: string
	FreeTie: string
}

export interface MixAndMatchImageUrl {
	mixAndMatchMainProductImageUrl: string;
	mixAndMatchProductImageUrl: string;
}

export interface ImageTagAttribute {
	"Amazon": "Amazon";
	"Facebook": "Facebook";
	"MixAndMatch": "Mix And Match";
	"MixAndMatchMain": "Mix And Match Main";
	"Featured": "Featured";
}
export enum ImageTagAttributeEnum {
	Amazon = "Amazon",
	Facebook = "Facebook",
	MixAndMatch = "Mix And Match",
	MixAndMatchMain = "Mix And Match Main",
	Featured = "Featured"
}
