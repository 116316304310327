import { AfterViewInit, Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from '../../shared.service';
import { PromoLineBarEnum } from 'src/app/enums/promo-line-bar-enum';
import { DomSanitizer } from '@angular/platform-browser';
import { WebPageTypeEnum } from 'src/app/enums/web-page-type-enum';
import { SlickPromoLineBarHeader } from 'src/assets/shared/helper_scripts.js';
import { Splide } from '@splidejs/splide';

@Component({
	selector: 'app-promo-line-bar,[app-promo-line-bar]',
	templateUrl: './promo-line-bar.component.html',
	styleUrls: ['./promo-line-bar.component.scss']
})
export class PromoLineBarComponent implements OnInit, AfterViewInit {
	@ViewChild('promotext') promotext: ElementRef<HTMLInputElement>;
	@Input() webPageType?: WebPageTypeEnum;
	LpAppSetting: LpAppSetting;
	promoLineBarBodyHtml: string;
	promoLineBarBodyBgHexColour: string;
	promoLineBarBodyTextHexColour: string;

	constructor(private sharedService: SharedService, private sanitizer: DomSanitizer) { }

	async ngOnInit() {
		await this.getLpAppSetting();
		this.renderPromoLineBar(this.webPageType);
	}



	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	renderPromoLineBar(webPageType: WebPageTypeEnum = null): void {
		let param = window.location.search.substr(1);

		if (webPageType === WebPageTypeEnum.ProductDetailPage) {
			this.promoLineBarBodyHtml = this.LpAppSetting.UserSettings.PromoLineBarBodyProductPage;
			this.promoLineBarBodyBgHexColour = this.LpAppSetting.UserSettings.PromoLineBarBodyProductPage_BgHexColour;
			this.promoLineBarBodyTextHexColour = this.LpAppSetting.UserSettings.PromoLineBarBodyProductPage_TextHexColour;

			this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.BodyProductPage).subscribe(response => {
				if (response && response.MarketingTag) {
					this.promoLineBarBodyHtml = response.MarketingTag.Content.PromoLineBarBodyProductPage ?
						response.MarketingTag.Content.PromoLineBarBodyProductPage : this.promoLineBarBodyHtml;
					this.promoLineBarBodyBgHexColour = response.MarketingTag.Content.PromoLineBarBodyProductPage_BgHexColour ?
						response.MarketingTag.Content.PromoLineBarBodyProductPage_BgHexColour : this.promoLineBarBodyBgHexColour;
					this.promoLineBarBodyTextHexColour = response.MarketingTag.Content.PromoLineBarBodyProductPage_TextHexColour ?
						response.MarketingTag.Content.PromoLineBarBodyProductPage_TextHexColour : this.promoLineBarBodyTextHexColour;
				}
			});
		} else {
			this.promoLineBarBodyHtml = this.LpAppSetting.UserSettings.PromoLineBarBody;
			this.promoLineBarBodyBgHexColour = this.LpAppSetting.UserSettings.PromoLineBarBody_BgHexColour;
			this.promoLineBarBodyTextHexColour = this.LpAppSetting.UserSettings.PromoLineBarBody_TextHexColour;

			this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.Header).subscribe(response => {
				if (response) {
					if (response.MarketingTag) {
						this.promoLineBarBodyHtml = response.MarketingTag.Content.PromoLineBarBody ? response.MarketingTag.Content.PromoLineBarBody : this.promoLineBarBodyHtml;
						this.promoLineBarBodyBgHexColour = response.MarketingTag.Content.PromoLineBarBody_BgHexColour ? response.MarketingTag.Content.PromoLineBarBody_BgHexColour : this.promoLineBarBodyBgHexColour;
						this.promoLineBarBodyTextHexColour = response.MarketingTag.Content.PromoLineBarBody_TextHexColour ? response.MarketingTag.Content.PromoLineBarBody_TextHexColour : this.promoLineBarBodyTextHexColour;
					}
					SlickPromoLineBarHeader();
					this.splidePromoLineBarHader();
				}
			});
		}
	}

	splidePromoLineBarHader() {
		new Splide('.splide-carousel-promobar', {
			type: "loop",
			arrows: false,
			pagination: false,
			autoplay: true,
		}).mount();
	}

	getPromoTextColour(bgColor, promoTxtColour) {
		if (promoTxtColour) {
			let styles = {
				'background-color': bgColor,
				'--promo-text-color': promoTxtColour
			};
			return styles;
		}
		return '';
	}

	ngAfterViewInit() {
		this.createStyle();
	}

	private async createStyle(): Promise<void> {
		if (!this.LpAppSetting) {
			await this.getLpAppSetting();
		}
		const style = `.promo-line {--promo-text-color :${this.LpAppSetting.UserSettings.PromoLineBarBody_TextHexColour ? this.LpAppSetting.UserSettings.PromoLineBarBody_TextHexColour : ''}; }`;
		const styleElement = document.createElement('style');
		styleElement.appendChild(document.createTextNode(style));
		if (this.promotext && this.promotext.nativeElement)
			this.promotext.nativeElement.appendChild(styleElement);
	}

	getContent(content: string) {
		if (content) {
			return this.sanitizer.sanitize(SecurityContext.HTML, content);
		}
		return content;
	}
}
