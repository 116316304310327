import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteContentDetail } from '../../models/siteContent';
import * as $ from 'jquery';
import { SiteContentService } from '../../sitecontent.service';
@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {

  siteContent: SiteContentDetail;
  constructor(private route: ActivatedRoute, private siteContentService: SiteContentService) {
    $("body").removeClass();
    $("body").addClass("checkout ng-scope");
  }
  resultObj;
  ngOnInit() {
    this.getSiteContent();
    this.route.queryParams
      .subscribe(params => {

        if (params) {


          if (!this.resultObj) {
            this.resultObj = {} as any;
          }
          this.resultObj.Error = {} as any;
          this.resultObj.Error.result = "error";
          this.resultObj.Error.statusDetail = "";
          this.resultObj.ProgressStep = 2;
          this.resultObj.ShowPaymentButton = true;
          if (params.orderId) {

            this.resultObj.Error.orderId = params.orderId;
          }
          if (params.message) {
           
            this.resultObj.Error.message = params.message;
            
          }

        }
      }
      );
  }
  getSiteContent() {
    if (!this.resultObj) {
      this.resultObj = {} as any;
    }

    this.siteContent = this.siteContentService.getSiteContentData();
    if (this.siteContent) {
      this.resultObj.Title = this.siteContent.PaymentFailedTitle;
     
      this.resultObj.Message=this.siteContent.PaymentFailedDescription.replace(/\\/gi,"");
      this.resultObj.TryAnotherPaymentOption = this.siteContent.TryAnotherPaymentOption;

    }
    if (!this.siteContent) {
      this.siteContentService.getSiteContent().subscribe(data => {
        if (data) {
          this.resultObj.Title = data.PaymentFailedTitle;
        
          this.resultObj.Message=data.PaymentFailedDescription.replace(/\\/gi,"");
          
          this.resultObj.TryAnotherPaymentOption = data.TryAnotherPaymentOption;
        }


      })
    }
  }
}
