import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapsModalComponent } from '../maps-modal/maps-modal.component';

@Component({
  selector: 'store-detail,[store-detail]',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit {

  @Input() store:any;
  @Input() currencyPrefix:any;
  @Input() freePrefix:any;
  @Input() openingTimeText:any;
  @Input() addressText:any;
  @Input() storeList:any;
  @Input() isselectable:any;
  @Input() showprice:any;
  //@Input() storeList:any[];
  @Output() selectStoreChange = new EventEmitter();
  stchecked:boolean=false;
  selectedItem:any;
  //stores;
  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
  }
  storeAddressAddress(input) {
    if (!input) {
        return '';
    }
    var sb = '';
    if (input.StreetAddress1) { sb += input.StreetAddress1 + ', '; }

    if (input.StreetAddress2) { sb += input.StreetAddress2 + ', '; }

    if (input.City) { sb += input.City + ', '; }

    if (input.County) { sb += input.County + ', '; }

    if (input.StateCode) { sb += input.StateCode + ', '; }

    if (input.PostCode) { sb += input.PostCode + ', '; }

    if (input.Country) { sb += input.Country; }

    if (input.CountryName && input.CountryId !== 1) { sb += ', ' + input.CountryName; }

    //lower case fields
    if (input.addressLine1) { sb += input.addressLine1 + ', '; }

    if (input.addressLine2) { sb += input.addressLine2 + ', '; }

    if (input.townCity) { sb += input.townCity + ', '; }

    if (input.county) { sb += input.county + ', '; }

    if (input.stateCode) { sb += input.stateCode + ', '; }

    if (input.postCode) { sb += input.postCode + ', '; }

    if (input.postcode) { sb += input.postcode + ', '; }

    if (input.country && input.country.Name) { sb += input.country.Name; }

    return sb;
}
getMiles(inputInKms) {
  if (!inputInKms) {
      return 0.00;
  }
  return (inputInKms * 0.62137119).toFixed(2);
}
getPriceForDisplay(input, freeText, currencyPrefix) {
 
  return input == 0.0
      ? freeText
      : currencyPrefix + input;
}
openMaps(store) {


if(!this.storeList){
  this.storeList=[];
  if(store){
    this.storeList.push(store);
  }
  
}

  this.dialog.open(MapsModalComponent, {
    data: {
      storeDetails: store,
      ref: store.StoreReference,
      storeList:this.storeList
    },
    // panelClass: 'my-dialog',
    // width: '1500px',
  });

}
selectStore(store){
  store.checked= true;
  store.selectedStoreReference=store.StoreReference
  this.selectStoreChange.emit(store);
  localStorage.setItem("selectedAddress",JSON.stringify(store));
}
}
