export namespace FacetFilter {
	export class SetSourceId {
		static readonly type = '[FacetFilter] SetSourceId';
		constructor(public payload: string | null) {}
	}

	export class SetAttraqtPLPEnabled {
		static readonly type = '[FacetFilter] SetAttraqtPLPEnabled';
		constructor(public payload: boolean) {}
	}
}
