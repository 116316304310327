import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddBasket } from 'src/app/model/basket-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { Matchproductmodel } from 'src/app/modules/product-details/models/matchproduct-model';
import { CufflinkTabClick, GetCufflinkCurrentSlide, GetTieCurrentSlide, TieTabClick } from '../../../../assets/shared/helper_scripts';
import { slickSliderRefreshInstant } from '../../../../assets/shared/helper_scripts.js';
import { AddToCartData } from '../../models/cart-model';
import { CartService } from '../../service/cart.service';
import { SharedService } from '../../shared.service';
import * as $ from 'jquery';

@Component({
	selector: 'matchproductmodel,[matchproductmodel]',
	templateUrl: './matchproductmodel.component.html',
	styleUrls: ['./matchproductmodel.component.scss']
})
export class MatchproductmodelComponent implements OnInit {

	matchproductmodel: Matchproductmodel;
	page: number = 1;
	ties = [];
	cufflinks = [];
	selectedTie: any;
	addedTie: boolean = false;
	isFreeTie: boolean = false;
	selectedCufflink: any;
	addedCufflink: boolean = false;
	addBasket: AddBasket;
	LpAppSetting: LpAppSetting;
	addToCartData: AddToCartData;

	constructor(
		private _mdr: MatDialogRef<MatchproductmodelComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private cartService: CartService,
		private sharedService: SharedService,
		@Inject(PLATFORM_ID) private platformId: any) {

		if (data && data.matchproductmodel && data.matchproductmodel.MatchedProducts && data.matchproductmodel.MatchedProducts.length > 0) {
			if (data.matchproductmodel.MatchedProducts.length === 2) {
				this.ties = data.matchproductmodel.MatchedProducts[0];
				this.cufflinks = data.matchproductmodel.MatchedProducts[1];

				this.selectedTie = data.matchproductmodel.MatchedProducts[0][0];
				this.selectedCufflink = data.matchproductmodel.MatchedProducts[1][0];
			}
		}

		this.matchproductmodel = data.matchproductmodel;
		this.isFreeTie = this.matchproductmodel.Title.toLowerCase().includes("free");
	}

	ngOnInit() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();

		this._mdr.afterOpened().subscribe(() => {
			slickSliderRefreshInstant();
		})
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			slickSliderRefreshInstant();
		}
	}

	CloseDialog() {
		this._mdr.close(false);
	}

	onCufflinkTabClick() {
		this.page = 1;
		CufflinkTabClick();
	}

	onTieTabClick() {
		this.page = 2;
		TieTabClick();
	}

	addToBag(isTie, inventoryProductId) {

		this.addBasket = {} as AddBasket;
		this.addBasket.quantity = 1;
		this.addBasket.inventoryProduct = +inventoryProductId;
		this.cartService.AddToBasket(this.addBasket).subscribe(data => {

			if (data && data.result) {
				if (isTie) {
					this.addedTie = true;
				} else {
					this.addedCufflink = true;
				}

				this.cartService.displayCart = true;
				this.cartService.refreshLocalBasket(data.result, true);
			}
		});
	}

	onCufflinkClick(event) {
		var index = GetCufflinkCurrentSlide();

		if (this.cufflinks) {
			this.selectedCufflink = this.cufflinks[index];
		}
	}

	onTieClick(event) {
		var index = GetTieCurrentSlide();

		if (this.ties) {
			this.selectedTie = this.ties[index];
		}
	}

	nextArrow = "<button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>";
	prevArrow = "<button  class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;'><i></i><span></span></button>";

	config1 = { slidesToShow: 1, showDots: false, arrows: true, nextArrow: this.nextArrow, prevArrow: this.prevArrow, speed: 0, autoplay: false, asNavFor: ".cufflink-text-slide" };
	config2 = { slidesToShow: 1, showDots: false, arrows: false, speed: 0, autoplay: false, asNavFor: ".cufflink-slide" };
	config3 = { slidesToShow: 1, showDots: false, arrows: true, nextArrow: this.nextArrow, prevArrow: this.prevArrow, speed: 0, autoplay: false, asNavFor: ".tie-text-slide" }
	config4 = { slidesToShow: 1, showDots: false, arrows: false, speed: 0, autoplay: false, asNavFor: ".tie-slide" };
}
