import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, PLATFORM_ID } from "@angular/core";
import * as uuid from "uuid";
import { trustpilotEnum } from "../../../shared/enums/trustpilotEnum";
import { TrustPilotSettings } from 'src/app/model/lpapp-setting';

declare global {
	interface Window {
		Trustpilot: any;
	}
}

@Component({
	selector: "review",
	templateUrl: "./trustbox.component.html",
	styleUrls: ["./trustbox.component.scss"],
})
export class TrustboxComponent implements AfterViewInit {
	@Input() sku: string;
	@Input() trustpilotType: trustpilotEnum;
	@Input() trustPilotSettings: TrustPilotSettings;

	trustpilotEnum: typeof trustpilotEnum = trustpilotEnum;
	id: string;

	constructor(
		@Inject(PLATFORM_ID) private platformId: any) {
		this.id = "trust-" + uuid.v4();
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			const trustboxRef = document.getElementById(this.id);

			if (trustboxRef) {
				window.Trustpilot.loadFromElement(trustboxRef);
			}
		}
	}
}
