import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Label } from '../../models/Labels';
import { SiteContentDetail } from '../../models/siteContent';
import { Store, StoresViewModel } from '../../models/stores-vm';
import { WebsiteContentDetail } from '../../models/websiteContent';
import { WebsiteService } from '../../service/website.service';
import { SharedService } from '../../shared.service';
import { currentStore } from 'src/app/model/store';
import { map } from 'rxjs';

@Component({
	selector: 'store-list,[store-list]',
	templateUrl: './store-list.component.html',
	styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {
	@Input() sitecontent: SiteContentDetail;
	@Input() label: Label;
	@Input() stores: StoresViewModel;
	@ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow
	websiteContentDetail: WebsiteContentDetail;
	previous;
	infoContent = '';

	// initial center position for the map
	lat: number;
	lng: number;
	zoomLevel: number = 13;
	activeMarkerIcon = "/assets/maps/maps_marker_active.png";

	zoom = 13;
	center: google.maps.LatLngLiteral;
	options: google.maps.MapOptions = {
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		mapTypeControlOptions: { mapTypeIds: [google.maps.MapTypeId.ROADMAP] },
		zoomControl: true,
		scrollwheel: true,
		disableDoubleClickZoom: true,
		maxZoom: 15,
		minZoom: 8,
	};
	storeData : any[] = [];;
	storesMapData;

	constructor(private activatedRoute: ActivatedRoute, private sharedService: SharedService,
		private titleService: Title, private meta: Meta, private websiteService: WebsiteService,
		@Inject(PLATFORM_ID) private platformId: any) {

	}

	processStoreDetails(res: any) {
		this.storesMapData = res.StoresJson;
		

		this.storesMapData.map(store => {
			this.storeData.push({
				position: {
					lat: store.Latitude,
					lng: store.Longitude,
				},
				store: store,
				options: { icon: "/assets/maps/maps_marker_active.png" }
			});
		})
	}

	ngOnInit() {

		this.sharedService.getStoresDetails().pipe(
			map(response => {
				const otherStoreDetails = response;
				return { otherStoreDetails: otherStoreDetails };
			})
		)
		.subscribe((data) => {
			this.processStoreDetails(data.otherStoreDetails);
		});

		// this.setTitle();
		if (this.stores && this.stores.StoresJson && this.stores.StoresJson.length > 0) {
			this.center = {
				lat: this.stores.StoresJson[0].Latitude,
				lng: this.stores.StoresJson[0].Longitude,
			};
		}
	}

	getFullAddress(store) {
		let address = store.FullAddress.trim().replace(/(\r\n|\n|\r)/gm, "<br/>");
		return address;
	}

	getFormatedPhoneNumber(store) {
		let phoneNumber = store.PhoneNumber;
		if (phoneNumber.length >= 10) {
			phoneNumber = phoneNumber.substr(0, 3) + " " + phoneNumber.substr(3, 4) + "" + phoneNumber.substr(7);
			return phoneNumber;
		}
		return phoneNumber;
	}

	// setTitle() {
	// 	if (this.stores) {
	// 		if (this.stores.WebStoreId === 2) {
	// 			this.titleService.setTitle("Ladenlokalitäten | Hawes & Curtis");
	// 			this.meta.updateTag({ name: 'description', content: "Wir sind weltweit mit unseren Ladenlokalitäten vertreten. Von Dubai bis Deutschland – Entdecken Sie Hawes & Curtis!" });
	// 		} else {
	// 			this.titleService.setTitle("Hawes and Curtis Stores UK");
	// 			this.meta.updateTag({ name: 'description', content: "Find a Hawes and Curtis store near you with our store locator." });
	// 		}
	// 	}
	// }

	getAddress(store: Store, includeCountry: boolean) {

		let excludeUk = true;
		let includePhone = false;
		let includeCustomerName = true
		let sb = '';
		if (store && store.Address) {

			if (includeCustomerName && store.Address.FirstName || store.Address.LastName) {
				sb = store.Address.FirstName + store.Address.LastName + ",";
			}

			if (store.Address.StoreName) {
				sb += store.Address.StoreName + ",";
			}

			if (store.Address.StreetAddress1) {

				sb += store.Address.StreetAddress1 + ",";
			}

			if (store.Address.StreetAddress2) {

				sb += store.Address.StreetAddress2 + ",";
			}

			if (store.Address.City) {

				sb += store.Address.City + ",";
			}

			if (store.Address.County) {

				sb += store.Address.County + ",";
			}

			if (store.Address.StateCode) {

				sb += store.Address.StateCode + ",";
			}

			if (store.Address.PostCode) {
				sb += store.Address.PostCode;
			}

			if (includeCountry && store.Address.Country) {
				if (!(excludeUk && store.Address.CountryId == 1)) {

					sb += store.Address.Country + ",";
				}
			}

			if (includePhone && store.Address.Phone) {
				sb += store.Address.Phone + ",";
			}

			return sb;
		}
	}

	clickedMarker(infowindow, Latitude, Longitude) {
		if (this.previous) {
			this.previous.close();
		}
		this.previous = infowindow;
		this.lat = Latitude;
		this.lng = Longitude;
		this.zoomLevel = 13;
	}
	selectedStore: currentStore;

	openInfo(marker: MapMarker, store: currentStore) {
		this.selectedStore = store;
		this.infoWindow.open(marker);
	}

	zoomChange(e) {
		this.zoomLevel = e;
	}

	isBrowser() {
		if (isPlatformBrowser(this.platformId)) {
			return true;
		}
	}
}
