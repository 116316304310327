// AR 20230918: Copy of the enum used in .NET
// \Common\Entities\Constants\ImageTypeKey.cs
// This should be kept insync

export enum ImageTypeKey {
	Thumbnail = "thumbnail",
	Medium = "medium",
	OldMedium = "old-medium",
	Large = "large",
	Zoom = "zoom",
	MediumNew = "medium-new",
	NewThumbnail = "n-thumb",
	NewMedium = "n-medium",
	NewLarge = "n-large",
	NewZoom = "n-zoom",
	New2Thumbnail = "n2-thumb",
	New2Medium = "n2-medium",
	New2Large = "n2-large",
	New2Zoom = "n2-zoom",
	Transparent = "transparent",
	Featured = "featured",

	// No references
	SmallThumbnail = "small-thumbnail",
	SmallMedium = "small-medium",
	ExtraSmallThumbnail = "extra-small-thumbnail",
	MediumTall = "medium-tall",
	LargeTall = "large-tall",
	ZoomTall = "zoom-tall",
	LargeNew = "large-new",
	MediumSuit = "medium-suit",
	MediumBig = "medium-big",
	LargeBig = "large-big",
	ZoomBig = "zoom-big",
	NewSmallThumbnail = "n-smallthumb",
	NewMediumNew = "n-medium-new",
	NewLargeZoom = "n-largezoom",
	NewMediumTall = "n-medium-tall",
	NewLargeTall = "n-large-tall",
	NewZoomTall = "n-zoom-tall",
}
