import { Directive, HostListener, Input } from '@angular/core';
import * as $ from 'jquery';
@Directive({
  selector: '[datascrollto]'
})
export class ScrolltoDirective {

  @Input('datascrollto') classname: string;
 
  constructor( ) { }


  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
  
  if(this.classname){
    let scrollSelector=this.classname;
    var scrollPos = 0;

    if (scrollSelector !== undefined && scrollSelector !== null && scrollSelector !== "") {

        if ($(".site-header").height() === undefined) {
            if ($(scrollSelector).position() !== undefined) {
                scrollPos = $(scrollSelector).position().top;
            }
        }
        else {
            var selectorOffsetTop = $(scrollSelector).offset() !== undefined ? $(scrollSelector).offset().top : 0;
            scrollPos = selectorOffsetTop - $(".site-header").height();
        }

    }

    $('html, body').animate({
        scrollTop: scrollPos
    }, 800);
  }
   
    
  }

}
