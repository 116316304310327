import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appIconBarModal]'
})
export class IconBarModalDirective implements AfterViewInit{

  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
  }
  @HostListener("click", ['$event'])
  onClick(event) {
   // modalService.showIconBarModal(scope.message, scope.title);
    event.preventDefault();
  }
}
