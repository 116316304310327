import { ErrorHandler, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class MyErrorHandler implements ErrorHandler {
	constructor(@Optional() @Inject('ERROR_WRAPPER') private errorWrapper: any) {

	}

	handleError(error: Error) {

		console.log(this.getTimestamp(), 'Custom Error Handler error: ' + error.toString());
		console.log(this.getTimestamp(), error.stack);

		if (this.errorWrapper)//serverSide
		{
			this.errorWrapper.error = error;
		}
	}

	getTimestamp() {
		return `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`;
	}
}
