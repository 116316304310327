import { Component, Input, OnInit } from '@angular/core';
import { TextBannerVM } from 'src/app/model/control-viewmodel';

@Component({
  selector: '[textbanner],textbanner',
  templateUrl: './textbanner.component.html',
  styleUrls: ['./textbanner.component.scss']
})
export class TextbannerComponent implements OnInit {
  @Input() textBannerVM: TextBannerVM;
  constructor() {

  }

  ngOnInit() {
  }

}
