import { Component, Input, ViewEncapsulation } from '@angular/core';

import { BreadcrumbItem } from 'src/app/model/bread-crum-item';
import {
	BrandHeritageVm,
	ContentPageRowVM,
} from 'src/app/model/content-page-row-viewmodel';

@Component({
	selector: '[stacked],stacked',
	templateUrl: './stacked.component.html',
	styleUrls: ['./stacked.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class StackedComponent {
	@Input() brandHeritageVm: BrandHeritageVm;
	@Input() contentPageRowVM: ContentPageRowVM;
	@Input() breadcrumbs: BreadcrumbItem[];

	constructor() {}
}
