import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { SharedService } from "src/app/shared/shared.service";

@Injectable()
export class MenuItemsResolver {

	constructor(private restRequest: SharedService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		// In my case i am using custom service for getting rest calls but you can use simple http.get()...
		return this.restRequest.getMenus();
	}
}
