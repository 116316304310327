import { Component, Input, OnInit } from '@angular/core';
import { SizeChartContent } from 'src/app/model/size-chart-content';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
  selector: '[fitsizechart],fitsizechart',
  templateUrl: './fitsizechart.component.html',
  styleUrls: ['./fitsizechart.component.scss']
})
export class FitsizechartComponent implements OnInit {
  @Input() sizeChartContent: SizeChartContent;
  isGermany: boolean = false;
  currentRow: number = 0;
  toggle: boolean = false;
  siteContent: SiteContentDetail;

  constructor(private siteContentService: SiteContentService) { }

  ngOnInit() {
    this.siteContent = this.siteContentService.getSiteContentData();
  }
  getHeader(header) {
    if (header) {
      return header.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    return header;
  }
}
