import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Label } from '../../models/Labels';
import { LabelService } from '../../service/label.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: '[progress],progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
@Input() model:number;
label:Label;
  constructor(private router: Router,private route:ActivatedRoute,
    private sharedService:SharedService, private labelService: LabelService) {

     }

  ngOnInit() {
    this.label = this.labelService.getLabelData();
    if(!this.label) {
      this.labelService.getLabels().subscribe(data => {
        if (data) {
          this.label = data;
        }
      });
    }
  }
  setDelivery(){
    this.router.navigate(['checkout/delivery']);
  }

}
