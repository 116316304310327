import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';

import { FacetFilter } from './facet-filter.action';
import { IFacetFilterState } from '../../interfaces/attraqt/facet-filter-state.interface';

const FACET_FILTER_STATE_TOKEN = new StateToken<IFacetFilterState>(
	'facetFilter'
);

@State({
	name: FACET_FILTER_STATE_TOKEN,
	defaults: {
		sourceId: null,
		attraqtPLPEnabled: false,
	},
})
@Injectable()
export class FacetFilterState {
	@Action(FacetFilter.SetSourceId)
	private setSourceId(
		ctx: StateContext<IFacetFilterState>,
		action: FacetFilter.SetSourceId
	) {
		const state = ctx.getState();
		ctx.setState({
			...state,
			sourceId: action.payload,
		});
	}

	@Action(FacetFilter.SetAttraqtPLPEnabled)
	private setAttraqtPLPEnabled(
		ctx: StateContext<IFacetFilterState>,
		action: FacetFilter.SetAttraqtPLPEnabled
	) {
		const state = ctx.getState();
		ctx.setState({
			...state,
			attraqtPLPEnabled: action.payload,
		});
	}
}
