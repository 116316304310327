import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { mobilemenuTriggerToggle } from '../../../../../assets/shared/helper_scripts.js'
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';

@Component({
	selector: 'mobile-nav-bar,[mobile-nav-bar]',
	templateUrl: './mobile-nav-bar.component.html',
	styleUrls: ['./mobile-nav-bar.component.scss']
})
export class MobileNavBarComponent implements OnInit, OnDestroy {

	@Output() closeMenu = new EventEmitter<string>();
	@Input() menuItems;

	openNavMobile: boolean = false;
	isLoggedIn = false;
	addCss = false;
	list;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	isMenSelected: boolean = true;
	isWomenSelected: boolean = false;
	sitecontent: SiteContentDetail;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(private router: Router, private attraqtService: AttraqtService, private siteContentService: SiteContentService,) { }

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.subscribeToState();
		this.isLoggedIn = localStorage.getItem('isSignedIn') ? true : false;
		this.list = this.menuItems;
		if (this.list && this.list.length > 0) {

			for (var i = 0; i < this.list.length; i++) {
				if (this.list[i]) {
					this.list[i].IsSelected = false;
				}

			}
		}

		this.setToDefault();

		function updateSelectedStatus(data: any): void {
			if (data.IsSelected) {
				data.NavigationStatus = false;
			}
		
			if (data.Children) {
				data.Children.forEach((child: any) => {
					updateSelectedStatus(child);
				});
			}
		}
		
		this.menuItems.forEach(item => {
			updateSelectedStatus(item);
		});

	}

	setToDefault(){
		this.menuItems.forEach(item => {
			item.IsSelected = true;
			item.NavigationStatus = false;
			
			if (item.Children) {
				item.Children.forEach(child => {
					child.IsSelected = false
					child.NavigationStatus = false;
					if (child.Children) {
						child.Children.forEach(grandchild => {
							this.isGrdandChild = false;
							grandchild.IsSelected = false;
						});
					}
				});
			}
		});
	}

	menButtonClicked() {
		this.isMenSelected = true;
		this.isWomenSelected = false;

		this.setToDefault();
	}

	womanButtonClicked() {
		this.isMenSelected = false;
		this.isWomenSelected = true;

		this.setToDefault();
	}

	selectAllMenuItems(items) {
		if (items != null) {
			for (let index = 0; index < items.length; index++) {
				items[index].IsSelected = true;
			}
		}
	}
	
	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	goToUrl(url) {
		this.closeMenu.emit('closemenu');
		this.router.navigate([url]);
	}

	refreshCss(add: boolean) {
		this.addCss = add ? true : false;
	}

	signOut() {
		this.closeMenu.emit('closemenu');
		localStorage.clear();
		this.router.navigate(['']);
	}

	showSubItems(name, thisVal) {

		if (!$('#' + name + 'childUl').hasClass('showUl')) {
			$('#' + name + 'childUl').addClass('showUl');
		}
		else {
			$('#' + name + 'childUl').removeClass('showUl');
		}
	}

	openMobileNav() {
		if (!this.openNavMobile) {
			this.openNavMobile = true;
		} else {
			this.openNavMobile = false;
		}
	}

	storeIndex = -1;
	handleLevelZero(menuItem, selectedIndex) {
		console.log('level 0');
		menuItem.NavigationStatus = !menuItem.NavigationStatus;
		let menuExpanded = false;
		this.menuItems.forEach(item => {
			if (item.IsSelected) {

				menuExpanded = true;
			}
		});

		if (this.menuItems.every(item => item.IsSelected)) {
			menuExpanded = false;
		}
		if (menuExpanded) {
			this.menuItems.forEach(item => {
				item.IsSelected = true;
				
				if (item.Children) {
					item.Children.forEach(child => {
						child.IsSelected = false
						child.NavigationStatus = false;
						if (child.Children) {
							child.Children.forEach(grandchild => {
								this.isGrdandChild = false;
								grandchild.IsSelected = false;
							});
						}
					});
				}
			});
		}
		else {
			for (let index = 0; index < this.menuItems.length; index++) {
				const isSelected = index === selectedIndex;
				this.menuItems[index].IsSelected = isSelected;
				if (isSelected) {
						this.selectAllMenuItems(this.menuItems[index].Children);
						this.storeIndex = index;
				}

			}
		}
	}

	checkGrandChild() {
		this.menuItems.forEach(item => {
			if (item.Children) {
				item.Children.forEach(child => {
					child.IsSelected = false
					child.NavigationStatus = false
					if (child.Children) {
						child.Children.forEach(grandchild => {
							if (grandchild.IsSelected) {
								this.isGrdandChild = true;
							}

						});
					}
				});
			}
		});
	}

	isGrdandChild: boolean = false;
	handleLevelOne(menuItem, selectedIndex) {
		this.checkGrandChild()

		if (!this.isGrdandChild) {
			const storeIndex = this.storeIndex;
			const children = this.menuItems[storeIndex]?.Children || [];
			children.forEach((child, index) => {
				const isSelected = index === selectedIndex;
				child.IsSelected = isSelected;
				child.NavigationStatus = !child.NavigationStatus
				if (isSelected) {
					this.selectAllMenuItems(child.Children);
				}
			});
		} else {
			this.menuItems.forEach(item => {
				if (item.Children) {
					item.Children.forEach(child => {
						child.IsSelected = true
						if (child.Children) {
							child.Children.forEach(grandchild => {

								grandchild.IsSelected = false;
								if (!grandchild.IsSelected) {
									this.isGrdandChild = false;
								}

							});
						}
					});
				}
			});
		}

	}

	resetLevelZeroSub() {
		let grandChildClicked = false;
		this.menuItems.forEach(item => {
			if (item.Children) {
				item.Children.forEach(child => {
					if (child.Children) {
						child.Children.forEach(grandchild => {
							if (grandchild.IsSelected) {
								grandChildClicked = true;
							}
						});
					}
				});
			}
		});

		return grandChildClicked;
	}

	goMenuItem(menuItem, i) {
		
		if (menuItem.Level === 0) {
			this.handleLevelZero(menuItem, i);
		} else if (menuItem.Level === 1) {
			this.handleLevelOne(menuItem, i);
		}

		if (menuItem.HasChildren) {
			mobilemenuTriggerToggle(menuItem.Children[0].$id);
		}

		if (!menuItem.HasChildren) {
			menuItem.IsSelected = false;
			if (menuItem && menuItem.Url && (menuItem.Url.startsWith('https://') || menuItem.Url.startsWith('http://'))) {
				window.location.href = menuItem.Url;
			} else {
				if (this.facetFilterState.attraqtPLPEnabled) {
					const { protocol, host } = window.location
					this.attraqtService.sendActivity(
						new AttraqtActivityBuilder(AttraqtActivityActionEnum.View)
							.setTarget({ pathToPage: `${protocol}//${host}${menuItem.Url}` })
							.setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) || 'en_GB' })
							.build()
					);
				}
				this.router.navigate([menuItem.Url]);
			}

			this.closeMenu.emit();
			this.setToDefault();
		}
	}

	userRedirect() {
		if (localStorage.getItem('isSignedIn') != "true") {
			this.router.navigate(['account']);
		} else {
			this.router.navigate(['myaccount']);
		}
		this.closeMenu.emit('closemenu');
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
