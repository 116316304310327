import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SizeChartContent } from "src/app/model/size-chart-content";
import { SharedService } from "../../shared.service";
import * as $ from "jquery";
import { SiteContentDetail } from "../../models/siteContent";
import { SiteContentService } from "../../sitecontent.service";

@Component({
    selector: "app-style-guide-image",
    templateUrl: "./style-guide-image.component.html",
    styleUrls: ["./style-guide-image.component.scss"],
})
export class SizeGuideImageComponent implements OnInit {
    @Input()  firstRow;
    @Input() row;
    @Input() selectedSize;
    @Input() selectedFilter;
    secondaryColumns = [];
    secondaryToShow = [];
    collengthval  = "";

    ngOnChanges() {
        this.updateUI();
    }
    ngOnInit() {
    
    }

    updateUI(){
        let firstColumn = this.row != null ? this.row.Columns[0] : null;

        this.secondaryColumns = this.row.Columns.filter(c => c.ColumnNumber != firstColumn.ColumnNumber)

        var colval = !firstColumn.Value ? '' : firstColumn.Value;
        var _colvalsplit = colval.split('|');
        if (_colvalsplit.length > 0)
        {
            colval = _colvalsplit[0].trim();
            if (_colvalsplit.length > 1)
            {
                this.collengthval = _colvalsplit[1].trim();
            }
        }
        if(colval == "ImageUrl") {
            this.secondaryColumns.forEach((column, index) => {
                var indexval = column.ColumnNumber;
                var SizeValue = null;
                for(let i=0;i< this.firstRow.Columns.length;i++) {
                    if(this.firstRow.Columns[i].ColumnNumber == indexval) {
                        SizeValue = this.firstRow.Columns[i];
                    }
                }
                var selectedclass = "";
                if (SizeValue.Value == this.selectedSize || SizeValue.Value.split(" ")[0].toLocaleLowerCase().trim() == this.selectedSize.split(" ")[0].toLocaleLowerCase().trim())
                {
                    selectedclass = "active";
                }
                else
                {
                    if (index == 0 && !this.selectedSize)
                    {
                        selectedclass = "active";
                    }
                    else
                    {
                        selectedclass = "";
                    }
                }
                column.isImage = true;
                column.showClass = `size-image size-${SizeValue.Value} ${selectedclass}`;
            })
        }
    }
    
}
