import { Component, Input, OnInit, PLATFORM_ID, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from 'src/app/modules/Home/home.service';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { SiteContentDetail } from '../../models/siteContent';
import { LabelService } from '../../service/label.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { MapsModalComponent } from '../maps-modal/maps-modal.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'find-postal-code,[find-postal-code]',
  templateUrl: './find-postal-code.component.html',
  styleUrls: ['./find-postal-code.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class FindPostalCodeComponent implements OnInit {
  
  constructor(public dialog: MatDialog, private router: Router,
     private fb: UntypedFormBuilder, private homeService: HomeService, 
     private commonService: DefaultService,private sharedService: SharedService,
     private siteContentService: SiteContentService,
    private labelService: LabelService, @Inject(PLATFORM_ID) private platformId: any) { }
  findPostCodeForm: UntypedFormGroup;
  stores;
  store: any;
  coll: any;
  slicedStores;
  showDescription: boolean = false;
  nextDelivery = '';
  shippingOptions;
  isSelected: boolean = false;
  selectedStore;
  acceptedTerms = false;
  data;
  showMore = false;
  clickedMore;
  sitecontent:SiteContentDetail;
  regExLibrary: RegExLibrary;
  label: Label;
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.sitecontent = this.siteContentService.getSiteContentData();
      this.label = this.labelService.getLabelData();
      this.getRegExLibrary();
      this.initiatePostalForm();
    }
}

  initiatePostalForm() {

    this.findPostCodeForm = this.fb.group({
      postCodeFind: ['', Validators.required],
      title: [1, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      phoneCode: ['GB'],
      addAsAGift: [false, Validators.required],
      acceptCollectingOrder: [false, Validators.required],
      addressId: ['']
    });

  }

  round(value) {
    let convertMiles = value / 1.609;
    let convertedValue = Math.round(convertMiles * 100) / 100;
    return convertedValue;
  }

  onSelectAddress(store) {

    this.selectedStore = store;
    this.isSelected = true;
    store.isSelected = true;

  }
  getRegExLibrary() {

    this.sharedService.getRegExLibrary().subscribe(data => {
      if (data) {
        this.regExLibrary = data;
      }
    });
  }
  submitfindPostCodeForm() {

    this.homeService.getPostcodeDetails(this.findPostCodeForm.value).subscribe(res => {
      let data = res.desc.Stores;
      this.data = res;
      data.filter(value => {
        value['isSelected'] = false;
      });
      this.isSelected = false;
      this.selectedStore = [];
      this.nextDelivery = res.desc.Stores.NextDelivery;
      this.shippingOptions = res.desc.ShippingOptions;
      this.stores = data.slice(0, 2);
      this.slicedStores = data;
    });

  }

  acceptedTerm() {

    if (this.findPostCodeForm.controls['acceptCollectingOrder'].value == false) {
      this.acceptedTerms = true;
    } else {
      this.acceptedTerms = false;
    }

  }

  submitForm() {

    if (this.findPostCodeForm.controls['acceptCollectingOrder'].value == false && !this.findPostCodeForm.valid) {
      this.acceptedTerms = true;
      this.commonService.validateAllFormFields(this.findPostCodeForm);
      return;
    } else if (this.findPostCodeForm.controls['acceptCollectingOrder'].value == false) {
      this.acceptedTerms = true;
      return;
    } else if (!this.findPostCodeForm.valid) {
      this.commonService.validateAllFormFields(this.findPostCodeForm);
      return;
    }
    this.findPostCodeForm.controls['addressId'].setValue(this.selectedStore.Address.AddressDetailId);
    this.homeService.saveClickAndCollectAddress(this.findPostCodeForm.value).subscribe(res => {
      if (res.result == "Success") {
        this.router.navigate(['/checkout/payment']);
      }
    })
  }

  openMaps(store) {

    this.dialog.open(MapsModalComponent, {
      data: {
        storeDetails: this.stores,
        ref: store.StoreReference
      },
      panelClass: 'my-dialog',
      width: '1500px',
    });

  }

  showMoreAddresses() {

    if (!this.showMore) {
      this.showMore = true;
      this.stores = this.slicedStores;
    } else {
      this.showMore = false;
      this.stores = this.slicedStores.slice(0, 2);
    }

  }

  getButtonText() {
    return "ss";
  }
  getGeneratedMessage(toReplace, text) {
    return text.replace('{0}', toReplace);
  }

}

