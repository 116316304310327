import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuVM } from 'src/app/model/content-page-row-viewmodel';
import { ProductFromSkus } from 'src/app/model/product-from-skus';
import { ProductWebPageModel } from 'src/app/model/product-webpage-model';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { SubPageVM } from 'src/app/model/subpage-viewmodel';
import { TrackingScript } from 'src/app/model/tracking-script';
import { WebPageSearchVM } from 'src/app/model/webpage-search-vm';
import { WebPageVM } from 'src/app/model/webpage-viewmodel';
import { ProductDetails } from 'src/app/modules/product-details/models/product-detail';
import { SearchModel } from 'src/app/modules/product-list/model/search-model';
import { EnvironmentService } from '../../shared/environment.service';
import { MixAndMatchImageUrl } from '../product-details/models/matchproduct-model';
import { AlgoliaProductViewModel } from '../product-list/model/algolia-product-viewmodel';
import { AlgoliaProduct } from '../product-list/model/product-list';
import { SearchVM } from '../product-list/model/search-viewmodel';

const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root'
})

export class DefaultService {

	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	private homePageCache$: Observable<WebPageVM>;
	private _bannerUrlSubject = new Subject<string>();
	[key: string]: any;

	constructor(
		private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	getHomePage(): Observable<WebPageVM> {

		if (!this.homePageCache$) {

			this.homePageCache$ = this.requestHomePage().pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this.homePageCache$;
	}

	getCategoryWebPage(url: string): Observable<ProductWebPageModel> {
		return this.httpClient.get<ProductWebPageModel>(this.environmentService.environment.siteUrl + 'getcategorywebpage?url=' + url, this.httpOptions);
	}

	getProductReviews(widgetBusinessUnitId: string, apiKey: string, productSku: string): Observable<any> {
		return this.httpClient.get<any>("https://api.trustpilot.com/v1/product-reviews/business-units/" + widgetBusinessUnitId + "/reviews?apikey=" + apiKey + "&sku=" + productSku);
	}

	getSubPageList(subpagevm: SubPageVM): Observable<WebPageSearchVM> {
		return this.httpClient.post<WebPageSearchVM>(this.environmentService.environment.siteUrl + "getsubpagelistsearch", subpagevm, this.httpOptions);
	}

	getMenuList(menuvm: MenuVM): Observable<string> {
		return this.httpClient.post<string>(this.environmentService.environment.siteUrl + "MenuListControl", menuvm, this.httpOptions);
	}

	getProductFromSkus(productFromSkus: ProductFromSkus): Observable<RelatedProductVM> {

		if (!this["productfromsku" + productFromSkus.Skus]) {

			this["productfromsku" + productFromSkus.Skus] = this.requestProductFromSkus(productFromSkus).pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this["productfromsku" + productFromSkus.Skus];
	}

	getProductCategoryDescriptionByUrl(url: string): Observable<string> {

		if (!this["category" + url]) {

			this["category" + url] = this.requestProductCategoryDescriptionByUrl(url).pipe(
				shareReplay(CACHE_SIZE)
			);
		}
		return this["category" + url];
	}

	private requestProductCategoryDescriptionByUrl(url: string): Observable<string> {
		return this.httpClient.get<string>(this.environmentService.environment.siteUrl + 'getdata?url=' + url, this.httpOptions);
	}

	GetProductsByCategoryUrl(url: string): Observable<SearchModel> {
		if (!this[url]) {

			this[url] = this.requestProductsByCategoryUrl(url).pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this[url];
	}

	private requestProductsByCategoryUrl(url: string): Observable<SearchModel> {
		return this.httpClient.get<SearchModel>(this.environmentService.environment.siteUrl + 'getproductsbycategoryUrl?url=' + url, this.httpOptions);
	}

	ProductDetailsById(id: any): Observable<ProductDetails> {
		if (!this[id]) {

			this[id] = this.requestProductDetailsByProductCode(id).pipe(
				shareReplay(CACHE_SIZE)
			);
		}

		return this[id];
	}

	private requestProductDetailsByProductCode(code): Observable<ProductDetails> {
		return this.httpClient.get<ProductDetails>(this.environmentService.environment.siteUrl + 'ProductDetailsByUrl?url=' + code, this.httpOptions);
	}

	private requestProductFromSkus(productFromSkus: ProductFromSkus): Observable<RelatedProductVM> {
		return this.httpClient.post<RelatedProductVM>(this.environmentService.environment.siteUrl + 'productsfromskus', productFromSkus, this.httpOptions).pipe(
			map(response => response)
		);
	}

	private requestHomePage() {
		return this.httpClient.get<WebPageVM>(this.environmentService.environment.siteUrl + 'gethomepage', this.httpOptions).pipe(
			map(response => response)
		);
	}

	getAlogoliaRefreshFlag() {
		return this.httpClient.get<boolean>(this.environmentService.environment.siteUrl + 'flag', this.httpOptions);
	}

	GetAlgoliaProduct(categoryId: string): Observable<AlgoliaProduct[]> {
		return this.httpClient.get<AlgoliaProduct[]>(this.environmentService.environment.siteUrl + 'GetAlgoliaProducts?CategoryId=' + categoryId, this.httpOptions);
	}

	GetTrackingScripts(webPageVM): Observable<TrackingScript[]> {
		return this.httpClient.post<TrackingScript[]>(this.environmentService.environment.siteUrl + 'TrackingScripts', webPageVM, this.httpOptions);
	}

	GetMatchedProductImageUrl(productId: number): Observable<MixAndMatchImageUrl> {
		return this.httpClient.get<MixAndMatchImageUrl>(this.environmentService.environment.siteUrl + 'GetMatchedProductImageUrl?productId=' + productId, this.httpOptions);
	}

	bannerUrlEvent(event) {
		this._bannerUrlSubject.next(event);
	}

	get getBannerUrlEvent$() {
		return this._bannerUrlSubject.asObservable();
	}

	AjaxProductsFromCategories(seachVM: SearchVM): Observable<AlgoliaProductViewModel> {
		return this.httpClient.post<AlgoliaProductViewModel>(this.environmentService.environment.siteUrl + 'AjaxProductsFromCategories', seachVM, this.httpOptions);
	}
}
