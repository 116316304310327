export const currencies = [
  {
    currency: "United Kingdom Pound",
    code: "GBP",
    symbol: "£",
  },
  {
    currency: "Euro Member Countries",
    code: "EUR",
    symbol: "€",
  },
  {
    currency: "United States Dollar",
    code: "USD",
    symbol: "$",
  },
  {
    currency: "Australia Dollar",
    code: "AUD",
    symbol: "A$",
  },
];
