import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { SiteContent, SiteContentDetail, SocialLinkListItem } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { MenuItem } from '../../models/menuItem';
import { SiteContentService } from '../../sitecontent.service';

@Component({
  selector: 'app-checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrls: ['./checkout-footer.component.scss']
})
export class CheckoutFooterComponent implements OnInit {
  siteContent: SiteContentDetail;
  menuItems: MenuItem[];

  socialLinkListItems$: Observable<SocialLinkListItem[]>;
  copyrightContent: string;
  year = new Date().getFullYear().toString();
  LpAppSetting: LpAppSetting;
  host: string;

  constructor(private defaultService: DefaultService,
    private sharedService: SharedService, private route: ActivatedRoute,
    private siteContentService: SiteContentService) {

  }
  ngOnInit() {
    this.getSiteContent();
    this.getLpAppSetting();
  }

 

  getSiteContent() {

    this.siteContent = this.siteContentService.getSiteContentData();
    if (!this.siteContent) {
      this.siteContentService.getSiteContent().subscribe(data => {
        this.siteContent = data;
      })
    }
  }

  private async getLpAppSetting(): Promise<void> {
    this.LpAppSetting = await this.sharedService.getLpAppSettings();
  }

}
