import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'userfriendly',
    pure: false
})
export class UserFriendlyPipe implements PipeTransform {
    transform(input: any,countryId:number): any {
        // Remove the duplicate elements
        if (input != null && input.length > 0) {
            if (input.toLowerCase() === 'adyen' && countryId != 2) {
                return "Debit/Credit Card";
            } else if (input.toLowerCase() === 'adyen' && countryId == 2) {
                return "Debitkarte:"; // Renamed from "Kreditkarte"
            } else if (input.toLowerCase() === 'klarna' && countryId != 2) {
                return "Buy Now Pay Later";
            } else if (input.toLowerCase() === 'klarna' && countryId == 2) {
                return "Per Rechnung zahlen";
            } else if (input.toLowerCase() === 'klarnav3' && countryId != 2) {
                return "Debit/Credit Card";
            }
            return input;
        }
    }
}
