import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AuthService } from '../../auth.service';
import { OrdersViewModel } from '../../models/orderVM';

enum OrderShipmentStateEnum {
	NotShipped = 1,
	ReadyForShipping = 2,
	Picking = 3,
	Shipped = 4,
	PartiallyShipped = 5,
	OnHold = 6,
	Closed = 7,
	WaitingForSupplier = 8,
	ReturnRejected = 9,
	PreHold = 10,
	InTailoring = 11,
}

@Component({
	selector: 'app-web-orders',
	templateUrl: './web-orders.component.html',
	styleUrls: ['./web-orders.component.scss']
})
export class WebOrdersComponent implements OnInit {
	pageSize = 36;
	totalPages = 1;
	pageNumber = 1;
	ordersViewModel: OrdersViewModel;
	ordersModal = [];

	@Input() resetWebOrdersCompnenet: Subject<boolean> = new Subject<boolean>();
	sitecontent: SiteContentDetail;

	constructor(
		private authService: AuthService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		this.resetWebOrdersCompnenet.subscribe(response => {
			if (response) {

			}
		});

		this.authService.getWebOrders().subscribe(res => {
			this.ordersViewModel = res;
		});
	}

	scrollTop() {
		this.scrollService.windowScrollTo(0, 0);
	}

	getShippmentStatus(status) {

		const shipped = status == OrderShipmentStateEnum.Shipped || status == OrderShipmentStateEnum.PartiallyShipped || status == OrderShipmentStateEnum.Closed;
		return shipped;
	}
}
