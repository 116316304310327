import { currencies } from "../constants/currency";

const resolveCurrencyBySymbol = (currencySymbol: string) => {
  const found = currencies.find(({ symbol }) => symbol === currencySymbol);
  if (found) {
    return found.code;
  }
  return null;
};

export default resolveCurrencyBySymbol;
