import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultService } from '../../layout/default.service';
// import { DefaultService } from 'src/app/layouts/default/default.service';
import * as productCategory from '../../product-details/models/category-description';

@Component({
  selector: 'headerbanner,[headerbanner]',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent implements OnInit {
  @Input() productCategoryUrl: string;
  productCategory: productCategory.ProductCategory.Category;
  content: string;


  constructor(private router: Router, private defaultService: DefaultService) { }


  ngOnInit() {
  }

  ngOnChanges() {
    if (this.productCategoryUrl) {
      this.getProductCategoryDescription(this.productCategoryUrl);
    }

  }

  getProductCategoryDescription(url: string) {

    this.defaultService.getProductCategoryDescriptionByUrl(url).subscribe(data => {
      this.productCategory = JSON.parse(data);
      if (this.productCategory && this.productCategory.Webpage && this.productCategory.Webpage.WebPageRows) {
        this.productCategory.Webpage.WebPageRows.forEach(element => {
          if (element.RowStylePartialView.toLocaleLowerCase() === "headerbanner") {
            if (element.Controls && element.Controls.length > 0) {
              element.Controls.forEach(control => {
                if (control && control.Properties && control.Properties.length > 0) {
                  this.content = '';
                  if (control.ControlTypeName.toLocaleLowerCase() === "banner") {
                    control.Properties.forEach(prop => {
                      if (prop.ControlTypeElementKey.toLocaleLowerCase() === "title") {
                        this.content = "<h3>" + prop.Value + "</h3>";
                      }
                      if (prop.ControlTypeElementKey.toLocaleLowerCase() === "content") {
                        this.content += prop.Value;
                      }
                    })
                  }
                }
              })
            }
          }
        })
      }
    })
  }
  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {

    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }

    event.preventDefault();
    let target = <HTMLAnchorElement>event.target;

    //this.router.navigate(['/', target.pathname]);
    this.router.navigate([target.pathname]);
  }

}
