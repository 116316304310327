const decodeParamsFromQuery = (queryString: string) => {
	const keyValuePairs = queryString.split('&');
	const decodedParams = {};

	keyValuePairs.forEach((keyValue) => {
		const [key, value] = keyValue.split('=');
		const decodedKey = decodeURIComponent(key);
		const decodedValue = decodeURIComponent(value);
		decodedParams[decodedKey] = decodedValue;
	});
	return decodedParams;
};

export default decodeParamsFromQuery;
