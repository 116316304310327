import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { DefaultService } from 'src/app/modules/layout/default.service';
import { MustMatch } from 'src/app/shared/helper/must-match.validator';
import { Label } from 'src/app/shared/models/Labels';
import { RegExLibrary } from 'src/app/shared/models/regex-library';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ScrollService } from '../../../../shared/service/scroll.service';
import { AuthService } from '../../auth.service';
import { List } from '../../models/account';
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	@Output() backButtonClicked = new EventEmitter<string>();
	registrationForm: UntypedFormGroup;

	emailAlreadyExisted: boolean = false;
	recaptcha_Active: boolean;
	recaptcha_V2_SiteKey: string;
	titleList: List[];
	LpAppSetting: LpAppSetting;
	@ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
	@ViewChild('langInput', { static: true }) langInput: ElementRef;

	sitecontent: SiteContentDetail;
	regExLibrary: RegExLibrary;
	label: Label;
	submitted: boolean = false;
	tickToSignUp: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private authService: AuthService,
		private router: Router,
		private commonService: DefaultService,
		private sharedService: SharedService,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService) {
	}

	get f() { return this.registrationForm.controls; }
	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		this.getApplicationSettings();
		this.getTitles();
	}
	getTitles() {
		this.authService.getTitles().subscribe(res => {
			if (res && res.Titles && res.Titles.List) {
				this.titleList = res.Titles.List;
			}
		})
	}
	getApplicationSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
		if (this.LpAppSetting) {
			this.recaptcha_Active = this.LpAppSetting.Recaptcha_Active;
			this.recaptcha_V2_SiteKey = this.LpAppSetting.Recaptcha_V2_SiteKey;
			this.initialForm();
		} 
		if (this.LpAppSetting && this.LpAppSetting.ThemeName) {
			this.tickToSignUp = this.LpAppSetting.ThemeName == "matchstickmonkey";
		}
	}
	initialForm() {
		this.submitted = false;
		this.registrationForm = this.fb.group({
			titleId: ["1"],
			firstName: ["", [Validators.required]],
			lastName: ["", [Validators.required]],
			emailAddress: ["", [Validators.required, Validators.email, Validators.pattern(this.LpAppSetting.RegexLib.Email)]],
			confirmEmailAddress: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required, Validators.minLength(5)]],
			confirmedPassword: ["", [Validators.required, Validators.minLength(5)]],
			emailOptIn: [false],
			postalMailOptIn: [false],
			recaptcha: ['', Validators.required]
		}, {
			validator: [MustMatch('emailAddress', 'confirmEmailAddress'),
			MustMatch('password', 'confirmedPassword')],

		});
	}

	submitRegisterForm() {
		this.submitted = true;

		if (this.registrationForm.invalid) {
			return;
		}
		this.emailAlreadyExisted = false;
		this.authService.register(this.registrationForm.value).subscribe(res => {
			if (res.result == "Success") {
				this.goBack();
				this.router.navigate(['account/signin']);
			} else {
				this.initialForm();
				this.emailAlreadyExisted = true;
			}

			this.scrollService.windowScrollTo(0, 0);
		},
			err => {

			})

	}
	goBack() {
		this.backButtonClicked.emit("back button clicked")
	}
	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}
	getGeneratedCompareMessage(toReplace1, toReplace2, text) {
		return text.replace('{0}', toReplace1).replace('{1}', toReplace2);
	}
}


