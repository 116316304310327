import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-videomodal',
  templateUrl: './videomodal.component.html',
  styleUrls: ['./videomodal.component.scss']
})
export class VideomodalComponent implements OnInit {

  videoSource: string;

  private inited;

  @HostListener('window:click')
   onNoClick(): void {
     if (this.inited) {
       this._mdr.close();
     }
   }

   ngAfterViewInit() {
    $('.open-in-modal').on('click',function(event){
      event.stopPropagation();     
    });
  }

  constructor(private _mdr: MatDialogRef<VideomodalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private rd: Renderer2, private router: Router) {

    this.videoSource = data.videosource;
  }

  ngOnInit() {
    this._mdr.afterOpened().subscribe(() => {
      this.inited = true;
    })
  }
  CloseDialog() {

    this._mdr.close();
  }
  
  mouseover(event) {

    const element: HTMLElement = event.target;
    if (element.nodeName === 'VIDEO') {
      event.preventDefault();
      const link = element.setAttribute("Controls", '');

    }

  }
  mouseout(event) {
    const element: HTMLElement = event.target;
    if (element.nodeName === 'VIDEO') {
      event.preventDefault();
      const link = element.removeAttribute("Controls");
    }
  }
  endsWith(value: string) {
    if (this.videoSource.endsWith(value)) {
      return true;
    }
    return false;
  }
  startsWith(value: string) {
    if (this.videoSource.startsWith(value)) {
      return true;
    }
    return false;
  }
  isContains(value: string) {
    if (this.videoSource.indexOf(value) >= 0) {
      return this.videoSource.indexOf(value);
    }
    return -1;

  }

}
