import { Component, Input, OnInit } from '@angular/core';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { AuthService } from '../../auth.service';
import { OrderDetails } from '../../models/order-details';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

	@Input() orderId = 0;

	orderInfo: OrderDetails;
	sitecontent: SiteContentDetail;
	dataToggler: boolean = false;

	constructor(
		private authService: AuthService,
		private sharedService: SharedService,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		this.authService.getWebOrderDetailsById(this.orderId).subscribe(res => {
			this.orderInfo = res;
		});
	}
}
