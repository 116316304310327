import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsFromCategoriesCriteria } from 'src/app/model/content-page-row-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { LoaderService } from '../../../shared/components/loader/loader.service';
import { DefaultService } from '../../layout/default.service';
import { Product } from '../model/algolia-product-viewmodel';
import { AlgoliaProduct, FacetedNavigationGroup, FacetedNavigationItem } from '../model/product-list';

@Component({
	selector: 'facet-filter,[facet-filter]',
	templateUrl: './facet-filter.component.html',
	styleUrls: ['./facet-filter.component.scss']
})
export class FacetFilterComponent implements OnInit {
	@Input() productsFromCategoriesCriteria: ProductsFromCategoriesCriteria;
	@Input() products: Product[];
	@Input() onFilterShow = false;
	@Input() defaultProductsOnPageLoad: AlgoliaProduct[];
	@Input() pageNumber: number;
	@Input() facetedNavigationGroups: FacetedNavigationGroup[];
	@Input() isPreloading: boolean;

	@Output() onFilterValueChanges = new EventEmitter();
	@Output() onFilterHide = new EventEmitter();
	@Output() sortItemsCall = new EventEmitter();

	isDataLoaded: Promise<boolean>;
	showFilter = true;
	innerWidth;
	showSmallScreenFilterOn: boolean = false;
	showAllGroups = false;
	showMoreFilterButton = false;
	ClearFiltersOnTop = false;
	desktopScreenWidth = 1439;
	smallDesktopScreenWidth = 1299;
	tabletScreenWidth = 1099;
	desktopLessCount = 6;
	smalldesktopLessCount = 5;
	tabletLessCount = 4;
	currentScreenWidth;
	hideDesktop = false;
	hideSmallDesktop = false;
	hideTablet = false;
	togglegroup;
	selectedSort = false;
	showSort = false;
	sortBy: string;
	sortDescending?: boolean = null;
	group;
	selectedTotal = 0;
	lpAppSetting: LpAppSetting;
	siteContent: SiteContentDetail;
	pageSize: number = 36;
	DoFacetFilter: Boolean;
	doSearchFilter;

	constructor(private activatedRoute: ActivatedRoute,
		private loaderService: LoaderService,
		private router: Router,
		private defaultService: DefaultService, private sharedService: SharedService,
		private siteContentService: SiteContentService) {
	}

	ngOnInit() {
		this.siteContent = this.siteContentService.getSiteContentData();
		this.innerWidth = window.innerWidth;
		this.currentScreenWidth = window.innerWidth;
		this.getLpAppSetting();
		this.setGroupVisibility();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.products) {
			this.setGroupVisibility();
			this.calculateTotal();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.currentScreenWidth = window.innerWidth;
		this.showMobileFilter(false);
	}

	hideFilterContent() {
		this.showFilter = false;
		this.onFilterHide.emit('true');
	}

	showFilterContent() {
		this.showFilter = true;
	}

	hideSmallScreenFilter() {
		this.showSmallScreenFilterOn = false;
	}

	showSmallScreenFilter() {
		this.showSmallScreenFilterOn = true;
	}

	hideShowFilter(status) {

	}

	showMoreFilter(togglegroup) {
		this.showAllGroups = !this.showAllGroups;
		this.setGroupVisibility();
		this.toggleFilterGrop(togglegroup);
	}

	FilterProductByFacets() {
		this.loaderService.isLoading.next(true);
		this.DoFacetFilter = false;
		this.onFilterValueChanges.emit();
	}

	setFilter(groupItem, group) {
		if (this.doSearchFilter) {
			clearTimeout(this.doSearchFilter);
			this.doSearchFilter = null;
		}

		this.doSearchFilter = setTimeout(() => { this.FilterProductByFacets() }, 1000);

		if (group && group.Key === "combifilters") {
			this.CombineSizeFilterSelected(groupItem, group);
			return;
		}

		if (group) {
			if (!group.SelectedFilter) {
				group.SelectedFilter = 0;
			}
			if (groupItem) {
				groupItem.IsSelected = !groupItem.IsSelected
				if (groupItem.IsSelected) {

					group.SelectedFilter++;
				} else {

					group.SelectedFilter--;
				}
				this.AddFilterKeyValueToQueryParam(groupItem.IsSelected, group.Key, groupItem.Id)
			}
		}
	}

	CombineSizeFilterSelected(groupItem: FacetedNavigationItem, group: FacetedNavigationGroup) {

		let combinedSizeFilterKeys;
		let comFilterStr = "";

		if (this.productsFromCategoriesCriteria.CombinedSizeFiltersStr) {
			comFilterStr = this.productsFromCategoriesCriteria.CombinedSizeFiltersStr;
			combinedSizeFilterKeys = comFilterStr.split(',');
		}

		if (group) {
			if (!group.SelectedFilter) {
				group.SelectedFilter = 0;
			}

			if (combinedSizeFilterKeys && combinedSizeFilterKeys.length > 0) {

				if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {

					let isExist = false;

					for (var i = 0; i < this.facetedNavigationGroups.length; i++) {

						if (this.facetedNavigationGroups[i]) {
							if (combinedSizeFilterKeys.includes(this.facetedNavigationGroups[i].Key)) {

								if (this.facetedNavigationGroups[i].Items) {
									for (var j = 0; j <= this.facetedNavigationGroups[i].Items.length; j++) {

										if (groupItem && this.facetedNavigationGroups[i].Items[j]) {
											if (groupItem.Id === this.facetedNavigationGroups[i].Items[j].Id) {
												groupItem.IsSelected = !groupItem.IsSelected;
												this.facetedNavigationGroups[i].Items[j].IsSelected = groupItem.IsSelected;
												if (groupItem.IsSelected) {
													group.SelectedFilter++;
												} else {
													group.SelectedFilter--;
												}

												this.AddFilterKeyValueToQueryParam(groupItem.IsSelected, this.facetedNavigationGroups[i].Key
													, this.facetedNavigationGroups[i].Items[j].Id)

												isExist = true;
												break;
											}
										}
									}

									if (isExist) {
										break;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	AddFilterKeyValueToQueryParam(IsSelected: boolean, key: string, value: string): void {

		var params = this.getSearchParameters();

		if (!params) {
			params = {};
		}

		if (params) {
			if (params["page"]) {
				delete params["page"];
			}
		}

		if (IsSelected) {

			if (!params[key]) {
				params[key] = value;
			}

			else {
				var values = params[key] + "," + value;
				params[key] = values;
			}
		} else {
			if (params[key]) {
				var values = params[key];
				var itemArr = values.split(',');

				const index = itemArr.indexOf(value.toString(), 0);

				if (index > -1) {
					itemArr.splice(index, 1);
				}

				if (itemArr && itemArr.length > 0) {
					var val = "";
					for (var i = 0; i < itemArr.length; i++) {
						if (i === 0) {
							val = itemArr[i];
						} else {
							val += "," + itemArr[i];
						}
					}
					params[key] = val;
				} else {
					delete params[key];
				}
			}
		}

		this.router.navigate(
			[],
			{
				queryParams: params,
			});
	}

	setGroupVisibility() {

		var groupIndex = 0;

		if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {

			this.facetedNavigationGroups.forEach(group => {

				if (group) {
					group.SelectedFilter = group.SelectedFilter ? group.SelectedFilter : 0;
					var visible = this.showFilterGroup(group);

					if (visible) {
						groupIndex++;

						if (this.showAllGroups) {
							this.hideDesktop = false;
							this.hideSmallDesktop = false;
							this.hideTablet = false;
						} else {
							this.hideDesktop = groupIndex > this.desktopLessCount;
							this.hideSmallDesktop = groupIndex > this.smalldesktopLessCount;
							this.hideTablet = groupIndex > this.tabletLessCount;
						}
					}
				}
			})
		}

		this.showMoreFilterButton = false;

		if (this.currentScreenWidth >= this.desktopScreenWidth && (groupIndex > this.desktopLessCount)) {
			this.showMoreFilterButton = true;
		}

		if ((this.currentScreenWidth >= this.smallDesktopScreenWidth && this.currentScreenWidth <= this.desktopScreenWidth) && (groupIndex > this.smalldesktopLessCount)) {
			this.showMoreFilterButton = true;
		}

		if ((this.currentScreenWidth >= this.tabletScreenWidth && this.currentScreenWidth <= this.smallDesktopScreenWidth) && (groupIndex > this.tabletLessCount)) {
			this.showMoreFilterButton = true;
		}
	}

	showFilterGroup(group) {
		if (group) {
			return group.IsVisible;
		}

		var items = this.visibleItemsInGroup(group);

		if (items.length == 0) {
			return false;
		}

		return group.Items.some(x => { return x.IsVisible; });
	}

	visibleItemsInGroup(group) {
		return group.Items.filter(x => { return x.IsVisible && (x.IsSelected || x.IsAvailable); });
	}

	toggleFilterGrop(groupobj) {

		if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {

			this.facetedNavigationGroups.forEach(group => {
				if (!groupobj) {
					group.IsActive = false;
				} else if (groupobj && groupobj.GroupId !== group.GroupId) {
					group.IsActive = false;
				} else {
					group.IsActive = !group.IsActive;
				}
			});
		}
	}

	clearFilters(groupobj) {

		var params = this.getSearchParameters();

		if (groupobj) {
			if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {
				this.facetedNavigationGroups.forEach(group => {
					if (groupobj && groupobj.GroupId === group.GroupId) {
						group.Items.forEach(item => {
							if (item.IsSelected) {
								item.IsSelected = false;
								group.SelectedFilter = 0;
							}
						});
						if (params) {
							if (params[groupobj.Key]) {
								delete params[groupobj.Key];
							}
						}

					}
				});
			}

		} else {
			if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {
				this.facetedNavigationGroups.forEach(group => {
					group.SelectedFilter = 0;
					group.Items.forEach(item => {
						item.IsSelected = false;
					});
					if (params) {
						if (params[group.Key]) {
							delete params[group.Key];
						}
					}
				});
			}
			this.showAllGroups = false;

		}
		this.router.navigate(
			[],
			{
				queryParams: params
			});
		this.onFilterValueChanges.emit();
	}

	showSortCat() {
		if (!this.showSort) {
			this.showSort = true;
		} else {
			this.showSort = false;
		}
	}

	setSort(sortby: string, sortdescending?: boolean): void {
		this.selectedSort = !this.selectedSort;
		this.sortBy = sortby;
		this.sortDescending = sortdescending;
		this.sortItemsCall.emit([sortby, sortdescending]);
		this.showSort = false;
	}

	showMobileFilter(IsShow) {
		if (IsShow) {
			document.querySelector('body').classList.add('filter-on');
		} else {
			document.querySelector('body').classList.remove('filter-on');
		}
	}

	getSearchParameters() {
		var prmstr = window.location.search.substr(1);
		return prmstr != null && prmstr != "" ? this.transformToAssocArray(prmstr) : {};
	}

	transformToAssocArray(prmstr) {
		var params = {};
		var prmarr = prmstr.split("&");

		for (var i = 0; i < prmarr.length; i++) {
			var tmparr = prmarr[i].split("=");
			if (!params[tmparr[0]]) {
				params[tmparr[0]] = tmparr[1];
			} else {
				params[tmparr[0]] = params[tmparr[0]] + "," + tmparr[1];
			}
		}

		return params;
	}

	calculateTotal() {
		let total = 0;
		if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {
			this.facetedNavigationGroups.forEach(e => {
				total += e.SelectedFilter;
			});
			this.selectedTotal = total;
		}
	}

	getLpAppSetting() {
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();
	}
}
