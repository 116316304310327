import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appProduction]'
})
export class ProductionDirective {
 @Input() promotion = '';
 @Input() icons = '';
 @Input() showBadge = '';
 @Input() badgeTextColour = '';
 @Input() badgeText = '';
 @Input() quickViewEnabled = '';
 @Input() badgeCssClass = '';
 @Input() promoIconCssClass = '';
 @Input() promoToCssClass = [];
 @Input() promoIconTitle = '';

  
  el;
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  ngAfterViewInit(){
    this.promoIconCssClass = "";
    this.promoIconTitle = "";
    this.promoToCssClass = [];

    // Populate icon classes
    if (this.promotion) {
        this.promoToCssClass["70% off"] = "badge-70-off";
        this.promoToCssClass["50% off"] = "badge-50-off";
        this.promoToCssClass["30% off"] = "badge-30-off";
    }

    // Set icon according to promotion
    if (this.promotion && this.promoToCssClass[this.promotion]) {
        this.promoIconCssClass = this.promoToCssClass[this.promotion];
        this.promoIconTitle = this.promotion;
    }
  }
  @HostListener("click", ['$event'])
  onClick(event) {
    // this.pinImage(event,'','');
  }
}
