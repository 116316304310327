exports.AddDynanicScriptAndStyleToDOM = function AddDynanicScriptAndStyleToDOM(webpagevm) {
	try {

		if (webpagevm) {

			try {
				if (webpagevm && webpagevm.HideFromSEO) {
					var meta;
					meta = document.getElementById("robotsId");
					if (!meta) {
						meta = document.createElement('meta');
					}

					meta.name = "robots";
					meta.content = "noindex,nofollow";
					meta.setAttribute("ID", "robotsId")

					document.head.appendChild(meta);


				}
			}
			catch (ex) {

			}
			//NoIndexNoFollow start
			try {
				if (webpagevm.NoIndexNoFollow) {
					var meta;
					meta = document.getElementById("robotsValueId");
					if (!meta) {
						meta = document.createElement('meta');
					}

					meta.name = "robots";
					meta.content = "noindex,follow";
					meta.setAttribute("ID", "robotsValueId")

					document.head.appendChild(meta);


				}
			}
			catch (ex) {

			}

			//NoIndexNoFollow end
		}


	}
	catch (ex) {

	}

}
exports.AddTrackingScriptToDOM = function AddTrackingScriptToDOM(scriptContent) {
	try {

		if (scriptContent && scriptContent.length > 0) {

			var scriptExist = true;
			var scripElementNumber = 0;

			while (scriptExist) {

				var trackingScriptId = "trackingScriptId" + scripElementNumber;
				var scriptElement = document.getElementById(trackingScriptId);
				if (scriptElement) {
					scriptElement.remove();
				} else {
					scriptExist = false;
				}
				scripElementNumber++;
			}
			for (var i = 0; i < scriptContent.length; i++) {
				var script;
				var trackingScriptId = "trackingScriptId" + i;
				script = document.getElementById(trackingScriptId);
				if (script) {
					script.remove();
				}

				script = document.createElement(scriptContent[i].name);
				var content;
				if (i == 0) {
					content = `
        if(localStorage.getItem("SignedOut")==="true"){
          window.tagManagerVars={};
          localStorage.removeItem("SignedOut");
        }
        `+ scriptContent[i].content;
				} else {
					content = scriptContent[i].content;
				}

				var inlineCode = document.createTextNode(`${content}`);

				script.type = 'text/javascript';
				script.setAttribute("ID", trackingScriptId);

				script.appendChild(inlineCode);
				if (scriptContent[i].content.indexOf("<script") > -1) {
					var divtag = document.getElementById(trackingScriptId);
					if (divtag) {
						divtag.remove();
					}
					divtag = document.createElement("div");

					divtag.setAttribute("ID", trackingScriptId);
					divtag.innerHTML = scriptContent[i].content;
					document.body.appendChild(divtag);
				} else {

					document.body.appendChild(script);
				}

			}

		}

		var scriptsList = document.getElementsByTagName("script");
		if (scriptsList && scriptsList.length > 0) {
			for (var i = 0; i < scriptsList.length; i++) {
				if (scriptsList[i].src && scriptsList[i].src.indexOf("www.googletagmanager.com") > -1 && i != 0) {
					scriptsList[i].remove();
				}
			}
		}


	}
	catch (ex) {

	}

}

exports.AddScriptStyleLinks = function AddScriptStyleLinks(LpAppSetting) {

	try {
		if (LpAppSetting) {

			//FaviconPath start
			if (LpAppSetting.UserInterface.FaviconPath) {
				var link;
				var favId = "FaviconPathId"
				link = document.getElementById(favId);
				if (!link) {
					link = document.createElement('link');
				}
				link.type = 'image/png';
				link.setAttribute("rel", "shortcut icon");
				link.setAttribute("ID", favId)
				link.setAttribute("href", LpAppSetting.UserInterface.FaviconPath);
				document.head.appendChild(link);

			}
			//FaviconPath end
			//plug in start
			if (LpAppSetting.Plugin) {
				var meta;
				var pluginId = "pluginId"
				meta = document.getElementById(pluginId);
				if (!meta) {
					meta = document.createElement('meta');
				}
				var content = "app-id=" + LpAppSetting.AppId + ", app-argument=https://apps.apple.com/gb/app/hawes-and-curtis/id815542600";

				meta.setAttribute("name", "apple-itunes-app");
				meta.setAttribute("content", content);
				meta.setAttribute("ID", pluginId)

				document.head.appendChild(meta);
			}

			//plugin end
			//theme css start
			if (LpAppSetting.UserInterface.ThemeCss && LpAppSetting.UserInterface.length > 0) {
				for (var css = 0; css < LpAppSetting.UserInterface.ThemeCss.length; css++) {
					var link;
					var themecssId = "themecssId" + css;
					link = document.getElementById(themecssId);
					if (!link) {
						link = document.createElement('link');
					}
					link.type = 'text/css';
					link.setAttribute("rel", "stylesheet");
					link.setAttribute("ID", themecssId)
					link.setAttribute("href", LpAppSetting.UserInterface.ThemeCss[css]);
					document.head.appendChild(link);

				}
			}

			////theme css end
			////CustomCssUrl start
			//if (LpAppSetting.UserInterface.CustomCssUrl) {
			//	var link;
			//	var CustomCssUrlId = "CustomCssUrlId";
			//	link = document.getElementById(CustomCssUrlId);
			//	if (!link) {
			//		link = document.createElement('link');
			//	}
			//	var href = LpAppSetting.UserInterface.CustomCssUrl + "?version=" + LpAppSetting.UserInterface.BundleCacheVersioning
			//	link.type = 'text/css';
			//	link.setAttribute("rel", "stylesheet");
			//	link.setAttribute("ID", CustomCssUrlId)
			//	link.setAttribute("href", href);
			//	document.head.appendChild(link);
			//}

			//CustomCssUrl end

			//Crazy Eggs start
			if (LpAppSetting.CrazyEggsSettings.Enabled) {
				if (LpAppSetting.UserInterface.ThemeName.toLowerCase() == "hawesandcurtis2".toLowerCase()) {
					var script;
					var CrazyEggsId = "CrazyEggsId";
					script = document.getElementById(CrazyEggsId);
					if (!script) {
						script = document.createElement('script');
					}

					script.type = 'text/javascript';
					script.setAttribute("ID", CrazyEggsId);
					script.setAttribute("src", "//script.crazyegg.com/pages/scripts/0090/0710.js");
					script.setAttribute("async", "async");
					document.head.appendChild(script);

				}
				else if (LpAppSetting.UserInterface.ThemeName.toLowerCase() == "finery".toLowerCase()) {
					var script;
					var CrazyEggsId = "CrazyEggsId";
					script = document.getElementById(CrazyEggsId);
					if (!script) {
						script = document.createElement('script');
					}

					script.type = 'text/javascript';
					script.setAttribute("ID", CrazyEggsId);
					script.setAttribute("src", "//script.crazyegg.com/pages/scripts/0103/2683.js");
					script.setAttribute("async", "async");
					document.head.appendChild(script);

				}
			}
			//Crazy Eggs end
			//Pingdom start
			if (LpAppSetting.PingdomSettings.Enabled) {
				var script;
				var PingdomId = "PingdomId";
				script = document.getElementById(PingdomId);
				if (!script) {
					script = document.createElement('script');
				}

				script.type = 'text/javascript';
				script.setAttribute("ID", PingdomId);
				script.setAttribute("src", "//rum-static.pingdom.net/pa-5e21e1cb229e930008000271.js");
				script.setAttribute("async", "async");
				document.head.appendChild(script);

			}

			//Pingdom end

			////Clarity start
			//if (LpAppSetting.ClaritySettings.Enabled) {

			//	if (LpAppSetting.UserInterface.ThemeName.toLowerCase() == "hawesandcurtis2".toLowerCase()) {
			//		var script;
			//		var ClarityId = "ClarityId";
			//		script = document.getElementById(ClarityId);
			//		if (!script) {
			//			script = document.createElement('script');
			//		}
			//		var inlineCode = document.createTextNode(`(function (c, l, a, r, i, t, y) {
   //         c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
   //         t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
   //         y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
   //         })(window, document, "clarity", "script", "59uowjew5u");`);

			//		script.type = 'text/javascript';
			//		script.setAttribute("ID", ClarityId);

			//		script.appendChild(inlineCode);
			//		document.head.appendChild(script);

			//	}
			//	else if (LpAppSetting.UserInterface.ThemeName.toLowerCase() == "ghost".toLowerCase()) {
			//		var script;
			//		var ClarityId = "ClarityId";
			//		script = document.getElementById(ClarityId);
			//		if (!script) {
			//			script = document.createElement('script');
			//		}
			//		var inlineCode = document.createTextNode(`(function (c, l, a, r, i, t, y) {
   //       c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
   //       t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
   //       y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
   //      })(window, document, "clarity", "script", "5r9wjrlzfl");`);

			//		script.type = 'text/javascript';
			//		script.setAttribute("ID", ClarityId);

			//		script.appendChild(inlineCode);
			//		document.head.appendChild(script);

			//	} else if (LpAppSetting.UserInterface.ThemeName.toLowerCase() == "finery".toLowerCase()) {
			//		var script;
			//		var ClarityId = "ClarityId";
			//		script = document.getElementById(ClarityId);
			//		if (!script) {
			//			script = document.createElement('script');
			//		}
			//		var inlineCode = document.createTextNode(` (function (c, l, a, r, i, t, y) {
   //       c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
   //       t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
   //       y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
   //   })(window, document, "clarity", "script", "5i6l6t378h");`);

			//		script.type = 'text/javascript';
			//		script.setAttribute("ID", ClarityId);

			//		script.appendChild(inlineCode);
			//		document.head.appendChild(script);

			//	}

			//}
			////Clarity end
		}
	} catch (ex) {

	}



}

exports.AddPostcodeKey = function AddPostcodeKey(LpAppSetting) {
	try {
		let key = LpAppSetting.PostCodeAnywhereSecurityKey;
		var url = "//services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css?key=" + key;
		var link;//=document.createElement('style');
		link = document.getElementById("postcodestyleId");
		if (!link) {
			link = document.createElement('style');
		}
		link.type = 'text/css';
		link.setAttribute("rel", "stylesheet");
		link.setAttribute("ID", "postcodestyleId")
		link.setAttribute("href", url);
		//script.innerHTML =scriptContent;
		document.head.appendChild(link);
	}
	catch (ex) {

	}
}
exports.mmWrappershoppingScript = function mmWrappershoppingScript(LpAppSetting) {

	try {

		if (LpAppSetting) {

			var script;
			var shoppingScript = "mmWrappershoppingScript";
			script = document.getElementById(shoppingScript);
			if (script) {
				script.remove();
			}

			script = document.createElement('script');

			var inlineCode = document.createTextNode(`if (window.MentionMeFiredTags != undefined && window.MentionMeFiredTags != '') {
        var situation = 'shoppingbag';
        var implementation = 'link';
        if (window.MentionMeFiredTags[implementation + situation] == true) {
            delete window.MentionMeFiredTags[implementation + situation];
        }
    }`);

			script.type = 'text/javascript';
			script.setAttribute("ID", shoppingScript);
			script.appendChild(inlineCode);
			document.body.appendChild(script);

			var script1;
			var shoppingScript1 = "mmWrappershoppingScript1";
			script1 = document.getElementById(shoppingScript1);
			if (script1) {
				script1.remove();
			}
			script1 = document.createElement('script');
			var source1 = '';

			if (LpAppSetting.WebStoreId === 1) {

				source1 = "https://tag.mention-me.com/api/v2/refereefind/mmc5af2c81?situation=shoppingbag&locale=en_GB";
			}
			else if (LpAppSetting.WebStoreId === 2) {
				source1 = "https://tag.mention-me.com/api/v2/refereefind/mmc5af2c81?situation=shoppingbag&locale=de_DE";
			}
			else if (LpAppSetting.WebStoreId === 4) {
				source1 = "https://tag.mention-me.com/api/v2/refereefind/mmc5af2c81?situation=shoppingbag&locale=en_GB";
			}
			else if (LpAppSetting.WebStoreId === 5) {
				source1 = "https://tag.mention-me.com/api/v2/refereefind/mmc5af2c81?situation=shoppingbag&locale=en_GB";
			}
			script1.type = 'text/javascript';
			script1.setAttribute("ID", shoppingScript1);
			script1.setAttribute("src", source1);


			document.body.appendChild(script1);

			var element = document.getElementById('mmWrappershoppingbag');
			if (!(element && element.children[0])) {

				setTimeout(mmWrappershoppingScript, 1000, LpAppSetting)
			}



		}

	}
	catch (ex) {

	}

}



