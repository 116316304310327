import { Component, Input, OnInit } from '@angular/core';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import {  SiteContentDetail } from 'src/app/shared/models/siteContent';


@Component({
  selector: 'accepted-payment-methods,[accepted-payment-methods]',
  templateUrl: './accepted-payment-methods.component.html',
  styleUrls: ['./accepted-payment-methods.component.scss']
})
export class AcceptedPaymentMethodsComponent implements OnInit {
  @Input() sitecontent: SiteContentDetail;
  @Input() LpAppSetting: LpAppSetting;
  theme: string;
  url: any;

  payPalLink = "https://www.paypal.com/uk/webapps/mpp/paypal-popup";
  constructor() { }

  ngOnInit() {

    if (this.LpAppSetting) {
      this.theme = this.LpAppSetting.ThemeName;
      this.url = window.location.host;

      switch (this.LpAppSetting.DefaultWebstoreId) {
        case 2:
          this.payPalLink = "https://www.paypal.com/de/webapps/mpp/paypal-popup";
          break;
        case 4:
          this.payPalLink = "https://www.paypal.com/us/webapps/mpp/paypal-popup";
          break;
        case 5:
          this.payPalLink = "https://www.paypal.com/au/webapps/mpp/paypal-popup";
          break;
      }
    }
  }
  endsWith(){
    if(this.url){
     return this.url.endsWith(".hcstage.com");
    }
    return false;
  }
}
