import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appFullHeight]'
})
export class FullHeightDirective implements AfterViewInit {
  @Input() fullheightUse:boolean;
  method= '';
  el;
  options = {
    maxWidth: 0,
    extraSelector: ''
   };
  accordianItems = [];
  constructor(el:ElementRef,private renderer2:Renderer2) { 
    this.el = el;
  }
  ngOnInit(){
    if (!this.fullheightUse) {
      return;
  }

  if (!this.method) {
    this.method = "";
  }
    this.options = {
      maxWidth: this.method == "prodlist" ? 1099 : 0,
      extraSelector: this.method == "prodlist" ? ".sort-results-mobile" : ""
  };

}
  ngAfterViewInit(){
   
    this.setElementHeight(this.el, this.options);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setElementHeight(this.el, this.options);
  }
  setElementHeight(el, options){
    
    if (options.maxWidth != 0 && window.innerWidth > options.maxWidth) {
      // Don't set the max height
      this.renderer2.setStyle(this.el.nativeElement, 'height', 'auto');
        return;
    }

  let screenHeight = window.innerHeight;
  let headerElement = document.getElementsByClassName('site-header')[0];

  if (headerElement) {
      screenHeight -= headerElement.clientHeight;
  }
  if (screenHeight > 0) {
      this.renderer2.setStyle(this.el.nativeElement, 'height', screenHeight + "px");
  }
  }
 

}
