export enum CourierEnum {
    DPD = 1,
    RoyalMailUK = 2,
    RoyalMailUSA = 3,
    RoyalMailAUS = 4,
    RoyalMailInternational = 5,
    OCS = 6,
    HawesAndCurtis = 7,
    WNDirect = 8,
    CollectPlus = 9,
    Doddle = 10,
    RoyalMailApi = 11,
    Borderfree = 12,
    HawesAndCurtisGermany = 13,
    DPDNextDay = 14,
    DPDStandard = 15
}

export enum PaymentMethodEnum {
    Amazon= 1,
    Barclays= 2,
    EBay= 3,
    GlobalCollect= 4,      // No longer supported
    GoogleCheckout= 5,     // No longer supported
    Paypal= 6,
    Card= 7,               // No longer supported
    YesPay= 8,             // Kiosk
    SagePayServer= 9,
    Sofort= 10,
    SagePayServerToken= 11,
    Klarna= 12,
    PrePaid= 13,
    Pentagon= 14,
    Mirakl= 15,
    Borderfree= 16,
    SagePayPI= 17,
    OneIota= 18,
    AmazonMWS= 19,
    KlarnaV3= 20,
    Adyen= 21
  }