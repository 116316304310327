import { Component, Input, OnInit } from '@angular/core';
import { LargeLeftOrRightStackedVM } from 'src/app/model/content-page-row-viewmodel';

@Component({
  selector: 'app-largestackedcontroll,[app-largestackedcontroll]',
  templateUrl: './largestackedcontroll.component.html',
  styleUrls: ['./largestackedcontroll.component.scss']
})
export class LargestackedcontrollComponent implements OnInit {
  @Input() largeLeftOrRightStackedVM: LargeLeftOrRightStackedVM;

  constructor() { }

  ngOnInit() {

  }

}
