import { ProductDetails } from "../modules/product-details/models/product-detail";
import { WebPageVM } from "./webpage-viewmodel";

export interface ProductWebPageModel {
	ElementType: ElementTypeEnum;
	ProductPageViewModel: ProductDetails;
	WebPageVM: WebPageVM;
}

export enum ElementTypeEnum {
	None = 0,
	Product = 1,
	WebPage = 2,
}
