import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../environment.service';

@Injectable({
	providedIn: 'root'
})
export class ReceiptService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	getReceipt(orderId): Observable<any> {
		return this.http.get<any>(this.environmentService.environment.checkoutUrl + 'Receipt?orderId=' + orderId, this.httpOptions);
	}

	postReceipt(receiptPage): Observable<any> {
		return this.http.post<any>(this.environmentService.environment.checkoutUrl + 'Receipt', receiptPage, this.httpOptions);
	}
}
