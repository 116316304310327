import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomSizeConfirmationComponent } from './custom-size-confirmation/custom-size-confirmation.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { ProductwithbundlesComponent } from './productwithbundles/productwithbundles.component';
import { SizeselectorHandcComponent } from './sizeselector-handc/sizeselector-handc.component';
import { TrustboxComponent } from './trustbox/trustbox.component';
import { MatIconModule } from '@angular/material/icon';
import { ProductListModule } from '../product-list/product-list.module';
import { ObserversModule } from '@angular/cdk/observers';

@NgModule({
    declarations: [
        ProductdetailsComponent,
        ImageSliderComponent,
        ProductwithbundlesComponent,
        TrustboxComponent,
        CustomSizeConfirmationComponent,
    ],
    imports: [
        SharedModule,
        MatIconModule,
        ProductListModule,
        ObserversModule
    ],
    exports: [
        ProductdetailsComponent
    ]
})
export class ProductDetailModule { }
