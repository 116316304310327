import { ElementRef, HostListener, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	constructor(protected sanitizer: DomSanitizer,
		private el: ElementRef,
		private router: Router,
		private renderer: Renderer2	) {
	}

	@HostListener('click', ['$event'])
	public onClick(event) {
		if (event.target.tagName === 'A') {
			this.router.navigate([event.target.getAttribute('href')]);
			event.preventDefault();
		} else {
			return;
		}
	}

	transform(value: any, type: string = '', value2: any = ''): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {

		let content = '';

		if (value) {
			content = value;
		}

		if (value2) {
			content += value2;
		}

		if (content) {
			const doc = new DOMParser().parseFromString(content, 'text/html');

			if (doc.getElementsByTagName('a') && doc.getElementsByTagName('a').length) {

				// FIX for "doc.getElementsByTagName(...)[i].setAttribute is not a function"
				// Looks like below is not required since Banner Component uses the openModalService directly which uses the "data-open-in-modal" attribute instead of href
				//for (let i = 0; i < doc.getElementsByTagName('a').length; i++) {
				//  if (doc.getElementsByTagName('a')[i].getAttribute('data-open-in-modal')) {
				//    doc.getElementsByTagName('a')[i].setAttribute('href', '#');
				//  }
				//}

				// FIX for "Cannot read property 'innerHTML' of undefined"
				if (doc.documentElement) {
					content = doc.documentElement.innerHTML;
				}
			}

			if (content.includes('data-location')) {

				const element = doc.getElementById('testdiv');
				if (element) {
					element.parentNode.removeChild(element);
				}

				// FIX for "Cannot read property 'innerHTML' of undefined"
				if (doc.documentElement) {
					content = doc.documentElement.innerHTML;
				}

				for (let i = 0; i < doc.getElementsByTagName('script').length; i++) {

					const parentElement = document.createElement('div');
					parentElement.setAttribute('class', 'layout');

					const el = doc.getElementsByTagName('script')[i];
					const frame = document.createElement('iframe');

					frame.id = "gocertify-iframe"
					frame.src = new URL(el.getAttribute('src')).origin + el.getAttribute('data-location') + "?embed=true&amp;iframe=true";
					frame.style.cssText = "border: 0px; width: 1px; min-width: 100%; height: 946px;"
					parentElement.appendChild(frame);

					let cont = document.getElementsByClassName("main-content");

					if (cont && cont.length) {
						cont[0].appendChild(parentElement);
					}
				}

			} else if (content.includes('data-iframe')) {

				const element = doc.getElementById('testdiv');
				element.parentNode.removeChild(element);

				// FIX for "Cannot read property 'innerHTML' of undefined"
				if (doc.documentElement) {
					content = doc.documentElement.innerHTML;
				}

				for (let i = 0; i < doc.getElementsByTagName('script').length; i++) {
					const script = document.createElement('script');
					const el = doc.getElementsByTagName('script')[i];

					if (el.getAttribute('data-iframe') && el.getAttribute('src')) {
						script.src = el.getAttribute('src');
						script['data-load'] = 'connect';
						script.id = 'stb_root';
						script['data-iframe'] = el.getAttribute('data-iframe');
						document.head.appendChild(script);
					}
				}
			}

			content = content.replace(new RegExp('href="#"', 'g'), '');
			content = content.replace(new RegExp('datascrolltostick', 'g'), 'datascrollto');
			content = content.replace(new RegExp('data-scrolltostick', 'g'), 'datascrollto');
			content = content.replace(new RegExp('data-scrollto', 'g'), 'datascrollto');

			// Finds any script tags, and then recreates the script tag into the head in order to run
			for (let i = 0; i < doc.getElementsByTagName('script').length; i++) {
				const el = doc.getElementsByTagName('script')[i];

				const script = this.renderer.createElement('script');

				if (el.getAttribute('src')) {
					script.src = el.getAttribute('src');

				} else if (el.innerHTML) {
					script.innerHTML = el.innerHTML;
				}

				this.renderer.appendChild(document.head, script);
			}
		}

		switch (type) {
			case 'html':
				return this.sanitizer.bypassSecurityTrustHtml(content);
			case 'style':
				return this.sanitizer.bypassSecurityTrustStyle(content);
			case 'script':
				return this.sanitizer.bypassSecurityTrustScript(content);
			case 'url':
				return this.sanitizer.bypassSecurityTrustUrl(content);
			case 'resourceUrl':
				return this.sanitizer.bypassSecurityTrustResourceUrl(content);
			default:
				throw new Error(`Invalid safe type specified: ${content}`);
		}
	}
}
