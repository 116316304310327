import { Injectable } from "@angular/core";
import * as xo from "@attraqt/xo-js";
import { ATTRAQTSESSIONID } from "../constants/attraqt";

@Injectable({
	providedIn: "root",
})
export class AttraqtService {
	private trackerKey: string;

	constructor() {}

	initXO(key: string) {
		if (key !== this.trackerKey) {
			this.trackerKey = key;
			xo.activity.init({
				trackerKey: this.trackerKey,
			});
		}
	}

	optIn() {
		xo.activity.optIn();
	}

	optOut() {
		xo.activity.optOut();
	}

	setLoggedUser(userId: number) {
		xo.activity.addUserIdentity("ecommerceId", userId.toString());
	}

	removeLoggedOutUser() {
		xo.activity.clearUser();
	}

	sendActivity(activity: any) {
		xo.activity.send(activity);
	}

	getAttraqtSessionId(){
		return localStorage.getItem(ATTRAQTSESSIONID);
	}
}
