import { Component, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { SiteContentDetail } from "../../models/siteContent";
@Component({
    selector: "app-scrollable-table-line",
    templateUrl: "./scrollable-table-line.component.html",
    styleUrls: ["./scrollable-table-line.component.scss"],
})
export class ScrollableTableLineComponent implements OnChanges {
    @Input() row: any;
    @Input() isHeader: boolean;
    @Input() selectedSize: String;
    @Input() selectedFilter: String;
    @Input() selectedIndexs;
    @Input() selectedSizeIndex;
    @Input() selectedMeasurement;
    @Input() siteContent: SiteContentDetail;
    @Output() changeSize = new EventEmitter<any>();
    @Output() changeFilter = new EventEmitter<any>();

    firstColumn: any;
    secondaryColumns: any;
    filterClass = '';
    colValSplitValue = '';

    constructor() {}

    ngOnChanges() {
        this.setScrollableContent();
    }

    setScrollableContent( ) {
        if (this.row && this.row.Columns && this.row.Columns.length) {
            this.firstColumn = this.row.Columns[0];
            this.secondaryColumns = this.row.Columns.filter((column: any) => column.ColumnNumber != this.firstColumn.ColumnNumber)
            if (this.isHeader) {
                this.secondaryColumns.forEach((secondaryColumn: any, index: number) => {
                    let selectedClass = "";
                    if (secondaryColumn.Value == this.selectedSize  || secondaryColumn.Value.split(" ")[0].trim() == this.selectedSize.split(" ")[0].trim()) {
                        selectedClass = "selected columnhead" + index;
                    }
                    else {
                        selectedClass = "columnhead" + index;

                        if (index == 0 && !this.selectedSize) {
                            selectedClass = "selected columnhead" + index;
                        }
                        else {
                            selectedClass = "columnhead" + index;
                        }
                    }
                    secondaryColumn.selectedClass = selectedClass;
                    secondaryColumn.dispColValue = secondaryColumn.Value != null ? secondaryColumn.Value.split('|')[0] : '';
                    secondaryColumn.dispColToggleValue = secondaryColumn.ToggleValue != null ? secondaryColumn.ToggleValue.split('|')[0] : '';
                })
            }else{
                this.filterClass = "";
                let colVal = !this.firstColumn.Value ? '' : this.firstColumn.Value;
                var _colvalsplit = colVal.split('|');
                if (_colvalsplit.length > 0) {
                    colVal = _colvalsplit[0].trim();
        
                }
                if (colVal != "ImageUrl" && this.firstColumn.Value) {
        
                    var colvalleft = this.firstColumn.Value != null ? this.firstColumn.Value.split('#')[0] : '';
                    var togcolvalleft = this.firstColumn.ToggleValue != null ? this.firstColumn.ToggleValue.split('#')[0] : '';
        
                    var colsplit = colvalleft.split('|');
                    this.firstColumn.dispColValue = colvalleft;
                    this.firstColumn.dispColToggleValue = togcolvalleft;
                    if (colsplit.length > 1) {
                        this.filterClass = colsplit[1].trim();
                        this.firstColumn.dispColValue = colvalleft.split('|')[0];
                        this.firstColumn.dispColToggleValue = togcolvalleft.split('|')[0];
                    }
                    if(this.firstColumn.Value){
                        let splitColVal = this.firstColumn.Value.split('#');
                        if(splitColVal.length > 1) {
                            this.firstColumn.colValSplitValue = splitColVal[1];
                        }
                        var hovercolsplit = colVal.split('#');
                        if (hovercolsplit.length > 1 && hovercolsplit[1])
                        {
                            this.firstColumn.colValSplitValue = hovercolsplit[1];
                        }
                    }

                    this.secondaryColumns.forEach((column, index) => {
                        var selectedclass = "";
                        if (index == 0 && !this.selectedSize)
                        {
                            selectedclass = "selected column" + index;
                        }
                        else
                        {
                            if(index == this.selectedSizeIndex) {
                                selectedclass = "selected column"  + index;
                            }else {
                                selectedclass = "column" + index;
                            }
                        }
                        column.dispColValue = !column.Value ? '' : column.Value.split('|')[0];
                        column.dispColToggleValue = !column.ToggleValue ? '' : column.ToggleValue.split('|')[0];
                        column.selectedClass = selectedclass;
                    })
                }
                if(!this.isHeader && colVal != "ImageUrl") {
                    this.secondaryColumns.forEach((secondaryColumn, index) => {
                        let selectedclass = "";
                        if (index == 0 && !this.selectedSize)
                        {
                            selectedclass = "selected column" + index;
                        }
                        else
                        {
                            if(index == this.selectedSizeIndex) {
                                selectedclass = "selected column"  + index;
                            }else {
                                selectedclass = "column" + index;
                            }
                        }
                        secondaryColumn.selectedClass = selectedclass;
                        secondaryColumn.dispColValue = !secondaryColumn.Value ? '' : secondaryColumn.Value.split('|')[0];
                        secondaryColumn.dispColToggleValue = !secondaryColumn.ToggleValue ? '' : secondaryColumn.ToggleValue.split('|')[0];
            
                        secondaryColumn.novalue = secondaryColumn.dispColValue == "" ? "no_value" : "";
                        secondaryColumn.selectedClass = secondaryColumn.selectedClass + " " + secondaryColumn.novalue;
                    })
                }
            }
        }

    }
    onChangeSize(value) {
        this.changeSize.emit(value);
    }
    onChangeFilter(value) {
        this.changeFilter.emit(value);
    }
}
