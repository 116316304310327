import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteContentDetail } from '../../models/siteContent';
import * as $ from 'jquery';
import { SiteContentService } from '../../sitecontent.service';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from '../../shared.service';
@Component({
  selector: 'app-amazon-failed',
  templateUrl: './amazon-failed.component.html',
  styleUrls: ['./amazon-failed.component.scss']
})
export class AmazonFailedComponent implements OnInit {

  siteContent: SiteContentDetail;
  LpAppSetting:LpAppSetting;
  constructor(private route: ActivatedRoute, private siteContentService: SiteContentService
    ,private sharedService:SharedService) {
    $("body").removeClass();
    $("body").addClass("checkout ng-scope");
  }
  resultObj;
  ngOnInit() {
    this.getLpAppSetting();
    this.getSiteContent();
    this.route.queryParams
      .subscribe(params => {

        if (params) {


          if (!this.resultObj) {
            this.resultObj = {} as any;
          }
          this.resultObj.Error = {} as any;
          this.resultObj.Error.result = "error";
          this.resultObj.Error.statusDetail = "";
          this.resultObj.ProgressStep = 2;
          this.resultObj.ShowPaymentButton = false;
          if (params.orderId) {

            this.resultObj.Error.orderId = params.orderId;
          }
          if (params.message) {

            this.resultObj.Error.message = params.message;
            
          }
          if(params.reason){
            this.resultObj.Error.message = params.reason;
          }

        }
      }
      );
  }
  getErrorMessage(errorMessage){
    let message='';
    if(this.LpAppSetting){
      let isGermany=this.LpAppSetting.WebStoreId===2?true:false;
      switch (errorMessage.toLowerCase())
      {
          case "amazonrejected":
            message= isGermany
                  ? "Ihre Zahlung konnte nicht vorgenommen werden. Bitte verwenden Sie eine andere Bezahlmethode, um Ihre Bestellung aufzugeben."
                  : "Your payment could not be processed. Please try to place the order again using another payment method";
              break;
          case "processingfailure":
            message= isGermany
                  ? "Ihre Zahlung konnte nicht vorgenommen werden. Bitte verwenden Sie eine andere Bezahlmethode, um Ihre Bestellung aufzugeben."
                  : "Amazon could not process the transaction because of an internal processing error. Please retry your payment";
              break;
          case "invalidpaymentmethod":
            message = isGermany
                  ? "Ihre Zahlung konnte nicht vorgenommen werden. Bitte verwenden Sie eine andere Bezahlmethode, um Ihre Bestellung aufzugeben."
                  : "There were problems with the selected payment method, please select a different method";
              break;
      }
    }
    if(!message){
      message=errorMessage;
    }
   
   return message;
  }
  
  private async getLpAppSetting(): Promise<void> {
    this.LpAppSetting = await this.sharedService.getLpAppSettings();
  }

  getSiteContent() {
    if (!this.resultObj) {
      this.resultObj = {} as any;
    }

    this.siteContent = this.siteContentService.getSiteContentData();
    if (this.siteContent) {
      this.resultObj.Title = this.siteContent.PaymentFailedTitle;
      this.resultObj.Message=this.siteContent.PaymentFailedDescription.replace(/\\/gi,"");
      this.resultObj.TryAnotherPaymentOption = this.siteContent.TryAnotherPaymentOption;

    }
    if (!this.siteContent) {
      this.siteContentService.getSiteContent().subscribe(data => {
        if (data) {
          this.resultObj.Title = data.PaymentFailedTitle;
          this.resultObj.Message=data.PaymentFailedDescription.replace(/\\/gi,"");
          this.resultObj.TryAnotherPaymentOption = data.TryAnotherPaymentOption;
        }


      })
    }
  }
}
