import { Component, NgZone, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
	selector: 'loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	loading: boolean;

	constructor(private loaderService: LoaderService, public zone: NgZone) {
		this.loaderService.isLoading.subscribe((v) => {
			this.zone.run(() => {
				this.loading = v;
			})
		});
	}

	ngOnInit() { }
}
