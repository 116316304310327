import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductDetailModule } from '../product-details/product-detail.module';
import { ProductListModule } from '../product-list/product-list.module';
import { AccordianItemComponent } from './accordian-item/accordian-item.component';
import { AccordianComponent } from './accordian/accordian.component';
import { BannerComponent } from './banner/banner.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { ButtonComponent } from './button/button.component';
import { CarouselComponent } from './carousel/carousel.component';
import { Carousel2Component } from './carousel2/carousel2.component';
import { CountdownComponent } from './countdown/countdown.component';
import { FilterBannerComponent } from './filter-banner/filter-banner.component';
import { FitContentComponent } from './fit-content/fit-content.component';
import { FitchartComponent } from './fitchart/fitchart.component';
import { FitguidePopupComponent } from './fitguide-popup/fitguide-popup.component';
import { FitsizechartComponent } from './fitsizechart/fitsizechart.component';
import { FormComponent } from './form/form.component';
import { GalleryPopupComponent } from './gallery-popup/gallery-popup.component';
import { HomePageComponent } from './home-page/home-page.component';
import { IconmodelpopupComponent } from './iconmodelpopup/iconmodelpopup.component';
import { LargestackedcontrollComponent } from './largestackedcontroll/largestackedcontroll.component';
import { MenuListComponent } from './menu-list/menu-list.component';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { PromoStripBannerComponent } from './promo-strip-banner/promo-strip-banner.component';
import { RelatedProductComponent } from './related-product/related-product.component';
import { SidebysideComponent } from './sidebyside/sidebyside.component';
import { SimpleImageComponent } from './simple-image/simple-image.component';
import { StackedComponent } from './stacked/stacked.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { SubpageListComponent } from './subpage-list/subpage-list.component';
import { TextbannerComponent } from './textbanner/textbanner.component';
import { TitleContentComponent } from './title-content/title-content.component';
import { VideoComponent } from './video/video.component';
import { VideomodalComponent } from './videomodal/videomodal.component';
import { ObserversModule } from '@angular/cdk/observers';


@NgModule({
    declarations: [
        HomePageComponent,
        SidebysideComponent,
        RelatedProductComponent,
        CarouselComponent,
        BannerComponent,
        TitleContentComponent,
        Carousel2Component,
        AccordianItemComponent,
        PromoStripBannerComponent,
        StackedComponent,
        BlogPostComponent,
        SubpageListComponent,
        MenuListComponent,
        SimpleImageComponent,
        FilterBannerComponent,
        StyleGuideComponent,
        FitchartComponent,
        FitsizechartComponent,
        ModalPopupComponent,
        VideomodalComponent,
        FitguidePopupComponent,
        AccordianComponent,
        VideoComponent,
        TextbannerComponent,
        FitContentComponent,
        GalleryPopupComponent,
        IconmodelpopupComponent,
        FormComponent,
        CountdownComponent,
        ButtonComponent,
        LargestackedcontrollComponent
    ],
    imports: [
        SharedModule, ProductListModule, ProductDetailModule, MatTabsModule, MatButtonModule, MatDialogModule, NgxCaptchaModule, ObserversModule
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ], exports: [
        RelatedProductComponent
    ]
})
export class HomeModule { }
