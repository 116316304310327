import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { WebsiteContentDetail } from '../models/websiteContent';
const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root'
})
export class WebsiteService {

	websiteContentDetail: WebsiteContentDetail;

	private websiteContentDetail$: any;

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService	) {
	}

	private requestWebsites() {
		return this.httpClient.get<WebsiteContentDetail>(this.environmentService.environment.siteUrl + 'getwebsite', this.httpOptions).pipe(
			map(response => { this.websiteContentDetail = response; return response; })
		);
	}

	getWebsites(): Observable<WebsiteContentDetail> {
		this.websiteContentDetail$ = this.requestWebsites().pipe(
			shareReplay(CACHE_SIZE)
		);

		return this.websiteContentDetail$;
	}

	setWebsite() {
		this.getWebsites().subscribe(data => {
			this.websiteContentDetail = data;
		})
	}

	getWebsiteData(): WebsiteContentDetail {
		return this.websiteContentDetail;
	}
}
