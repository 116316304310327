import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {

  constructor(private _mdr: MatDialogRef<ModalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    
  }

  ngOnInit() {
  }
  CloseDialog() {
    this._mdr.close(false);
  }
}
