import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/shared/models/menuItem';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'footer-menu-list,[footer-menu-list]',
  templateUrl: './footer-menu-list.component.html',
  styleUrls: ['./footer-menu-list.component.scss']
})
export class FooterMenuListComponent implements OnInit {

  @Input() menuItems: MenuItem[];
  LpAppSetting:LpAppSetting;
  isGermany: boolean;
  numberInWords = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen'];
  classTotalInWord: string;
  activeTab: boolean = false;

  constructor(private sharedService:SharedService, private router: Router) { }

  ngOnInit() {
    this.setValues();
    this.getLpAppSetting();
  }

  private async getLpAppSetting(): Promise<void> {
    this.LpAppSetting = await this.sharedService.getLpAppSettings();
  }
  
  setValues(): void {
    if (this.menuItems) {
      this.classTotalInWord = this.numberInWords[this.menuItems.length - 1];
    }
  }

  toggleSelector(menuItem) {
    if (menuItem.Active) {
      menuItem.Active = false;
    } else {
      menuItem.Active = true;
    }
  }
  startWithHttps(url) {
    return url.startsWith("https:/");
  }
  checkNameContains(classNameAsCollection,value) {
    var isExist = false;
    if (classNameAsCollection && classNameAsCollection.length > 0) {

      for (var i = 0; i < classNameAsCollection.length; i++) {
        if (classNameAsCollection[i].indexOf(value) > -1) {
          isExist = true;
        }
      }

    }
    return isExist;
  }
  navigateMenu(url, isExternal) {
    localStorage.setItem('scrollY', window.scrollY.toString());
    localStorage.setItem('originalUrl', window.location.pathname);
    localStorage.setItem('isExternal', isExternal);
    localStorage.setItem('isScrollUpdated', 'false');

    if(!isExternal) {
      this.router.navigate([url]);
    }else {
      window.location.href = url;
    }

  }
}
