import { Directive, ElementRef, OnInit, AfterViewInit, HostListener, Renderer2, AfterContentChecked, Input } from '@angular/core';
import * as $ from 'jquery';
@Directive({
  selector: '[appFixedHeader]'
})
export class FixedHeaderDirective implements AfterViewInit {
  el;
  headerHeight;
  constructor(el:ElementRef,private renderer2:Renderer2) {
    this.el = el;
  }
  currentlyFixed;
  ngAfterViewInit(){
    this.headerHeight = this.el.nativeElement.offsetHeight;

    // Temporary fix for zero height being returned
    if (this.headerHeight === 0) {
      this.headerHeight = $(".site-header").height();
    }

    this.currentlyFixed = this.onScroll(this.headerHeight, false);
  }
  onScroll(headerH, isCurrentlyFixed) {
    let currentScroll = window.pageYOffset;

    // Expand nav bar if top of window, otherwise collapse nav bar if scrolling
    if (currentScroll > headerH && !isCurrentlyFixed) {
        this.changeFixedState(true);
        return true;
    }

    if (currentScroll <= headerH && isCurrentlyFixed) {
        this.changeFixedState(false);
        return false;
    }

    return isCurrentlyFixed;
}
  changeFixedState(fixHeader) {
    let body = document.body;

    if (fixHeader) {
      body.classList.add("fixed-header");
    } else {
      body.classList.remove("fixed-header");
    }
}
  addClass(className: string, element: any) {
    this.renderer2.addClass(element, className);
  }
  removeClass(className: string, element: any) {
    this.renderer2.removeClass(element, className);
  }
  @HostListener('window:scroll', [])
    onWindowScroll() {
       this.onScroll(this.headerHeight,this.currentlyFixed);
    }

}
