/* tslint:disable:member-ordering */
import { Directive, ElementRef, Input } from '@angular/core';
import { AppEventType } from '../enums/event.type';
import { AppEvent } from '../events/app.event.class';
import { EventQueueService } from '../events/event.queue';
import { AmazonpayService } from '../service/amazonpay.service';

@Directive({
    selector: '[appAmazonpayAddress]'
})
export class AmazonpayAddressDirective {
    @Input() sellerId: string = '';
    @Input() isReadOnly: string = '';

    el;
    constructor(el: ElementRef, private amazonService: AmazonpayService,
        private eventQueue: EventQueueService) {
        this.el = el;
    }

    ngAfterViewInit() {
        var containerId = this.el.id;

        if (containerId === undefined || containerId === "") {
            containerId = "addressBookWidgetDiv";
            this.el.nativeElement.id = containerId;
        }

        this.eventQueue.on(AppEventType.onAmazonPaymentsReady).subscribe(event => {
            var widgetVars = {
                sellerId: this.sellerId,
                scope: "profile payments:widget payments:shipping_address payments:billing_address",
                onOrderReferenceCreate: (orderReference) => {
                    this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onOrderReferenceCreate', { orderReference: orderReference.getAmazonOrderReferenceId() }));
                },
                onAddressSelect: (orderReference) => {
                    this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onAddressSelect',{}));
                },
                design: {
                    designMode: 'responsive'
                },
                onReady: (orderReference) => {
                    this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onReady', {}));
                },
                onError: (error) => {
                    this.eventQueue.dispatch(new AppEvent(AppEventType.amazonpay, 'onError', {
                        code: error.getErrorCode(),
                        message: error.getErrorMessage()
                    }));
                }
            };
            if (this.isReadOnly === "1") {
                widgetVars['displayMode'] = "Read";
            }

            return new window.OffAmazonPayments.Widgets.AddressBook(widgetVars).bind(containerId);

        });
    }
}