import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Observable } from 'rxjs/internal/Observable';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting, TrustPilotSettings, YotpoSettings } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { SizeGuideComponent } from 'src/app/shared/components/size-guide/size-guide.component';
import { CartModel } from 'src/app/shared/models/cart-model';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { WebsiteContentDetail } from 'src/app/shared/models/websiteContent';
import { CartService } from 'src/app/shared/service/cart.service';
import { WebsiteService } from 'src/app/shared/service/website.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { InitialiseProductDetailPageTracking } from '../../../../assets/shared/gtm.js';
import { goProdAttributeToggle, prodDescriptionToggle } from '../../../../assets/shared/helper_scripts.js';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { ImageTypeKey } from '../../../enums/image-type-key-enum';
import { ProductImageVM } from '../../../model/product-image-viewmodel';
import { ReviewsModalComponent } from '../../../shared/components/reviews-modal/reviews-modal.component';
import { trustpilotEnum } from '../../../shared/enums/trustpilotEnum';
import { ScrollService } from '../../../shared/service/scroll.service';
import { DefaultService } from '../../layout/default.service';
import { ProductListForView } from '../../product-list/model/product-list';
import { QuickViewComponent } from '../../product-list/quick-view/quick-view.component';
import { ImageTagAttributeEnum } from '../models/matchproduct-model';
import { Image, InventoryProduct, ProductAtrribute, ProductAttribute, ProductDetails, Property, SizesSelectList, SizeValue } from '../models/product-detail';
import { MatIconRegistry } from '@angular/material/icon';
import { ViewportService } from '../../../shared/viewport.service';
import { ScriptLocation, ScriptService } from 'src/app/shared/service/script.service';
import { Subscription } from 'rxjs';
import { CdkObserveContent } from '@angular/cdk/observers';

@Component({
	selector: '[product-details],product-details',
	templateUrl: './productdetails.component.html',
	styleUrls: ['./productdetails.component.scss'],

})
export class ProductdetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() detail: ProductDetails;
	@ViewChild('slickModal') slickModal: SlickCarouselComponent;

	showSlider: boolean = false;
	showReviewModal = false;
	// @Input() url: string;
	baseUrl: string = "";

	matDialogRef: MatDialogRef<SizeGuideComponent>;
	matDialogRef2: MatDialogRef<QuickViewComponent>;
	matDialogRef3: MatDialogRef<ReviewsModalComponent>;
	productDetails: ProductDetails;
	getProductData!: Observable<ProductDetails>;

	sizeData: SizesSelectList[];
	items: ProductImageVM[] = [];
	quantity = 1;
	currentRate = 0
	Property: Property[];
	isProductAdded = false;
	isSingleProductAdded = false;
	shirtCare: boolean = false;
	cartModel: CartModel;
	selectedSize: string;
	availableQuantity = 0;
	inventoryProduct: InventoryProduct[];
	productAttribute: ProductAtrribute[] = [];
	colorSize: SizeValue[];
	sleeveLength: SizeValue[];
	selectedColorSize: number;
	selectedSleeveLength: number;
	productData: ProductListForView;
	proData;
	selectedMultiItemSize: boolean = false;
	trustPilotSettings: TrustPilotSettings;
	yotpoSettings: YotpoSettings;
	isReviewShow: boolean = false;
	scriptContent = [];
	siteContent: SiteContentDetail;
	lpAppSetting: LpAppSetting;
	websiteContentDetail: WebsiteContentDetail;
	primaryImageUrl: string;
	priceValidUntil: string;
	trustpilotEnum: typeof trustpilotEnum = trustpilotEnum;
	hoverColour: string;
	yotpoInitialized: boolean = false;
	isscriptContentNgAfterviewinit: boolean = false;
	classlist = "fixed-header mask-hide ng-scope ";
	colourPalettes = [];
	areAllImagesResolved = false;
	ImageTypeKeyEnum: typeof ImageTypeKey = ImageTypeKey;
	deviceType: string;
	badgeText: string | null = null;
	HasYotpoReviews: boolean = false;
	subscription: Subscription;
	scriptProcessed: boolean = false;
	yotpoCheckboxId: any;
	scrolltoNavigationYotpoId:any;
	yotporeviewsubmit:boolean = false;
	modalDescription:boolean = false;
	private filterPopup: boolean = false;

	slideConfig = {
		"slidesToShow": 4,
		"slidesToScroll": 1,
		"nextArrow": "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;left: 0%;top:38%'><i></i><span></span></button>",
		"prevArrow": " <button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;right: 2.5%;;top:38%'><i></i><span></span></button>",
		"infinite": true
	};

	constructor(@Inject(DOCUMENT) document,
		private r: Renderer2,
		private route: ActivatedRoute,
		private matDialog: MatDialog,
		private _location: Location,
		private router: Router,
		private defaultService: DefaultService,
		private cartService: CartService,
		public dialog: MatDialog,
		private sanitizer: DomSanitizer,
		private titleService: Title,
		private meta: Meta,
		private siteContentService: SiteContentService,
		private sharedService: SharedService,
		private websiteService: WebsiteService,
		private scrollService: ScrollService,
		private matIconRegistry: MatIconRegistry,
		private domSanitzer: DomSanitizer,
		private viewportService: ViewportService,
		private elRef: ElementRef,
		private scriptService: ScriptService,
		@Inject(PLATFORM_ID) private platformId: any) {
		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass(this.classlist);
			router.events
				.subscribe((event: NavigationStart) => {
					if (event.navigationTrigger === 'popstate') {
						this.cartService.displayCart = false;
						this.scrollService.windowScrollTo(0, 0);
					}
				});
		}

		this.matIconRegistry.addSvgIconLiteral(
			'twitter_x',
			this.domSanitzer.bypassSecurityTrustHtml('<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>')
		);
	}
	ngOnDestroy(): void {
		this.unloadYotpoScriptFile();

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		if (this.yotpoCheckboxId) {
			clearInterval(this.yotpoCheckboxId);
		}

		if(this.scrolltoNavigationYotpoId){
			clearInterval(this.scrolltoNavigationYotpoId);
		}
	}

	ngOnInit(): void {
		this.siteContent = this.siteContentService.getSiteContentData();
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();

		if (this.lpAppSetting) {
			if (this.lpAppSetting.UserSettings.PromoLineBarHeader == "") {
				if (isPlatformBrowser(this.platformId)) {
					this.classlist += "no-promo-text ";
					$("body").removeClass();
					$("body").addClass(this.classlist);
				}
			}
		}

		this.getWebsiteData();
		this.getBaseUrl();
		/*let getItemId = history.state;*/
		this.showSlider = false;
		//let visitedRecently = JSON.parse(localStorage.getItem("recenlyViewedItemsList"));
		this.getData();

		this.scrollService.windowScrollTo(0, 0);
		this.deviceType = this.viewportService.getDeviceType();
	}

	ngAfterViewInit() {
		if (this.detail && this.detail.Product && this.detail.Product.ColourPalettes && this.detail.Product.ColourPalettes.length > 0) {
			this.colourPalettes = this.detail.Product.ColourPalettes.filter(x => x.HexCode || x.ImagePath);
		}

		this.primaryImageUrl = this.getImageUrl(this.detail.Product.PrimaryImage, ImageTypeKey.New2Medium);
		this.priceValidUntil = moment().format('yyyy-MM-DD');

		//gtm start
		let gender = '';
		if (this.detail && this.detail.Product && this.detail.Product.ProductAttributes) {
			if (this.detail.Product.ProductAttributes.length > 0) {
				this.detail.Product.ProductAttributes.forEach(item => {
					if (item.Key === "gender") {
						if (item.AttributeValues && item.AttributeValues.length > 0) {
							gender = item.AttributeValues[0].Name;
							if (gender) {
								gender = gender.toLocaleLowerCase();
							}
						}
					}
				})
			}
		}

		InitialiseProductDetailPageTracking(gender, this.detail.Product, this.detail.Breadcrumbs);
		//gtm end

		if (this.isscriptContentNgAfterviewinit && this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			this.isscriptContentNgAfterviewinit = false;
		}

		if ((window as any).yotpoWidgetsContainer) {
			this.loadYotpoScriptFile();
		}

		this.startScriptProcessing();
	}
	
	yotpoReviewWidgetTracking(){
		const mobileFiltersPopup = document.querySelector('#yotpo-mobile-filters-popup');
		if (mobileFiltersPopup && !this.filterPopup) {
			document.querySelector('#reviewContent').classList.add('content-stop-scroll');
			this.filterPopup = true;
		}

		if (!mobileFiltersPopup && this.filterPopup) {
			document.querySelector('#reviewContent').classList.remove('content-stop-scroll');
			this.filterPopup = false;
		}
	  }

	checkYotpoInitilized(){
		var yotpoStarElement = document.querySelector('.yotpo-sr-star-full');
		if (yotpoStarElement) {
			this.HasYotpoReviews = true;
		} else {
			this.HasYotpoReviews = false;
		}
	}

	loadYotpoScriptFile() {
		const scriptsToLoad = [
			'yotpoPdpWidgetScripts',
		];

		this.scriptService
			.load(
				ScriptLocation.Body,
				...scriptsToLoad
			).then((scripts) => {
			
			})
			.catch((error) => console.debug(error));
	}

	unloadYotpoScriptFile() {
		this.scriptService
			.unload(
				ScriptLocation.Body,
				'yotpoPdpWidgetScripts',
			)
			.then((data) => {
				// console.debug('script unloaded ', data);
			})
			.catch((error) => console.debug(error));
	}

	loadYotpoScript(scriptUrl) {
		(function () {
			var e = document.createElement("script");
			e.type = "text/javascript";
			e.async = true;
			e.src = scriptUrl;
			var t = document.getElementsByTagName("script")[0];
			t.parentNode.insertBefore(e, t);
		})();
	}

	startScriptProcessing() {
		this.yotpoCheckboxId = setInterval(() => {
		  this.setYotpoSchema();
		}, 1000);
	}
	
	setYotpoSchema() {
		if (!this.scriptProcessed) {
			const scriptElement = document.querySelector('.y-rich-snippet-script');
			const schemaElement = document.querySelector('section[itemscope][itemtype="http://schema.org/Product"]');

			if (scriptElement && scriptElement.textContent) {

			if (scriptElement.textContent && schemaElement) {
			  this.scriptProcessed = true;

			  const productData = JSON.parse(scriptElement.textContent);
	
			  var newDiv = document.createElement("div");
			  newDiv.setAttribute("itemprop", productData["aggregateRating"]["@type"]);
			  newDiv.setAttribute("itemscope", "");
			  newDiv.setAttribute("itemtype", "http://schema.org/" + productData["aggregateRating"]["@type"]);
	
			  newDiv.innerHTML = `
				<meta itemprop="ratingValue" content="${productData["aggregateRating"]["ratingValue"]}">
				<meta itemprop="reviewCount" content="${productData["aggregateRating"]["reviewCount"]}">
			  `;
	
			  schemaElement.appendChild(newDiv);
			  document.querySelectorAll('.y-rich-snippet-script').forEach(node => node.remove());
	
			  clearInterval(this.yotpoCheckboxId);
			}
		  }
		}
	}
	
	changeCheckboxYotpo() {
		const checkAndInitialize = () => {
			var yotpoWidget = document.querySelector('.yotpo-review-form-modal');
			if (yotpoWidget) {
				var checkbox = document.getElementById('checkboxInput');
				if (checkbox) {
					checkbox.classList.add('hc-input');
				}
				var label = document.querySelector('label[for="checkboxInput"]');
				if (label) {
					var newSpan = document.createElement('span');
					newSpan.classList.add('yotpo-checkbox', 'hc-checkbox');
					label.appendChild(newSpan);
				}

				if(!this.scrolltoNavigationYotpoId){
					this.scrolltoNavigationYotpoId = setInterval(() => {
						this.scrolltoNavigationYotpo();
					}, 1500);
				}
				
			} else {
				setTimeout(checkAndInitialize, 500);
			}
		};
		document.querySelector('#yotpo-main-widget-btn').addEventListener('click', function () {
			checkAndInitialize();
		});
	}

	scrolltoNavigationYotpo() {
		if(!this.yotporeviewsubmit && document.querySelector(".yotpo-new-review-submit")){
			document.querySelector(".yotpo-new-review-submit").addEventListener('click', function () {
				var starRatinError = document.querySelector('.yotpo-star-rating-error');
				if (starRatinError) {
					document.querySelector('.yotpo-star-rating-icons-wrapper').scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
					this.yotporeviewsubmit = true;
				}
			});
		}
		
		if (!this.modalDescription  && document.querySelector("#modalDescription > div > button")) {
			document.querySelector("#modalDescription > div > button").addEventListener('click', function () {
				const hostUrl = window.location.protocol + "//" + window.location.host;
				window.location.href = hostUrl;
			});
			this.modalDescription = true;
		}
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (event.srcElement && event.srcElement.classList && event.srcElement.classList[0] == "content-mask-out" && this.showReviewModal) {
			this.openReviewModal();
		}
	}

	getData(): void {
		if (this.detail) {
			this.storeVisitedProductId(this.detail.Product);
			this.detail = Object.assign({}, this.detail);
			this.getTrackingScript();

			if (this.detail && this.detail.MetaTitle) {
				this.setTitle(this.detail.MetaTitle);
			}

			if (this.detail && this.detail.MetaDescription) {
				this.updateDescription(this.detail.MetaDescription);
			}

			this.getTrustPilotSettings();
			this.getYotpoSettings();
			this.getProductAttributeData(this.detail);
			this.getRelatedProducts();
			this.sizeData = this.detail.Product.SizesSelectList;
			this.Property = this.detail.Product.Property;
			this.inventoryProduct = this.detail.Product.InventoryProducts;
			this.getInventoryProduct();
			let oneSizeProducts = this.detail.Product.InventoryProducts.length;
			if (oneSizeProducts == 1) {
				this.selectedSize = (this.detail.Product.InventoryProducts[0].Id).toString();
				this.availableQuantity = this.detail.Product.Qty;
			}
			this.getAllImages();
			this.badgeText = this.getBadgeText(this.detail.Product?.ProductAttributes);
		}
	}

	private async getTrustPilotSettings(): Promise<void> {
		if (!this.lpAppSetting) {
			this.lpAppSetting = await this.sharedService.getLpAppSettings();
		}
		if (this.lpAppSetting.TrustPilotSettings) {
			this.trustPilotSettings = this.lpAppSetting.TrustPilotSettings;
			const {
				TrustPilot_EnableDisplayReview: displayReviewEnabled,
				TrustPilot_WidgetBusinessUnitId: widgetBusinessUnitId,
				TrustPilot_API_Key: apiKey,
			} = this.trustPilotSettings;
			if (displayReviewEnabled) {
				this.defaultService
					.getProductReviews(
						widgetBusinessUnitId,
						apiKey,
						this.detail.Product.Code
					)
					.subscribe((result) => {
						if (
							result &&
							result.productReviews &&
							result.productReviews.length > 0
						) {
							this.isReviewShow = true;
						}
					});
			}
		}
	}

	private async getYotpoSettings(): Promise<void> {
		if (!this.lpAppSetting) {
			this.lpAppSetting = await this.sharedService.getLpAppSettings();
		}
		if (this.lpAppSetting.YotpoSettings) {
			this.yotpoSettings = this.lpAppSetting.YotpoSettings;
			const {
				Enabled: isEnabled,
				EnableDisplayReview: isEnableDisplayReview,
				ApiKey: apiKey,
			} = this.yotpoSettings;
			if (isEnabled && isEnableDisplayReview) {
				const isScriptLoaded = (url) => {
					var scripts = document.getElementsByTagName('script');
					for (var i = 0; i < scripts.length; i++) {
						if (scripts[i].src.includes(url)) {
							return true;
						}
					}
					return false;
				};
				const scriptUrl =
					'//cdn-widgetsrepository.yotpo.com/v1/loader/' + apiKey;
				if (!isScriptLoaded(scriptUrl)) {
					setTimeout(this.loadYotpoScript, 0, scriptUrl);
				}
			}
		}
	}

	goBack() {
		this._location.back();
		this.scrollService.windowScrollTo(0, 0);
	}

	goProdDescriptionToggle() {
		prodDescriptionToggle();
	}

	goProdAttributeToggle(index: number) {
		goProdAttributeToggle(index);
	}

	getWebsiteData() {
		this.websiteContentDetail = this.websiteService.getWebsiteData();
		if (!this.websiteContentDetail) {
			this.websiteService.getWebsites().subscribe(data => {
				this.websiteContentDetail = data;
			})
		}
	}

	getBaseUrl() {
		this.baseUrl = window.location.href;
	}

	getTitleContent(content: string) {
		if (!content) {
			return null;
		}
		return this.sanitizer.sanitize(SecurityContext.HTML, content);
	}

	getProductAttributeData(prod: ProductDetails) {
		this.productAttribute = [];

		try {
			if (prod && prod.Product.ProductAttributes && prod.Product.ProductAttributes) {
				let ProductAttributes = prod.Product.ProductAttributes

				for (var i = 0; i < ProductAttributes.length; i++) {
					let title = '';
					let content = '';

					if (ProductAttributes[i].AttributeValues && ProductAttributes[i].AttributeValues.length > 0) {
						let AttributeValues = ProductAttributes[i].AttributeValues;
						for (var j = 0; j < AttributeValues.length; j++) {
							if (AttributeValues[j].Control && AttributeValues[j].Control.Active) {
								if (AttributeValues[j].Control.Properties) {
									for (var m = 0; m < AttributeValues[j].Control.Properties.length; m++) {
										if (AttributeValues[j].Control.Properties[m].ControlTypeElementKey == "Title") {
											title = AttributeValues[j].Control.Properties[m].Value;
										}

										if (AttributeValues[j].Control.Properties[m].ControlTypeElementKey == "Content") {
											content = AttributeValues[j].Control.Properties[m].Value;
										}
									}
								}
							}
						}
					}

					if (title && content) {
						let attributeCon = { Title: title, Content: content, IsActive: false };
						this.productAttribute.push(attributeCon);
					}
				}
			}
		}
		catch (ex) {

		}
	}

	revieEventClicked(event) {

		this.showReviewModal = event;
		this.openReviewModal();
	}

	getImageUrl(Images, key) {

		try {
			if (Images && Images.ImageSizes && key) {
				for (var i = 0; i < Images.ImageSizes.length; i++) {
					if (Images.ImageSizes[i].Key == key || Images.ImageSizes[i].Key == ImageTypeKey.NewMedium) {
						return Images.ImageSizes[i].CdnFullUrl;
					}
				}
			}
		} catch (ex) {

		}
	}

	setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	}

	updateDescription(desc: string) {
		this.meta.updateTag({ name: 'description', content: desc })
	}

	setBundleProduct() {
		if (this.detail.BundleProductItems && this.detail.IsBundledItem) {
			let bundleProductIds = [];
			this.detail.BundleProductItems.forEach(x => {
				bundleProductIds.push(x.ProductId);

			});
			localStorage.setItem("bundleProductIds", JSON.stringify(bundleProductIds));
		}
		else {
			localStorage.setItem("bundleProductIds", null);
		}
	}

	getAllImages() {
		const images: Image[] = this.detail.Product.Images;
		this.items = [];

		if (images?.length) {

			// Mix and Match Images
			images.forEach((image, index) => {
				if (image.AttributeValues.length > 0) {
					image.AttributeValues.forEach((element) => {
						if (element.Name === ImageTagAttributeEnum.MixAndMatch || element.Name === ImageTagAttributeEnum.MixAndMatchMain) {
							images.splice(index, 2);
						}
					});
				}
			});

			// Regular Images
			images.forEach((image) => {

				let thumbImage = null;
				let normalImage = null;
				let zoomImage = null;

				const { ImageSizes: imageSizes, AltText: altText } = image;

				imageSizes.forEach((imageSize) => {

					// Hawes and Curtis specific Image Keys
					if (imageSize.Key === ImageTypeKey.New2Thumbnail) {
						thumbImage = imageSize.CdnFullUrl;
					}

					if (imageSize.Key === ImageTypeKey.New2Large) {
						normalImage = imageSize.CdnFullUrl;
					}

					if (imageSize.Key === ImageTypeKey.New2Zoom) {
						zoomImage = imageSize.CdnFullUrl;
					}
				});

				if (thumbImage && normalImage && zoomImage) {
					this.items.push({
						Source: normalImage,
						ZoomSource: zoomImage,
						ThumbnailSource: thumbImage,
						Alt: altText || this.detail.Product.Name,
					});
				}
			});

			this.areAllImagesResolved = this.items.length > 0;
		}
	}

	getInventoryProduct() {

		this.colorSize = [];
		this.sleeveLength = [];
		if (this.inventoryProduct) {
			this.inventoryProduct.forEach(element => {
				if (element.Qty > 0 && element.SizeValues.length == 2) {
					element.SizeValues.forEach(size => {
						if (size.SizeTypeFilterKey === "collarsize") {
							this.colorSize.push(size);
						}
						if (size.SizeTypeFilterKey === "sleevelen") {
							this.sleeveLength.push(size)
						}
					});
				}
			})
		}
	}

	getImageSrcForRecent(data) {
		let src = [];
		let path = '';
		let value = 0;

		data.filter(item => {
			item.ImageSizes.filter(img => {
				if (img.Key === ImageTypeKey.New2Large) {
					value = value + 1;

					path = img.CdnFullUrl;
					src.push(path);
					return 1;
				}
			})

			if (value >= 2) {
				return 1;
			}
		});

		return src;
	}

	storeVisitedProductId(res) {
		let data = res;
		let imageSource: any = this.getImageSrcForRecent(res.Images);
		this.productData = {
			productId: res.ProductId,
			code: res.Code,
			name: res.Name,
			image1: imageSource[0],
			image2: imageSource[1],
			qty: res.Qty,
			retailPrice: res.RetailPrice,
			salePrice: res.SalePrice,
			icons: res.Icons,
			showBundlePrice: res.ShowBundlePrice,
			bundlePrice: res.BundlePrice,
			showCustomPriceText: res.ShowCustomPriceText,
			isVirtual: res.IsVirtual,
			promotion: res.Promotion,
			promoTxtColour: res.PromoTxtColour,
			customPriceText: res.CustomPriceText,
			hasPromotion: res.HasPromotion,
			currencyPrefix: res.CurrencyPrefix,
			productUrl: res.ProductUrl
		}

		if (data) {
			let viewedList = JSON.parse(localStorage.getItem("recenlyViewedItemsList"));
			if (viewedList) {
				viewedList.push(this.productData);
				const key = 'productId';
				viewedList = [...new Map(viewedList.map(item => [item[key], item])).values()]
				localStorage.setItem("recenlyViewedItemsList", JSON.stringify(viewedList))
			} else {
				var visitedIds = [];
				visitedIds.push(this.productData);
				localStorage.setItem("recenlyViewedItemsList", JSON.stringify(visitedIds));
			}
		}
	}

	IsOutOfStock(code: string): boolean {
		if (code) {
			if (code.toLowerCase() == 'nydmr078-n09') {
				return true;
			}
		}

		return false;
	}

	promoNameContains(promotion) {
		if (promotion) {
			let promoName = promotion.replace("*", "<br />*").replace(" - ", "<br />");
			if (promoName.indexOf('<br/>') >= 0) {
				return true;
			}
		}

		return false;
	}

	AddHtmlForNumerics(promotion) {
		let promoName = '';

		if (promotion) {
			promoName = promotion.replace("*", "<br />*").replace(" - ", "<br />");
		}

		var list = promoName.split(' ');

		if (!list) {
			return null;
		}

		var sb = '';

		for (var i = 0; i < list.length; i++) {
			sb += '<b class="numeric">' + list[i] + '</b>'

		}
		return this.sanitizer.sanitize(SecurityContext.HTML, sb);
	}

	getImageSrc(data) {
		return data[0].ImageSizes[1].CdnFullUrl;
	}

	openReviewModal() {
		if (this.showReviewModal) {
			this.showReviewModal = false;
			this.r.removeClass(document.body, 'mask-show');
			this.r.removeClass(document.body, 'hide-scroll');
		} else {
			this.showReviewModal = true;
			this.r.addClass(document.body, 'mask-show');
			this.r.addClass(document.body, 'hide-scroll');
			var content = document.getElementById('reviewContent');
			content.scrollTop = 0;

			this.changeCheckboxYotpo();
		}
	}

	getRelatedProducts() {
		if (this.detail) {

		}
	}

	getTrackingScript() {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = PageTypeDimensionEnum.ProductDetails;
		//trackingVm.ProductPageViewModel = this.detail;

		this.defaultService.GetTrackingScripts(trackingVm).subscribe(data => {
			this.scriptContent = this.sharedService.ParseScript(data);
			this.isscriptContentNgAfterviewinit = true;
			this.ngAfterViewInit();
		}, err => {

		});
	}

	toPriceDisplayToString(price, currencyPrefix) {
		if (price % 1 == 0) {
			return currencyPrefix + price;
		} else {
			return currencyPrefix + price.toFixed(2);
		}
	}

	setHoverColour(colourText) {
		this.hoverColour = colourText;
	}

	getBadgeText(productAttributes: ProductAttribute[] = []): string | null {
		let badgeKey = 'italian_mills';

		let badge = productAttributes.find(x => x.Key === badgeKey && x.Name && x.AttributeValues.length && x.AttributeValues.some(y => y.Name));

		if (!badge) {
			return null;
		}

		let { Name, AttributeValues } = badge;
		let values = AttributeValues.map(x => x.Name).join(', ');

		return `${Name} | ${values}`;
	}
}
