import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';

@Injectable({
	providedIn: 'root'
})
export class CheckoutService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient,
		private environmentService: EnvironmentService) {
	}

	getPaymentMethods(): Observable<any> {
		let isGuest = localStorage.getItem("isGuestLogin") ? true : false;
		let tab = localStorage.getItem("activeTab");
		let ccpostcode = null;

		if (tab && tab === "Tab2") {
			ccpostcode = localStorage.getItem("ccpostcode");

		}

		return this.http.get<any>(this.environmentService.environment.checkoutUrl + 'Payment?isGuest=' + isGuest + "&postcode=" + ccpostcode, this.httpOptions);
	}

	getPaymentInitialize(model: any) {
		return this.http.post<any>(this.environmentService.environment.checkoutUrl + 'Payment', model, this.httpOptions);
	}

	paypalSubmit(data) {
		return this.http.post<any>(this.environmentService.environment.checkoutUrl + 'Payment', data, this.httpOptions);
	}
}
